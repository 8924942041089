import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { get } from 'lodash';

import Icon from '../../../../common/icon/components/icon';

import ToolboxWrapper from '../../../../common/toolbox/components/tool-wrapper';

import { formConstants, inspectionPresets, presetConstants } from '../../constants/inspection-settings';

import { FEATURES } from '../../../../common/constants';
import '../../styles/toolbox.scss';

const viewModeDropdownActions = {
  view_3D: '3D-view-mode',
  view_360: '360-view-mode',
};

class ScreenToolbox extends Component {
  state = {
    activePreset: presetConstants._sd,
  };

  componentWillUnmount() {
    const { setIs3DViewModeActive } = this.props;
    // reset to 3D view
    if (setIs3DViewModeActive) {
      setIs3DViewModeActive(true);
    }
  }

  setInitialPosition = () => {
    const { viewer, inspectionSettings } = this.props;
    const cameraPosition = get(inspectionSettings, formConstants.fields.cameraPosition);
    if (!viewer) return;
    if (cameraPosition) {
      viewer.zoomToPosition({ x: cameraPosition[0], y: cameraPosition[1], z: cameraPosition[2] }, null, 500);
    } else {
      viewer.fitToScreen(0, 600);
    }
  };

  updateInspectionSettings = preset => {
    const { viewer, inspectionDetails, setInspectionDetails } = this.props;
    if (viewer && inspectionDetails) {
      const data = { ...inspectionDetails, ...inspectionPresets[preset] };

      viewer.setPointBudget(data[formConstants.fields.maxPoints] * 1000 * 1000);
      viewer.useHQ = data[formConstants.fields.HighQuality];

      setInspectionDetails(data);
      this.setState({ activePreset: preset });
    }
  };

  zoomIn = e => {
    e.stopPropagation();
    const { viewer } = this.props;
    if (viewer) viewer.zoomInOut(1);
  };

  zoomOut = e => {
    e.stopPropagation();
    const { viewer } = this.props;
    if (viewer) viewer.zoomInOut(-1);
  };

  switchViewMode = act => {
    const { images360, setIs3DViewModeActive } = this.props;
    if (images360 && setIs3DViewModeActive) {
      const activate3D = act === viewModeDropdownActions.view_3D;
      const isChanged = images360.setView360Enabled(!activate3D);
      if (isChanged) {
        setIs3DViewModeActive(activate3D);
      }
    }
  };
  render() {
    const { openSettings, images360, is3DViewModeActive, showHelp, openHelp, show360 } = this.props;

    return (
      <>
        <div className="bottom-toolbox">
          <ToolboxWrapper
            type={'merged'}
            toolbox={[
              {
                icon: props => <Icon name="zoomin" solid {...props} />,
                action: this.zoomIn,
                title: 'SCREEN_TOOLBOX.ZOOM_IN_TITLE',
              },
              {
                icon: props => {
                  return <Icon name="zoomout" solid {...props} />;
                },
                action: this.zoomOut,
                title: 'SCREEN_TOOLBOX.ZOOM_OUT_TITLE',
              },
              {
                icon: props => <Icon name="resize" solid {...props} />,
                action: this.setInitialPosition,
                isHidden: !is3DViewModeActive,
                title: 'SCREEN_TOOLBOX.RESET_POSITION_TITLE',
              },
            ]}
          />
          <ToolboxWrapper
            type={'merged'}
            toolbox={[
              {
                icon: props => (is3DViewModeActive ? <Icon name="3D" {...props} solid /> : <Icon name="360" {...props} solid />),
                action: this.switchViewMode,
                title: 'SCREEN_TOOLBOX.SET_VIEW_MODE',
                toolsMenu: [
                  {
                    id: '1',
                    icon: ({ className, ...restProps }) => <Icon name="3D" size="sm" active={is3DViewModeActive} handleHover={false} className={`${className}`} solid {...restProps} />,
                    title: 'SCREEN_TOOLBOX.SET_VIEW_MODE.3D',
                    action: viewModeDropdownActions.view_3D,
                  },
                  {
                    id: '2',
                    icon: ({ className, ...restProps }) => <Icon name="360" size="sm" active={!is3DViewModeActive} handleHover={false} className={`${className}`} solid {...restProps} />,
                    title: 'SCREEN_TOOLBOX.SET_VIEW_MODE.360',
                    action: viewModeDropdownActions.view_360,
                  },
                ],
                dropdownOffsetY: '48px',
                isHidden: !show360 || !FEATURES.settings360.visible || !images360,
              },
              {
                icon: props => <Icon name="settings" solid {...props} />,
                action: openSettings,
                title: 'SCREEN_TOOLBOX.SETTINGS',
                isHidden: typeof openSettings !== 'function',
              },
              {
                icon: props => <Icon name="help-circle" solid {...props} />,
                action: openHelp,
                title: 'SCREEN_TOOLBOX.HELP',
                isHidden: !showHelp || typeof openHelp !== 'function',
              },
            ]}
          />
        </div>
      </>
    );
  }
}

ScreenToolbox.contextTypes = {
  t: PropTypes.func.isRequired,
};

ScreenToolbox.defaultProps = {
  showHelp: false,
};

export default ScreenToolbox;
