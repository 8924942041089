import PropTypes from 'prop-types';
import Tab from '../../../../../../common/tabs/component/tab';
import Tabs from '../../../../../../common/tabs/component/tabs';

import { connect } from 'react-redux';
import AccessRenderer from '../../../../../../common/access-renderer/components/access-renderer';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../../common/permissions-constants';
import { getMeasurementLocationAnalysisGraph, getMeasurementLocationTimeSeriesGraph, saveGraphingGroup } from '../../actions/measurement-location-actions';
import { getMeasurementPointGraphDetails, getMeasurementPointTimeSeriesGraph, saveMeasurementPointGraphDetails } from '../../actions/measurement-point-actions';
import { settingsFormConstants } from '../../constants/time-series-graph-constants';
import { tabNames, toolbarItems } from '../../constants/time-series-graph-modal-constants';
import '../../styles/time-series-graph-modal.scss';
import { TIMESERIES_GRAPH_TYPE } from '../graphing-groups/graphing-groups-list';
import MeasurementReadings from './tabs/measurement-readings';
import TimeSeriesGraphConfiguration from './time-series-graph-configuration';

const TimeSeriesGraphModal = (
  {
    defaultTabKey,
    measurementPointID,
    measurementLocationID,
    getMeasurementLocationTimeSeriesGraph,
    getMeasurementPointTimeSeriesGraph,
    getMeasurementLocationAnalysisGraph,
    createdBy,
    saveGraphingGroup,
    saveMeasurementPointGraphDetails,
    getMeasurementPointGraphDetails,
    graphingGroupDetails,
    unit = '-',
    isTimeSeriesGraph = true,
  },
  { t }
) => {
  const fetchMesurementLocationSeriesGraph = (graphParams, successCallback, errorCallback) => {
    if (graphParams?.[settingsFormConstants.graphType.name] !== TIMESERIES_GRAPH_TYPE) return;

    getMeasurementLocationTimeSeriesGraph(
      measurementLocationID,
      graphParams,
      graphData => {
        successCallback && typeof successCallback === 'function' && successCallback(graphData || []);
      },
      () => {
        errorCallback && typeof errorCallback === 'function' && errorCallback();
      }
    );
  };

  const fetchMesurementLocationAnalysisGraph = (graphParams, successCallback, errorCallback) => {
    if (graphParams?.[settingsFormConstants.graphType.name] === TIMESERIES_GRAPH_TYPE) return;
    getMeasurementLocationAnalysisGraph(
      measurementLocationID,
      graphParams,
      graphData => {
        successCallback && typeof successCallback === 'function' && successCallback(graphData || []);
      },
      () => {
        errorCallback && typeof errorCallback === 'function' && errorCallback();
      }
    );
  };

  const fetchMesurementPointSeriesGraph = (graphParams, successCallback, errorCallback) => {
    getMeasurementPointTimeSeriesGraph(
      measurementPointID,
      graphParams,
      graphData => {
        successCallback && typeof successCallback === 'function' && successCallback([{ ...graphData }]);
      },
      () => {
        errorCallback && typeof errorCallback === 'function' && errorCallback();
      }
    );
  };

  const getGraphDataHandler = (graphType = TIMESERIES_GRAPH_TYPE, values, successCallback, errorCallback) => {
    if (graphType === TIMESERIES_GRAPH_TYPE) return fetchMesurementLocationSeriesGraph(values, successCallback, errorCallback);
    return fetchMesurementLocationAnalysisGraph(values, successCallback, errorCallback);
  };

  return (
    <div className="time-series-graph-modal">
      <Tabs defaultTabKey={defaultTabKey} navigationClassName="time-series-graph__tabs" onChange={() => null}>
        <Tab title={toolbarItems[tabNames.graph].label} tabKey={tabNames.graph}>
          {!!measurementLocationID && (
            <AccessRenderer
              visibleFor={[PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].locationEdit.name]}
              id={createdBy}
              ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].locationCreate.name}
            >
              {({ hasAccess: canSave }) => (
                <TimeSeriesGraphConfiguration
                  queryItem={measurementLocationID}
                  getGraphData={getGraphDataHandler}
                  saveSettings={(queryItem, settings, successCallback, errorCallback) => saveGraphingGroup({ ...settings, MeasurementLocationID: queryItem }, successCallback, errorCallback)}
                  canSave={canSave}
                  graphingGroupDetails={graphingGroupDetails}
                  unit={unit}
                  isTimeSeriesGraph={isTimeSeriesGraph}
                />
              )}
            </AccessRenderer>
          )}
          {!!measurementPointID && (
            <AccessRenderer
              visibleFor={[PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].pointEdit.name]}
              id={createdBy}
              ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].pointCreate.name}
            >
              {({ hasAccess: canSave }) => (
                <TimeSeriesGraphConfiguration
                  queryItem={measurementPointID}
                  getGraphData={fetchMesurementPointSeriesGraph}
                  showGraphingGroup={false}
                  saveSettings={(queryItem, settings, successCallback, errorCallback) =>
                    saveMeasurementPointGraphDetails({ ...settings, MeasurementPointID: queryItem }, successCallback, errorCallback)
                  }
                  canSave={canSave}
                  getMeasurementPointGraphDetails={getMeasurementPointGraphDetails}
                  graphingGroupDetails={graphingGroupDetails}
                  unit={unit}
                  isTimeSeriesGraph={isTimeSeriesGraph}
                />
              )}
            </AccessRenderer>
          )}
        </Tab>
        <Tab title={toolbarItems[tabNames.measurementReadings].label} tabKey={tabNames.measurementReadings} visible={!!measurementPointID}>
          <MeasurementReadings measurementPointID={measurementPointID} />
        </Tab>
        <Tab title={toolbarItems[tabNames.predictiveAnalyticSettings].label} tabKey={tabNames.predictiveAnalyticSettings} disabled={true} customContent={<div className="soon">{t('SOON')}</div>}></Tab>
      </Tabs>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  getMeasurementLocationTimeSeriesGraph: (measurementLocationId, graphSettings, successCallback, errorCallback) =>
    dispatch(getMeasurementLocationTimeSeriesGraph(measurementLocationId, graphSettings, successCallback, errorCallback)),
  getMeasurementPointTimeSeriesGraph: (measurementPointId, graphSettings, successCallback, errorCallback) =>
    dispatch(getMeasurementPointTimeSeriesGraph(measurementPointId, graphSettings, successCallback, errorCallback)),
  saveGraphingGroup: (data, successCallback, errorCallback) => dispatch(saveGraphingGroup(data, successCallback, errorCallback)),
  saveMeasurementPointGraphDetails: (data, successCallback, errorCallback) => dispatch(saveMeasurementPointGraphDetails(data, successCallback, errorCallback)),
  getMeasurementPointGraphDetails: (measurementPointId, successCallback, errorCallback) => dispatch(getMeasurementPointGraphDetails(measurementPointId, successCallback, errorCallback)),
  getMeasurementLocationAnalysisGraph: (measurementLocationId, graphSettings, successCallback, errorCallback) =>
    dispatch(getMeasurementLocationAnalysisGraph(measurementLocationId, graphSettings, successCallback, errorCallback)),
});

TimeSeriesGraphModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(TimeSeriesGraphModal);
