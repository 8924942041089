import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { debounce, findIndex, isEmpty, isEqual } from 'lodash';
import TableTemplate from '../../../../../../common/double-table/components/table-template';
import EmptyState from '../../../../../../common/empty-state-v2/components/empty-state';
import CustomCheckBox from '../../../../../../common/form/components/custom-checkbox';
import SearchInput from '../../../../../../common/input/components/search-input';
import LoadMore from '../../../../../../common/load-more/components/load-more';
import ReducerHelpers from '../../../../../../common/reducer-helpers';
import { reorderDirection } from '../../../right-toolbar/readings-and-gauges/constants/measurement-point-obeservations-constant';
import { MAXIMUM_MEASUREMENT_POINTS_TIME_SERIES } from '../../constants/measurement-location-constants';
import { addMeasurementPointsTableConfig, filterParams, formatAddMeasurementPointsTableCells, formConstants } from '../../constants/measurement-point-constants';
import '../../styles/add-measurement-point-field.scss';

const AddMeasurementPointField = (
  {
    readonly,
    items,
    input,
    filters,
    searchApiRequired,
    loadMoreOnClick,
    handleAPISearch,
    isLoading,
    keepDropdownVisible,
    meta,
    handleChange,
    showError = true,
    measurementLocationId,
    maximumSelectionOfMPs = MAXIMUM_MEASUREMENT_POINTS_TIME_SERIES,
    allowMeasurementPointReordering,
  },
  { t }
) => {
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    if (searchApiRequired && !isEqual(filteredItems, items)) {
      setFilteredItems(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const handleSearch = value => {
    const lowerCaseValue = value.toLowerCase();

    if (searchApiRequired) {
      handleAPISearch(lowerCaseValue);
    } else {
      const filtered = value ? items.filter(item => Object.values(formConstants.fields).some(key => item[key].toString().toLowerCase().includes(lowerCaseValue))) : items;
      setFilteredItems(filtered);
    }
  };

  const handleSearchDebounce = debounce(handleSearch, 300);

  const handleSelect = (item, index) => {
    const selectedItems = input?.value;
    if (isEmpty(item)) {
      return;
    }
    if ((selectedItems || []).findIndex(mp => mp?.[formConstants.fields.id] === item[formConstants.fields.id]) > -1) {
      const newData = ReducerHelpers.removeItemByProp(selectedItems, item, formConstants.fields.id);
      input?.onChange([...newData]);
      handleChange && handleChange(item, [...newData]);
    } else {
      const newData = [...selectedItems, item];
      input?.onChange(newData);
      handleChange && handleChange(item, newData);
    }
  };

  const handleReorder = (e, direction, index, _id) => {
    e.stopPropagation();
    e.preventDefault();

    // Early return if trying to move first item up or last item down
    if (direction === reorderDirection.up && index === 0) return;
    if (direction === reorderDirection.down && index === input?.value?.length - 1) return;

    const selectedItems = input?.value;
    if (isEmpty(selectedItems)) return;

    // Calculate new index based on direction
    const newIndex = direction === reorderDirection.up ? index - 1 : index + 1;

    // Create new array with reordered items
    const reorderedItems = [...selectedItems];
    const [movedItem] = reorderedItems.splice(index, 1);
    reorderedItems.splice(newIndex, 0, movedItem);

    // Update indices for all items
    const updatedItems = reorderedItems.map((item, idx) => ({
      ...item,
      index: idx,
    }));

    // Update UI state immediately for better UX, which will also trigger a re-render and re-fetch the graph data
    input?.onChange(updatedItems);
  };

  const handleInputFocus = () => {
    if (!filteredItems || !filteredItems.length) {
      if (searchApiRequired) {
        handleAPISearch('');
      } else {
        setFilteredItems(items);
      }
    }
  };

  const emptyStateProps = {
    emptyStateText: t('GRAPHING_GROUP_DETAILS.FORM_MEASUREMENT_POINTS.EMPTY_STATE'),
    showButton: false,
    transparent: true,
    buttonAction: () => null,
    buttonDisabled: true,
  };

  let measurementLocations = useRef({}).current;
  let showHeaderSections = useRef({
    currentLocation: null,
    otherLocations: null,
  }).current;

  return (
    <div className="add-measurement-point-field">
      <div className="input-with-dropdown">
        <SearchInput
          onChange={e => handleSearchDebounce(e.target.value)}
          label={'GRAPHING_GROUP_DETAILS.FORM_MEASUREMENT_POINTS'}
          grayLabel={t('MAX_SELECTED', { number: maximumSelectionOfMPs })}
          placeholder={t('PROJECT.SEARCH.PLACEHOLDER')}
          includeDropdown={true}
          items={filteredItems || []}
          emptyStateLabel={'GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.EMPTY_STATE'}
          isDropdownDataLoading={isLoading}
          onInputFocus={handleInputFocus}
          keepDropdownVisible={keepDropdownVisible}
          shouldRenderPortal={true}
          portalProps={{
            id: 'add-mp-point-field',
          }}
          renderItem={(item, index) => {
            const isSelected = findIndex(input?.value, { [formConstants.fields.id]: item[formConstants.fields.id] }) > -1;
            if (index === 0) {
              // reset list state on each rerender
              measurementLocations = {};
              showHeaderSections = {
                currentLocation: false,
                otherLocations: false,
              };
            }

            // init row state
            let showMeasurementLocationName = false;
            let headerTitle = null;

            if (!measurementLocations[item[formConstants.fields.measurementLocationID]]) {
              measurementLocations[item[formConstants.fields.measurementLocationID]] = item[formConstants.fields.measurementLocationName];

              if (measurementLocationId === item[formConstants.fields.measurementLocationID] && !showHeaderSections.currentLocation) {
                showHeaderSections.currentLocation = true;
                headerTitle = t('GRAPHING_GROUP_DETAILS.FORM_MEASUREMENT_POINTS.SECTION_HEADER_MPS_IN_CURRENT_ML');
              } else if (measurementLocationId !== item[formConstants.fields.measurementLocationID] && !showHeaderSections.otherLocations) {
                showHeaderSections.otherLocations = true;
                headerTitle = t('GRAPHING_GROUP_DETAILS.FORM_MEASUREMENT_POINTS.SECTION_HEADER_MPS_IN_OTHER_MLS');
              }

              showMeasurementLocationName = true;
            }

            return (
              <div>
                {headerTitle && (
                  <div className="section-header-title">
                    <p className="f-secondary-dark">{headerTitle}</p>
                  </div>
                )}
                <div
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!readonly) {
                      handleSelect(item);
                    }
                  }}
                  className={`dropdown-render-item-container ${isSelected ? 'selected' : ''} ${readonly ? 'disabled' : ''}`}
                  key={`measurement-point-${item[formConstants.fields.id]}`}
                >
                  <div id={`row-${index}`} className="measurement-point-name">
                    {index === 0 && <p className="f-secondary-dark user-title bold">{t('GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.NAME')}</p>}
                    {showMeasurementLocationName && (
                      <div className="measurement-location-name">
                        <p className="f-secondary-dark">{item[formConstants.fields.measurementLocationName]}</p>
                      </div>
                    )}
                    <div className="measurement-point-name-value">
                      <div>
                        <CustomCheckBox
                          meta={{}}
                          input={{
                            value: isSelected,
                            onChange: () => (readonly ? null : handleSelect(item)),
                          }}
                        />
                      </div>
                      <p className="f-primary light-bold module-name name" title={item[formConstants.fields.name]}>
                        {item[formConstants.fields.name]}
                      </p>
                    </div>
                  </div>
                  <div className="measurement-point-unit">
                    {index === 0 && <p className="f-secondary-dark user-title bold">{t('GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.UNIT')}</p>}
                    {showMeasurementLocationName && <div className="measurement-location-name"></div>}
                    <p className="f-primary light-bold module-name" title={item[formConstants.fields.unit] || '-'}>
                      {item[formConstants.fields.unit] || '-'}
                    </p>
                  </div>
                  <div className="measurement-point-exp-range">
                    {index === 0 && <p className="f-secondary-dark user-title bold">{t('GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.EXPECTED_RANGE')}</p>}
                    {showMeasurementLocationName && <div className="measurement-location-name"></div>}
                    <p className="f-primary light-bold module-name" title={item[formConstants.fields.expectedRange] || '-'}>
                      {item[formConstants.fields.expectedRange] || '-'}
                    </p>
                  </div>
                </div>
                {searchApiRequired && filteredItems?.length > 0 && filteredItems.length - index === 1 && filters[filterParams.hasNext] && (
                  <LoadMore
                    disabled={!filters[filterParams.hasNext]}
                    label="COMPONENT_HISTORY.LOAD_MORE"
                    showButton
                    buttonVariant="success-outline"
                    buttonWidth="sm"
                    onClick={loadMoreOnClick}
                    showTotalItems={false}
                  />
                )}
              </div>
            );
          }}
          isRequired
        />
        {showError && <div className={`error-message${meta?.error && meta?.touched ? '--visible' : ''}`}>{meta?.error && t(meta.error)}</div>}
        <div id="add-mp-point-field" />
      </div>
      <div className="added-items">
        <p className="f-secondary-dark">{t('CREATE_WORKORDER_FORM.ADDED_COMPONENTS', { number: input?.value ? input?.value.length : 0 })}</p>
        <div className="added-items-list">
          <TableTemplate
            data={input?.value || []}
            handleFormatCells={(
              value,
              type,
              searchTerm,
              row,
              _onDropdownClick,
              t,
              _pointerDotID,
              _user,
              _onToggleClick,
              _onDeleteRow,
              _statusData,
              _handleChangeStatus,
              _onLinkFileClick,
              _selectedItem,
              _cell,
              _hasLinkDMSFilePermissions,
              _onRowClick,
              _toggleModalAction,
              _onReorderClick,
              rowIndex
            ) =>
              formatAddMeasurementPointsTableCells(value, type, searchTerm, row, t, rowIndex, readonly, {
                removeMeasurementPoint: (_e, item) => handleSelect(item),
                allowMeasurementPointReordering,
                reorderMeasurementPoint: (e, direction, index, id) => handleReorder(e, direction, index, id),
                totalNumberOfMeasurementPoints: input?.value?.length,
              })
            }
            tableConfig={addMeasurementPointsTableConfig(allowMeasurementPointReordering)}
            filters={{}}
            isLoading={false}
            onRowClick={() => null}
            showSearchBar={false}
            showInfo={false}
            emptyStateComponent={() => <EmptyState {...emptyStateProps} />}
          />
        </div>
      </div>
    </div>
  );
};

AddMeasurementPointField.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default AddMeasurementPointField;
