import { Field } from 'redux-form';
import { createNumberMask } from 'redux-form-input-masks';
import CustomInput from '../../../../../common/form/components/input';
import ToggleField from '../../../../../common/form/components/toggle-field';
import TruncatedText from '../../../../../common/form/components/truncated-text';
import Icon from '../../../../../common/icon/components/icon';
import { measurementTypes } from '../../../constants/constants';
import { reorderDirection } from '../../right-toolbar/readings-and-gauges/constants/measurement-point-obeservations-constant';
import { formConstants as graphFormConstants } from './time-series-graph-constants';

export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    code: 'Code',
    unit: 'Unit',
    expectedRange: 'ExpectedRange',
    color: 'Colour',
    description: 'Description',
    points: 'Geometry.coordinates',
    camPosition: 'CameraPosition.coordinates',
    componentId: 'ComponentID',
    alarms: 'Alarms',
    scaleFactor: 'ScaleFactor',
    inverted: 'Inverted',
    measurementLocationID: 'MeasurementLocationID',
    measurementLocationName: 'MeasurementLocationName',
    readingsAndGaugesGroupName: 'ReadingsAndGaugesGroupName',
    measurementPointUnit: 'MeasurementPointUnit',
    questionMeasurementPointAlarm: 'QuestionMeasurementPointAlarm',
    colour: 'Colour',
    measurementLocationGeometry: 'MeasurementLocationGeometry',
    measurementLocationCameraPosition: 'MeasurementLocationCameraPosition',
    measurementPointGeometry: 'MeasurementPointGeometry',
    measurementPointCameraPosition: 'MeasurementPointCameraPosition',
    coordinates: 'coordinates',
    visible: 'Visible',
    cameraPosition: 'CameraPosition',
    geometry: 'Geometry',
    systemType: 'SystemType',
    lastReadingDate: 'LastReadingDate',
    lastReadingValue: 'LastReadingValue',
    measurementPointComponents: 'MeasurementPointComponents',
    measurementPointColour: 'MeasurementPointColour',
    measurementLocationColour: 'MeasurementLocationColour',
    measurementPointId: 'MeasurementPointID',
    index: 'index',
  },
};

export const filterParams = {
  searchText: 'SearchText',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
  projectID: 'ProjectID',
  measurementLocationID: 'MeasurementLocationID',
  measurementLocationSelectedId: 'MeasurementLocationSelectedID',
};

export const sortDirections = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const defaultFilter = {
  [filterParams.searchText]: '',
  [filterParams.lastSeen]: 0,
  [filterParams.perPage]: 20,
  [filterParams.totalItems]: 0,
  [filterParams.hasNext]: false,
  [filterParams.sortDirection]: sortDirections.DESC,
  [filterParams.sortByColumn]: formConstants.fields.id,
};

export const DefaultMeasurementPoint = camPos => ({
  Name: 'New Measurement Point',
  SystemType: measurementTypes.rgMeasurementPoint,
  Geometry: {
    type: 'Point',
    coordinates: [],
  },
  CameraPosition: camPos || {
    coordinates: [0, 0, 0],
  },
});

export const steps = {
  firstStep: 1,
  secondStep: 2,
};

export const addMeasurementPointsTableConfig = allowMeasurementPointReordering => {
  // allowMeasurementPointReordering reduces the width of the name column by enough percentage to fit the actions container
  return [
    {
      title: 'GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.NAME',
      key: formConstants.fields.name,
      type: formConstants.fields.name,
      class: allowMeasurementPointReordering ? 'name-column reorder-enabled' : 'name-column',
      isSort: false,
    },
    {
      title: 'GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.UNIT',
      key: formConstants.fields.unit,
      type: formConstants.fields.unit,
      isSort: false,
      class: 'unit-column',
    },
    {
      title: 'GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.EXPECTED_RANGE',
      key: formConstants.fields.expectedRange,
      type: formConstants.fields.expectedRange,
      isSort: false,
      class: 'expected-range-column',
    },
    {
      title: 'GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.SCALE_FACTOR',
      key: formConstants.fields.scaleFactor,
      type: formConstants.fields.scaleFactor,
      isSort: false,
      class: 'scale-factor-column',
      isInfoTooltip: true,
      infoTooltipLabel: 'INFO.SCALABILITY_FACTOR',
    },
    {
      title: 'GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.INVERTED',
      key: formConstants.fields.inverted,
      type: formConstants.fields.inverted,
      isSort: false,
      class: 'inverted-column',
      isInfoTooltip: true,
      infoTooltipLabel: 'INFO.INVERT',
    },
    {
      title: '',
      key: 'actions',
      type: 'actions',
      class: 'action-column',
    },
  ];
};

export const formatAddMeasurementPointsTableCells = (value, type, _searchTerm, row, t, rowIndex, readonly, restProps) => {
  const { removeMeasurementPoint, allowMeasurementPointReordering, reorderMeasurementPoint, totalNumberOfMeasurementPoints } = restProps;
  const numberMask = createNumberMask({
    decimalPlaces: 0,
    allowNegative: false,
    allowEmpty: true,
  });
  if (type === formConstants.fields.name) {
    return <TruncatedText className="title-col f-primary name" text={value} maxChar={50} />;
  } else if (type === 'actions') {
    return (
      <div className={`actions-container ${allowMeasurementPointReordering ? 'reorder-enabled' : ''}`}>
        {allowMeasurementPointReordering && (
          <>
            <Icon name="arrow-up" size="sm" onClick={e => reorderMeasurementPoint(e, reorderDirection.up, row.index, row[formConstants.fields.id])} disabled={row.index === 0} />
            <Icon
              name="arrow-down"
              size="sm"
              onClick={e => reorderMeasurementPoint(e, reorderDirection.down, row.index, row[formConstants.fields.id])}
              disabled={row.index === totalNumberOfMeasurementPoints - 1}
            />
          </>
        )}
        <Icon name="close" size="sm" onClick={e => removeMeasurementPoint(e, row)} />
      </div>
    );
  } else if (type === formConstants.fields.scaleFactor) {
    return (
      <Field
        id={`${graphFormConstants.measurementPoints}[${rowIndex}].${formConstants.fields.scaleFactor}`}
        name={`${graphFormConstants.measurementPoints}[${rowIndex}].${formConstants.fields.scaleFactor}`}
        component={CustomInput}
        disabled={readonly}
        placeholder={t('GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.SCALE_FACTOR_PLACEHOLDER')}
        type="tel"
        size="sm"
        withHiddenError
        {...numberMask}
      />
    );
  } else if (type === formConstants.fields.inverted) {
    return (
      <Field
        id={`${graphFormConstants.measurementPoints}[${rowIndex}].${formConstants.fields.inverted}`}
        name={`${graphFormConstants.measurementPoints}[${rowIndex}].${formConstants.fields.inverted}`}
        component={ToggleField}
        inline={false}
      />
    );
  } else if (type === formConstants.fields.unit) {
    return <TruncatedText className="f-primary unit" text={value} maxChar={10} />;
  } else if (type === formConstants.fields.expectedRange) {
    return <TruncatedText className="f-primary expected-range" text={value} maxChar={30} />;
  } else {
    return <TruncatedText className="f-primary" text={value} maxChar={50} />;
  }
};

// CP linked measurement Points
export const selectedMeasurementPointsTableConfig = [
  {
    title: 'ID',
    key: formConstants.fields.id,
    type: formConstants.fields.id,
    class: 'linked-mp-id-column',
    isSort: true,
  },
  {
    title: 'GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.NAME',
    key: formConstants.fields.name,
    type: formConstants.fields.name,
    class: 'linked-mp-name-column',
    isSort: true,
  },
  {
    title: '',
    key: 'actions',
    type: 'actions',
    class: 'linked-mp-action-column',
  },
];

export const addedMeasurementPointsFilterParams = {
  searchText: 'SearchText',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
  sectionID: 'SectionID',
};

export const addedMeasurementPointsDefaultFilter = {
  [filterParams.searchText]: '',
  [filterParams.lastSeen]: 0,
  [filterParams.perPage]: 20,
  [filterParams.totalItems]: 0,
  [filterParams.hasNext]: false,
  [filterParams.sortDirection]: sortDirections.ASC,
};
