import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import DateRangePickerField from '../../../../../../common/form/components/date-range-picker-field';
import CustomMultiSelectV2 from '../../../../../../common/form/components/multiselect-v2';
import CustomSelect from '../../../../../../common/form/components/select';
import Toggle from '../../../../../../common/form/components/toggle-field';
import Icon from '../../../../../../common/icon/components/icon';
import {
  aggregationFields,
  aggregationsDropdownItemsInfo,
  chunkFields,
  settingsFormConstants as formConstants,
  getDefaultChunkDateRanges,
  formConstants as graphingGroupFields,
  timePeriodFields,
} from '../../constants/time-series-graph-constants';
import '../../styles/graphing-group-details-form.scss';
import TimePeriodField from './time-period-field';

const GraphingGroupSettingsFormFields = ({ readonly, aggregationList, chunks, timePeriodsList, formValues, changeField, isTimeSeriesGraph }, { t }) => {
  const handleChunkChange = useCallback(
    value => {
      if (!isTimeSeriesGraph) {
        const defaultDateRanges = getDefaultChunkDateRanges(value);
        changeField(formConstants.dateRanges.name, [defaultDateRanges]);
      }
      changeField(formConstants.chunk.name, value);
    },
    [changeField, isTimeSeriesGraph]
  );

  return (
    <div className="graphing-group-details-form" id="graphing-group-details-form">
      <div className="double">
        <div className="split-input">
          <Field
            id={formConstants.aggregation.id}
            name={formConstants.aggregation.name}
            label={formConstants.aggregation.label}
            placeholder={t(formConstants.aggregation.placeholder)}
            disabled={readonly}
            component={CustomMultiSelectV2}
            data={aggregationList}
            textField={aggregationFields.value}
            valueField={aggregationFields.key}
            size="lg"
            isEnhancedDesignCustomProp
            isRequired
            groupDropdownItems
            showDropdownItemInfoIcon
            dropdownItemsInfo={aggregationsDropdownItemsInfo}
          />
        </div>
        <div className="split-input">
          {isTimeSeriesGraph ? (
            <TimePeriodField
              id={formConstants.timePeriod.id}
              name={formConstants.timePeriod.name}
              label={formConstants.timePeriod.label}
              placeholder={t(formConstants.timePeriod.placeholder)}
              disabled={readonly}
              data={timePeriodsList}
              textField={timePeriodFields.key}
              valueField={timePeriodFields.value}
              formValues={formValues}
              changeField={changeField}
            />
          ) : (
            <Field
              id={formConstants.chunk.id}
              name={formConstants.chunk.name}
              label={formConstants.chunk.label}
              placeholder={t(formConstants.chunk.placeholder)}
              disabled={readonly}
              component={CustomSelect}
              data={chunks}
              textField={chunkFields.value}
              valueField={chunkFields.key}
              size="lg"
              isEnhancedDesignCustomProp
              isRequired
              onChange={handleChunkChange}
              CustomSelectIcon={<Icon name="chevron-down" className="dropdown-icon" handleHover={true} size="lg" />}
            />
          )}
        </div>
      </div>
      <div className="double">
        <div className="split-input">
          {isTimeSeriesGraph ? (
            <Field
              id={formConstants.chunks.id}
              name={formConstants.chunks.name}
              label={formConstants.chunks.label}
              placeholder={t(formConstants.chunks.placeholder)}
              disabled={readonly}
              component={CustomSelect}
              data={chunks}
              textField={chunkFields.value}
              valueField={chunkFields.key}
              size="lg"
              isEnhancedDesignCustomProp
              isRequired
            />
          ) : (
            <Field
              id={formConstants.dateRanges.id}
              name={formConstants.dateRanges.name}
              label={formConstants.dateRanges.label}
              placeholder={t(formConstants.dateRanges.placeholder)}
              disabled={readonly || !formValues?.[formConstants.chunk.name]}
              component={DateRangePickerField}
              size="lg"
              isEnhancedDesignCustomProp
              isRequired
              chunk={formValues?.[formConstants.chunk.name]}
            />
          )}
        </div>
        <div className="split-input">
          <Field
            id={graphingGroupFields.isDefault}
            disabled={readonly}
            name={graphingGroupFields.isDefault}
            component={Toggle}
            className={'is-default-field'}
            label={t('GRAPHING_GROUP_DETAILS.FORM_SET_AS_DEFAULT')}
          />
        </div>
      </div>
    </div>
  );
};

GraphingGroupSettingsFormFields.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default GraphingGroupSettingsFormFields;
