import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { setGenericNotification } from '../actions/action-creators';
import Notification from './notification';

const STANDARD_ICON_SIZE_IN_GENERIC_NOTIFICATION = 'xsPlus';

const GenericNotification = ({ notification, setGenericNotification }) => {
  const closeNotification = useCallback(() => {
    setGenericNotification({ isDisplayed: false });
  }, [setGenericNotification]);

  useEffect(() => {
    if (notification.isDisplayed === true) {
      setTimeout(closeNotification, 6000);
    }
  }, [notification.isDisplayed, closeNotification]);

  return <Notification {...notification} iconProps={{ size: STANDARD_ICON_SIZE_IN_GENERIC_NOTIFICATION }} onClickClose={closeNotification} />;
};

const mapStateToProps = state => ({
  notification: state.genericNotificationReducer,
});

const mapDispatchToProps = dispatch => ({
  setGenericNotification: data => dispatch(setGenericNotification(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenericNotification);
