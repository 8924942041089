import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import { cloneDeep, isEmpty } from 'lodash';
import Helpers from '../../../common/helpers';
import routesConstants from '../../../common/routes-constants';

import ReactResizeDetector from 'react-resize-detector';
import InstructionsScreen from '../../../common/inspection-instructions/components/instructions-screen';
import Modal from '../../../common/modal/components/modal';
import ModalMinimizable from '../../../common/modal/components/modal-minimizable';
import SplitScreen from '../../../common/resize-box/components/split-screen';
import InspectionView from '../potree/components/main-view';
import InspectionScreen from './inspection-screen';
import VisibleArea from './visible-area';

import { routes } from '../../../common/routes-constants';
import { changeRealCameraType, selectObjectTool, setComponentsFilters, setInspectionDetails } from '../actions/action-creators';
import { addArea } from '../actions/area-actions';
import { createNewDefect, toggleInspectionModal, updateElementGeometry } from '../actions/inspection-actions';
import { defaultArea } from '../constants/areas-constants';
import { componentDropdownActions, filterParams as componentsFilterParams } from '../constants/component-constants';
import { detailsPages, imagesCameraPosition, measurementTypes, modules, objectTools } from '../constants/constants';
import { shapeTypes, zoneAreaConstants } from '../constants/explosive-zones-constants';
import { measurementDropdownActions } from '../constants/measurement-constants';
import { componentHasAllCoordinatesZero, getElementActions } from '../helpers/inspection-helper';
import { createViewerInstance } from '../potree/actions/action-creators';

import { generateParamsFromQuery } from '../../../api/helpers/helper';
import '../styles/inspection.scss';
import { viewOptions } from './readings-and-gauges/constants/constants';

const mainDivClass = 'workflow-resizable-div';

const splitScreenLayouts = ['w', 'n'];

class Inspection extends Component {
  constructor(props) {
    super(props);
    this.inspectionRef = React.createRef();
    this.state = {
      defaultWidth: 0,
      style: null,
      width: 0,
      height: 0,
      direction: 0,
      currentInsertion: null,
      modalData: {
        isOpen: false,
      },
    };
    this.currentListener = null;
  }

  createMeasurementListener = (enforceCreate, onFinish) => {
    if (process.env.NODE_ENV === 'development') {
      // check in the dev mode if the listener is created and compare the values with onInsertionFinish
      console.log('Creating new measurement listener:', {
        enforceCreate,
        hasOnFinish: !!onFinish,
      });
    }
    // Clean up previous listener if it exists
    this.removeMeasurementListener();

    // Create new listener with current values
    this.currentListener = e => this.onInsertionFinish(e, enforceCreate, onFinish);
    return this.currentListener;
  };

  removeMeasurementListener = () => {
    if (this.currentListener) {
      // Remove listener from any existing targets
      if (this.currentTarget) {
        this.currentTarget.removeEventListener('finish_inserting_measurement', this.currentListener);
      }
      if (this.currentMeasure) {
        this.currentMeasure.removeEventListener('finish_inserting_measurement', this.currentListener);
      }
      this.currentListener = null;
    }
  };

  setCurrentInsertion = insertion => {
    this.setState({ currentInsertion: insertion });
  };

  handleObjectToolClick = (value, action = '', withRedirection = true, onFinish = null, enforceCreate = false) => {
    const listener = this.createMeasurementListener(enforceCreate, onFinish);

    const { currentInsertion } = this.state;
    const { selectTool, viewer, addArea, location, images360Ref, isDirty } = this.props;

    const { project_id, inspection_id, type, view } = location.query;
    const params = [{ project_id }, { inspection_id }];
    if (view) {
      params.push({ view });
    }

    if (objectTools.settings === value) {
      selectTool(value);
      Helpers.goTo(routes.protectedRoutes.inspections.fullPath, generateParamsFromQuery({ ...location.query, details: detailsPages.settings }));

      return;
    }

    if (viewer) {
      selectTool(value);
      const measuringTool = new window.Potree.MeasuringTool(viewer);
      measuringTool.selectedTool = value;
      // Store references for cleanup
      this.currentTarget = measuringTool;

      if (withRedirection) {
        const toolsToModuleMap = {
          [objectTools.measurement]: modules.measurements,
          [objectTools.link]: modules.defects,
          [objectTools.component]: modules.components,
          [objectTools.volumeClip]: modules.areas,
          [objectTools.inspect]: modules.details,
          [objectTools.notification]: modules.notifications,
          [objectTools.cursor]: type,
        };
        if (toolsToModuleMap[value]) {
          // 'explicitCreateAction' added for handling in useConfirmOnExit hook
          Helpers.goTo(routes.protectedRoutes.inspections.fullPath, params.concat([{ type: toolsToModuleMap[value] }]), 'explicitCreateAction');
        }
      }

      // in case the form has unsaved changes, prevent the insertion of objects
      if (isDirty) return;

      if (currentInsertion !== null) {
        viewer.scene.removeMeasurement(currentInsertion);
        viewer.scene.removeVolume(currentInsertion);
        viewer.scene.removeShape(currentInsertion);
        // If tool is changed cancel all previous insertions
        if (value) {
          viewer.dispatchEvent({
            type: 'cancel_all_insertions',
          });
        }
        this.removeMeasurementListener();
        this.setCurrentInsertion(null);
        currentInsertion.removeEventListener('finish_inserting_measurement', e => this.onInsertionFinish(e, enforceCreate));
      }

      if (objectTools.link === value) {
        if (images360Ref) {
          // Hide all footprints while insertion is in progress
          images360Ref.footprintsVisible = false;
        }
        let measurement = measuringTool.startInsertion({
          showDistances: false,
          showAngles: false,
          showCoordinates: true,
          showArea: false,
          closed: true,
          maxMarkers: 1,
          coordinatesText: 'New Observation',
          systemType: measurementTypes.defect,
          name: 'Point',
        });
        measurement.selectedTool = value;
        measurement.MainType = action;
        this.currentMeasure = measurement;

        measuringTool.addEventListener('finish_inserting_measurement', listener);
        measurement.addEventListener('finish_inserting_measurement', listener);
        this.setCurrentInsertion(measurement);
      } else if (objectTools.inspect === value) {
        if (images360Ref) {
          // Hide all footprints while insertion is in progress
          images360Ref.footprintsVisible = false;
        }
        let measurement = measuringTool.startInsertion({
          showDistances: false,
          showAngles: false,
          showCoordinates: true,
          showArea: false,
          closed: true,
          maxMarkers: 1,
          coordinatesText: '',
          systemType: measurementTypes.inspect,
          name: 'Point',
        });
        measurement.selectedTool = value;
        this.currentMeasure = measurement;

        measuringTool.addEventListener('finish_inserting_measurement', listener);
        measurement.addEventListener('finish_inserting_measurement', listener);
        this.setCurrentInsertion(measurement);
      } else if (objectTools.measurement === value) {
        if (action === measurementDropdownActions.point) {
          if (images360Ref) {
            // Hide all footprints while insertion is in progress
            images360Ref.footprintsVisible = false;
          }
          let measurement = measuringTool.startInsertion({
            showDistances: false,
            showAngles: false,
            showCoordinates: true,
            showArea: false,
            closed: true,
            maxMarkers: 1,
            coordinatesText: 'New Observation',
            systemType: measurementTypes.measurement,
            name: 'Point',
          });
          measurement.selectedTool = value;
          this.currentMeasure = measurement;

          measuringTool.addEventListener('finish_inserting_measurement', listener);
          measurement.addEventListener('finish_inserting_measurement', listener);
          this.setCurrentInsertion(measurement);
        } else if (action === measurementDropdownActions.area) {
          if (images360Ref) {
            // Hide all footprints while insertion is in progress
            images360Ref.footprintsVisible = false;
          }
          let measurement = measuringTool.startInsertion({
            showDistances: true,
            showArea: true,
            closed: true,
            coordinatesText: 'New Observation',
            systemType: measurementTypes.measurement,
            name: 'Area',
          });
          measurement.selectedTool = value;
          this.currentMeasure = measurement;

          measuringTool.addEventListener('finish_inserting_measurement', listener);
          measurement.addEventListener('finish_inserting_measurement', listener);
          this.setCurrentInsertion(measurement);
        } else if (action === measurementDropdownActions.distance) {
          if (images360Ref) {
            // Hide all footprints while insertion is in progress
            images360Ref.footprintsVisible = false;
          }
          let measurement = measuringTool.startInsertion({
            showDistances: true,
            showArea: false,
            closed: false,
            coordinatesText: 'New Observation',
            systemType: measurementTypes.measurement,
            name: 'Distance',
          });
          measurement.selectedTool = value;
          this.currentMeasure = measurement;

          measuringTool.addEventListener('finish_inserting_measurement', listener);
          measurement.addEventListener('finish_inserting_measurement', listener);
          this.setCurrentInsertion(measurement);
        } else if (action === measurementDropdownActions.circle) {
          if (images360Ref) {
            // Hide all footprints while insertion is in progress
            images360Ref.footprintsVisible = false;
          }
          let measurement = measuringTool.startInsertion({
            showDistances: false,
            showHeight: true,
            showArea: false,
            showCircle: true,
            closed: false,
            coordinatesText: 'New Circle',
            maxMarkers: 2,
            systemType: measurementTypes.measurement,
            name: 'Circle',
          });
          measurement.selectedTool = value;
          this.currentMeasure = measurement;

          measuringTool.addEventListener('finish_inserting_measurement', listener);
          measurement.addEventListener('finish_inserting_measurement', listener);
          this.setCurrentInsertion(measurement);
        } else if (action === measurementDropdownActions.angle) {
          if (images360Ref) {
            // Hide all footprints while insertion is in progress
            images360Ref.footprintsVisible = false;
          }
          let measurement = measuringTool.startInsertion({
            showDistances: false,
            showAngles: true,
            showArea: false,
            closed: true,
            maxMarkers: 3,
            coordinatesText: 'New Observation',
            systemType: measurementTypes.measurement,
            name: 'Angle',
          });
          measurement.selectedTool = value;
          this.currentMeasure = measurement;

          measuringTool.addEventListener('finish_inserting_measurement', listener);
          measurement.addEventListener('finish_inserting_measurement', listener);
          this.setCurrentInsertion(measurement);
        } else if (action === measurementDropdownActions.height) {
          if (images360Ref) {
            // Hide all footprints while insertion is in progress
            images360Ref.footprintsVisible = false;
          }
          let measurement = measuringTool.startInsertion({
            showDistances: false,
            showHeight: true,
            showArea: false,
            closed: false,
            maxMarkers: 2,
            name: 'Height',
            coordinatesText: 'New Observation',
            systemType: measurementTypes.measurement,
          });
          measurement.selectedTool = value;
          this.currentMeasure = measurement;

          measuringTool.addEventListener('finish_inserting_measurement', listener);
          measurement.addEventListener('finish_inserting_measurement', listener);
          this.setCurrentInsertion(measurement);
        } else return;
      } else if (objectTools.component === value) {
        if (action === componentDropdownActions.points) {
          if (images360Ref) {
            // Hide all footprints while insertion is in progress
            images360Ref.footprintsVisible = false;
          }
          let measurement = measuringTool.startInsertion({
            showDistances: false,
            showAngles: false,
            showCoordinates: true,
            showArea: false,
            closed: true,
            maxMarkers: 1,
            coordinatesText: 'New Equipment',
            systemType: measurementTypes.component,
            name: 'Point',
          });
          measurement.selectedTool = value;
          this.currentMeasure = measurement;

          measuringTool.addEventListener('finish_inserting_measurement', listener);
          measurement.addEventListener('finish_inserting_measurement', listener);
          this.setCurrentInsertion(measurement);
        } else return;
      } else if (objectTools.volumeClip === value) {
        this.startVolumeInsertion(
          shapeTypes.volume,
          {},
          onFinish
            ? onFinish
            : (newBody, areas) =>
                addArea(
                  newBody.InspectionID,
                  areas,
                  { ...newBody, ...defaultArea },
                  { x: newBody.CameraPosition.coordinates[0], y: newBody.CameraPosition.coordinates[1], z: newBody.CameraPosition.coordinates[2] },
                  data => {
                    this.scrollToElement(data, Helpers.autoScrollConstants[modules.areas].generateCustomIdentificator, Helpers.autoScrollConstants[modules.areas].offset);
                    Helpers.goTo(routes.protectedRoutes.inspections.fullPath, [{ inspection_id }, { project_id }, { type: modules.areas }, { selected_item: data.ID }]);
                  }
                )
        );
      } else if (objectTools.notification === value) {
        if (images360Ref) {
          // Hide all footprints while insertion is in progress
          images360Ref.footprintsVisible = false;
        }
        let measurement = measuringTool.startInsertion({
          showDistances: false,
          showAngles: false,
          showCoordinates: true,
          showArea: false,
          closed: true,
          maxMarkers: 1,
          coordinatesText: 'New Notification',
          systemType: measurementTypes.notification,
          name: 'Point',
        });
        measurement.selectedTool = value;
        measurement.MainType = action;
        this.currentMeasure = measurement;

        measuringTool.addEventListener('finish_inserting_measurement', listener);
        measurement.addEventListener('finish_inserting_measurement', listener);
        this.setCurrentInsertion(measurement);
      } else if (objectTools.rgMeasurementLocation === value) {
        if (images360Ref) {
          // Hide all footprints while insertion is in progress
          images360Ref.footprintsVisible = false;
        }
        let measurement = measuringTool.startInsertion({
          showDistances: false,
          showAngles: false,
          showCoordinates: true,
          showArea: false,
          closed: true,
          maxMarkers: 1,
          coordinatesText: 'New Measurement Location',
          systemType: measurementTypes.rgMeasurementLocation,
          name: 'Point',
        });
        measurement.selectedTool = value;
        this.currentMeasure = measurement;

        measuringTool.addEventListener('finish_inserting_measurement', listener);
        measurement.addEventListener('finish_inserting_measurement', listener);
        this.setCurrentInsertion(measurement);
      } else if (objectTools.rgMeasurementPoint === value) {
        if (images360Ref) {
          // Hide all footprints while insertion is in progress
          images360Ref.footprintsVisible = false;
        }
        let measurement = measuringTool.startInsertion({
          showDistances: false,
          showAngles: false,
          showCoordinates: true,
          showArea: false,
          closed: true,
          maxMarkers: 1,
          coordinatesText: 'New Measurement Point',
          systemType: measurementTypes.rgMeasurementPoint,
          name: 'Point',
        });
        measurement.selectedTool = value;
        this.currentMeasure = measurement;

        measuringTool.addEventListener('finish_inserting_measurement', listener);
        measurement.addEventListener('finish_inserting_measurement', listener);
        this.setCurrentInsertion(measurement);
      } else {
        return;
      }
    }
  };

  startVolumeInsertion = (shapeType, element, onFinish) => {
    const { currentInsertion } = this.state;
    const { viewer } = this.props;

    if (viewer) {
      const volumeTool = new window.Potree.VolumeTool(viewer);

      if (currentInsertion !== null) {
        currentInsertion.removeEventListener('finish_inserting_volume', e => this.onVolumeInsertionFinish(e, onFinish));
      }
      // Start insertion
      let measurement = volumeTool.startInsertion({
        shapeType,
        modelDetails: element.ModelDetails,
        clip: true,
        name: 'Volume',
      });
      volumeTool.addEventListener('finish_inserting_volume', e => this.onVolumeInsertionFinish(e, onFinish));
      measurement.addEventListener('finish_inserting_volume', e => this.onVolumeInsertionFinish(e, onFinish));
      this.setCurrentInsertion(measurement);
    }
  };

  onVolumeInsertionFinish = (e, callback) => {
    const { query } = this.props.location;
    const inspectionId = parseInt(query.inspection_id);

    const { selectTool, viewer, inspectionDetails, areas } = this.props;

    selectTool(null);
    if (!callback) {
      return;
    }
    this.setCurrentInsertion(null);
    const camPos = this.getCameraPosition();
    const type = e.volume.shapeType;
    let body = {
      InspectionID: parseInt(inspectionId),
      AreaGroupID: inspectionDetails.DefaultAreaGroup,
      CameraPosition: {
        coordinates: [camPos.x, camPos.y, camPos.z],
      },
      Geometry: {
        type: type,
        coordinates: [[e.volume.position.x, e.volume.position.y, e.volume.position.z]],
      },
      [zoneAreaConstants.fields.modelDetails]: e.volume.modelDetails,
    };

    callback(body, areas);

    viewer.scene.removeVolume(e.volume);
    e.target.removeEventListener('finish_inserting_volume', e => this.onVolumeInsertionFinish(e, callback));
    e.volume.removeEventListener('finish_inserting_volume', e => this.onVolumeInsertionFinish(e, callback));
  };

  onInsertionFinish = (e, enforceCreate, onFinish) => {
    const { inspectionDetails, realCameraType, changeRealCameraType, selectTool, createNewDefect, viewer, selectedDefect, changeField, updateGeometry, chHierarchyID, images360Ref } = this.props;
    const { query } = this.props.location;
    const inspectionId = parseInt(query.inspection_id);

    selectTool(null);
    this.currentInsertion = null;

    if (isEmpty(e.measure.points)) {
      this.removeMeasurementListener();
      viewer.scene.removeMeasurement(e.measure);
      return;
    }

    const camPos = this.getCameraPosition();
    const type = Helpers.getDefectType(e.measure.name);
    let body = {
      InspectionID: parseInt(inspectionId),
      HierarchyID: chHierarchyID,
      CameraPosition: {
        coordinates: [camPos.x, camPos.y, camPos.z],
      },
      Geometry: {
        type: type,
        coordinates: [...e.measure.points.map(point => [point.position.x, point.position.y, point.position.z])],
      },
    };
    if (objectTools.link === e.measure.selectedTool) {
      body = {
        ...body,
        ComponentID: inspectionDetails.DefaultComponent,
        SystemType: measurementTypes.defect,
        Name: 'New Observation',
        MainType: e.measure.MainType,
        Drawing: {
          objects: [],
        },
      };

      const { defaultElement } = getElementActions({ SystemType: measurementTypes.measurement });
      body = { ...defaultElement(inspectionDetails.CameraPosition), ...body };
    } else if (objectTools.inspect === e.measure.selectedTool) {
      body.SystemType = measurementTypes.inspect;
      //change to UAV or Ground for easier implementation
      body.Type = [imagesCameraPosition.uav, imagesCameraPosition.ground].indexOf(realCameraType) > -1 ? realCameraType : imagesCameraPosition.uav;
      changeRealCameraType(body.Type);
    } else if (objectTools.component === e.measure.selectedTool) {
      body.SystemType = measurementTypes.component;
      body.Name = 'New Equipment';
      body.ComponentType = 'Default';
      body.ComponentCode = '';
      body.DefectType = 'Default';
    } else if (objectTools.notification === e.measure.selectedTool) {
      body.SystemType = measurementTypes.notification;
      body.Name = 'New Notification';
      // Not necessary since notifications are created from other modules
      // const { defaultElement } = getElementActions({ SystemType: measurementTypes.notification });
      // body = { ...defaultElement(inspectionDetails.CameraPosition), ...body, SystemType: measurementTypes.notification };
    } else if (objectTools.rgMeasurementLocation === e.measure.selectedTool) {
      body.SystemType = measurementTypes.rgMeasurementLocation;
      body.Name = 'New Measurement Location';
    } else if (objectTools.rgMeasurementPoint === e.measure.selectedTool) {
      body.SystemType = measurementTypes.rgMeasurementPoint;
      body.Name = 'New Measurement Point';
    } else {
      const { defaultElement } = getElementActions({ SystemType: measurementTypes.measurement });
      body = { ...defaultElement(inspectionDetails.CameraPosition), ...body, SystemType: measurementTypes.measurement };
    }
    if (!isEmpty(selectedDefect) && !enforceCreate) {
      let updatedDefect = cloneDeep(selectedDefect);
      updatedDefect.Geometry = { ...body.Geometry };
      updatedDefect.CameraPosition = { ...body.CameraPosition };
      updatedDefect.SystemType = selectedDefect.SystemType || body.SystemType;

      const { FORM } = getElementActions(updatedDefect);
      // skips update of the coordinates if they are [0, 0, 0], meaning it is outside 3D model (mark on 3D button persists)
      if (!componentHasAllCoordinatesZero(updatedDefect.Geometry.coordinates)) {
        updateGeometry(updatedDefect);
        changeField(FORM.name, FORM.fields.points, updatedDefect.Geometry.coordinates);
        changeField(FORM.name, FORM.fields.camPosition, updatedDefect.CameraPosition.coordinates);
      }
    } else {
      createNewDefect({ ...body }, data => {
        const { setComponentsFilters, componentFilters } = this.props;
        this.scrollToElement(data, Helpers.autoScrollConstants[modules.defects].generateCustomIdentificator, Helpers.autoScrollConstants[modules.defects].offset);
        setComponentsFilters({ ...componentFilters, [componentsFilterParams.totalItems]: componentFilters[componentsFilterParams.totalItems] + 1 });
        if (objectTools.link === e.measure.selectedTool) {
          Helpers.goTo(routes.protectedRoutes.inspections.fullPath, [{ inspection_id: query.inspection_id }, { project_id: query.project_id }, { type: modules.defects }, { selected_item: data.ID }]);
        }

        if (objectTools.component === e.measure.selectedTool) {
          Helpers.goTo(routes.protectedRoutes.inspections.fullPath, [
            { inspection_id: query.inspection_id },
            { project_id: query.project_id },
            { type: modules.components },
            { selected_item: data.ID },
          ]);
        }
        if (objectTools.measurement === e.measure.selectedTool) {
          Helpers.goTo(routes.protectedRoutes.inspections.fullPath, [
            { inspection_id: query.inspection_id },
            { project_id: query.project_id },
            { type: modules.measurements },
            { selected_item: data.ID },
          ]);
        }
        if (objectTools.notification === e.measure.selectedTool) {
          Helpers.goTo(routes.protectedRoutes.inspections.fullPath, [
            { inspection_id: query.inspection_id },
            { project_id: query.project_id },
            { type: modules.notifications },
            { selected_item: data.ID },
          ]);
        }

        if (objectTools.rgMeasurementLocation === e.measure.selectedTool) {
          Helpers.goTo(routes.protectedRoutes.inspections.fullPath, [
            { inspection_id: query.inspection_id },
            { project_id: query.project_id },
            { type: modules.readingsAndGauges },
            { view: viewOptions.location },
            { selected_item: data.ID },
          ]);
        }
      });
    }
    viewer.scene.removeMeasurement(e.measure);
    this.removeMeasurementListener();
    onFinish && onFinish(body);

    // state cleanup
    this.setState({
      currentInsertion: null,
      modalData: {
        isOpen: false,
      },
    });
    // TODO: check if here we could dispatch clearUnsavedChangesDirty so it does not get called in multiple components repetitevely
    if (images360Ref) {
      // Show all footprints when insertion is done
      images360Ref.footprintsVisible = true;
    }
  };

  getCameraPosition = () => {
    const { viewer, inspectionDetails } = this.props;
    if (viewer && viewer.scene && viewer.scene.cameraP) {
      return { x: viewer.scene.cameraP.position.x, y: viewer.scene.cameraP.position.y, z: viewer.scene.cameraP.position.z };
    } else if (inspectionDetails.CameraPosition && !isEmpty(inspectionDetails.CameraPosition.coordinates)) {
      return { x: inspectionDetails.CameraPosition.coordinates[0], y: inspectionDetails.CameraPosition.coordinates[1], z: inspectionDetails.CameraPosition.coordinates[2] };
    } else return { x: 0, y: 0, z: 0 };
  };

  onResize = ({ width }) => {
    const { defaultWidth } = this.state;

    const minWidth = defaultWidth / 2;
    const maxWidth = defaultWidth + minWidth;

    if (width > maxWidth) {
      width = maxWidth;
    }
    if (width < minWidth) {
      width = minWidth;
    }

    this.setState({ width: width });
    this.setState({ style: { gridTemplateColumns: `auto ${width}px` } });
  };

  componentDidMount() {
    const {
      query: { project_id, inspection_id },
    } = this.props.location;
    if (!inspection_id || !project_id) {
      Helpers.goTo(routesConstants.routes.protectedRoutes.project.fullPath);
      return;
    }
    this.setBoundaries();
  }

  componentWillUnmount() {
    const { toggleInspectionModal } = this.props;
    toggleInspectionModal({
      componentDrawings: [],
      isOpen: false,
      isMinimized: false,
    });
    this.removeMeasurementListener();

    // Issue with potree doesn't have built in function for clearing everything so we need to go with this approach reloading the page
    window.location.reload();
  }

  setBoundaries = () => {
    let el = document.getElementsByClassName(mainDivClass)[0];
    el = el && el.getBoundingClientRect();

    if (el && !isNaN(el.width)) {
      this.setState({ width: Math.floor(el.width) });
      this.setState({ defaultWidth: Math.floor(el.width) });
      this.setState({ style: { gridTemplateColumns: `auto ${el.width}px` } });
    }
  };

  onScreenResize = () => {
    this.setState({ style: { gridTemplateColumns: `50% 50%` } });

    setTimeout(() => {
      this.setBoundaries();
    }, 300);
  };

  setSplitScreenLayout = direction => {
    this.setState({ direction });
  };

  scrollToElement = (data, generateCustomIdentificator, autoScrollOffset, delay = 500) => {
    setTimeout(() => {
      Helpers.scrollIntoView('items-table-renderer', null, autoScrollOffset, generateCustomIdentificator(data));
    }, delay);
  };

  openInstructionsModal = () => {
    const closeAction = () => {
      this.setState({ modalData: { isOpen: false } });
    };

    const { t } = this.context;
    this.setState(prevState => ({
      modalData: {
        ...prevState.modalData,
        title: t('INSPECTION_DETAILS.INSTRUCTIONS_MODAL_TITLE'),
        CustomContent: () => <InstructionsScreen />,
        isOpen: true,
        customClassName: 'instructions-modal modal-large',
        closeAction: closeAction,
        type: 'none',
      },
    }));
  };

  render() {
    const {
      inspectionModalData,
      pdfPopupLoading,
      activeTool,
      viewer,
      setInspectionDetails,
      selectTool,
      createNewDefect,
      inspectionReducer: { leftCollapsed, rightCollapsed, topCollapsed, activeDetailsPage },
      createViewerInstance,
      router,
      route,
    } = this.props;
    const { style, direction, currentInsertion, modalData } = this.state;
    const { pathname } = this.props.location;
    const isWorkflowScreen = routesConstants.routes.protectedRoutes.startWorkflow.fullPath === pathname;

    const splitScreenActive = inspectionModalData.separatePage && inspectionModalData.isOpen;

    const screen = isWorkflowScreen ? (
      <ReactResizeDetector handleWidth skipOnMount onResize={this.onScreenResize}>
        <div className="inspection-container workflow" style={style}>
          <InspectionView
            parentRef={this.inspectionRef}
            createViewerInstance={createViewerInstance}
            viewer={viewer}
            currentInsertion={currentInsertion}
            isWorkflow={isWorkflowScreen}
            preSelectedDefect={this.props.location.state?.preSelectedDefect}
          />
        </div>
      </ReactResizeDetector>
    ) : (
      <div className={`inspection-container ${rightCollapsed || !activeDetailsPage ? '' : 'module-picker__half-width'}`}>
        <VisibleArea interactable={true} rightCollapsed={rightCollapsed || !activeDetailsPage} leftCollapsed={leftCollapsed} topCollapsed={topCollapsed}>
          <SplitScreen
            direction={splitScreenLayouts[direction]}
            isActive={splitScreenActive}
            screenRenderers={[
              () => (
                <InspectionView
                  parentRef={this.inspectionRef}
                  createViewerInstance={createViewerInstance}
                  viewer={viewer}
                  currentInsertion={currentInsertion}
                  activeTool={activeTool}
                  handleObjectToolClick={this.handleObjectToolClick}
                  setInspectionDetails={setInspectionDetails}
                  isWorkflow={isWorkflowScreen}
                  isActive={splitScreenActive}
                  openInstructionsModal={this.openInstructionsModal}
                  openSettings={() => this.handleObjectToolClick(objectTools.settings)}
                  showObjectToolbox
                  showScreenToolbox
                  show360
                />
              ),
              () => (
                <ModalMinimizable
                  {...inspectionModalData}
                  splitScreenEnabled={true}
                  componentsLoading={pdfPopupLoading}
                  setSplitScreenLayout={this.setSplitScreenLayout}
                  splitScreenLayoutDirection={direction}
                />
              ),
            ]}
          />
        </VisibleArea>
        {!splitScreenActive && <ModalMinimizable {...inspectionModalData} splitScreenEnabled={true} componentsLoading={pdfPopupLoading} setSplitScreenLayout={this.setSplitScreenLayout} />}

        <InspectionScreen
          location={this.props.location}
          viewer={viewer}
          selectTool={selectTool}
          currentInsertion={currentInsertion}
          setCurrentInsertion={this.setCurrentInsertion}
          createNewDefect={(...args) =>
            createNewDefect(...args, data => this.scrollToElement(data, Helpers.autoScrollConstants[modules.defects].generateCustomIdentificator, Helpers.autoScrollConstants[modules.defects].offset))
          }
          getCameraPosition={this.getCameraPosition}
          handleObjectToolClick={this.handleObjectToolClick}
          getDefectType={Helpers.getDefectType}
          showObjectToolbox={!splitScreenActive}
          scrollToElement={this.scrollToElement}
          router={router}
          route={route}
        />
        <Modal {...modalData} />
      </div>
    );

    return screen;
  }
}

Inspection.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  inspectionModalData: state.inspectionReducer.inspectionModalData,
  pdfPopupLoading: state.pdfTagReducer.pdfPopupLoading,
  inspectionReducer: state.inspectionReducer,
  activeTool: state.inspectionReducer.activeObjectTool,
  inspectionDetails: state.inspectionReducer.inspectionDetails,
  viewer: state.potreeReducer.viewerInstance,
  activeItem: state.inspectionReducer.activeLeftSidebar,
  components: state.inspectionReducer.components,
  searchText: state.inspectionReducer.searchText,
  realCameraType: state.inspectionReducer.realCameraType,
  selectedDefect: state.inspectionReducer.selectedDefect,
  chHierarchyID: state.inspectionReducer.chHierarchyID,
  componentFilters: state.inspectionReducer.componentsFilter,
  areas: state.areasReducer.areas,
  images360Ref: state.inspectionReducer.images360Ref,
  isDirty: state.unsavedChangesReducer.isDirty,
});

const mapDispatchToProps = dispatch => ({
  setInspectionDetails: data => dispatch(setInspectionDetails(data)),
  selectTool: val => dispatch(selectObjectTool(val)),
  addArea: (inspectionId, currentAreas, newData, camPos, callback) => dispatch(addArea(inspectionId, currentAreas, newData, camPos, callback)),
  createNewDefect: (data, callback) => dispatch(createNewDefect(data, callback)),
  toggleInspectionModal: data => dispatch(toggleInspectionModal(data)),
  changeRealCameraType: type => dispatch(changeRealCameraType(type)),
  changeField: (form, field, value) => dispatch(change(form, field, value)),
  updateGeometry: data => dispatch(updateElementGeometry(data)),
  setComponentsFilters: filters => dispatch(setComponentsFilters(filters)),
  createViewerInstance: potreeInstance => dispatch(createViewerInstance(potreeInstance)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inspection);
