export const formConstants = {
  fields: {
    name: 'Name',
    id: 'ID',
    value: 'Value',
    properties: 'properties',
    fieldType: 'FieldType',
    fieldValues: 'FieldValues',
    allowDropdownItemUnselect: 'AllowDropdownItemUnselect',
    description: 'Description',
  },
};

export const plannerGroupProperty = 'Planner Group';
export const shortDescriptionProperty = 'Short Description';
export const longDescriptionProperty = 'Long Description';

export const customPropertyFieldTypes = {
  input: 'INPUT',
  select: 'SELECT',
};

export const customPropertyNonMandatoryFields = [shortDescriptionProperty, longDescriptionProperty, plannerGroupProperty];
