import axios from 'axios';
import { isEmpty, pick } from 'lodash';
import { updateSyncErrors } from 'redux-form';
import readingsAndGaugesAPI from '../../../../../api/readings-and-gauges/actions';
import { FORMS } from '../../../../../common/constants';
import Helpers from '../../../../../common/helpers';
import { dispatchErrorModalWired } from '../../../../../common/modal/actions/modal-actions';
import { setGenericNotification } from '../../../../../common/notification/actions/action-creators';
import { measurementTypes } from '../../../constants/constants';
import { formConstants as inspectionSettingsConstants } from '../../../constants/inspection-settings';

import { deleteWithModalFields } from '../constants/constants';
import { filterParams, formConstants, formConstants as mpFormConstants } from '../constants/measurement-point-constants';
import {
  amendMeasurementPoints,
  setMeasurementPoints,
  setMeasurementPointsClustered,
  setMeasurementPointsFilters,
  setMeasurementPointsLoading,
  updateMeasurementPointInArray,
} from './action-creators';

let cancelTokens = {
  measurementPointsClustered: undefined,
};

export const fetchMeasurementPoints = (filters, loadMore, successCallback, errorCallback, saveToReducer = true) => {
  return async dispatch => {
    try {
      if (saveToReducer) {
        dispatch(setMeasurementPointsLoading(true));
      }
      const res = await readingsAndGaugesAPI.fetchMeasurementPoints(filters);
      const { Items, ...newFilters } = res.data.Data;

      if (saveToReducer) {
        if (loadMore) {
          dispatch(amendMeasurementPoints(Items || []));
        } else {
          dispatch(setMeasurementPoints(Items || []));
        }
        dispatch(setMeasurementPointsFilters({ ...filters, ...newFilters }));
        dispatch(setMeasurementPointsLoading(false));
      }
      successCallback && typeof successCallback === 'function' && successCallback({ data: Items, filters: { ...filters, ...newFilters } });
    } catch (e) {
      console.error(e);
      if (saveToReducer) {
        dispatch(setMeasurementPointsLoading(false));
      }
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const createMeasurementPoint = (data, ProjectID, MeasurementLocationID, filters, successCallback, errorCallback) => {
  return async dispatch => {
    try {
      const res = await readingsAndGaugesAPI.createMeasurementPoint({ ...data, ProjectID, MeasurementLocationID });
      const { Data } = res.data;

      dispatch(updateMeasurementPointInArray(Data, 'add'));
      dispatch(setMeasurementPointsFilters({ ...filters, [filterParams.totalItems]: filters[filterParams.totalItems] + 1 }));
      successCallback && typeof successCallback === 'function' && successCallback(Data);
    } catch (e) {
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
      console.error(e);
    }
  };
};

export const updateMeasurementPoint = (data, callback) => {
  if (isEmpty(data)) return;
  return async dispatch => {
    try {
      await readingsAndGaugesAPI.updateMeasurementPointDetails(data);

      dispatch(updateMeasurementPointInArray(data, 'update'));

      callback && callback();

      return data;
    } catch (e) {
      const customError = Helpers.getErrorContent(e);
      if (customError) {
        dispatch(
          updateSyncErrors(FORMS.measurementPointForm, {
            Code: { string: customError },
          })
        );
      } else {
        dispatchErrorModalWired(true, e);
      }
    }
  };
};

export const deleteMeasurementPoint = (data, ConfirmationCheck, context, successCallback, errorCallback) => {
  return async dispatch => {
    try {
      const { t } = context;
      await readingsAndGaugesAPI.deleteMeasurementPoint({ [formConstants.fields.id]: data[formConstants.fields.id], ConfirmationCheck });
      dispatch(updateMeasurementPointInArray({ [formConstants.fields.id]: data[formConstants.fields.id] }, 'delete'));
      // set generic notification
      dispatch(
        setGenericNotification({
          isDisplayed: true,
          type: 'error',
          icon: 'trash',
          text: t('MEASUREMENT_POINT.NOTIFICATIONS.DELETE_SUCCESS', { mpName: data[formConstants.fields.name] }),
        })
      );

      successCallback && typeof successCallback === 'function' && successCallback();
    } catch (e) {
      const customError = Helpers.getErrorContent(e);
      if (customError) {
        dispatch(
          updateSyncErrors(FORMS.deleteWithModalForm, {
            [deleteWithModalFields.field.name]: customError,
          })
        );
      } else {
        console.error(e);
        dispatchErrorModalWired(true, e);
      }

      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const getMeasurementPointsClustered = (inspection_id, activeLeftSidebar, searchText = '', optionalParams = {}, callback) => {
  return async (dispatch, getState) => {
    const {
      inspectionReducer: { inspectionSettings },
    } = getState();
    const clusterConfiguration = pick(inspectionSettings, [
      inspectionSettingsConstants.fields.numberOfRings,
      inspectionSettingsConstants.fields.numberOfSlices,
      inspectionSettingsConstants.fields.initialDistance,
      inspectionSettingsConstants.fields.multiplier,
    ]);
    try {
      // TODO: Figure out how to send measurement location
      //Check if there are any previous pending requests
      if (typeof cancelTokens.measurementPointsClustered != typeof undefined) {
        cancelTokens.measurementPointsClustered.cancel('Operation canceled due to new request.');
      }

      //Save the cancel token for the current request
      cancelTokens.measurementPointsClustered = axios.CancelToken.source();

      const res = await readingsAndGaugesAPI.getMeasurementPointsClustered(
        {
          InspectionID: parseInt(inspection_id),
          SearchText: searchText,
          SystemType: measurementTypes.rgMeasurementPoint,
          ...clusterConfiguration,
          ...optionalParams,
        },
        { cancelToken: cancelTokens.measurementPointsClustered.token }
      );
      const { Data } = res.data;
      const allClusters = (Data.Items || []).map((el, index) => ({
        ...el,
        ID: el.ID > 0 ? el.ID : `CLUSTER_${Date.now()}_${index}`,
      }));
      let filteredClusters = allClusters;
      if (optionalParams.MeasurementLocationID) {
        // Exclude RG_MEASUREMENT_LOCATION
        filteredClusters = allClusters.filter(el => el.SystemType === measurementTypes.rgMeasurementPoint || el.SystemType === measurementTypes.cluster);
      }
      dispatch(setMeasurementPointsClustered(filteredClusters));
      callback && callback(filteredClusters);
    } catch (e) {
      console.error(e);
    }
  };
};

export const linkMeasurementPointComponent = (ComponentID, MeasurementPointID, successCallback, errorCallback) => {
  return async () => {
    try {
      await readingsAndGaugesAPI.linkMeasurementPointComponent({ ComponentID, MeasurementPointID });

      successCallback && typeof successCallback === 'function' && successCallback();
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const unlinkMeasurementPointComponent = (ComponentID, MeasurementPointID, successCallback, errorCallback) => {
  return async () => {
    try {
      await readingsAndGaugesAPI.unlinkMeasurementPointComponent({ ComponentID, MeasurementPointID });

      successCallback && typeof successCallback === 'function' && successCallback();
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const fetchMeasurementPointUsersAndTeams = (SearchText, successCallback, errorCallback) => {
  return async () => {
    try {
      const res = await readingsAndGaugesAPI.fetchUsersAndTeams({ SearchText });
      const { Data } = res.data;

      successCallback && typeof successCallback === 'function' && successCallback(Data);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const fetchAlarmsDescriptors = (successCallback, errorCallback) => {
  return async () => {
    try {
      const res = await readingsAndGaugesAPI.fetchAlarmsDescriptors();
      const { Data } = res.data;

      successCallback && typeof successCallback === 'function' && successCallback(Data);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const fetchMeasurementPointAlarms = (MeasurementPointID, successCallback, errorCallback) => {
  return async () => {
    try {
      const res = await readingsAndGaugesAPI.fetchMeasurementPointAlarms({ MeasurementPointID });
      const { Data } = res.data;

      successCallback && typeof successCallback === 'function' && successCallback(Data);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const updateMeasurementPointAlarms = (MeasurementPointID, Alarms, successCallback, errorCallback) => {
  const data = { MeasurementPointID, Alarms };
  if (isEmpty(data)) return;
  return async () => {
    try {
      await readingsAndGaugesAPI.updateMeasurementPointAlarms(data);

      successCallback && typeof successCallback === 'function' && successCallback(data);
    } catch (e) {
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const getMeasurementPointObservations = (filters, callback, filtersCallback, errorCallback, loadingCallback) => {
  return async () => {
    try {
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(true);
      const res = await readingsAndGaugesAPI.fetchMeasurementPointObservations(filters);
      const { Items, ...newFilters } = res.data.Data;
      callback && typeof callback === 'function' && callback(Items);
      filtersCallback && typeof filtersCallback === 'function' && filtersCallback({ ...filters, ...newFilters });
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback();
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    }
  };
};

export const getMeasurementPointNotifications = (filters, callback, filtersCallback, errorCallback, loadingCallback) => {
  return async () => {
    try {
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(true);
      const res = await readingsAndGaugesAPI.fetchMeasurementPointNotifications(filters);
      const { Items, ...newFilters } = res.data.Data;
      callback && typeof callback === 'function' && callback(Items);
      filtersCallback && typeof filtersCallback === 'function' && filtersCallback({ ...filters, ...newFilters });
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback();
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    }
  };
};

export const fetchMeasurementPointsForMeasurementLocation = (filters, callback, filtersCallback, errorCallback, loadingCallback) => {
  return async () => {
    try {
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(true);
      const res = await readingsAndGaugesAPI.fetchMeasurementPointsForMeasurementLocation(filters);
      const { Items, ...newFilters } = res.data.Data;
      callback && typeof callback === 'function' && callback(Items);
      filtersCallback && typeof filtersCallback === 'function' && filtersCallback({ ...filters, ...newFilters });
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback();
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    }
  };
};

export const updateOrderMeasurementPoints = (MeasurementPointID, MeasurementLocationID, NewPosition, successCallback, errorCallback) => {
  const data = { MeasurementPointID, MeasurementLocationID, NewPosition };
  if (isEmpty(data)) return;
  return async () => {
    try {
      await readingsAndGaugesAPI.updateOrderMeasurementPoints(data);

      successCallback && typeof successCallback === 'function' && successCallback(data);
    } catch (e) {
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const getMeasurementPointsTimeSeriesGraph = (measurementPoints = [], graphSettings, successCallback, errorCallback) => {
  return async () => {
    try {
      let data = [];
      for (const measurementPoint of measurementPoints) {
        const res = await readingsAndGaugesAPI.getMeasurementPointTimeSeriesGraph({ MeasurementPointID: measurementPoint[mpFormConstants.fields.id], ...graphSettings });
        const { Data } = res.data;
        data = [
          ...data,
          { ...Data, [mpFormConstants.fields.scaleFactor]: measurementPoint[mpFormConstants.fields.scaleFactor], [mpFormConstants.fields.inverted]: measurementPoint[mpFormConstants.fields.inverted] },
        ];
      }

      successCallback && typeof successCallback === 'function' && successCallback(data);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const getMeasurementPointsAnalysisGraph = (measurementPoints = [], graphSettings, successCallback, errorCallback) => {
  return async () => {
    try {
      let data = [];
      for (const measurementPoint of measurementPoints) {
        const res = await readingsAndGaugesAPI.getMeasurementPointAnalysisGraph({ MeasurementPointID: measurementPoint[mpFormConstants.fields.id], ...graphSettings });
        const { Data } = res.data;
        data = [...data, ...Data];
      }

      successCallback && typeof successCallback === 'function' && successCallback(data);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const getMeasurementPointTimeSeriesGraph = (measurementPointId, graphSettings, successCallback, errorCallback) => {
  return async () => {
    try {
      const res = await readingsAndGaugesAPI.getMeasurementPointTimeSeriesGraph({ MeasurementPointID: measurementPointId, ...graphSettings });
      const { Data } = res.data;

      successCallback && typeof successCallback === 'function' && successCallback(Data);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const getMeasurementPointGraphDetails = (MeasurementPointID, successCallback, errorCallback) => {
  return async () => {
    try {
      const res = await readingsAndGaugesAPI.getMeasurementPointGraphDetails({ MeasurementPointID });
      const { Data } = res.data;

      successCallback && typeof successCallback === 'function' && successCallback(Data);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const saveMeasurementPointGraphDetails = (data, successCallback, errorCallback) => {
  return async () => {
    try {
      const res = await readingsAndGaugesAPI.saveMeasurementPointGraphDetails(data);
      const { Data } = res.data;

      successCallback && typeof successCallback === 'function' && successCallback(Data);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

// Checklist and Procedure template actions
export const fetchChecklistProceduresMeasurementPoints = (filters, dataCallback, loadingCallback, errorCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await readingsAndGaugesAPI.fetchChecklistProcedureMeasurementPoints(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const fetchChecklistProceduresLinkedMeasurementPoints = (filters, dataCallback, loadingCallback, errorCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await readingsAndGaugesAPI.fetchChecklistProcedureLinkedMeasurementPoints(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const linkMeasurementPointToChecklistProcedure = (params, dataCallback, loadingCallback, errorCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await readingsAndGaugesAPI.linkMeasurementPointToChecklistProcedure(params);
      const { Data } = res?.data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Data);
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const unlinkMeasurementPointFromChecklistProcedure = (data, dataCallback, loadingCallback, errorCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await readingsAndGaugesAPI.unlinkMeasurementPointFromChecklist(data);
      dataCallback && typeof dataCallback === 'function' && dataCallback(data);
      isLoadingCallbackValid && loadingCallback(true);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const archiveMeasurementPoint = (measurementPoint, callback, errorCallback) => {
  return async () => {
    try {
      const res = await readingsAndGaugesAPI.archiveMeasurementPoint(measurementPoint);
      const { Data } = res.data;
      callback && typeof callback === 'function' && callback(Data);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

export const unarchiveMeasurementPoint = (measurementPoint, callback, errorCallback) => {
  return async () => {
    try {
      const res = await readingsAndGaugesAPI.unarchiveMeasurementPoint(measurementPoint);
      const { Data } = res.data;
      callback && typeof callback === 'function' && callback(Data);
    } catch (e) {
      console.error(e);
      errorCallback && typeof errorCallback === 'function' && errorCallback(e);
    }
  };
};

// comments actions

export const fetchMeasurementPointComments = (params, callback) => {
  return async () => {
    try {
      callback({
        commentsLoading: true,
      });
      const res = await readingsAndGaugesAPI.fetchMeasurementPointComments([params]);
      const { Data } = res?.data;
      callback({
        commentsList: Data,
        commentsLoading: false,
      });
    } catch (e) {
      callback({
        commentsLoading: false,
      });
      console.error(e);
    }
  };
};

export const addMeasurementPointComment = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await readingsAndGaugesAPI.addMeasurementPointComment(filters);
      dataCallback && typeof dataCallback === 'function' && dataCallback();
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const deleteMeasurementPointComment = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await readingsAndGaugesAPI.deleteMeasurementPointComment(filters);
      dataCallback && typeof dataCallback === 'function' && dataCallback();
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};
