import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Helpers from '../../helpers';

const TruncatedText = ({ text = '', maxChar = 30, className = '', style = {}, ...props }) => {
  const [maxWidth, setMaxWidth] = useState('100%');
  const [minWidth, setMinWidth] = useState('100%');
  const paragraphRef = useRef(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (paragraphRef.current) {
        const parentElement = paragraphRef.current.parentElement;
        // Ensure parent element has min-width and max-width set to 100%
        const parentStyle = window.getComputedStyle(parentElement);
        if (!parentStyle.maxWidth || parentStyle.maxWidth === 'none') {
          parentElement.style.maxWidth = '100%';
        }

        const parentRect = parentElement.getBoundingClientRect();
        const computedStyle = window.getComputedStyle(parentElement);
        const padding = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
        const border = parseFloat(computedStyle.borderLeftWidth) + parseFloat(computedStyle.borderRightWidth);

        // Calculate total width of sibling elements
        let siblingsWidth = 0;
        Array.from(parentElement.children).forEach(child => {
          if (child !== paragraphRef.current) {
            const childRect = child.getBoundingClientRect();
            siblingsWidth += childRect.width;
          }
        });

        // Calculate available max width for the TruncatedText component
        let availableWidth = parentRect.width - padding - border - siblingsWidth;
        if (availableWidth <= 0) {
          /* handles a scenario where the calculated available width for the `TruncatedText` component is less
          than or equal to 0. e.g. InfoTooltip, where the DOM element is invoked only on hover or click */
          availableWidth = parentRect.width;
        }
        setMaxWidth(`${availableWidth}px`);

        // Calculate min width based on text length or other criteria
        const minWidth = Math.min(availableWidth, text?.length * 6); // Assuming average character width
        setMinWidth(`${minWidth}px`);
      }
    };

    const debouncedUpdateDimensions = debounce(updateDimensions, 200); // To prevent excessive recalculations on resize or DOM changes

    // Initial calculation
    debouncedUpdateDimensions();

    // Recalculate on window resize
    window.addEventListener('resize', debouncedUpdateDimensions);

    // Use MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(debouncedUpdateDimensions);
    if (paragraphRef.current && paragraphRef.current.parentElement) {
      observer.observe(paragraphRef.current.parentElement, { childList: true, subtree: true }); //  The observer is set to watch for changes in the child list of the parent element, which includes adding or removing siblings.
    }

    return () => {
      window.removeEventListener('resize', debouncedUpdateDimensions);
      observer.disconnect(); // The observer is disconnected when the component is unmounted to prevent memory leaks.
    };
  }, [maxChar, text]);

  // Default styles
  const defaultStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: maxWidth,
    minWidth: minWidth,
    boxSizing: 'border-box', // Ensure padding and border are included in the width
    ...style,
  };

  // Determine if text needs truncation
  const shouldTruncate = text?.length > maxChar;

  // Truncate text using helper method if necessary
  const displayText = shouldTruncate ? Helpers.truncateText(text, maxChar) : text;

  return (
    <p ref={paragraphRef} style={defaultStyle} {...props} className={`f-primary ${className}`} title={text}>
      {displayText}
    </p>
  );
};

TruncatedText.propTypes = {
  text: PropTypes.string.isRequired,
  maxChar: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default TruncatedText;
