import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { settingsFormConstants } from '../../../app/inspections/components/readings-and-gauges/constants/time-series-graph-constants';
import Icon from '../../icon/components/icon';
import '../styles/date-range-picker-field.scss';
import DayRangePicker from './day-range-picker';
import MonthRangePicker from './month-range-picker';
import WeekRangePicker from './week-range-picker';
import YearRangePicker from './year-range-picker';

const CHUNK_TYPES = {
  YEAR: 'YEARLY',
  MONTH: 'MONTHLY',
  WEEK: 'WEEKLY',
  DAY: 'DAILY',
};

/**
 * Format the selected date ranges for display
 * @param {Array} dateRanges Array of date range objects
 * @param {string} chunkType Type of chunk (YEAR, MONTH, etc)
 * @returns {string} Formatted string for display
 */
const formatSelectedRanges = (dateRanges, chunkType, t) => {
  if (!dateRanges || !Array.isArray(dateRanges) || dateRanges.length === 0) {
    return '';
  }

  // Filter out any null or non-object values
  const validRanges = dateRanges.filter(range => range !== null && typeof range === 'object');

  if (validRanges.length === 0) {
    return '';
  }

  if (validRanges.length > 1) {
    return t('MULTIPLE', { numberOfItems: validRanges.length });
  }

  // Additional validation to prevent null/undefined access
  if (!validRanges[0] || typeof validRanges[0] !== 'object' || validRanges[0][settingsFormConstants.dateRangeFrom.name] === undefined) {
    console.warn('Invalid date range format', validRanges[0]);
    return '';
  }

  const date = new Date(validRanges[0][settingsFormConstants.dateRangeFrom.name] * 1000);

  const chunkTypeKey = chunkType.Key;

  switch (chunkTypeKey) {
    case CHUNK_TYPES.YEAR:
      return date.getFullYear().toString();
    case CHUNK_TYPES.MONTH:
      return date.toLocaleDateString(undefined, { month: 'short' }) + ', ' + date.getFullYear();
    case CHUNK_TYPES.WEEK: {
      // Calculate week number
      const target = new Date(date.valueOf());
      const dayNumber = (date.getDay() + 6) % 7;
      target.setDate(target.getDate() - dayNumber + 3);
      const firstThursday = target.valueOf();
      target.setMonth(0, 1);
      if (target.getDay() !== 4) {
        target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
      }
      const weekNumber = 1 + Math.ceil((firstThursday - target) / 604800000);

      // Get week start and end dates
      const weekStartDate = new Date(date);
      // Adjust to start week on Monday instead of Sunday
      // getDay() returns 0 for Sunday, 1 for Monday, etc.
      // For Monday as first day: if day is 0 (Sunday), we go back 6 days, otherwise day-1
      const day = weekStartDate.getDay();
      const daysToSubtract = day === 0 ? 6 : day - 1;
      weekStartDate.setDate(weekStartDate.getDate() - daysToSubtract);

      const weekEndDate = new Date(weekStartDate);
      weekEndDate.setDate(weekEndDate.getDate() + 6);

      // Format as "Week X: MM/DD - MM/DD, YYYY"
      return `Week ${weekNumber}: ${weekStartDate.getMonth() + 1}/${weekStartDate.getDate()} - ${weekEndDate.getMonth() + 1}/${weekEndDate.getDate()}, ${weekStartDate.getFullYear()}`;
    }
    case CHUNK_TYPES.DAY:
      // Format as "MMM DD, YYYY" (e.g., "Jan 15, 2023")
      return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' });
    default:
      return date.toLocaleDateString();
  }
};

const DateRangePickerField = (
  {
    input,
    meta,
    placeholder,
    className = '',
    label,
    labelClass = '',
    disabled = false,
    size = 'lg',
    isRequired = false,
    isEnhancedDesignCustomProp = true,
    withHiddenError = false,
    chunk = CHUNK_TYPES.YEAR,
    maxRangesCount = 5,
  },
  { t }
) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const fieldRef = useRef(null);
  const pickerRef = useRef(null);

  // Initialize currentView from chunk prop
  const [currentView, setCurrentView] = useState(() => chunk.Key);

  // Reset currentView when chunk changes
  useEffect(() => {
    setCurrentView(chunk.Key);
  }, [chunk]);

  const [, setNavigationHistory] = useState([]);

  // Get current month and year for default values
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  const currentDay = now.getDate();

  // Navigation state
  const [navigationState, setNavigationState] = useState({
    fromPicker: null, // Which picker initiated navigation ('MONTH', 'WEEK', or 'DAY')
    originalPicker: null, // To track the original picker when navigating through multiple pickers
    selectedYear: currentYear, // Initialize with current year instead of null
    selectedMonth: currentMonth, // Initialize with current month instead of null
    selectedDay: currentDay, // Initialize with current day for day picker
    keepDropdownOpen: false, // Flag to keep dropdown open during navigation
  });

  useEffect(() => {
    // Reset navigation state when chunk changes
    setNavigationState({
      fromPicker: null,
      originalPicker: null,
      selectedYear: currentYear,
      selectedMonth: currentMonth,
      selectedDay: currentDay,
      keepDropdownOpen: false,
    });
  }, [chunk, currentYear, currentMonth, currentDay]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (!isPickerOpen) return;

      const isOutsideField = fieldRef.current && !fieldRef.current.contains(event.target);
      const isOutsidePicker = pickerRef.current && !pickerRef.current.contains(event.target);

      if (isOutsideField && isOutsidePicker) {
        setIsPickerOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPickerOpen]);

  const handleInputClick = useCallback(() => {
    if (!disabled) {
      setIsPickerOpen(prev => !prev);
      // Reset navigation history when reopening the picker
      if (!isPickerOpen) {
        setNavigationHistory([]);
        setCurrentView(chunk.Key);
      }
    }
  }, [disabled, isPickerOpen, chunk]);

  const handleChange = useCallback(
    dateRanges => {
      input?.onChange?.(dateRanges);
    },
    [input]
  );

  const formattedValue = formatSelectedRanges(input?.value, chunk, t);

  // Function to handle navigation from a picker to YearRangePicker
  const handleNavigateToYearPicker = useCallback(
    fromPicker => {
      setNavigationState(prev => {
        const newState = {
          fromPicker,
          // If we're already navigating from another picker, preserve the original picker
          originalPicker: prev.originalPicker || prev.fromPicker,
          selectedYear: null,
          selectedMonth: null,
          selectedDay: null,
          keepDropdownOpen: true,
        };
        return newState;
      });
      setCurrentView(CHUNK_TYPES.YEAR);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chunk]
  );

  // Function to handle navigation from WeekRangePicker or DayRangePicker to MonthRangePicker
  const handleNavigateToMonthPicker = useCallback(
    (fromPicker, yearValue, monthValue) => {
      setNavigationState(prev => {
        const newState = {
          fromPicker,
          // If we're already navigating from another picker, preserve the original picker
          originalPicker: prev.originalPicker || fromPicker,
          selectedYear: yearValue,
          selectedMonth: monthValue,
          selectedDay: null,
          keepDropdownOpen: true,
        };
        return newState;
      });
      setCurrentView(CHUNK_TYPES.MONTH);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chunk]
  );

  // Function to handle year selection from the YearRangePicker when in navigation mode
  const handleYearNavigate = useCallback(
    year => {
      const { fromPicker, originalPicker } = navigationState;

      // Set the selected year and go back to the original picker
      setNavigationState(prev => {
        const newState = {
          ...prev,
          selectedYear: year,
          keepDropdownOpen: true,
          // Preserve the original picker info
          originalPicker: originalPicker || fromPicker,
        };
        return newState;
      });

      // Determine which view to show next based on navigation context and chunk type

      // If the current chunk is MONTH and we're not in navigation from another picker type,
      // we should stay in MONTH view
      if (chunk.Key === CHUNK_TYPES.MONTH && !originalPicker && !fromPicker) {
        setCurrentView(CHUNK_TYPES.MONTH);
        return;
      }

      // Go back to the original picker type based on navigation history
      if (fromPicker === CHUNK_TYPES.MONTH || originalPicker === CHUNK_TYPES.MONTH) {
        setCurrentView(CHUNK_TYPES.MONTH);
      } else if (fromPicker === CHUNK_TYPES.WEEK || originalPicker === CHUNK_TYPES.WEEK) {
        setCurrentView(CHUNK_TYPES.WEEK);
      } else if (fromPicker === CHUNK_TYPES.DAY || originalPicker === CHUNK_TYPES.DAY) {
        setCurrentView(CHUNK_TYPES.MONTH); // First go to month picker when navigating from day picker
      } else {
        // If no specific navigation path is found, use the current chunk as the destination
        setCurrentView(chunk.Key);
      }
    },
    [navigationState, chunk]
  );

  // Handle dropdown closing
  const handleDropdownClose = useCallback(() => {
    if (navigationState.keepDropdownOpen) {
      // If we're in navigation mode and need to keep the dropdown open,
      // reset this flag but don't actually close the dropdown
      setNavigationState(prev => ({
        ...prev,
        keepDropdownOpen: false,
      }));
      return;
    }

    // Normal dropdown closing behavior
    setIsPickerOpen(false);
    setNavigationState({
      fromPicker: null,
      originalPicker: null,
      selectedYear: null,
      selectedMonth: null,
      selectedDay: null,
      keepDropdownOpen: false,
    });
  }, [navigationState]);

  const renderDropdown = () => {
    if (!isPickerOpen) {
      return null;
    }

    return (
      <div className="date-range-picker-field__dropdown">
        {currentView === CHUNK_TYPES.YEAR && (
          <YearRangePicker
            selectedRanges={input?.value || []}
            onChange={handleChange}
            maxRangesCount={maxRangesCount}
            isNavigationMode={!!navigationState.fromPicker}
            onNavigate={handleYearNavigate}
            onClose={handleDropdownClose}
          />
        )}
        {currentView === CHUNK_TYPES.MONTH && (
          <MonthRangePicker
            selectedRanges={input?.value || []}
            onChange={handleChange}
            maxRangesCount={maxRangesCount}
            onNavigateToYearPicker={() => handleNavigateToYearPicker(CHUNK_TYPES.MONTH)}
            selectedYear={navigationState.selectedYear}
            onClose={handleDropdownClose}
            isNavigationMode={!!navigationState.fromPicker}
            onNavigate={(year, month) => {
              // When a month is selected in navigation mode, set the state and go back to the original picker
              setNavigationState(prev => ({
                ...prev,
                selectedYear: year,
                selectedMonth: month,
                keepDropdownOpen: true,
              }));

              // Check if we need to go back to other pickers
              if (navigationState.fromPicker === CHUNK_TYPES.WEEK || navigationState.originalPicker === CHUNK_TYPES.WEEK) {
                setCurrentView(CHUNK_TYPES.WEEK);
              } else if (navigationState.fromPicker === CHUNK_TYPES.DAY || navigationState.originalPicker === CHUNK_TYPES.DAY) {
                setCurrentView(CHUNK_TYPES.DAY);
              } else {
                // This is the case when we're in Month chunk mode or navigating within Month mode
                // We need to generate a date range for the selected month only if:
                // 1. We're in Month chunk mode (currentView is MONTH and chunk.Key is MONTH)
                // 2. Or we're navigating from Month to Year and back to Month

                // If this is a Month chunk (not just navigating temporarily to month picker)
                // or we originated from Month chunk, add the selection
                if (chunk.Key === CHUNK_TYPES.MONTH || navigationState.originalPicker === CHUNK_TYPES.MONTH || navigationState.fromPicker === null) {
                  // Generate a date range for the selected month
                  const startDate = new Date(year, month, 1);
                  startDate.setHours(0, 0, 0, 0);
                  const endDate = new Date(year, month + 1, 0);
                  endDate.setHours(23, 59, 59, 999);

                  const monthRange = {
                    [settingsFormConstants.dateRangeFrom.name]: Math.floor(startDate.getTime() / 1000),
                    [settingsFormConstants.dateRangeTo.name]: Math.floor(endDate.getTime() / 1000),
                  };

                  // Update the selected range
                  const newSelectedRanges = [...(input?.value || [])].filter(range => range !== null && typeof range === 'object');

                  // Check if this month is already selected
                  const isAlreadySelected = newSelectedRanges.some(range => {
                    const date = new Date(range[settingsFormConstants.dateRangeFrom.name] * 1000);
                    return date.getFullYear() === year && date.getMonth() === month;
                  });

                  if (!isAlreadySelected && newSelectedRanges.length < maxRangesCount) {
                    newSelectedRanges.push(monthRange);
                    handleChange(newSelectedRanges.sort((a, b) => a[settingsFormConstants.dateRangeFrom.name] - b[settingsFormConstants.dateRangeFrom.name]));
                  }
                }
              }
            }}
          />
        )}
        {currentView === CHUNK_TYPES.WEEK && (
          <WeekRangePicker
            selectedRanges={input?.value || []}
            onChange={handleChange}
            maxRangesCount={maxRangesCount}
            onNavigateToYearPicker={() => handleNavigateToYearPicker(CHUNK_TYPES.WEEK)}
            onNavigateToMonthPicker={(year, month) => handleNavigateToMonthPicker(CHUNK_TYPES.WEEK, year, month)}
            selectedYear={navigationState.selectedYear}
            selectedMonth={navigationState.selectedMonth}
            onClose={handleDropdownClose}
          />
        )}
        {currentView === CHUNK_TYPES.DAY && (
          <DayRangePicker
            selectedRanges={input?.value || []}
            onChange={handleChange}
            maxRangesCount={maxRangesCount}
            onNavigateToYearPicker={() => handleNavigateToYearPicker(CHUNK_TYPES.DAY)}
            onNavigateToMonthPicker={(year, month) => handleNavigateToMonthPicker(CHUNK_TYPES.DAY, year, month)}
            selectedYear={navigationState.selectedYear}
            selectedMonth={navigationState.selectedMonth}
            onClose={handleDropdownClose}
          />
        )}
      </div>
    );
  };

  return (
    <div className={`field-wrapper ${className}`}>
      {label && (
        <div className="inline-wrapper">
          <label className={`field-wrapper__label ${labelClass} noselect`}>{`${t(label)}${isRequired ? '*' : ''}`}</label>
        </div>
      )}

      <div className={`input-wrapper ${isEnhancedDesignCustomProp ? 'enhanced-design-custom-prop' : ''}`}>
        <div className={`date-range-picker-field ${size} ${disabled ? 'disabled' : ''}`} ref={fieldRef}>
          <div className={`date-range-picker-field__input ${meta?.error && meta.invalid ? 'error' : ''}`} onClick={handleInputClick}>
            <span className="date-range-picker-field__value">{formattedValue || t(placeholder)}</span>
            <Icon name="chevron-down" className="dropdown-icon" handleHover={true} size="lg" />
          </div>

          {isPickerOpen && (
            <div className="date-range-picker-field__dropdown" ref={pickerRef}>
              {renderDropdown()}
            </div>
          )}

          <div className={`error-message${meta?.error && meta.invalid ? '--visible' : ''} ${withHiddenError ? 'with-hidden-error-message' : ''}`}>
            {meta?.error && meta.invalid && t(typeof meta.error === 'string' ? meta.error : meta.error.string)}
          </div>
        </div>
      </div>
    </div>
  );
};

DateRangePickerField.contextTypes = {
  t: PropTypes.func.isRequired,
};

DateRangePickerField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  isRequired: PropTypes.bool,
  isEnhancedDesignCustomProp: PropTypes.bool,
  withHiddenError: PropTypes.bool,
  chunk: PropTypes.object.isRequired,
  maxRangesCount: PropTypes.number,
};

export default DateRangePickerField;
