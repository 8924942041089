import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';

import { debounce } from 'lodash';
import { FORMS } from '../../../../../../common/constants';
import EmptyState from '../../../../../../common/empty-state/components/empty-state';
import Button from '../../../../../../common/form/components/button';
import TimeSeriesGraph from '../../../../../../common/graphs/components/time-series-graph';
import Helpers from '../../../../../../common/helpers';
import { MAXIMUM_MEASUREMENT_POINTS_MEASUREMENT_LOCATION_ANALYSIS, MAXIMUM_MEASUREMENT_POINTS_TIME_SERIES, formConstants as mlFormConstants } from '../../constants/measurement-location-constants';
import { defaultFilter, filterParams } from '../../constants/measurement-point-constants';
import { formConstants, settingsFormConstants, steps } from '../../constants/time-series-graph-constants';
import '../../styles/create-graphing-group-form.scss';
import { createGraphingGroupValidate } from '../../validators/create-graphing-group-validator';
import GraphingGroupDetailsFormFields from './graphing-group-details-form-fields';
import GraphingGroupSettingsFormFields from './graphing-group-settings-form-fields';
import { TIMESERIES_GRAPH_TYPE } from './graphing-groups-list';

const CreateGraphingGroupForm = (props, { t }) => {
  const {
    handleSubmit,
    activeStep,
    lastStep,
    stepAction,
    handleCancel,
    aggregationList,
    chunks,
    fetchMeasurementPoints,
    projectID,
    formValues,
    timePeriodsList,
    changeField,
    graphData,
    graphLoading,
    formIsValid,
    measurementLocation,
    isTimeSeriesGraph,
  } = props;
  const [measurementPoints, setMeasurementPoints] = useState([]);
  const [measurementPointsLoading, setMeasurementPointsLoading] = useState(false);
  const [measurementPointsFilters, setMeasurementPointsFilters] = useState({});
  const [measurementPointsSearchText, setMeasurementPointsSearchText] = useState('');
  const [keepMPsDropdownVisible, setKeepMPsDropdownVisible] = useState(false);

  useEffect(() => {
    getMeasurementPoints({
      ...defaultFilter,
      [filterParams.sortByColumn]: filterParams.measurementLocationID,
      [filterParams.measurementLocationSelectedId]: measurementLocation?.[mlFormConstants.fields.id],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMeasurementPoints = (filters, loadMore = false) => {
    setMeasurementPointsLoading(true);
    const handleSuccess = ({ data, filters: newFilters }) => {
      const newData = loadMore ? [...measurementPoints, ...data] : data;

      setMeasurementPoints(newData);
      setMeasurementPointsFilters({ ...measurementPointsFilters, ...newFilters });
      setMeasurementPointsLoading(false);
      if (loadMore) {
        Helpers.scrollIntoView('dropdown-render-item-container', `row-${newData?.length - 1}`, 0);
      }
    };
    const handleError = () => {
      setMeasurementPointsLoading(false);
    };
    fetchMeasurementPoints({ ...filters, [filterParams.projectID]: projectID }, false, handleSuccess, handleError, false);
  };

  const handleMeasurementPointsSearchTextChanged = SearchText => {
    setKeepMPsDropdownVisible(true);
    setMeasurementPointsSearchText(SearchText);
    measurementPointsSearchTextChanged(SearchText);
  };

  const onSearch = (SearchText, filters, apiCall, additionalFilters = {}) => {
    const newFilters = {
      ...filters,
      ...additionalFilters,
      [filterParams.lastSeen]: 0,
      SearchText,
    };
    apiCall && typeof apiCall === 'function' && apiCall(newFilters);
  };

  const handleMeasurementPointsSearch = SearchText => onSearch(SearchText, measurementPointsFilters, getMeasurementPoints);

  const measurementPointsSearchTextChanged = debounce(handleMeasurementPointsSearch, 300);

  const loadMoreMeasurementPoints = () => {
    setKeepMPsDropdownVisible(true);
    getMeasurementPoints(measurementPointsFilters, true);
  };

  const isTimeSeriesGraphCheck = isTimeSeriesGraph || formValues?.[settingsFormConstants.graphType.name] === TIMESERIES_GRAPH_TYPE;

  return (
    <form className="create-graphing-group-form" onSubmit={values => handleSubmit(values, isTimeSeriesGraphCheck)} noValidate>
      <div className="create-graphing-group-form__container">
        <div className="create-graphing-group-form__content">
          {activeStep === steps.firstStep && (
            <GraphingGroupDetailsFormFields
              readonly={false}
              measurementPoints={measurementPoints}
              searchApiRequired={true}
              handleAPISearch={handleMeasurementPointsSearchTextChanged}
              measurementPointsFilters={measurementPointsFilters}
              loadMoreOnClick={loadMoreMeasurementPoints}
              componentsSearchText={measurementPointsSearchText}
              keepMPsDropdownVisible={keepMPsDropdownVisible}
              measurementPointsLoading={measurementPointsLoading}
              measurementLocationId={measurementLocation?.[mlFormConstants.fields.id]}
              maximumSelectionOfMPs={isTimeSeriesGraphCheck ? MAXIMUM_MEASUREMENT_POINTS_TIME_SERIES : MAXIMUM_MEASUREMENT_POINTS_MEASUREMENT_LOCATION_ANALYSIS}
              allowMeasurementPointReordering={!isTimeSeriesGraphCheck}
            />
          )}
          {activeStep === steps.secondStep && (
            <GraphingGroupSettingsFormFields
              readonly={false}
              aggregationList={aggregationList}
              chunks={chunks}
              timePeriodsList={timePeriodsList}
              formValues={formValues}
              changeField={changeField}
              isTimeSeriesGraph={isTimeSeriesGraphCheck}
            />
          )}
        </div>
        <div className="create-graphing-group-form__graph-wrapper">
          {!formIsValid && !graphLoading ? (
            <EmptyState icon="bar-chart" title="GRAPHING_GROUP_DETAILS.GRAPH.EMPTY_STATE.TITLE" message="GRAPHING_GROUP_DETAILS.GRAPH.EMPTY_STATE.MESSAGE" />
          ) : (
            <TimeSeriesGraph
              data={graphData}
              isLoading={graphLoading}
              showLegend={false}
              visibleAggregations={formValues?.[settingsFormConstants.aggregation.name]}
              ignoreTheme
              isTimeSeriesGraph={isTimeSeriesGraphCheck}
            />
          )}
        </div>
      </div>
      <div className="buttons">
        <Button type="button" variant="gray-outline" width="sm" height="md" text={t('CANCEL')} onClick={handleCancel} />
        <div className="right-buttons">
          <Button type="button" variant="gray-outline" width="sm" disabled={activeStep === steps.firstStep} height="md" text={t('BACK')} onClick={() => stepAction(false)} />
          <Button
            height="md"
            className="button-submit"
            type="submit"
            width="sm"
            disabled={!formIsValid} // disables submit button if form is not valid, but only for last step for now
            text={t(activeStep === lastStep ? (formValues[formConstants.id] && formValues[formConstants.id] > 0 ? 'SAVE' : 'CREATE') : 'NEXT')}
          />
        </div>
      </div>
    </form>
  );
};

CreateGraphingGroupForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.createGraphingGroupForm,
  enableReinitialize: true,
  validate: (values, props) => createGraphingGroupValidate(values, props.isTimeSeriesGraph),
})(CreateGraphingGroupForm);
