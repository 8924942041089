import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { settingsFormConstants } from '../../../app/inspections/components/readings-and-gauges/constants/time-series-graph-constants';
import Icon from '../../icon/components/icon';

/**
 * MonthRangePicker component allows users to select up to 5 months with navigation controls
 * Can also operate in navigation mode where only one month can be selected for navigation purposes
 */
const MonthRangePicker = (
  {
    selectedRanges = [],
    onChange,
    className = '',
    disabled,
    isNavigationMode = false, // Flag to indicate navigation mode
    onNavigate, // Called when a month is selected in navigation mode
    onClose, // Called when picker should close (e.g. after navigation selection)
    selectedYear, // Used in navigation mode to set the initial year
    onNavigateToYearPicker, // renamed from onYearClick for consistency
  },
  { t }
) => {
  // Calculate the current date for validation
  const currentDate = useMemo(() => new Date(), []);
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // State for the visible year
  const [visibleYear, setVisibleYear] = useState(() => {
    // In navigation mode use the provided year, if any
    if (selectedYear) {
      return selectedYear;
    }

    // Otherwise use normal logic
    if (selectedRanges.length > 0) {
      const earliestDate = Math.min(...selectedRanges.map(range => range[settingsFormConstants.dateRangeFrom.name]));
      return new Date(earliestDate * 1000).getFullYear();
    }
    return currentYear;
  });

  // Update visible year when selectedYear prop changes (for navigation)
  useEffect(() => {
    if (selectedYear) {
      setVisibleYear(selectedYear);
    }
  }, [selectedYear]);

  // Month names for display
  const months = useMemo(
    () => [
      t('MONTH_JANUARY_SHORT'),
      t('MONTH_FEBRUARY_SHORT'),
      t('MONTH_MARCH_SHORT'),
      t('MONTH_APRIL_SHORT'),
      t('MONTH_MAY_SHORT'),
      t('MONTH_JUNE_SHORT'),
      t('MONTH_JULY_SHORT'),
      t('MONTH_AUGUST_SHORT'),
      t('MONTH_SEPTEMBER_SHORT'),
      t('MONTH_OCTOBER_SHORT'),
      t('MONTH_NOVEMBER_SHORT'),
      t('MONTH_DECEMBER_SHORT'),
    ],
    [t]
  );

  // Convert month to DateRange format
  const monthToDateRange = useCallback((year, month) => {
    const startDate = new Date(year, month, 1); // First day of month
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(year, month + 1, 0); // Last day of month
    endDate.setHours(23, 59, 59, 999);

    return {
      [settingsFormConstants.dateRangeFrom.name]: Math.floor(startDate.getTime() / 1000),
      [settingsFormConstants.dateRangeTo.name]: Math.floor(endDate.getTime() / 1000),
    };
  }, []);

  // Get selected months from ranges
  const selectedMonths = useMemo(() => {
    return selectedRanges.map(range => {
      const date = new Date(range[settingsFormConstants.dateRangeFrom.name] * 1000);
      return {
        year: date.getFullYear(),
        month: date.getMonth(),
      };
    });
  }, [selectedRanges]);

  // Handle month selection
  const handleMonthClick = useCallback(
    month => {
      if (disabled || (visibleYear === currentYear && month > currentMonth)) return;

      // In navigation mode, we just call onNavigate with the selected month information
      if (isNavigationMode) {
        if (onNavigate) {
          // When in navigation mode, only navigate, don't modify selection
          onNavigate(visibleYear, month);
          if (onClose) onClose();
        } else {
          console.warn('MonthRangePicker: isNavigationMode is true but onNavigate callback is not provided');
        }
        return;
      }

      // Regular selection mode (original behavior)
      const newSelectedRanges = [...(selectedRanges || [])].filter(range => range !== null && typeof range === 'object');

      // Check if this month is already selected
      const monthIndex = selectedMonths.findIndex(m => m.year === visibleYear && m.month === month);

      if (monthIndex !== -1) {
        // Deselect the month
        newSelectedRanges.splice(monthIndex, 1);
      } else if (newSelectedRanges.length < 5) {
        // Select the month if under limit
        newSelectedRanges.push(monthToDateRange(visibleYear, month));
      }

      onChange?.(newSelectedRanges.sort((a, b) => a[settingsFormConstants.dateRangeFrom.name] - b[settingsFormConstants.dateRangeFrom.name]));
    },
    [selectedRanges, selectedMonths, onChange, disabled, monthToDateRange, visibleYear, currentYear, currentMonth, isNavigationMode, onNavigate, onClose]
  );

  // Navigation handlers
  const handlePrevious = useCallback(() => {
    if (disabled) return;
    setVisibleYear(prev => prev - 1);
  }, [disabled]);

  const handleNext = useCallback(() => {
    if (disabled || visibleYear >= currentYear) return;
    setVisibleYear(prev => prev + 1);
  }, [visibleYear, currentYear, disabled]);

  return (
    <div className={`month-range-picker ${className}`}>
      <div className="month-range-picker__header">
        <Icon name="chevron" size="sm" className="month-range-picker__nav-btn previous" onClick={handlePrevious} disabled={disabled} />

        {/* Make the year clickable and add a chevron icon */}
        <div className="month-range-picker__year-display" onClick={onNavigateToYearPicker}>
          <span>{visibleYear}</span>
          <Icon name="chevron-down" size="sm" className="month-range-picker__year-icon" />
        </div>

        <Icon name="chevron" size="sm" className="month-range-picker__nav-btn next" onClick={handleNext} disabled={visibleYear >= currentYear || disabled} />
      </div>
      <div className="month-range-picker__grid">
        {months.map((month, index) => {
          const isSelected = selectedMonths.some(m => m.year === visibleYear && m.month === index);
          const isDisabled = visibleYear === currentYear && index > currentMonth;

          return (
            <button
              key={month}
              type="button"
              className={`
                month-range-picker__month-btn 
                ${isSelected ? 'month-range-picker__month-btn--selected selected' : ''} 
                ${isDisabled || disabled ? 'month-range-picker__month-btn--disabled' : ''}
                ${isNavigationMode ? 'month-range-picker__month-btn--navigation' : ''}
              `}
              onClick={() => handleMonthClick(index)}
              disabled={isDisabled || disabled}
            >
              {month}
            </button>
          );
        })}
      </div>
    </div>
  );
};

MonthRangePicker.contextTypes = {
  t: PropTypes.func.isRequired,
};

MonthRangePicker.propTypes = {
  selectedRanges: PropTypes.arrayOf(
    PropTypes.shape({
      [settingsFormConstants.dateRangeFrom.name]: PropTypes.number.isRequired,
      [settingsFormConstants.dateRangeTo.name]: PropTypes.number.isRequired,
    })
  ),
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isNavigationMode: PropTypes.bool,
  onNavigate: PropTypes.func,
  onClose: PropTypes.func,
  selectedYear: PropTypes.number,
  onNavigateToYearPicker: PropTypes.func, // renamed from onYearClick for consistency
};

export default MonthRangePicker;
