import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { isEmpty } from 'lodash';
import { Field } from 'redux-form';
import DateRangePickerForm, { dateRangeFormConstants } from '../../../../../../common/form/components/date-range-picker-form';
import CustomSelect from '../../../../../../common/form/components/select';
import Helpers from '../../../../../../common/helpers';
import Modal from '../../../../../../common/modal/components/modal';
import { customTimePeriodIndex, settingsFormConstants, timePeriodFields } from '../../constants/time-series-graph-constants';
import '../../styles/time-period-field.scss';

const TimePeriodField = (
  {
    id,
    name,
    label,
    placeholder,
    disabled,
    data,
    textField,
    valueField,
    changeField,
    formValues,
    size = 'lg',
    isEnhancedDesignCustomProp = true,
    isRequired = true,
    CustomSelectIcon = undefined,
    ...restProps
  },
  { t }
) => {
  const [modalData, setModalData] = useState({ isOpen: false });
  const [previousSelection, setPreviousSelection] = useState(formValues?.[name] || null);

  const handleOpenDatePicker = () => {
    const closeAction = () => {
      setModalData({ isOpen: false });
    };

    const handleSubmit = values => {
      changeField(settingsFormConstants.dateFrom.name, values[dateRangeFormConstants.dateFrom.name]);
      changeField(settingsFormConstants.dateTo.name, values[dateRangeFormConstants.dateTo.name]);
      setPreviousSelection(data[customTimePeriodIndex]?.[timePeriodFields.value] || null);
      closeAction();
    };

    const handleCancel = () => {
      if (previousSelection) {
        changeField(name, previousSelection); // Revert to the previous selection
      }
      closeAction();
    };

    setModalData({
      isOpen: true,
      title: t('TIME_PERIOD.DATE_PICKER_MODAL.TITLE'),
      type: 'none',
      customClassWrapperName: 'custom-time-period-modal',
      CustomContent: dynamicProps => (
        <DateRangePickerForm
          onSubmit={handleSubmit}
          initialValues={{
            [dateRangeFormConstants.dateFrom.name]: formValues[settingsFormConstants.dateFrom.name],
            [dateRangeFormConstants.dateTo.name]: formValues[settingsFormConstants.dateTo.name],
          }}
          size={size}
          isEnhancedDesignCustomProp={isEnhancedDesignCustomProp}
          isRequired={isRequired}
          customCloseAction={handleCancel}
        />
      ),
      customCloseAction: handleCancel,
      closeAction: handleCancel,
    });
  };

  const handleSelect = val => {
    if (val[timePeriodFields.value] === data[customTimePeriodIndex]?.[timePeriodFields.value]) {
      handleOpenDatePicker();
    } else {
      setPreviousSelection(val[timePeriodFields.value] || null);
    }
  };

  const onChange = val => {
    if (val[timePeriodFields.value] === data[customTimePeriodIndex]?.[timePeriodFields.value]) {
      return;
    } else {
      const dateRange = Helpers.getDateRangeByTimePeriod(val[timePeriodFields.value]);

      changeField(settingsFormConstants.dateFrom.name, new Date(dateRange.DateFrom * 1000));
      changeField(settingsFormConstants.dateTo.name, new Date(dateRange.DateTo * 1000));
    }
  };

  const customPeriodValue = useMemo(() => {
    if (isEmpty(formValues) || !formValues[settingsFormConstants.dateFrom.name] || !formValues[settingsFormConstants.dateTo.name]) {
      return null;
    }
    const dateFrom = Helpers.isUnixDateLengthCorrect(formValues[settingsFormConstants.dateFrom.name])
        ? formValues[settingsFormConstants.dateFrom.name]
        : Helpers.dateToUnix(formValues[settingsFormConstants.dateFrom.name]),
      dateTo = Helpers.isUnixDateLengthCorrect(formValues[settingsFormConstants.dateTo.name])
        ? formValues[settingsFormConstants.dateTo.name]
        : Helpers.dateToUnix(formValues[settingsFormConstants.dateTo.name]);

    const formattedDateFrom = !isNaN(dateFrom) && dateFrom > 0 ? Helpers.getDateFromUnix(dateFrom, 'DD/MM/YYYY HH:mm') : '-',
      formattedDateTo = !isNaN(dateTo) && dateTo > 0 ? Helpers.getDateFromUnix(dateTo, 'DD/MM/YYYY HH:mm') : '-';
    return <p className="f-primary">{`${formattedDateFrom} - ${formattedDateTo}`}</p>;
  }, [formValues]);

  return (
    <div className="time-period-field">
      <Field
        id={id}
        name={name}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        component={CustomSelect}
        handleChange={onChange}
        onSelect={handleSelect}
        valueComponent={({ item }) => {
          if (item[timePeriodFields.value] === data[customTimePeriodIndex][timePeriodFields.value]) {
            return customPeriodValue;
          }
          return <p className="f-primary">{item[timePeriodFields.key]}</p>;
        }}
        data={data}
        textField={textField}
        valueField={valueField}
        size={size}
        isEnhancedDesignCustomProp={isEnhancedDesignCustomProp}
        isRequired={isRequired}
        CustomSelectIcon={CustomSelectIcon}
      />
      <Modal {...modalData} />
    </div>
  );
};

TimePeriodField.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default TimePeriodField;
