export default {
  FETCH_GROUPS: '/project/readings/groups/list',
  CREATE_GROUP: '/project/readings/group/create',
  EDIT_GROUP: '/project/readings/group/edit',
  DELETE_GROUP: '/project/readings/group/delete',
  FETCH_MEASUREMENT_LOCATIONS: '/project/measurement/location/all/list',
  CREATE_MEASUREMENT_LOCATION: '/project/measurement/location/create',
  GET_MEASUREMENT_LOCATION_DETAILS: '/project/measurement/location/details',
  UPDATE_MEASUREMENT_LOCATION_DETAILS: '/project/measurement/location/edit',
  DELETE_MEASUREMENT_LOCATION: '/project/measurement/location/delete',
  GET_MEASUREMENT_LOCATIONS_CLUSTERED: '/project/measurement/location/3d/list',
  UPDATE_MEASUREMENT_LOCATION_GEOMETRY: '/project/measurement/location/geometry/update',
  LINK_MEASUREMENT_LOCATION_COMPONENT: '/project/measurement/location/component/link',
  CHANGE_MEASUREMENT_GROUP: '/project/measurement/location/group/update',
  FETCH_MEASUREMENT_POINTS: '/project/measurement/point/all/list',
  CREATE_MEASUREMENT_POINT: '/project/measurement/point/create',
  GET_MEASUREMENT_POINT_DETAILS: '/project/measurement/point/details',
  UPDATE_MEASUREMENT_POINT_DETAILS: '/project/measurement/point/edit',
  DELETE_MEASUREMENT_POINT: '/project/measurement/point/delete',
  GET_MEASUREMENT_POINTS_CLUSTERED: '/project/measurement/point/3d/list',
  UPDATE_MEASUREMENT_POINT_GEOMETRY: '/project/measurement/point/geometry/update',
  LINK_MEASUREMENT_POINT_COMPONENT: '/project/measurement/point/component/link',
  UNLINK_MEASUREMENT_POINT_COMPONENT: '/project/measurement/point/component/unlink',
  FETCH_USERS_AND_TEAMS: '/project/measurement/point/alarm/assignment/search',
  FETCH_ALARM_DESCRIPTORS: '/project/measurement/point/alarm/descriptors/list',
  FETCH_MEASUREMENT_POINT_ALARMS: '/project/measurement/point/alarms/list',
  UPDATE_MEASUREMENT_POINT_ALARMS: '/project/measurement/point/alarm/edit',
  SAVE_MEASUREMENT_READING: '/project/measurement/reading/save',
  UPDATE_MEASUREMENT_READING: '/project/measurement/reading/update',
  DELETE_MEASUREMENT_READING: '/project/measurement/reading/delete',
  FETCH_MEASUREMENT_READINGS: '/project/measurement/reading/list',
  GET_MEASUREMENT_POINT_OBSERVATIONS: '/project/measurement/point/defects/list',
  GET_MEASUREMENT_POINT_NOTIFICATIONS: '/project/measurement/point/notifications/list',
  GET_MEASUREMENT_POINTS_FOR_MEASUREMENT_LOCATION: '/project/measurement/location/point/list',
  UPDATE_ORDER_MEASUREMENT_POINTS_INSIDE_MEASUREMENT_LOCATION: '/project/measurement/location/point/order',
  GET_GRAPHING_GROUP_LIST: '/project/measurement/location/default/graph/list',
  GET_AGGREGATION_TYPES: '/project/readings/graph/aggregation/list',
  GET_CHUNKS: '/project/readings/graph/chunk/list',
  GET_MEASUREMENT_LOCATION_ANALYSIS_CHUNKS: '/project/measurement/location/analysis/chunk/list',
  CREATE_GRAPHING_GROUP: '/project/measurement/location/default/graph/save',
  DELETE_GRAPHING_GROUP: '/project/measurement/location/default/graph/delete',
  GET_GRAPHING_GROUP_DETAILS: '/project/measurement/location/default/graph/details',
  GET_MEASUREMENT_LOCATION_TIME_SERIES_GRAPH: '/project/measurement/location/timeseries/graph',
  GET_MEASUREMENT_POINT_TIME_SERIES_GRAPH: '/project/measurement/point/timeseries/graph',
  GET_MEASUREMENT_POINT_ANALYSIS_GRAPH: '/project/measurement/point/analysis/graph',
  GET_MEASUREMENT_LOCATION_ANALYSIS_GRAPH: '/project/measurement/location/analysis/graph',
  SAVE_AS_DEFAULT_GRAPHING_GROUP: '/project/measurement/location/default/graph/set',
  GENERATE_AND_DOWNLOAD_REPORT: '/project/readings/report/xlsx',
  FETCH_UNITS: '/project/readings/units/list',
  GET_MEASUREMENT_POINT_GRAPH_DETAILS: '/project/measurement/point/default/graph/details',
  SAVE_MEASUREMENT_POINT_GRAPH_DETAILS: '/project/measurement/point/default/graph/save',
  FETCH_CP_MEASUREMENT_LOCATIONS: '/project/checklists/section/measurement/location/list',
  FETCH_CP_MEASUREMENT_POINTS: '/project/checklist/template/measurement/point/list',
  FETCH_CP_MEASUREMENT_GROUPS: '/project/checklists/groups/list',
  LINK_MEASUREMENT_LOCATION_TO_CP: '/project/checklists/measurement/location/link',
  LINK_MEASUREMENT_POINT_TO_CP: '/project/checklists/measurement/point/link',
  FETCH_CP_LINKED_MEASUREMENT_POINTS: '/project/checklists/measurement/point/linked/list',
  UNLINK_MEASUREMENT_POINT_FROM_CP: '/project/checklists/measurement/point/unlink',
  UNLINK_MEASUREMENT_LOCATION_FROM_CP: '/project/checklists/measurement/location/unlink',
  FETCH_MEASUREMENT_READING_UPLOADED_FILES: '/project/measurement/reading/files/list',
  FETCH_MEASUREMENT_READING_HIERARCHY: '/project/readings/deeplink/hierarchy',
  ARCHIVE_MEASUREMENT_LOCATION: '/project/measurement/location/archive',
  UNARCHIVE_MEASUREMENT_LOCATION: '/project/measurement/location/unarchive',
  ARCHIVE_MEASUREMENT_POINT: '/project/measurement/point/archive',
  UNARCHIVE_MEASUREMENT_POINT: '/project/measurement/point/unarchive',
  FETCH_MEASUREMENT_LOCATION_COMMENTS: '/project/measurement/location/comment/list',
  ADD_MEASUREMENT_LOCATION_COMMENT: '/project/measurement/location/comment/add',
  DELETE_MEASUREMENT_LOCATION_COMMENT: '/project/measurement/location/comment/delete',
  FETCH_MEASUREMENT_POINT_COMMENTS: '/project/measurement/point/comment/list',
  ADD_MEASUREMENT_POINT_COMMENT: '/project/measurement/point/comment/add',
  DELETE_MEASUREMENT_POINT_COMMENT: '/project/measurement/point/comment/delete',
};
