import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { alarmColorPalette, formConstants as alarmConstants, ALARMS } from '../../../app/inspections/components/readings-and-gauges/constants/alarm-constants';
import { formConstants as mpFormConstants } from '../../../app/inspections/components/readings-and-gauges/constants/measurement-point-constants';
import { aggregationFields, formConstants } from '../../../app/inspections/components/readings-and-gauges/constants/time-series-graph-constants';
import { filterParams } from '../../../app/inspections/components/readings-and-gauges/constants/time-series-graph-modal-constants';
import { THEMES } from '../../constants';
import Loader from '../../global-loader/components/simple-loader';
import Helpers from '../../helpers';
import '../styles/time-series-graph.scss';
import CustomLegend from './custom-legend';

highchartsMore(Highcharts);

let TimeSeriesGraph = (
  { colors, unit = '-', data = [], isLoading = false, showLegend = true, showAlarms = false, forwardRef, visibleAggregations = [], isGraphPreview = false, isTimeSeriesGraph = true },
  { t }
) => {
  const [chartInstance, setChartInstance] = useState(null);
  const [extremes, setExtremes] = useState({ min: null, max: null });
  const [selectedMeasurementPoint, setSelectedMeasurementPoint] = useState(null);
  const [plotLines, setPlotLines] = useState([]);

  // Add an effect to synchronize the plotLines when selectedMeasurementPoint changes
  useEffect(() => {
    if (selectedMeasurementPoint && !isGraphPreview && showAlarms) {
      // If there's a selectedMeasurementPoint but no plotLines, we should generate them
      if (plotLines.length === 0 && selectedMeasurementPoint.alarms?.length > 0) {
        // Generate plotLines from the alarms
        const newPlotLines = selectedMeasurementPoint.alarms.map(alarm => ({
          color: alarm.color,
          dashStyle: 'ShortDash',
          value: alarm.value,
          width: 2,
          label: { text: alarm.name },
          scaleFactor: selectedMeasurementPoint.scaleFactor || 1,
          inverted: selectedMeasurementPoint.inverted || false,
          measurementPointID: selectedMeasurementPoint.measurementPointID,
          unit: alarm.unit || selectedMeasurementPoint.unit || '-',
        }));

        // Update the plotLines
        setPlotLines(newPlotLines);
      }

      // Verify plotLines match the selectedMeasurementPoint
      if (plotLines.length > 0) {
        const matchingMP = plotLines.every(line => line.measurementPointID === selectedMeasurementPoint.measurementPointID);

        if (!matchingMP) {
          // Clear plotLines that don't match the currently selected MP if it's a time series graph
          if (isTimeSeriesGraph) {
            setPlotLines([]);
          }

          // Regenerate plotLines from the current selectedMeasurementPoint
          if (selectedMeasurementPoint.alarms?.length > 0) {
            const fixedPlotLines = selectedMeasurementPoint.alarms.map(alarm => ({
              color: alarm.color,
              dashStyle: 'ShortDash',
              value: alarm.value,
              width: 2,
              label: { text: alarm.name },
              scaleFactor: selectedMeasurementPoint.scaleFactor || 1,
              inverted: selectedMeasurementPoint.inverted || false,
              measurementPointID: selectedMeasurementPoint.measurementPointID,
              unit: alarm.unit || selectedMeasurementPoint.unit || '-',
            }));

            // Update with the corrected plotLines
            setTimeout(() => {
              setPlotLines(fixedPlotLines);
            }, 10);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMeasurementPoint, plotLines, isGraphPreview, showAlarms]);

  // Function to build time series graph data
  const buildTimeSeriesGraphData = useCallback((data, visibleAggregations, colors) => {
    const mergedSeries = [];
    const availableColors = [
      colors.colorGreen,
      colors.colorYellow,
      colors.colorOrange,
      colors.colorRed,
      colors.colorPink,
      colors.colorPurple,
      colors.colorBlue,
      colors.colorGreenDark,
      colors.colorYellowDark,
      colors.colorOrangeDark,
      colors.colorRedDark,
      colors.colorPinkDark,
      colors.colorPurpleDark,
      colors.colorBlueDark,
      colors.colorGreenLight,
      colors.colorYellowLight,
      colors.colorOrangeLight,
      colors.colorRedLight,
      colors.colorPinkLight,
      colors.colorPurpleLight,
      colors.colorBlueLight,
    ];
    const lineOptions = {
      type: 'line',
    };
    const areaRangeOptions = {
      type: 'arearange',
    };

    const getAlarmColor = alarm => {
      const color = alarmColorPalette.hasOwnProperty(alarm[alarmConstants.fields.colour.name]) ? alarmColorPalette[alarm[alarmConstants.fields.colour.name]] : null;
      const isObject = typeof color === 'object';

      return isObject ? color.value : color;
    };

    // Function for constructing series objects based on visible aggregations
    const constructSeriesObjectByVisibleAggregations = (seriesItem, parent, parentIndex) => {
      const staticProps = {
        measurementPointName: parent?.[filterParams.measurementPointName] || `Measurement Point Name ${parentIndex}`,
        measurementPointID: parent?.[filterParams.measurementPointID],
        alarms: (parent?.[mpFormConstants.fields.alarms] || []).map(alarm => ({
          name: alarm[alarmConstants.fields.name.name],
          value: alarm[alarmConstants.fields.alarmLevel.name],
          color: getAlarmColor(alarm),
          description: alarm[alarmConstants.fields.description.name],
          descriptor: alarm[alarmConstants.fields.descriptor.name],
          ID: alarm[mpFormConstants.fields.id],
          unit: parent?.[mpFormConstants.fields.unit] || '-',
        })),
        scaleFactor: parent?.[mpFormConstants.fields.scaleFactor] && parent?.[mpFormConstants.fields.scaleFactor] !== 0 ? parent?.[mpFormConstants.fields.scaleFactor] : 1,
        inverted: parent?.[mpFormConstants.fields.inverted],
        dashStyle: seriesItem[mpFormConstants.fields.name] === 'Alarms' ? 'ShortDash' : seriesItem[mpFormConstants.fields.name] === 'Median' ? 'Dash' : 'Solid',
        color: availableColors[parentIndex] || colors.primaryFontColor,
        unit: parent?.[mpFormConstants.fields.unit] || '-',
      };
      let dynamicProps = {
        data: seriesItem.Data,
        name: `${seriesItem[mpFormConstants.fields.name]}`,
      };

      if (seriesItem[mpFormConstants.fields.name] === 'Min/Max' || seriesItem[mpFormConstants.fields.name] === 'MinMin/MaxMax') {
        // Min/Max or MinMin/MaxMax clustered
        const isMinMax = seriesItem[mpFormConstants.fields.name] === 'Min/Max';
        const isMinMinMaxMax = seriesItem[mpFormConstants.fields.name] === 'MinMin/MaxMax';

        if (isMinMax || isMinMinMaxMax) {
          const minKey = isMinMax ? 'MINIMUM' : 'MINMIN';
          const maxKey = isMinMax ? 'MAXIMUM' : 'MAXMAX';

          if (visibleAggregations.some(aggregation => aggregation[aggregationFields.key] === maxKey) && visibleAggregations.some(aggregation => aggregation[aggregationFields.key] === minKey)) {
            // show min and max on the chart in form of area range
            dynamicProps = { ...dynamicProps, ...areaRangeOptions };
          } else {
            dynamicProps = { ...dynamicProps, ...lineOptions };
            if (visibleAggregations.some(aggregation => aggregation[aggregationFields.key] === minKey)) {
              dynamicProps = { ...dynamicProps, data: (seriesItem.Data || []).map(values => [values[0], values[1]]), name: isMinMax ? 'Min' : 'MinMin' };
            } else if (visibleAggregations.some(aggregation => aggregation[aggregationFields.key] === maxKey)) {
              dynamicProps = { ...dynamicProps, data: (seriesItem.Data || []).map(values => [values[0], values[2]]), name: isMinMax ? 'Max' : 'MaxMax' };
            } else {
              // Series should not be visible on graph, aggregation is not visible
              return null;
            }
          }
        }
      } else {
        if (visibleAggregations.some(aggregation => aggregation[aggregationFields.value] === seriesItem[formConstants.name] || aggregation[aggregationFields.key] === seriesItem[formConstants.name])) {
          dynamicProps = { ...dynamicProps, ...lineOptions };
        } else {
          // Series should not be visible on graph, aggregation is not visible
          return null;
        }
      }

      return { ...staticProps, ...dynamicProps };
    };

    // Process time series data
    data.forEach((parent, i) => {
      parent.Series?.forEach(seriesItem => {
        if (!isEmpty(seriesItem.Data)) {
          const seriesObject = constructSeriesObjectByVisibleAggregations(seriesItem, parent, i);
          if (seriesObject) {
            // Ensure dashStyle is explicitly set based on series type
            if (!seriesObject.dashStyle) {
              seriesObject.dashStyle = seriesItem[mpFormConstants.fields.name] === 'Alarms' ? 'ShortDash' : seriesItem[mpFormConstants.fields.name] === 'Median' ? 'Dash' : 'Solid';
            }
            mergedSeries.push(seriesObject);
          }
        }
      });
    });

    return mergedSeries;
  }, []);

  // Function to build analysis graph data
  const buildAnalysisGraphData = useCallback((data, visibleAggregations, colors) => {
    const mergedSeries = [];

    // Create a unique list of measurement points
    const processedPoints = new Set();
    const uniqueMeasurementPoints = [];

    // First, collect unique measurement points
    data.forEach(point => {
      if (!processedPoints.has(point.MeasurementPointID)) {
        processedPoints.add(point.MeasurementPointID);
        uniqueMeasurementPoints.push(point);
      }
    });

    // Create a unique and ordered list of measurement point IDs for the x-axis
    const mpIDs = [...new Set(data.map(mp => mp.MeasurementPointID))].sort((a, b) => a - b);

    // Initialize the series data structure
    const seriesTypes = ['Average', 'Median', 'MinMin', 'MaxMax', 'Alarms'];
    const seriesMap = {};

    // Initialize each series type with an empty array
    seriesTypes.forEach(type => {
      seriesMap[type] = {
        name: type,
        type: 'line',
        color: type === 'Average' ? '#34A853' : type === 'Median' ? '#1A73E8' : type === 'MinMin' ? '#FBBC04' : type === 'MaxMax' ? '#EA4335' : type === 'Alarms' ? '#FF0000' : '#1A73E8',
        dashStyle: type === 'Median' ? 'Dash' : type === 'MinMin' || type === 'MaxMax' ? 'Solid' : type === 'Alarms' ? 'ShortDash' : 'Solid',
        lineWidth: 2,
        data: [],
        measurementPoints: [], // Store additional data for tooltips
      };
    });

    // Map series types to aggregation field keys for filtering
    const seriesTypeToAggregationKey = {
      Average: 'AVERAGE',
      Median: 'MEDIAN',
      MinMin: 'MINMIN',
      MaxMax: 'MAXMAX',
      Min: 'MINIMUM',
      Max: 'MAXIMUM',
      Alarms: 'ALARMS',
    };

    // Process all data points and group them by series type
    data.forEach(point => {
      if (!point.Series) return;

      const mpID = point.MeasurementPointID;
      const mpName = point.MeasurementPointName || `MP ${mpID}`;
      const mpUnit = point.Unit || '-';
      const xIndex = mpIDs.indexOf(mpID);

      // Process each series in the measurement point
      point.Series.forEach(serie => {
        // Check for both Values and Data formats to support different data structures
        const hasValues = serie.Values && serie.Values.length && !serie.Values.every(v => v === null);
        const hasData = serie.Data && serie.Data.length;

        // Skip if no valid data
        if (!hasValues && !hasData) return;

        // Get the series type - from Type or Name field depending on the data structure
        const seriesType = serie.Type || serie.Name;

        if (!seriesType) {
          return;
        }

        // Normalize the series type name
        let normalizedType = seriesType;
        if (seriesType === 'Min/Max') {
          // Skip for now - will handle special case
          return;
        } else if (seriesType === 'MinMin/MaxMax') {
          // Skip for now - will handle special case
          return;
        } else if (!['Average', 'Median', 'MinMin', 'MaxMax', 'Alarms'].includes(normalizedType)) {
          return;
        }

        // Check if this series type should be visible based on aggregation settings
        const aggregationKey = seriesTypeToAggregationKey[normalizedType];
        if (aggregationKey && visibleAggregations.length > 0 && !visibleAggregations.some(agg => agg[aggregationFields.key] === aggregationKey)) {
          return;
        }

        // Handle different series types
        if (normalizedType === 'Average' || normalizedType === 'Median') {
          // For Average and Median, add a single point
          let value = null;

          if (hasValues) {
            value = serie.Values[0]; // Traditional format
          } else if (hasData) {
            // Mock data format: [mpID, value]
            value = serie.Data[0][1];
          }

          if (value !== null) {
            seriesMap[normalizedType].data.push([xIndex, value]);
            seriesMap[normalizedType].measurementPoints.push({
              x: xIndex,
              y: value,
              measurementPointID: mpID,
              measurementPointName: mpName,
              unit: mpUnit,
              seriesType: normalizedType,
            });
          }
        }
      });

      // Process Min/Max and MinMin/MaxMax as special cases
      point.Series.forEach(serie => {
        if (!serie.Data || !serie.Data.length) return;

        const seriesType = serie.Name;

        if (seriesType === 'Min/Max') {
          // Min/Max has format [mpID, min, max]
          if (serie.Data[0].length >= 3) {
            const min = serie.Data[0][1];
            const max = serie.Data[0][2];

            // Check visibility based on aggregation settings
            if (visibleAggregations.length > 0) {
              const minKey = 'MINIMUM';
              const maxKey = 'MAXIMUM';

              if (min !== null && visibleAggregations.some(agg => agg[aggregationFields.key] === minKey)) {
                seriesMap['MinMin'].data.push([xIndex, min]);
                seriesMap['MinMin'].measurementPoints.push({
                  x: xIndex,
                  y: min,
                  measurementPointID: mpID,
                  measurementPointName: mpName,
                  unit: mpUnit,
                  seriesType: 'Min',
                });
              }

              if (max !== null && visibleAggregations.some(agg => agg[aggregationFields.key] === maxKey)) {
                seriesMap['MaxMax'].data.push([xIndex, max]);
                seriesMap['MaxMax'].measurementPoints.push({
                  x: xIndex,
                  y: max,
                  measurementPointID: mpID,
                  measurementPointName: mpName,
                  unit: mpUnit,
                  seriesType: 'Max',
                });
              }
            } else {
              // If no visibility filtering, add both
              if (min !== null) {
                seriesMap['MinMin'].data.push([xIndex, min]);
                seriesMap['MinMin'].measurementPoints.push({
                  x: xIndex,
                  y: min,
                  measurementPointID: mpID,
                  measurementPointName: mpName,
                  unit: mpUnit,
                  seriesType: 'Min',
                });
              }

              if (max !== null) {
                seriesMap['MaxMax'].data.push([xIndex, max]);
                seriesMap['MaxMax'].measurementPoints.push({
                  x: xIndex,
                  y: max,
                  measurementPointID: mpID,
                  measurementPointName: mpName,
                  unit: mpUnit,
                  seriesType: 'Max',
                });
              }
            }
          }
        } else if (seriesType === 'MinMin/MaxMax') {
          // MinMin/MaxMax has format [mpID, min, max]
          if (serie.Data[0].length >= 3) {
            const minmin = serie.Data[0][1];
            const maxmax = serie.Data[0][2];

            // Check visibility based on aggregation settings
            if (visibleAggregations.length > 0) {
              const minKey = 'MINMIN';
              const maxKey = 'MAXMAX';

              if (minmin !== null && visibleAggregations.some(agg => agg[aggregationFields.key] === minKey)) {
                seriesMap['MinMin'].data.push([xIndex, minmin]);
                seriesMap['MinMin'].measurementPoints.push({
                  x: xIndex,
                  y: minmin,
                  measurementPointID: mpID,
                  measurementPointName: mpName,
                  unit: mpUnit,
                  seriesType: 'MinMin',
                });
              }

              if (maxmax !== null && visibleAggregations.some(agg => agg[aggregationFields.key] === maxKey)) {
                seriesMap['MaxMax'].data.push([xIndex, maxmax]);
                seriesMap['MaxMax'].measurementPoints.push({
                  x: xIndex,
                  y: maxmax,
                  measurementPointID: mpID,
                  measurementPointName: mpName,
                  unit: mpUnit,
                  seriesType: 'MaxMax',
                });
              }
            } else {
              // If no visibility filtering, add both
              if (minmin !== null) {
                seriesMap['MinMin'].data.push([xIndex, minmin]);
                seriesMap['MinMin'].measurementPoints.push({
                  x: xIndex,
                  y: minmin,
                  measurementPointID: mpID,
                  measurementPointName: mpName,
                  unit: mpUnit,
                  seriesType: 'MinMin',
                });
              }

              if (maxmax !== null) {
                seriesMap['MaxMax'].data.push([xIndex, maxmax]);
                seriesMap['MaxMax'].measurementPoints.push({
                  x: xIndex,
                  y: maxmax,
                  measurementPointID: mpID,
                  measurementPointName: mpName,
                  unit: mpUnit,
                  seriesType: 'MaxMax',
                });
              }
            }
          }
        }
      });

      // Process alarms if present
      if (point.alarms && point.alarms.length > 0) {
        // Check visibility based on aggregation settings
        if (visibleAggregations.length === 0 || visibleAggregations.some(agg => agg[aggregationFields.key] === 'ALARMS')) {
          point.alarms.forEach(alarm => {
            if (alarm.Level !== undefined && alarm.Level !== null) {
              seriesMap['Alarms'].data.push([xIndex, alarm.Level]);
              seriesMap['Alarms'].measurementPoints.push({
                x: xIndex,
                y: alarm.Level,
                measurementPointID: mpID,
                measurementPointName: mpName,
                unit: mpUnit,
                seriesType: 'Alarms',
                alarmName: alarm.Name,
                alarmColor: alarm.Colour,
                alarmDesc: alarm.Description,
              });
            }
          });
        }
      }
    });

    // Convert seriesMap to array and add to mergedSeries
    Object.values(seriesMap).forEach(series => {
      // Only add series that have data points
      if (series.data.length > 0) {
        // Sort data points by x-axis position
        series.data.sort((a, b) => a[0] - b[0]);

        // Sort measurementPoints by x-axis position
        series.measurementPoints.sort((a, b) => a.x - b.x);

        // Ensure dashStyle is explicitly set based on series type
        if (!series.dashStyle) {
          series.dashStyle = series.name === 'Alarms' ? 'ShortDash' : series.name === 'Median' ? 'Dash' : 'Solid';
        }

        mergedSeries.push(series);
      }
    });

    // Store the measurement point IDs for x-axis labels
    window.analysisGraphMpIDs = mpIDs;

    return mergedSeries;
  }, []);

  const mappedChartData = useMemo(() => {
    // Delegate to the appropriate builder function based on graph type
    return isTimeSeriesGraph ? buildTimeSeriesGraphData(data, visibleAggregations, colors) : buildAnalysisGraphData(data, visibleAggregations, colors);
  }, [data, visibleAggregations, colors, isTimeSeriesGraph, buildTimeSeriesGraphData, buildAnalysisGraphData]);

  const calculateNewExtremes = useCallback(
    ({ min: currentMin, max: currentMax }, plotlines) => {
      if (showAlarms) {
        const alarmValues = plotlines.map(p => p.value);
        const nextMin = Math.min(...alarmValues, currentMin);
        const nextMax = Math.max(...alarmValues, currentMax);

        if (currentMin !== nextMin || currentMax !== nextMax) {
          setExtremes({ min: nextMin, max: nextMax });
        }
      }
    },
    [showAlarms]
  );

  // Debounce chart updates to prevent excessive re-renders
  const debouncedChartUpdate = useMemo(
    () =>
      debounce((chart, config) => {
        try {
          // Verify chart is a valid Highcharts instance with an update method
          if (chart && typeof chart.update === 'function') {
            // Create a safe config object with responsive rules always properly defined
            const safeConfig = {
              // Always ensure responsive is properly defined with empty rules as fallback
              responsive: {
                rules: [],
              },
              // Spread the rest of the config, defaulting to empty object if undefined
              ...(config || {}),
            };

            // Apply the update with the safe configuration
            chart.update(safeConfig);
          } else {
            console.warn('Chart update attempted on invalid chart instance');
          }
        } catch (error) {
          console.error('Error updating chart:', error);
        }
      }, 100),
    []
  );

  // Combined series mouse over handler that delegates to the appropriate handler
  const handleSeriesMouseOver = debounce(function (event) {
    return;
  }, 50);

  // Combined series mouse out handler that delegates to the appropriate handler
  const handleSeriesMouseOut = debounce(function (event) {
    // In preview mode, we want tooltips to persist once shown
    // Only disable tooltips on mouseOut if not in preview mode
    if (this.chart && !isGraphPreview) {
      debouncedChartUpdate(this.chart, {
        tooltip: {
          enabled: false,
        },
      });
    }
  }, 150);

  // Helper function to toggle measurement point selection
  const toggleMeasurementPointSelection = useCallback(
    (currentMpId, newMpData) => {
      // For analysis graphs, don't clear plot lines - they should always remain visible when showAlarms is true
      if (!isTimeSeriesGraph && showAlarms) {
        // Just update the selected measurement point without affecting plot lines
        // This applies to both normal and preview modes
        if (currentMpId && currentMpId === selectedMeasurementPoint?.measurementPointID) {
          // If clicking the same point, deselect it (but keep plot lines for analysis graphs)
          setSelectedMeasurementPoint(null);
          return true; // indicates deselection
        } else {
          // Select the clicked measurement point
          setSelectedMeasurementPoint(newMpData);
          return false; // indicates selection
        }
      }

      // Original behavior for time series graphs
      if (currentMpId && currentMpId === selectedMeasurementPoint?.measurementPointID) {
        // If clicking the same point, deselect it
        setSelectedMeasurementPoint(null);
        setPlotLines([]);
        setExtremes({ min: null, max: null });
        return true; // indicates deselection
      } else {
        // Always clear previous plotlines when clicking a different MP
        setPlotLines([]);

        // Select the clicked measurement point
        setSelectedMeasurementPoint(newMpData);
        return false; // indicates selection
      }
    },
    [selectedMeasurementPoint, isTimeSeriesGraph, showAlarms]
  );

  // Helper function to update chart tooltip
  const updateChartTooltip = useCallback(
    (chart, isPreview = false, point = null) => {
      if (!chart) return;

      debouncedChartUpdate(chart, {
        tooltip: {
          enabled: true,
        },
      });

      // For preview mode, manually refresh the tooltip if a point is provided
      if (isPreview && chart.tooltip && point) {
        setTimeout(() => {
          chart.tooltip.refresh(point);
        }, 10);
      }
    },
    [debouncedChartUpdate]
  );

  // Helper to process alarm plot lines
  const processAlarmPlotLines = useCallback(
    (alarms, mpId, scaleFactor = 1, inverted = false, unit = '-') => {
      if (!showAlarms || !alarms || alarms.length === 0) return [];

      return alarms.map(alarm => ({
        color: alarm.color,
        dashStyle: 'ShortDash',
        value: alarm.value,
        width: 2,
        label: { text: alarm.name },
        scaleFactor,
        inverted,
        descriptor: alarm.descriptor,
        description: alarm.description,
        unit: alarm.unit || unit,
        measurementPointID: mpId,
      }));
    },
    [showAlarms]
  );

  // New function to extract all alarms from analysis graph data
  const extractAllAlarmsForAnalysisGraph = useCallback(() => {
    // Only continue if we have data and we're in analysis graph mode
    if (!data || data.length === 0 || isTimeSeriesGraph || !showAlarms) return [];

    const allAlarmPlotLines = [];

    // Create a unique list of measurement points
    const processedPoints = new Set();
    const uniqueMeasurementPoints = [];

    // First, collect unique measurement points
    data.forEach(point => {
      if (!processedPoints.has(point.MeasurementPointID)) {
        processedPoints.add(point.MeasurementPointID);
        uniqueMeasurementPoints.push(point);
      }
    });

    // Extract alarms from each measurement point
    uniqueMeasurementPoints.forEach(mp => {
      if (mp.Alarms && mp.Alarms.length > 0) {
        const alarms = mp.Alarms.map(alarm => ({
          color: alarm.Color || alarmColorPalette[alarm.Type] || '#FF0000',
          name: alarm.Name || alarm.Type || 'Alarm',
          value: alarm[alarmConstants.fields.alarmLevel.name],
          Level: alarm[alarmConstants.fields.alarmLevel.name],
          unit: alarm.Unit || mp.Unit || unit,
          descriptor: alarm.Descriptor || '',
          description: alarm.Description || '',
          type: alarm.Type || 'UNKNOWN',
        }));

        // Process these alarms into plot lines
        const mpPlotLines = processAlarmPlotLines(alarms, mp.MeasurementPointID, mp.ScaleFactor || 1, mp.Inverted || false, mp.Unit || unit);

        // Add to our collection
        allAlarmPlotLines.push(...mpPlotLines);
      }
    });

    return allAlarmPlotLines;
  }, [data, isTimeSeriesGraph, processAlarmPlotLines, unit, showAlarms]);

  // Auto-show all alarms for analysis graphs
  useEffect(() => {
    // Only apply for analysis graphs (not time series) when showAlarms is enabled
    if (!isTimeSeriesGraph && showAlarms) {
      const allAlarms = extractAllAlarmsForAnalysisGraph();
      if (allAlarms.length > 0) {
        setPlotLines(allAlarms);
      }
    } else if (isTimeSeriesGraph) {
      // reset plot lines when time series graph is enabled, as they will be set in the click handler
      setPlotLines([]);
    }
  }, [isTimeSeriesGraph, showAlarms, extractAllAlarmsForAnalysisGraph]);

  // Time series click handler
  const handleTimeSeriesClick = useCallback(
    function (event) {
      // For analysis graphs, don't modify the plot lines on click when showAlarms is true
      // This ensures alarms remain visible at all times for analysis graphs
      if (!isTimeSeriesGraph && showAlarms) {
        // Just update the tooltip and return, preserving all plot lines
        const chart = this.chart;
        if (chart) {
          updateChartTooltip(chart, isGraphPreview, this.points?.[0] || (this.points && this.points[0]));
        }
        return;
      }

      // Existing time series graph click handling below
      // Check if we have proper userOptions
      if (!this.userOptions) {
        console.warn('handleTimeSeriesClick: this.userOptions is undefined', this);
        // Continue execution, but log the issue
      }

      const chart = this.chart;
      if (!chart) {
        console.warn('handleTimeSeriesClick: No chart available');
        return;
      }

      const measurementPointName = this.userOptions?.measurementPointName;
      const measurementPointID = this.userOptions?.measurementPointID;
      const measurementPointUnit = this.userOptions?.unit;
      const scaleFactor = this.userOptions?.scaleFactor || 1;
      const inverted = this.userOptions?.inverted || false;
      const tmpAlarms = this.userOptions?.alarms || [];
      // Previously selected MP
      const previousMPID = selectedMeasurementPoint?.measurementPointID;

      // Toggle selection state - returns true if point was deselected
      const wasDeselected = toggleMeasurementPointSelection(previousMPID, {
        measurementPointName,
        measurementPointID,
        unit: measurementPointUnit,
        alarms: tmpAlarms,
        scaleFactor,
        inverted,
      });

      // If not deselected and we should show alarms, process them
      if (!wasDeselected && showAlarms) {
        const newPlotLines = processAlarmPlotLines(tmpAlarms, measurementPointID, scaleFactor, inverted, measurementPointUnit);

        // Set a slight delay to ensure state update order
        setTimeout(() => {
          setPlotLines(newPlotLines);
          if (chart && this.yAxis) {
            calculateNewExtremes({ min: this.yAxis.min, max: this.yAxis.max }, newPlotLines);
          }
        }, 10);
      }

      // Update tooltip configuration
      updateChartTooltip(chart, isGraphPreview, this.points?.[0] || (this.points && this.points[0]));
    },
    [
      toggleMeasurementPointSelection,
      selectedMeasurementPoint,
      showAlarms,
      isGraphPreview,
      processAlarmPlotLines,
      updateChartTooltip,
      calculateNewExtremes,
      isTimeSeriesGraph, // Add isTimeSeriesGraph to the dependency array
    ]
  );

  // Analysis graph click handler
  const handleAnalysisGraphClick = useCallback(
    function (event) {
      const chart = this.chart;
      if (!chart) return;

      const hoveredX = this.x || (this.points && this.points[0]?.x);
      if (hoveredX === undefined) return;

      // Find the measurement point info from our data structure
      const mpIDs = window.analysisGraphMpIDs || [];
      const mpID = mpIDs[hoveredX];

      if (!mpID) return;

      // Find the measurement point details
      const measurementPoints = this.options?.measurementPoints || [];
      const pointInfo = measurementPoints.find(p => p.x === hoveredX);

      if (!pointInfo) return;

      // Check if we're clicking on a previously selected point
      const wasDeselected = toggleMeasurementPointSelection(pointInfo.measurementPointID, {
        measurementPointName: pointInfo.measurementPointName,
        measurementPointID: pointInfo.measurementPointID,
        unit: pointInfo.unit,
        seriesType: this.name,
        value: pointInfo.y,
      });

      // Update series opacity based on selection state
      if (wasDeselected) {
        // Reset all series opacity
        chart.series.forEach(s => {
          s.update(
            {
              opacity: 1,
              lineWidth: 2,
            },
            false
          );
        });
        chart.redraw();
      } else {
        // Highlight only this series
        chart.series.forEach(s => {
          if (s !== this) {
            s.update(
              {
                opacity: 0.2, // Dim other series more on click
              },
              false
            );
          } else {
            s.update(
              {
                opacity: 1,
                lineWidth: 3, // Make selected series more prominent
              },
              false
            );
          }
        });
        chart.redraw();
      }

      // Update tooltip configuration
      updateChartTooltip(
        chart,
        isGraphPreview,
        this.data?.find(p => p.x === hoveredX)
      );
    },
    [toggleMeasurementPointSelection, updateChartTooltip, isGraphPreview]
  );

  // Combined series click handler that delegates to the appropriate handler
  const handleSeriesClick = debounce(function (event) {
    // Fix for non-preview mode: ensure context is properly passed
    // Store the 'this' context for use in the handler
    const clickContext = this;

    // Ensure we have proper context with userOptions
    if (!clickContext.userOptions && clickContext.series) {
      // Try to get the userOptions from the series
      clickContext.userOptions = clickContext.series.userOptions;
    }

    if (isTimeSeriesGraph) {
      handleTimeSeriesClick.call(clickContext, event);
    } else {
      handleAnalysisGraphClick.call(clickContext, event);
    }
  }, 150);

  // Add a console log in the useEffect hook to check plotLines state changes
  useEffect(() => {
    // cleanup logic
    return () => {
      handleSeriesMouseOver.cancel();
      handleSeriesMouseOut.cancel();
      handleSeriesClick.cancel();
      debouncedChartUpdate.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotLines]);

  // Helper function to create tooltip container
  const createTooltipContainer = useCallback((title, isAlarm = false, position = '') => {
    // Create opening tag with optional attributes for alarm tooltips
    const containerAttrs = isAlarm ? ` data-alarm-tooltip="true" data-position="${position}"` : '';
    return `
      <div class="time-series-graph__tooltip-container"${containerAttrs}>
        <h6 class="f-primary time-series-graph__tooltip-container__title">
          ${title}
        </h6>`;
  }, []);

  // Helper function to format additional properties (scale factor and inverted status)
  const formatAdditionalProperties = useCallback(
    (scaleFactor, isInverted) => {
      let additionalHtml = '';

      // Add scale factor
      additionalHtml += `
        <p class="f-secondary-dark time-series-graph__tooltip-container__label">
          ${t('GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.SCALE_FACTOR')}
        </p>
        <p class="f-primary time-series-graph__tooltip-container__value">
          ${scaleFactor}
        </p>`;

      // Add inverted status
      additionalHtml += `
        <p class="f-secondary-dark time-series-graph__tooltip-container__label">
          ${t('GRAPHING_GROUPS.ADD_MEASUREMENT_POINT.INVERTED_LABEL')}
        </p>
        <p class="f-primary time-series-graph__tooltip-container__value">
        ${isInverted ? t('TABLE_COLUMN_DATA.YES') : t('TABLE_COLUMN_DATA.NO')}
        </p>`;

      return additionalHtml;
    },
    [t]
  );

  // Helper function to format alarm tooltip content
  const formatAlarmTooltip = useCallback(
    (clickedAlarmLine, plotLine) => {
      // Check if we have a valid alarm line
      if (!clickedAlarmLine) {
        return '';
      }

      // For time series graphs, check if measurement point matches the selected one
      // For analysis graphs, always show the tooltip regardless of selection
      if (isTimeSeriesGraph && clickedAlarmLine.measurementPointID !== selectedMeasurementPoint?.measurementPointID) {
        return '';
      }

      // Get alarm properties
      const alarmDescription = plotLine.series?.options?.alarms?.find(a => a.name === clickedAlarmLine.name)?.description || plotLine.options?.description || '';
      const isInverted = clickedAlarmLine.inverted || false;
      const scaleFactor = clickedAlarmLine.scaleFactor || 1;
      const alarmTitle = ALARMS.find(a => a.id === clickedAlarmLine.name)?.displayName || clickedAlarmLine.name;
      const alarmUnit = clickedAlarmLine.unit || plotLine.series?.options?.unit || '-';
      const position = plotLine.options?.isBelow ? 'below' : 'above';

      // Create tooltip container with alarm title
      const titleContent = `<span style="color: ${clickedAlarmLine.color}">&#9632;</span> ${t(alarmTitle) || t('ALARM')} (${alarmUnit && alarmUnit !== '-' ? alarmUnit : unit})`;
      let tooltipHtml = createTooltipContainer(titleContent, true, position);

      // Add content section
      tooltipHtml += `
      <div class="time-series-graph__tooltip-container__content">
        <p class="f-secondary-dark time-series-graph__tooltip-container__label">
            ${alarmDescription || t('ALARMS.FORM_DESCRIPTOR.DROPDOWN.LESS_THAN')}
        </p>
        <p class="f-primary time-series-graph__tooltip-container__value">
          ${clickedAlarmLine.value}
        </p>`;

      // Add scale factor and inverted status
      tooltipHtml += formatAdditionalProperties(scaleFactor, isInverted);

      tooltipHtml += `
      </div>
    </div>`;

      return tooltipHtml;
    },
    [createTooltipContainer, formatAdditionalProperties, selectedMeasurementPoint, t, unit, isTimeSeriesGraph]
  );

  // Helper function to format measurement point value labels
  const formatMeasurementPointValues = useCallback((name, value, unit = null) => {
    let valueHtml = `
      <p class="f-secondary-dark time-series-graph__tooltip-container__label">${name}</p>
      <p class="f-primary time-series-graph__tooltip-container__value">`;

    // Add unit if provided
    if (unit && unit !== '-') {
      valueHtml += `${value} (${unit})`;
    } else {
      valueHtml += `${value}`;
    }

    valueHtml += `</p>`;
    return valueHtml;
  }, []);

  // Tooltip formatter for time series graphs
  const getTimeSeriesGraphTooltipFormatter = useCallback(
    (isAlarmLine, clickedAlarmLine) => {
      return function () {
        if (!this) return '';

        // For alarm lines
        if ((isAlarmLine || this.isAlarmLine) && clickedAlarmLine) {
          return formatAlarmTooltip(clickedAlarmLine, this);
        }

        // Regular measurement point tooltip for time series graph
        const hoveredX = this.x;
        const measurementPointName = this.series?.userOptions?.measurementPointName;

        if (isEmpty(measurementPointName)) return '';

        // Create tooltip container with measurement point title
        const titleContent = `<span style="color: ${this.series.color}">&#9632;</span>${measurementPointName}`;
        let tooltipHtml = createTooltipContainer(titleContent);

        // Add date for time series graphs
        tooltipHtml += `
          <p class="f-primary time-series-graph__tooltip-container__date">
            ${Highcharts.dateFormat('%A, %b %e, %Y', hoveredX)}
          </p>`;

        // Add measurement point values
        this.series.chart.series?.forEach(series => {
          if (series?.userOptions?.measurementPointName === measurementPointName) {
            series.points.forEach(point => {
              if (point.x === hoveredX && series.visible) {
                const isMinMax = series.name.toLowerCase().includes('min/max');
                if (isMinMax && point.low !== null && point.high !== null) {
                  tooltipHtml += formatMeasurementPointValues('Min', point.low);
                  tooltipHtml += formatMeasurementPointValues('Max', point.high);
                } else if (isMinMax && point.low !== null) {
                  tooltipHtml += formatMeasurementPointValues('Min', point.low);
                } else if (isMinMax && point.high !== null) {
                  tooltipHtml += formatMeasurementPointValues('Max', point.high);
                } else if (point.y !== null) {
                  tooltipHtml += formatMeasurementPointValues(series.name, point.y);
                }
              }
            });
          }
        });

        // Add scale factor and inverted status
        tooltipHtml += formatAdditionalProperties(this.series?.userOptions?.scaleFactor || 1, this.series?.userOptions?.inverted || false);

        tooltipHtml += '</div>';
        return tooltipHtml;
      };
    },
    [createTooltipContainer, formatAlarmTooltip, formatMeasurementPointValues, formatAdditionalProperties]
  );

  // Tooltip formatter for analysis graphs
  const getAnalysisGraphTooltipFormatter = useCallback(
    (isAlarmLine, clickedAlarmLine) => {
      return function () {
        if (!this) return '';

        // For alarm lines
        if ((isAlarmLine || this.isAlarmLine) && clickedAlarmLine) {
          return formatAlarmTooltip(clickedAlarmLine, this);
        }

        // Get measurement point details for analysis graph
        const hoveredX = this.x;
        const seriesName = this.series?.name;

        // Get the measurement point details from our data structure
        const seriesOptions = this.series?.options;
        const measurementPoints = seriesOptions?.measurementPoints || [];
        const pointInfo = measurementPoints.find(p => p.x === hoveredX);

        if (!pointInfo) return '';

        // Get the actual measurement point ID from our stored IDs
        const mpIDs = window.analysisGraphMpIDs || [];
        const mpID = mpIDs[hoveredX];
        const mpName = pointInfo.measurementPointName || `MP ${mpID}`;
        const mpUnit = pointInfo.unit || '-';
        const value = this.y !== undefined ? this.y : pointInfo.y;

        // Create tooltip container with measurement point title
        const titleContent = `<span style="color: ${this.series.color}">&#9632;</span> #${mpID}-${mpName}`;
        let tooltipHtml = createTooltipContainer(titleContent);

        // Add series value if series name exists
        if (seriesName) {
          tooltipHtml += formatMeasurementPointValues(seriesName, value, mpUnit);
        }

        // Close the container div
        tooltipHtml += '</div>';

        return tooltipHtml;
      };
    },
    [createTooltipContainer, formatAlarmTooltip, formatMeasurementPointValues]
  );

  // Combined tooltip formatter that delegates to the appropriate formatter
  function getTooltipFormatter(isAlarmLine, clickedAlarmLine) {
    return function () {
      if (isTimeSeriesGraph) {
        return getTimeSeriesGraphTooltipFormatter(isAlarmLine, clickedAlarmLine).call(this);
      } else {
        return getAnalysisGraphTooltipFormatter(isAlarmLine, clickedAlarmLine).call(this);
      }
    };
  }

  const options = useMemo(
    () => {
      const mpCount = data.length;

      // Calculate if we need to enable special handling for many MPs
      const hasManyMPs = !isTimeSeriesGraph && mpCount > 10;

      return {
        legend: {
          enabled: isTimeSeriesGraph, // Only enable legend for time series graphs
          itemStyle: {
            color: colors.primaryFontColor,
          },
          itemHiddenStyle: { color: '#cccccc' },
          itemHoverStyle: { color: colors.secondaryFontColorLight },
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          description: 'Time series graph showing measurement points and alarms',
          point: {
            valueDescriptionFormat: '{value} {unit}',
          },
        },
        title: {
          text: '',
        },
        plotOptions: {
          series: {
            dashStyle: 'Solid',
            showInLegend: false,
            cursor: 'pointer',
            states: {
              hover: {
                enabled: true,
                lineWidthPlus: 1,
                halo: {
                  size: 5,
                  opacity: 0.25,
                },
              },
              inactive: {
                opacity: 0.3,
              },
              select: {
                enabled: true,
              },
            },
            marker: {
              enabled: false, // Hide markers for all graphs
              states: {
                hover: {
                  enabled: true,
                  symbol: 'square',
                },
              },
              // Different symbol for time series and analysis graphs
              symbol: isTimeSeriesGraph ? 'square' : 'circle',
              radius: 4,
              lineWidth: 1,
            },
            events: {
              mouseOver: function (event) {
                handleSeriesMouseOver.call(this, event);
              },
              mouseOut: function (event) {
                handleSeriesMouseOut.call(this, event);
              },
              click: function (event) {
                handleSeriesClick.call(this, event);
              },
            },
            // graph point events
            point: {
              events: {
                // updates the tooltip configuration of a chart in a series by enabling it
                click: function (e) {
                  const chart = this.series.chart;
                  const clickedAlarmLine = this.options?.alarms?.[0];
                  if (!chart || !clickedAlarmLine) {
                    return;
                  }

                  // For analysis graphs, we don't need to check the selected measurement point
                  if (this.options.measurementPointID === selectedMeasurementPoint?.measurementPointID || !isTimeSeriesGraph) {
                    // Get click position relative to plot area
                    const clickX = Math.max(chart.plotLeft, Math.min(e.pageX - chart.container.getBoundingClientRect().left, chart.plotLeft + chart.plotWidth));

                    debouncedChartUpdate(chart, {
                      tooltip: {
                        enabled: true,
                        formatter: getTooltipFormatter(true, clickedAlarmLine),
                        borderColor: this.options.color,
                        isAlarmTooltip: true,
                        alarmValue: clickedAlarmLine.value,
                        clickX: clickX,
                        shape: 'rect', // Set rectangular shape for alarm tooltips
                      },
                    });

                    // Ensure tooltip is shown by manually refreshing it
                    setTimeout(() => {
                      if (chart.tooltip) {
                        // Manually force tooltip to show near cursor
                        const point = chart.hoverPoint || chart.series[0]?.points[0];
                        chart.tooltip.refresh(point);

                        // Override tooltip HTML with our alarm tooltip
                        if (chart.tooltip.label && chart.tooltip.label.element) {
                          const tooltipHtml = formatAlarmTooltip(clickedAlarmLine, this);
                          if (tooltipHtml) {
                            chart.tooltip.label.attr({
                              text: tooltipHtml,
                            });
                          }
                        }
                      }
                    }, 50);
                  }
                },
                mouseOut: function () {
                  // Only disable tooltip on mouseOut if not in preview mode
                  // In preview mode, we want tooltips to persist once shown
                  if (!isGraphPreview && this.series.chart) {
                    debouncedChartUpdate(this.series.chart, {
                      tooltip: {
                        enabled: !isGraphPreview, // Restore default based on mode
                      },
                    });
                  }
                },
                mouseOver: function () {
                  // In preview mode, don't enable tooltip on hover
                  if (isGraphPreview) return;

                  // For non-preview mode, enable tooltip on hover
                  if (this.series.chart) {
                    debouncedChartUpdate(this.series.chart, {
                      tooltip: {
                        enabled: true,
                      },
                    });
                  }
                },
              },
            },
            connectNulls: !isTimeSeriesGraph, // Connect null points in analysis graphs
            lineWidth: !isTimeSeriesGraph ? 2 : 1, // Thicker lines for analysis graphs
          },
          accessibility: {
            description: 'Time series graph showing measurement points and alarms',
            point: {
              valueDescriptionFormat: '{value} {unit}',
            },
          },
          title: {
            text: '',
          },
          line: {
            zIndex: 1,
            findNearestPointBy: 'x',
            cursor: 'pointer',
            dashStyle: 'Solid',
            marker: {
              enabled: false, // Hide markers for all graphs
            },
            connectNulls: !isTimeSeriesGraph, // Connect gaps between points for analysis graphs
          },
          arearange: {
            lineWidth: 2,
            fillOpacity: 0.4,
            findNearestPointBy: 'x',
            marker: {
              enabled: false, // Hide markers for all graphs
            },
            connectNulls: !isTimeSeriesGraph, // Connect gaps between points for analysis graphs
          },
        },
        series: mappedChartData.map(series => ({
          ...series,
          type: series.type || 'line', // Ensure type is explicitly set
        })),
        tooltip: {
          crosshairs: true,
          useHTML: true,
          enabled: !isGraphPreview, // Disable tooltips by default in preview mode
          followPointer: false,
          formatter: getTooltipFormatter(false),
          shape: 'callout', // Default shape for regular tooltips
          positioner: function (labelWidth, labelHeight, point) {
            const chart = this.chart;
            const chartContainer = chart.container.parentElement;
            const chartRect = chartContainer.getBoundingClientRect();

            // Check if this is an alarm tooltip
            if (this.options.isAlarmTooltip) {
              // Get the y-coordinate of the alarm line
              const yAxis = chart.yAxis[0];
              const alarmY = yAxis.toPixels(this.options.alarmValue);

              // Use the click X position or center if not available
              const clickX = this.options.clickX || chart.plotLeft + chart.plotWidth / 2;

              // Position the tooltip centered above the click point
              let tooltipX = clickX - labelWidth / 2;
              let tooltipY = alarmY - labelHeight - 8; // Reduced space since we don't have pointer

              // Adjust if tooltip would go outside chart bounds
              if (tooltipX < chart.plotLeft) {
                tooltipX = chart.plotLeft + 10;
              } else if (tooltipX + labelWidth > chart.plotLeft + chart.plotWidth) {
                tooltipX = chart.plotLeft + chart.plotWidth - labelWidth - 10;
              }

              // If tooltip would go above chart, show it below the line instead
              if (tooltipY < chart.plotTop) {
                tooltipY = alarmY + 8; // Reduced space since we don't have pointer
                this.options.isBelow = true;
              } else {
                this.options.isBelow = false;
              }

              return {
                x: tooltipX,
                y: tooltipY,
              };
            }

            // Original positioning logic for regular points
            const tooltipX = point.plotX + chart.plotLeft;
            const tooltipY = point.plotY + chart.plotTop;

            // Get the absolute position relative to the viewport
            const absoluteX = chartRect.left + tooltipX;
            const absoluteY = chartRect.top + tooltipY;

            // Calculate the best position for the tooltip
            let x = absoluteX - labelWidth / 2;
            let y = absoluteY - labelHeight - 10;

            // Adjust if tooltip would go outside viewport
            if (x + labelWidth > window.innerWidth) {
              x = window.innerWidth - labelWidth - 10;
            }
            if (x < 0) {
              x = 10;
            }
            if (y < 0) {
              y = absoluteY + 10;
            }

            return {
              x: x - chartRect.left,
              y: y - chartRect.top,
            };
          },
          style: {
            pointerEvents: 'auto',
            zIndex: 1000,
          },
          backgroundColor: 'var(--dropdown-bg)',
          borderWidth: 3,
          borderRadius: 8,
          outside: true, // Render the tooltip outside the chart
          className: 'time-series-graph-tooltip', // Added this class for custom styling (absolute positioning)
        },
        xAxis: {
          gridLineColor: '#E3E3E3',
          minorGridLineColor: '#E3E3E3',
          baselineColor: '#E3E3E3',
          gridLineWidth: 1,
          tickWidth: 0,
          lineWidth: 1,
          lineColor: '#E3E3E3',
          // For analysis graph with many MPs, ensure proper spacing
          tickmarkPlacement: !isTimeSeriesGraph ? 'on' : 'between',
          // When many MPs are present, ensure there's enough space between labels
          tickPixelInterval: !isTimeSeriesGraph && data.length > 10 ? 80 : null,
          // Reserve more space for labels when there are many MPs
          reserveSpace: true,
          labels: {
            useHTML: true,
            // Add proper spacing between labels for analysis graph
            padding: !isTimeSeriesGraph ? 8 : 0,
            style: {
              color: isTimeSeriesGraph ? colors.primaryFontColor : '#999',
            },
            formatter: function () {
              if (!isTimeSeriesGraph) {
                // Format measurement point ID labels for analysis graphs
                // Hide labels in preview mode
                if (isGraphPreview) {
                  return '';
                }
                const index = this.value;
                const series = this.chart.series[0];
                if (!series) return;
                const measurementPoints = series?.options?.measurementPoints;
                if (isEmpty(measurementPoints)) return;
                const mpIDs = window.analysisGraphMpIDs || [];
                const mpID = mpIDs[index];
                const measurementPoint = measurementPoints.find(mp => mp.measurementPointID === mpID);
                if (!measurementPoint) return;
                const measurementPointName = measurementPoint.measurementPointName;

                // Create a wrapper div with tooltip
                // Make sure we have proper spacing and clickable area for the info icon
                return `<div class="x-axis-label">
                <span class="x-axis-label__text">#${mpID || index}</span> 
                <div class="x-axis-label__icon" data-mp-id="${mpID || index}" data-mp-name="${measurementPointName}">
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#B3B3B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 16V12" stroke="#B3B3B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 8H12.01" stroke="#B3B3B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<div class="x-axis-tooltip">#${mpID || index} - ${measurementPointName}</div>
  </div>
                </div>`;
              } else {
                // Time series graph date formatting
                // Check if the timestamp is already in milliseconds (13 digits)
                // or if it's in seconds (10 digits)
                const timestampStr = this.value.toString();
                let formattedDate;

                if (timestampStr.length >= 13) {
                  // Already in milliseconds format, use getDateFromUnixMs
                  formattedDate = new Date(this.value).toLocaleString('en-US', { month: 'short', year: '2-digit' });
                } else {
                  // In seconds format, use getDateFromUnix
                  const date = Helpers.getDateFromUnix(this.value);
                  formattedDate = new Date(date).toLocaleString('en-US', { month: 'short', year: '2-digit' });
                }

                return `<div class="x-axis-label">
                <span class="x-axis-label__text f-primary">${formattedDate}</span>
                </div>`;
              }
            },
            enabled: isTimeSeriesGraph || !isGraphPreview,
          },
          type: isTimeSeriesGraph ? 'datetime' : 'category',
          overflow: 'justify',
        },
        yAxis: {
          gridLineColor: '#E3E3E3',
          minorGridLineColor: '#E3E3E3',
          baselineColor: '#E3E3E3',
          gridLineWidth: 1,
          tickWidth: 0,
          // For analysis graphs, ensure we have clear tick intervals
          tickInterval: !isTimeSeriesGraph ? null : undefined, // Use null to allow automatic calculation
          tickAmount: !isTimeSeriesGraph ? 6 : undefined, // Show approximately 6 ticks for analysis graphs
          visible: true, // Explicitly make the axis visible
          showEmpty: true, // Show axis even without data
          lineWidth: 0, // Make the axis line visible
          lineColor: '#E3E3E3',
          title: {
            text: ``,
            style: {
              color: isTimeSeriesGraph ? colors.primaryFontColor : '#999',
            },
          },
          labels: {
            enabled: true, // Always enable labels
            align: 'right',
            style: {
              color: isTimeSeriesGraph ? colors.primaryFontColor : '#999',
              fontSize: '12px',
              backgroundColor: '#fff', // Add background color of white to yAxis labels
            },
            formatter: function () {
              // Display values with one decimal place for analysis charts
              return !isTimeSeriesGraph ? this.value.toFixed(1) : this.value;
            },
            distance: 10,
            // Ensure labels are visible
            reserveSpace: true,
            x: 0,
          },
          minPadding: 0.05,
          maxPadding: 0.05,
          min: extremes.min,
          max: extremes.max,
          plotLines: showAlarms
            ? plotLines?.map(line => {
                return {
                  color: line.color,
                  dashStyle: 'ShortDash',
                  value: line.value,
                  width: 2,
                  label: { text: line.label?.text },
                  isAlarmLine: true,
                  zIndex: 5,
                  className: 'alarm-plot-line',
                  // Ensure these properties are set from the correct source
                  measurementPointID: line.measurementPointID,
                  measurementPointName: selectedMeasurementPoint?.measurementPointName,
                  alarms: [
                    {
                      name: line.label?.text || 'Alarm',
                      value: line.value,
                      scaleFactor: line.scaleFactor || 100,
                      inverted: line.inverted || false,
                      color: line.color,
                      unit: line.unit || '-',
                      measurementPointID: line.measurementPointID,
                      measurementPointName: selectedMeasurementPoint?.measurementPointName,
                    },
                  ],
                  events: {
                    mouseover: function (e) {
                      const chart = this.axis.chart;
                      if (!chart) return;

                      // For analysis graphs, always show tooltips regardless of selectedMeasurementPoint
                      if (this.options.measurementPointID === selectedMeasurementPoint?.measurementPointID || !isTimeSeriesGraph) {
                        debouncedChartUpdate(chart, {
                          tooltip: {
                            enabled: true,
                            formatter: getTooltipFormatter(true),
                          },
                        });
                      }
                    },
                    mouseout: function () {
                      const chart = this.axis.chart;
                      if (!chart) return;

                      debouncedChartUpdate(chart, {
                        tooltip: {
                          enabled: true,
                          formatter: getTooltipFormatter(false),
                          borderColor: undefined,
                          isAlarmTooltip: false,
                          alarmValue: undefined,
                          clickX: undefined,
                          shape: 'callout', // Reset to callout shape for regular tooltips
                        },
                      });
                    },
                    click: function (e) {
                      const chart = this.axis.chart;
                      const clickedAlarmLine = this.options?.alarms?.[0];
                      if (!chart || !clickedAlarmLine) {
                        return;
                      }

                      // For analysis graphs, we don't need to check the selected measurement point
                      if (this.options.measurementPointID === selectedMeasurementPoint?.measurementPointID || !isTimeSeriesGraph) {
                        // Get click position relative to plot area
                        const clickX = Math.max(chart.plotLeft, Math.min(e.pageX - chart.container.getBoundingClientRect().left, chart.plotLeft + chart.plotWidth));

                        debouncedChartUpdate(chart, {
                          tooltip: {
                            enabled: true,
                            formatter: getTooltipFormatter(true, clickedAlarmLine),
                            borderColor: this.options.color,
                            isAlarmTooltip: true,
                            alarmValue: clickedAlarmLine.value,
                            clickX: clickX,
                            shape: 'rect', // Set rectangular shape for alarm tooltips
                          },
                        });

                        // Ensure tooltip is shown by manually refreshing it
                        setTimeout(() => {
                          if (chart.tooltip) {
                            // Manually force tooltip to show near cursor
                            const point = chart.hoverPoint || chart.series[0]?.points[0];
                            chart.tooltip.refresh(point);

                            // Override tooltip HTML with our alarm tooltip
                            if (chart.tooltip.label && chart.tooltip.label.element) {
                              const tooltipHtml = formatAlarmTooltip(clickedAlarmLine, this);
                              if (tooltipHtml) {
                                chart.tooltip.label.attr({
                                  text: tooltipHtml,
                                });
                              }
                            }
                          }
                        }, 50);
                      }
                    },
                  },
                };
              })
            : [],
        },
        rangeSelector: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        chart: {
          panning: {
            enabled: isTimeSeriesGraph, // Only enable panning for time series graphs
            type: 'x',
          },
          panKey: 'alt',
          zoomType: isTimeSeriesGraph ? 'x' : '', // Only enable zooming for time series graphs
          backgroundColor: 'transparent',
          height: isGraphPreview ? 270 : 450,
          reflow: true,
          style: {
            fontFamily: 'DM Sans',
          },
          spacingTop: 10,
          spacingBottom: 0,
          spacingLeft: 10,
          spacingRight: 0,
          marginTop: 5,
          marginBottom: isGraphPreview && !isTimeSeriesGraph ? 30 : 50,
          marginLeft: isGraphPreview && !isTimeSeriesGraph ? 43 : 40,
          marginRight: 5,
          scrollablePlotArea: hasManyMPs
            ? {
                minWidth: Math.max(800, mpCount * 100),
                scrollPositionX: 0,
              }
            : undefined,
          events: {
            load() {
              setChartInstance(this);

              // For preview mode, ensure Y-axis is visible
              if (isGraphPreview && this.yAxis && this.yAxis[0]) {
                // Force the Y-axis to be visible in preview mode
                this.yAxis[0].update({
                  visible: true,
                  labels: {
                    enabled: true,
                  },
                });
              }

              // Add event listeners to plot lines after chart loads
              this.yAxis[0].plotLinesAndBands.forEach((plotLine, _index) => {
                if (plotLine.svgElem) {
                  plotLine.svgElem
                    .css({ cursor: 'pointer' })
                    .on('mouseover', function () {
                      const options = plotLine.options;
                      if (options.events?.mouseOver) {
                        options.events.mouseOver.call(plotLine);
                      }
                    })
                    .on('mouseout', function () {
                      const options = plotLine.options;
                      if (options.events?.mouseOut) {
                        options.events.mouseOut.call(plotLine);
                      }
                    })
                    .on('click', function (e) {
                      const options = plotLine.options;
                      if (options.events?.click) {
                        options.events.click.call(plotLine, e);
                      }
                    });
                }
              });

              // Add a direct fix for click events
              // This implements a more direct approach to handle clicks in non-preview mode
              if (!isGraphPreview && this.series.length > 0) {
                this.series.forEach((series, index) => {
                  // Ensure the series has the proper userOptions
                  if (!series.userOptions.alarms && series.options.alarms) {
                    series.userOptions.alarms = series.options.alarms;
                  }

                  // Add direct click handler that will set the plotlines
                  series.update(
                    {
                      cursor: 'pointer',
                      events: {
                        click: function (event) {
                          // Get alarms data
                          const alarms = this.userOptions.alarms || [];

                          // Get measurement point info
                          const mpID = this.userOptions.measurementPointID;
                          const mpName = this.userOptions.measurementPointName;
                          const unit = this.userOptions.unit || '-';
                          const scaleFactor = this.userOptions.scaleFactor || 1;
                          const inverted = this.userOptions.inverted || false;

                          // Check if this is the same measurement point that was already selected
                          const previousMPID = selectedMeasurementPoint?.measurementPointID;

                          if (previousMPID === mpID && isTimeSeriesGraph) {
                            // If same point, clear selection and plotlines if it's a time series graph
                            setSelectedMeasurementPoint(null);
                            setPlotLines([]);
                            return;
                          }

                          // Clear any previous plotlines if it's a time series graph
                          if (isTimeSeriesGraph) {
                            setPlotLines([]);
                          }

                          // Set the selection state to the new measurement point
                          setSelectedMeasurementPoint({
                            measurementPointName: mpName,
                            measurementPointID: mpID,
                            unit: unit,
                            alarms: alarms,
                            scaleFactor: scaleFactor,
                            inverted: inverted,
                          });

                          if (alarms.length === 0) {
                            return;
                          }

                          // Create the plotlines from the alarms
                          const newPlotLines = alarms.map(alarm => ({
                            color: alarm.color,
                            dashStyle: 'ShortDash',
                            value: alarm.value,
                            width: 2,
                            label: { text: alarm.name },
                            scaleFactor: scaleFactor,
                            inverted: inverted,
                            measurementPointID: mpID,
                          }));

                          // Set a slight delay to ensure state updates in the right order
                          setTimeout(() => {
                            setPlotLines(newPlotLines);
                          }, 10);
                        },
                      },
                    },
                    false
                  ); // false = don't redraw yet
                });

                // Redraw once after updating all series
                this.redraw();
              }
            },
            // Add a click handler on the chart to hide tooltips when clicking elsewhere
            click: function (e) {
              // Only handle this in preview mode
              if (isGraphPreview) {
                // Check if we clicked on a point or whitespace
                if (!this.hoverPoint) {
                  // Clicked on whitespace, hide tooltip
                  if (this.tooltip) {
                    this.tooltip.hide();
                  }

                  // Update tooltip configuration to be disabled - ensure we pass a valid chart instance
                  const chartInstance = this;
                  debouncedChartUpdate(chartInstance, {
                    tooltip: {
                      enabled: false,
                    },
                  });
                }
              }
            },
          },
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      colors,
      showAlarms,
      plotLines,
      isTimeSeriesGraph,
      isGraphPreview,
      extremes,
      debouncedChartUpdate,
      selectedMeasurementPoint,
      getTooltipFormatter,
      handleSeriesMouseOver,
      handleSeriesMouseOut,
      handleSeriesClick,
    ]
  );

  // React to data and extremes changes to update the chart instance
  useEffect(() => {
    if (chartInstance && data.length > 0) {
      // For analysis graphs with many MPs, ensure proper spacing and scrolling
      // Works in both preview and non-preview modes
      if (!isTimeSeriesGraph && data.length > 10 && chartInstance.container) {
        // Add class to parent container to enable horizontal scrolling
        const container = chartInstance.container.parentElement;
        if (container) {
          container.classList.add('time-series-graph--scrollable');
        }

        // Simple update to enable scrollable plot area for analysis graphs
        chartInstance.update({
          chart: {
            scrollablePlotArea: {
              minWidth: Math.max(800, data.length * 100), // Each MP needs ~100px of space
              scrollPositionX: 0,
            },
            height: isGraphPreview ? '270px' : '450px', // Ensure chart takes appropriate height based on mode
          },
          xAxis: {
            // Ensure consistent tick spacing for analysis graphs with many MPs
            tickPixelInterval: 80,
          },
        });
      }
    }
  }, [chartInstance, data, isTimeSeriesGraph, isGraphPreview]);

  if (isLoading) {
    return <Loader height="100%" isLoading={true} divStyle={{ margin: 'auto' }} />;
  }

  const isDataEmpty = isTimeSeriesGraph ? data?.every(parent => parent.Series?.every(seriesItem => isEmpty(seriesItem.Data))) : mappedChartData.length === 0;

  return (
    <div className="time-series-graph">
      {!isDataEmpty ? (
        <>
          <HighchartsReact ref={forwardRef} highcharts={Highcharts} options={options} containerProps={{ style: { flex: '1 0 0' } }} />
          {showLegend && !isGraphPreview && <CustomLegend chart={chartInstance} />}
        </>
      ) : (
        <div style={{ width: '100%', height: '100%' }}>
          <div className={`empty-state-active`}>
            <h4 className="empty-state-title f-secondary-light"> {t('GRAPH.NO_DATA_AVAILABLE')}</h4>
          </div>
        </div>
      )}
    </div>
  );
};

TimeSeriesGraph.propTypes = {
  colors: PropTypes.object.isRequired,
  unit: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showLegend: PropTypes.bool.isRequired,
  showAlarms: PropTypes.bool,
  isGraphPreview: PropTypes.bool,
  isTimeSeriesGraph: PropTypes.bool,
  visibleAggregations: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    })
  ),
};

TimeSeriesGraph.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  colors: props.ignoreTheme ? THEMES.light.colors : state.themeReducer.severityColors,
});

export default connect(mapStateToProps, null)(forwardRef((props, ref) => <TimeSeriesGraph forwardRef={ref} {...props} />));
