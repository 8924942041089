import Toggle from '../../../../../common/form/components/toggle-field';
import TruncatedText from '../../../../../common/form/components/truncated-text';
import Helpers from '../../../../../common/helpers';
import Icon from '../../../../../common/icon/components/icon';
import InfoTooltip from '../../../../../common/tooltip/components/info-tooltip';
import MultiplePreviewPopup from '../../../../../common/tooltip/components/multiple-preview-popover';

export const settingsFormConstants = {
  graphingGroup: {
    id: 'graphingGroup',
    name: 'DefaultGraphID',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.GRAPHING_GROUP.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.GRAPHING_GROUP.PLACEHOLDER',
  },
  graphType: {
    id: 'graphType',
    name: 'GraphType',
    label: 'GRAPHING_GROUPS.MODAL.TYPE',
    placeholder: 'GRAPHING_GROUPS.MODAL.TYPE.CHOOSE',
  },
  aggregation: {
    id: 'aggregation',
    name: 'AggregationType',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.AGGREGATION.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.AGGREGATION.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.AGGREGATION.ERROR_IS_REQUIRED',
  },
  timePeriod: {
    id: 'timePeriod',
    name: 'TimePeriodType',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.TIME_PERIOD.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.TIME_PERIOD.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.TIME_PERIOD.ERROR_IS_REQUIRED',
  },
  chunks: {
    id: 'chunks',
    name: 'Chunk',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.CHUNKS.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.CHUNKS.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.CHUNKS.ERROR_IS_REQUIRED',
  },
  chunk: {
    id: 'chunk',
    name: 'Chunk',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.CHUNK.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.CHUNK.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.CHUNKS.ERROR_IS_REQUIRED',
  },
  showAlarms: {
    id: 'showAlarms',
    name: 'ShowAlarms',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.SHOW_ALARMS.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.SHOW_ALARMS.PLACEHOLDER',
    description: 'TIME_SERIES_GRAPH.SETTINGS_FORM.SHOW_ALARMS.DESCRIPTION',
  },
  dateFrom: {
    id: 'dateFrom',
    name: 'DateFrom',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.ERROR_REQUIRED',
  },
  dateTo: {
    id: 'dateTo',
    name: 'DateTo',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_TO.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_TO.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.ERROR_REQUIRED',
  },
  measurementPoints: {
    id: 'measurementPoints',
    name: 'MeasurementPoints',
  },
  dateRanges: {
    id: 'dateRanges',
    name: 'DateRanges',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_RANGES.LABEL',
    labelWithCount: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_RANGES.LABEL_WITH_COUNT',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_RANGES.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_RANGES.ERROR_REQUIRED',
  },
  dateRangeFrom: {
    id: 'from',
    name: 'From',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_FROM.ERROR_REQUIRED',
  },
  dateRangeTo: {
    id: 'to',
    name: 'To',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_TO.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_TO.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_TO.ERROR_REQUIRED',
  },
  defaultGraphID: {
    id: 'defaultGraphID',
    name: 'DefaultGraphID',
    label: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DEFAULT_GRAPH_ID.LABEL',
    placeholder: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DEFAULT_GRAPH_ID.PLACEHOLDER',
    errorRequired: 'TIME_SERIES_GRAPH.SETTINGS_FORM.DEFAULT_GRAPH_ID.ERROR_REQUIRED',
  },
};

export const formConstants = {
  aggregation: 'Aggregation',
  chunk: 'Chunk',
  createdBy: 'CreatedBy',
  id: 'ID',
  isDefault: 'IsDefault',
  measurementPoints: 'MeasurementPoints',
  name: 'Name',
  startDateFrom: 'StartDateFrom',
  startDateTo: 'StartDateTo',
  timePeriod: 'TimePeriodType',
  graphType: 'GraphType',
  graphID: 'GraphID',
};

export const timePeriods = t => [
  { ID: 'LAST_WEEK', Name: t('TIME_SERIES_GRAPH.LAST_WEEK') },
  { ID: 'LAST_MONTH', Name: t('TIME_SERIES_GRAPH.LAST_MONTH') },
  { ID: 'LAST_YEAR', Name: t('TIME_SERIES_GRAPH.LAST_YEAR') },
  { ID: 'CUSTOM', Name: t('TIME_SERIES_GRAPH.CUSTOM') },
];

export const aggregationFields = {
  key: 'Key',
  value: 'Value',
};

export const timePeriodFields = {
  key: 'Name',
  value: 'ID',
};

export const chunkFields = {
  key: 'Key',
  value: 'Value',
};

export const customTimePeriodIndex = 3;

export const defaultGraphSettingsValues = (aggregationList, chunkList, timePeriodList, isTimeSeriesGraph = true) => {
  const defaultTimePeriod = timePeriodList[2]; // LAST_YEAR
  const rangeLastYear = Helpers.getDateRangeByTimePeriod(defaultTimePeriod[timePeriodFields.value]);
  const startOfCurrentYearInUnix = Math.floor(new Date(new Date().getFullYear(), 0, 1).getTime() / 1000);
  const endOfCurrentYearInUnix = Math.floor(new Date(new Date().getFullYear(), 11, 31).getTime() / 1000);
  let graphSettings = {
    [settingsFormConstants.aggregation.name]: [aggregationList[0]], // AVERAGE
    [settingsFormConstants.timePeriod.name]: defaultTimePeriod,
    [settingsFormConstants.chunks.name]: isTimeSeriesGraph ? chunkList[chunkList.length - 2] : chunkList[chunkList.length - 1], // MONTHLY for time series graph, YEARLY for analysis graph
    [settingsFormConstants.showAlarms.name]: false,
  };

  if (!isTimeSeriesGraph) {
    graphSettings = {
      ...graphSettings,
      [settingsFormConstants.dateRanges.name]: [
        {
          [settingsFormConstants.dateRangeFrom.name]: startOfCurrentYearInUnix,
          [settingsFormConstants.dateRangeTo.name]: endOfCurrentYearInUnix,
        },
      ],
    };
  } else {
    graphSettings = {
      ...graphSettings,
      [settingsFormConstants.dateFrom.name]: rangeLastYear[settingsFormConstants.dateFrom.name] * 1000,
      [settingsFormConstants.dateTo.name]: rangeLastYear[settingsFormConstants.dateTo.name] * 1000,
    };
  }

  return graphSettings;
};

export const graphingGroupsTableConfig = [
  {
    title: 'GRAPHING_GROUPS_LIST.TABLE.NAME',
    key: formConstants.name,
    type: formConstants.name,
    class: 'name-column',
    isSort: false,
  },
  {
    title: 'GRAPHING_GROUPS.MODAL.TYPE',
    key: formConstants.graphType,
    type: formConstants.graphType,
    isSort: false,
    class: 'type-column',
  },
  {
    title: 'GRAPHING_GROUPS_LIST.TABLE.MEASUREMENT_POINTS',
    key: formConstants.measurementPoints,
    type: formConstants.measurementPoints,
    isSort: false,
    class: 'measurement-points-column',
  },
  {
    title: 'GRAPHING_GROUPS_LIST.TABLE.SET_AS_DEFAULT',
    key: formConstants.isDefault,
    type: formConstants.isDefault,
    isSort: false,
    class: 'is-default-column',
  },
  {
    title: '',
    key: 'actions',
    type: 'actions',
    class: 'action-column',
  },
];

export const formatGraphingGroupTableCells = (value, type, _searchTerm, row, t, restProps) => {
  const { editGraphingGroup, deleteGraphingGroup, setAsDefault } = restProps;
  if (type === formConstants.name) {
    return <TruncatedText text={value} maxChar={50} />;
  } else if (type === formConstants.graphType) {
    return <TruncatedText text={value ? t(value) : t('GRAPHING_GROUPS.MODAL.TYPE.TIME_SERIES_GRAPH')} maxChar={20} />;
  } else if (type === formConstants.measurementPoints) {
    const measurementPointsNumber = (value || []).length;
    const firstFiveMeasurementPoints = value ? value.slice(0, 5) : [];

    if (measurementPointsNumber < 2) {
      return (
        <TruncatedText text={row?.[formConstants.measurementPoints] && row?.[formConstants.measurementPoints][0] ? row?.[formConstants.measurementPoints][0][formConstants.name] : '-'} maxChar={50} />
      );
    } else {
      return (
        <div className="graphing-groups-list__measurement-points">
          <InfoTooltip
            customComponent={
              <MultiplePreviewPopup
                title={row[formConstants.name]}
                subTitle={t('GRAPHING_GROUPS_LIST.TABLE.MEASUREMENT_POINTS')}
                list={firstFiveMeasurementPoints}
                customContent={
                  // display custom content if the number of measurement points is greater than 5
                  measurementPointsNumber > 5 ? (
                    <div className="measurement-points-popover__custom-content">
                      <p className="f-primary mb-20">...</p>
                      <p className="f-primary">{t('GRAPHING_GROUPS_LIST.TABLE.MEASUREMENT_POINTS.CUSTOM_CONTENT')}</p>
                    </div>
                  ) : null
                }
              />
            }
            offsetY={10}
            offsetX={-20}
            Component={() => (
              <div className="graphing-groups-list__measurement-points-popover">
                <p className="f-primary">{t('MULTIPLE', { numberOfItems: measurementPointsNumber })}</p>
                <Icon name="info" handleHover={false} size="xs" />
              </div>
            )}
          />
        </div>
      );
    }
  } else if (type === formConstants.isDefault) {
    return <Toggle input={{ value, onChange: e => setAsDefault(e, row) }} inline={false} className="graphing-groups-list__toggle" />;
  } else if (type === 'actions') {
    const canDelete = !row[formConstants.isDefault];
    return (
      <>
        <Icon name="pen" size="xs" onClick={e => editGraphingGroup(e, row)} />
        <Icon disabled={!canDelete} handleHover={canDelete} name="trash" size="xs" onClick={e => (canDelete ? deleteGraphingGroup(e, row) : null)} />
      </>
    );
  } else {
    return <p className="f-primary">{value ? value : '-'}</p>;
  }
};

export const steps = {
  firstStep: 1,
  secondStep: 2,
};

export const aggregationsDropdownItemsInfo = [
  {
    title: 'AVERAGE',
    description: 'AVERAGE.DESCRIPTION',
    conflicts: [],
  },
  {
    title: 'MEDIAN',
    description: 'MEDIAN.DESCRIPTION',
    conflicts: [],
  },
  {
    title: 'MAXIMUM',
    description: 'MAX_OF_AVERAGES.DESCRIPTION',
    conflicts: ['MAXMAX'],
  },
  {
    title: 'MAXMAX',
    description: 'MAX_OF_MAX_VALUES.DESCRIPTION',
    conflicts: ['MAXIMUM'],
  },
  {
    title: 'MINIMUM',
    description: 'MIN_OF_AVERAGES.DESCRIPTION',
    conflicts: ['MINMIN'],
  },
  {
    title: 'MINMIN',
    description: 'MIN_OF_MIN_VALUES.DESCRIPTION',
    conflicts: ['MINIMUM'],
  },
];

// used to order the aggregations in the dropdown
export const TIME_SERIES_AGGREGATION_ORDER_MAP = {
  MAXIMUM: 1,
  MAXMAX: 2,
  MINIMUM: 3,
  MINMIN: 4,
  AVERAGE: 5,
  MEDIAN: 6,
};

const CHUNK_TYPES = {
  YEARLY: 'YEARLY',
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY',
  DAILY: 'DAILY',
};

/**
 * Returns default date range values based on the chunk type
 * @param {string} dateRangeKey - The chunk type (YEARLY, MONTHLY, WEEKLY, DAILY)
 * @returns {Object} Object containing dateFrom and dateTo values in seconds
 */
export const getDefaultChunkDateRanges = dateRangeKey => {
  const now = new Date();

  switch (dateRangeKey.Key) {
    case CHUNK_TYPES.YEARLY: {
      // First day of current year to last day of current year
      const startDate = new Date(now.getFullYear(), 0, 1); // January 1st
      const endDate = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999); // December 31st

      return {
        [settingsFormConstants.dateRangeFrom.name]: Math.floor(startDate.getTime() / 1000),
        [settingsFormConstants.dateRangeTo.name]: Math.floor(endDate.getTime() / 1000),
      };
    }
    case CHUNK_TYPES.MONTHLY: {
      // First day of current month to last day of current month
      const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);

      return {
        [settingsFormConstants.dateRangeFrom.name]: Math.floor(startDate.getTime() / 1000),
        [settingsFormConstants.dateRangeTo.name]: Math.floor(endDate.getTime() / 1000),
      };
    }
    case CHUNK_TYPES.WEEKLY: {
      // Current week (Monday to Sunday)
      const today = now.getDay(); // 0 = Sunday, 1 = Monday, etc.
      const daysSinceMonday = today === 0 ? 6 : today - 1; // Adjust for Monday as first day

      const startDate = new Date(now);
      startDate.setDate(now.getDate() - daysSinceMonday);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
      endDate.setHours(23, 59, 59, 999);

      return {
        [settingsFormConstants.dateRangeFrom.name]: Math.floor(startDate.getTime() / 1000),
        [settingsFormConstants.dateRangeTo.name]: Math.floor(endDate.getTime() / 1000),
      };
    }
    case CHUNK_TYPES.DAILY: {
      // Current day (00:00:00 to 23:59:59)
      const startDate = new Date(now);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(now);
      endDate.setHours(23, 59, 59, 999);

      return {
        [settingsFormConstants.dateRangeFrom.name]: Math.floor(startDate.getTime() / 1000),
        [settingsFormConstants.dateRangeTo.name]: Math.floor(endDate.getTime() / 1000),
      };
    }
    default:
      return null;
  }
};
