import { filter, find, findIndex, isObject, uniqBy, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import { FORMS } from '../../../../../common/constants';
import Helpers from '../../../../../common/helpers';
import { questionTypes } from '../../../../../common/question-components/constants/question-constants';
import RenderIf from '../../../../../common/render-if/components/render-if';
import Stepper from '../../../../../common/stepper/components/stepper';
import { getModuleQuestionFieldValue, getModuleQuestionsInitialValues } from '../../../helpers/inspection-helper';
import {
  changeStatusFields,
  changeStatusQuestionField,
  fields,
  isolationComponentFields,
  questionFields,
  questionsIsolationComponentsStepperData,
  steps,
} from '../../right-toolbar/isolation-certificate-details/constants/change-status-constants';
import ChangeStatusForm from './change-status-form';
import ChangeStatusQuestions from './change-status-questions';

import { moduleSectionsFields } from '../../../constants/constants';
import '../../../styles/change-status-modal.scss';
import { statuses } from '../../isolation-certificates/constants/isolation-certificates-table';
import ChangeStatusIsolationComponents from './change-status-isolation-components';

class ChangeStatusModal extends Component {
  constructor(props) {
    super(props);
    const { isolationComponents, status } = props;
    const section = find(props.sections, { Name: props.status.sectionName });
    const types = values(questionTypes);

    const questions = section?.SectionQuestions
      ? uniqBy(
          filter(section.SectionQuestions, item => findIndex(types, { valueId: item[questionFields.type], isSelectable: true }) > -1),
          questionFields.id
        )
      : [];
    /* The below code is checking if there are any questions and isolation components available. If
    both are available, it sets the step to the third step and updates the stepperData with step
    values. If only isolation components are available, it removes the first step from stepperData
    and updates the step values. If only questions are available, it removes the second step from
    stepperData and updates the step values. */
    let step = steps.third;
    const isDeisolatedStatusRequested = status && status[moduleSectionsFields.status] === statuses.deIsolated.value;
    let stepperData = Object.assign([], questionsIsolationComponentsStepperData(isDeisolatedStatusRequested));

    if (questions?.length > 0 && isolationComponents?.length > 0) {
      step = steps.first;
      stepperData[0].stepValue = steps.first;
      stepperData[1].stepValue = steps.second;
      stepperData[2].stepValue = steps.third;
    } else if (isolationComponents?.length > 0) {
      stepperData.splice(0, 1);
      step = steps.first;
      stepperData[0].stepValue = steps.first;
      stepperData[1].stepValue = steps.second;
    } else if (questions?.length > 0) {
      stepperData.splice(1, 1);
      step = steps.first;
      stepperData[0].stepValue = steps.first;
      stepperData[1].stepValue = steps.second;
    }

    this.state = {
      step,
      stepperData,
      questions,
      isolationComponents,
      answers: [],
      questionsInitialValues: {},
      changeStatusValues: {},
      questionsAltered: false,
      isolationComponentsAltered: false,
    };
  }

  componentDidUpdate = (_prevProps, prevState) => {
    const { step, answers } = this.state;

    if (step === steps.first && prevState.step !== step) {
      const initialValues = getModuleQuestionsInitialValues(answers, changeStatusQuestionField);

      this.setState({ questionsInitialValues: initialValues });
    }
  };

  componentWillUnmount = () => {
    const { isolationComponents } = this.state;
    this.handleResetIsolationComponentCheckboxes(isolationComponents);
  };

  handleFieldChange = (name, question, value, change, questionType) => {
    const { user, formValues } = this.props;
    const fieldValue = getModuleQuestionFieldValue(name, question, value, change, formValues);
    const userAnswers = Object.assign([], this.state.answers);
    const index = findIndex(userAnswers, { [questionFields.id]: question[questionFields.id] });
    let answer = Object.assign({ QuestionID: question[questionFields.id], AnsweredAt: Helpers.getCurrentDateAndTimeInMs(), AnsweredBy: user.UserID }, question);

    if (index > -1) {
      answer = Object.assign({}, userAnswers[index]);
      answer.AnsweredAt = Helpers.getUnixDate(new Date().getTime());
    }

    if (question[questionFields.type] === questionTypes.MULTI_ANSWER.valueId) {
      const answerOptionIndex = findIndex(answer[questionType.fieldName], item => item === value);

      if (answerOptionIndex > -1) {
        answer[questionType.fieldName].splice(answerOptionIndex, 1);
      } else {
        if (answer[questionType.fieldName] && question[questionFields.allowMultipleAnswers]) {
          answer[questionType.fieldName].push(fieldValue);
        } else {
          answer[questionType.fieldName] = [fieldValue];
        }
      }
    } else {
      answer[questionType.fieldName] = fieldValue;
    }

    if (index > -1) {
      userAnswers.splice(index, 1, answer);
    } else {
      userAnswers.push(answer);
    }

    this.setState({ answers: userAnswers });
  };

  handleCheckboxToggleIsolationComponent = (isolationComponent, value) => {
    const { isolationComponents, isolationComponentsAltered } = this.state;
    const newIsolationComponents = Object.assign([], isolationComponents);
    const foundIndex = findIndex(newIsolationComponents, { [isolationComponentFields.componentId]: isolationComponent[isolationComponentFields.componentId] });

    if (foundIndex > -1) {
      isolationComponent.isChecked = value;
      newIsolationComponents.splice(foundIndex, 1, isolationComponent);
      this.setState({ isolationComponents: newIsolationComponents, isolationComponentsAltered: !isolationComponentsAltered });
    }
  };

  /* The below code is a JavaScript function that takes an optional parameter `isolationComponents`
  (defaulting to the `isolationComponents` state of the component) and resets the `isChecked`
  property of each object in the `isolationComponents` array to `false`. It does this by creating a
  new array using `Object.assign`, filtering the array to set the `isChecked` property of each
  object to `false`, and then updating the state of the component with the new array. */
  handleResetIsolationComponentCheckboxes = (isolationComponents = this.state.isolationComponents) => {
    const newIsolationComponents = Object.assign([], isolationComponents);
    newIsolationComponents.filter(isolationComponent => (isolationComponent[isolationComponentFields.isChecked] = false));
    this.setState({
      isolationComponents: newIsolationComponents,
    });
  };

  toggleSteps = step => {
    this.setState({ step });
  };

  toggleQuestionDetails = (index, imagesProp) => {
    const { questions, questionsAltered } = this.state;
    const statusQuestions = Object.assign([], questions);
    const question = statusQuestions[index];

    if (question) {
      if (imagesProp) {
        question[imagesProp] = !question[imagesProp];
      } else {
        question.isExpanded = !question.isExpanded;
      }

      statusQuestions.splice(index, 1, question);
      this.setState({ questions: statusQuestions, questionsAltered: !questionsAltered });
    }
  };

  toggleIsolationComponentDetails = (index, prop) => {
    const { isolationComponents, isolationComponentsAltered } = this.state;
    const statusIsolationComponents = Object.assign([], isolationComponents);
    const isolationComponent = statusIsolationComponents[index];

    if (isolationComponent) {
      if (prop) {
        isolationComponent[prop] = !isolationComponent[prop];
      } else {
        isolationComponent.isExpanded = !isolationComponent.isExpanded;
      }

      statusIsolationComponents.splice(index, 1, isolationComponent);
      this.setState({ isolationComponents: statusIsolationComponents, isolationComponentsAltered: !isolationComponentsAltered });
    }
  };

  handleChangeStatus = values => {
    const { answers } = this.state;
    const { status, user, sections, handleStatusChanged, signatureRequired } = this.props;
    const contractorId =
      values[changeStatusFields.contractor.name] && isObject(values[changeStatusFields.contractor.name]) ? values[changeStatusFields.contractor.name].ID : values[changeStatusFields.contractor.name];
    const section = find(sections, { Name: status.sectionName });
    const questions = Object.assign([], section?.SectionQuestions);

    const data = {
      [fields.status]: status.value,
      [fields.answers]: answers,
      [fields.contractorId]: contractorId,
      [fields.sectionId]: section.ID,
    };

    const assigneeQuestion = find(questions, {
      [questionFields.type]: questionTypes.SIGNATURE.valueId,
      [questionFields.name]: signatureRequired ? questionTypes.SIGNATURE.assigneeName : questionTypes.SIGNATURE.assignee,
    });
    const witnessQuestion = find(questions, { [questionFields.type]: questionTypes.SIGNATURE.valueId, [questionFields.name]: questionTypes.SIGNATURE.witnessName });

    data[changeStatusFields.assigneeSignature.name] = {
      QuestionID: assigneeQuestion?.[questionFields.id],
      PersonID: contractorId,
      Payload: values[changeStatusFields.assigneeSignature.name],
    };

    data[changeStatusFields.witnessSignature.name] = {
      QuestionID: witnessQuestion?.[questionFields.id],
      PersonID: user.UserID,
      Payload: values[changeStatusFields.witnessSignature.name],
    };

    handleStatusChanged(data);
  };

  handleStatusChangeFormChange = values => {
    this.setState({
      changeStatusValues: values,
    });
  };

  render() {
    const { step, questions, answers, stepperData, questionsInitialValues, questionsAltered, isolationComponents, isolationComponentsAltered, changeStatusValues } = this.state;
    const { contractors = [], status, signatureRequired, closeAction, disabled, selectedContractor, changeStatusDisclaimer } = this.props;

    const initialFormValues = {
      ...changeStatusValues,
      ...(selectedContractor && {
        [changeStatusFields.contractor.name]: selectedContractor,
      }),
    };

    const formProps = {
      ...(selectedContractor && { disableContractorPicker: true }),
    };

    const hasQuestions = questions && questions.length > 0;
    const hasIsolationComponents = isolationComponents && isolationComponents.length > 0;

    return (
      <div className="change-status-modal">
        <RenderIf if={changeStatusDisclaimer && changeStatusDisclaimer !== ''}>
          <p className="status-disclaimer">{changeStatusDisclaimer}</p>
        </RenderIf>
        <RenderIf if={hasQuestions || hasIsolationComponents}>
          <div className="stepper-wrapper">
            <Stepper stepperData={stepperData} activeStep={step} />
          </div>
        </RenderIf>
        <RenderIf if={hasQuestions && step === steps.first}>
          <ChangeStatusQuestions
            initialValues={questionsInitialValues}
            questions={questions}
            answers={answers}
            handleFieldChange={this.handleFieldChange}
            handleFinishQuestions={this.toggleSteps}
            handleCloseModal={closeAction}
            toggleQuestionDetails={this.toggleQuestionDetails}
            questionsAltered={questionsAltered}
          />
        </RenderIf>
        <RenderIf if={hasIsolationComponents && (stepperData?.length === 3 ? step === steps.second : step === steps.first)}>
          <ChangeStatusIsolationComponents
            isolationComponents={isolationComponents}
            toggleIsolationComponentDetails={this.toggleIsolationComponentDetails}
            handleCloseModal={closeAction}
            handleFieldChange={this.handleCheckboxToggleIsolationComponent}
            handleFinishIsolationComponents={this.toggleSteps}
            isolationComponentsAltered={isolationComponentsAltered}
            hasQuestions={hasQuestions}
            handleGoBack={this.toggleSteps}
            handleResetIsolationComponentCheckboxes={this.handleResetIsolationComponentCheckboxes}
          />
        </RenderIf>
        <RenderIf if={stepperData?.length === 3 ? step === steps.third : step === steps.second}>
          <ChangeStatusForm
            contractors={contractors}
            handleFormSubmit={this.handleChangeStatus}
            status={status}
            signatureRequired={signatureRequired}
            showGoBackButton={hasQuestions || hasIsolationComponents}
            handleCloseModal={closeAction}
            handleGoBack={this.toggleSteps}
            disabled={disabled}
            onChange={this.handleStatusChangeFormChange}
            hasQuestions={hasQuestions}
            hasIsolationComponents={hasIsolationComponents}
            handleResetIsolationComponentCheckboxes={this.handleResetIsolationComponentCheckboxes}
            initialValues={initialFormValues}
            {...formProps}
          />
        </RenderIf>
      </div>
    );
  }
}

ChangeStatusModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChangeStatusModal.propTypes = {
  contractors: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string,
    })
  ),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string,
      SectionQuestions: PropTypes.arrayOf(
        PropTypes.shape({
          ID: PropTypes.number,
          Name: PropTypes.string.isRequired,
          QuestionType: PropTypes.number,
          QuestionOrder: PropTypes.number,
          IsMandatory: PropTypes.bool,
          AllowMultipleAnswers: PropTypes.bool,
          EstimatedTime: PropTypes.number,
          CreatedAt: PropTypes.number,
          SectionID: PropTypes.number,
        })
      ),
    })
  ),
  selectedContractor: PropTypes.shape({
    ID: PropTypes.number,
    Name: PropTypes.string,
  }),
  status: PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string,
    permission: PropTypes.string,
    modalTitle: PropTypes.string,
    actionButtonText: PropTypes.string,
  }).isRequired,
  signatureRequired: PropTypes.bool,
  handleStatusChanged: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.userReducer,
  formValues: getFormValues(FORMS.changeModuleStatusQuestionsForm)(state),
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatusModal);
