import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../icon/components/icon';
import { constants } from '../constants/constants';

import TruncatedText from '../../form/components/truncated-text';
import '../styles/notifications.scss';

const Notification = ({ isDisplayed, type, icon = 'checkmark', text, iconProps, onClickClose, wrapperClassName }) => {
  return (
    <div className={`notification notification--${type} notification--${isDisplayed ? 'show' : 'hide'} ${wrapperClassName ? wrapperClassName : ''}`}>
      <div className="notification__info">
        <div className={`notification__icon notification__icon--${type}`}>
          <Icon name={icon || 'checkmark'} handleHover={false} {...iconProps} />
        </div>
      </div>
      <div className="notification__info-text">
        <TruncatedText className="notification__info-text__content" text={text} maxChar={150} />
        <button className="notification__button" onClick={onClickClose}>
          <Icon name="close" size="sm" />
        </button>
      </div>
    </div>
  );
};

Notification.propTypes = {
  isDisplayed: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.values(constants)).isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  iconProps: PropTypes.object,
  onClickClose: PropTypes.func.isRequired,
  wrapperClassName: PropTypes.string,
};

export default Notification;
