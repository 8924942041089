import { isEmpty, map } from 'lodash';
import { fields } from '../app/document-management/constants/constants';
import { formConstants } from '../app/inspections/constants/component-constants';
import { componentFields, componentFilterValues, filterValues } from '../app/pdf-tag/constants/constants';
import LinkedImagesSliderSlider from '../app/start-workflow/components/linked-images-slider';
import orientationConstants from '../app/start-workflow/constants/orientation-constants';
import { ReactComponent as PDFIcon } from '../common/assets/pdf-icon.svg';
import Helpers from '../common/helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../common/permissions-constants';

export const createModalConfig = ({
  file,
  title,
  customClassName = 'modal-large',
  CustomContent,
  closeAction,
  minimizeAction,
  maximizeAction,
  separatePageAction,
  pdfPageNumber,
  selectedComponent,
  FileIcon = props => <PDFIcon height={18} {...props} />,
}) => ({
  isOpen: true,
  fileName: file.name,
  title,
  customClassName,
  CustomContent,
  closeAction,
  minimizeAction,
  maximizeAction,
  separatePageAction,
  FileIcon,
  type: 'none',
  pdfPageNumber,
  selectedComponent,
});

export const useFileOpener = config => {
  const handlePDFPageChange = (file, callback) => {
    const { changePDFPage, inspectionId, pdfPageNumber } = config;
    return changePDFPage(
      pdfPageNumber,
      inspectionId,
      file[fields.fileId],
      {
        [filterValues.searchText]: '',
        [filterValues.componentFilter]: componentFilterValues.all,
      },
      null,
      true,
      callback
    );
  };

  const showPDFModal = (file, CustomContent, isLoadedFromNonInspectionModule = false) => {
    const { setModalData, setPdfComponents, toggleInspectionModal, inspectionModalData, closeInspectionModal, minimizeInspectionModal, maximizeInspectionModal, openAsSeparatePageInspectionModal, t } =
      config;

    const modalConfig = createModalConfig({
      file,
      title: t('PDF_VIEWER.MODAL.TITLE'),
      closeAction: isLoadedFromNonInspectionModule
        ? () => {
            setPdfComponents([]);
            setModalData({ isOpen: false });
          }
        : closeInspectionModal,
      minimizeAction: !isLoadedFromNonInspectionModule ? () => handlePDFPageChange(file, () => minimizeInspectionModal()) : undefined,
      maximizeAction: !isLoadedFromNonInspectionModule ? () => handlePDFPageChange(file, () => maximizeInspectionModal()) : undefined,
      separatePageAction: !isLoadedFromNonInspectionModule ? () => handlePDFPageChange(file, () => openAsSeparatePageInspectionModal()) : undefined,
      CustomContent: props => <CustomContent {...props} isLoadingTags={true} />,
    });

    if (isLoadedFromNonInspectionModule && setModalData) {
      setModalData(prevProps => ({
        ...prevProps.modalData,
        ...modalConfig,
      }));
    } else if (toggleInspectionModal) {
      toggleInspectionModal({
        ...inspectionModalData,
        ...modalConfig,
      });
    }
  };

  const openPDFFile = (file, moduleItemId, CustomContent, selectedModuleItem) => {
    const { inspectionId, isLoadedFromNonInspectionModule = false, getPdfComponentsForPopup, setPdfComponents, loadAllComponents = false, pdfPageNumber } = config;

    // Create filter object once
    const filter = {
      [filterValues.searchText]: '',
      [filterValues.componentFilter]: isLoadedFromNonInspectionModule && loadAllComponents ? componentFilterValues.all : componentFilterValues.cvLinked,
    };

    // Common callback function
    const handleComponentsResponse = d => {
      const confirmedDrawings = (d || []).filter(cDrawing => cDrawing[componentFields.confirmed]);
      setPdfComponents(confirmedDrawings);
      showPDFModal(file, props => <CustomContent {...props} isLoadingTags={false} />, isLoadedFromNonInspectionModule);
    };

    // Show initial loading state
    showPDFModal(file, CustomContent, isLoadedFromNonInspectionModule);

    // Determine if we're using getPdfComponentsAndChangePageBySelectedComponent
    const isFromComponentDetails = selectedModuleItem && typeof selectedModuleItem === 'object' && selectedModuleItem[componentFields.componentId];

    // Call appropriate function with correct parameters
    if (isFromComponentDetails) {
      getPdfComponentsForPopup(
        inspectionId,
        file[fields.fileId],
        filter,
        selectedModuleItem,
        true, // showOnlyConfirmedDrawings
        handleComponentsResponse
      );
    } else {
      getPdfComponentsForPopup(inspectionId, file[fields.fileId], filter, pdfPageNumber || 1, moduleItemId, handleComponentsResponse);
    }
  };

  const handleImagesModal = (selectedImage, linkedImages) => {
    let index = 0;
    const processedImages = map(linkedImages, (item, i) => {
      item.src = Helpers.getUploadImageSource(item[fields.fileName], item[fields.url]);
      if (selectedImage[fields.fileId] === item[fields.fileId]) {
        index = i;
      }
      return item;
    });

    config.setModalData({
      customClassName: 'linked-images-slider',
      CustomContent: () => (
        <LinkedImagesSliderSlider
          linkedImages={processedImages}
          currentSlideIndex={index}
          setOrientation={config.setOrientation}
          disabledOrientations={[orientationConstants.orientation.chart]}
          isPreviewModal={true}
          openDeleteFileModal={config.openDeleteFileModal}
          hasUnlinkAccess={{
            user: config.user,
            id: config.selectedComponent?.[formConstants.fields.createdById],
            ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.components].create.name,
            visibleFor: [PERMISSIONS[PERMISSION_TYPES.components].edit.name],
          }}
        />
      ),
      isOpen: true,
      type: 'none',
      closeAction: () => config.setModalData({ isOpen: false }),
    });
  };

  const openFile = (file, imageFiles, moduleItemId, CustomContent, selectedModuleItem) => {
    if (file.isPDF || Helpers.isPDF(file[fields.fileName] || file[fields.name] || file.name, file[fields.fileType])) {
      openPDFFile(file, moduleItemId, CustomContent, selectedModuleItem);
    } else if (file.isImage || Helpers.isImage(file[fields.fileName] || file[fields.name] || file.name, file[fields.fileType])) {
      const images = isEmpty(imageFiles) ? [file] : imageFiles;
      handleImagesModal(file, images);
    } else {
      Helpers.getFileExtensionAndDownload(file);
    }
  };

  return {
    openFile,
  };
};
