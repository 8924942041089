import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { cloneDeep, get, isEmpty, isEqual, some } from 'lodash';

import { measurementTypes } from '../../constants/constants';
import { defectType } from '../../constants/defect-constants';
import measureConfig from '../../constants/measure-config';

class InspectionRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      objectElements: [],
    };
  }

  componentDidMount() {
    const { elements, viewer } = this.props;
    if (!viewer) return;
    this.measuringTool = new window.Potree.MeasuringTool(viewer);
    elements.forEach(element => {
      this.addMeasure(element);
    });
  }

  componentWillUnmount() {
    this.removeAll();
  }

  removeAll = () => {
    const { viewer } = this.props;
    const { objectElements } = this.state;
    if (viewer) {
      objectElements.forEach(defectObject => {
        const measure = defectObject.measure;
        measure.removeEventListener('marker_clicked', this.onMarkerClick);
        measure.removeEventListener('marker_dropped', this.onMarkerDrop);
        viewer.scene.removeMeasurement(defectObject.measure);
      });
      this.setState({ objectElements: [] });
    }
  };

  componentDidUpdate = prevProps => {
    let { elements, viewer, disableMovement, queryItem } = this.props;
    if (!viewer) {
      return;
    }
    if (viewer !== prevProps.viewer) {
      this.measuringTool = new window.Potree.MeasuringTool(viewer);
    }
    if (this.defectsChanged(prevProps.elements, elements) || viewer !== prevProps.viewer || disableMovement !== prevProps.disableMovement || queryItem !== prevProps.queryItem) {
      this.removeAll();
      viewer.scene.emptyAddedMeasurementsQueue();
      // Some element id changed but arrays has same length
      elements.forEach(element => {
        if (element.visible) {
          this.addMeasure(element);
        }
      });
    }
  };

  elementCoordinatesChanged = (prevElementCoordinates, currentElementCoordinates) => {
    // deep check if any of the coordinates have changed
    // isEqual performs a deep comparison between two values to determine if they are equivalent.
    return !isEqual(prevElementCoordinates, currentElementCoordinates);
  };

  defectsChanged = (prevElements, currentElements) => {
    if (prevElements.length !== currentElements.length) return true;
    // ARRAYS HAS SAME LENGTH
    else if (!isEmpty(prevElements)) {
      prevElements.forEach((element, index) => {
        if (element.ID === currentElements[index].ID) {
          // UPDATE MEASURE

          if (!isEmpty(element.Geometry) && !isEmpty(currentElements[index]) && !isEmpty(currentElements[index].Geometry)) {
            // Check if there is an Geometry object in both elements
            if (
              !isEmpty(currentElements[index].Geometry.coordinates) &&
              !isEmpty(currentElements[index].Geometry.coordinates[0]) &&
              !isEmpty(element.Geometry.coordinates) &&
              !isEmpty(element.Geometry.coordinates[0]) &&
              // Check if the element coordinates changed
              this.elementCoordinatesChanged(element.Geometry.coordinates, currentElements[index].Geometry.coordinates)
            ) {
              // If both objects have coordinates and they are different
              if (currentElements[index].visible) {
                this.removeMeasure(currentElements[index]);
                this.addMeasure(currentElements[index]);
              }
            } else if (
              !isEmpty(currentElements[index].Geometry.coordinates) &&
              !isEmpty(currentElements[index].Geometry.coordinates[0]) &&
              (isEmpty(element.Geometry.coordinates) || isEmpty(element.Geometry.coordinates[0]))
            ) {
              // If incoming object has coordinates but previous one does not have that means that the coordinates have been added
              if (currentElements[index].visible) {
                this.removeMeasure(currentElements[index]);
                this.addMeasure(currentElements[index]);
              }
            } else if (
              !isEmpty(element.Geometry.coordinates) &&
              !isEmpty(element.Geometry.coordinates[0]) &&
              (isEmpty(currentElements[index].Geometry.coordinates) || isEmpty(currentElements[index].Geometry.coordinates[0]))
            ) {
              // If previous object has coordinates but incoming does not have that means that the coordinates have been removed
              this.removeMeasure(currentElements[index]);
            }
          }

          if (element.Colour !== currentElements[index].Colour || element.Name !== currentElements[index].Name) {
            if (currentElements[index].visible) {
              this.removeMeasure(currentElements[index]);
              this.addMeasure(currentElements[index]);
            }
          }
          if (element.visible !== currentElements[index].visible) {
            if (currentElements[index].visible) this.addMeasure(currentElements[index]);
            else this.removeMeasure(currentElements[index]);
          }
          if (element.enableMove !== currentElements[index].enableMove) {
            const existingIndex = this.state.objectElements.findIndex(el => el.id === currentElements[index].ID);

            if (existingIndex > -1) {
              const measure = this.state.objectElements[existingIndex].measure;
              measure.enableMove = currentElements[index].enableMove;
            }
          }
        }
      });
      return some(prevElements, (element, index) => element.ID !== currentElements[index].ID); // Array has the same length but don't have same elements
    }
  };

  isMeasurement = element => {
    return element.SystemType === measurementTypes.measurement;
  };

  addAllMeasurementsToScene = (type, persistState) => {
    const { elements } = this.props;

    elements.forEach(element => {
      if (this.state.objectElements.findIndex(el => el.id === element.ID) > -1) return;
      if (element.Geometry && type === element.Geometry.type) {
        if (persistState) {
          if (element.visible) this.addMeasure(element);
        }
      }
    });
  };

  removeAllMeasurementsFromScene = type => {
    const { elements } = this.props;

    elements.forEach(element => {
      if (!element.visible) return;
      if (element.Geometry && type === element.Geometry.type) this.removeMeasure(element);
    });
  };

  onMarkerClick = (e, b) => {
    const { elements, selectElement } = this.props;
    const defectObject = this.state.objectElements.find(el => el.measure.uuid === e.measurement.uuid);
    if (defectObject && defectObject.id) {
      const element = elements.find(d => d.ID === defectObject.id);
      if (isNaN(element.ID) || element.ID < 0) {
        return;
      }

      selectElement(element, true);
    }
  };

  onMarkerDrop = e => {
    const { elements, updateElementGeometry, onMarkerDrop } = this.props;
    const defectObject = this.state.objectElements.find(el => el.measure.uuid === e.measurement.uuid);

    if (onMarkerDrop) return onMarkerDrop(defectObject.id, e);

    if (!defectObject) return;
    let defect = elements.find(el => el.ID === defectObject.id);

    if (defect && defect.Geometry && !isEmpty(defect.Geometry.coordinates) && updateElementGeometry && defect.SystemType !== measurementTypes.cluster) {
      const element = cloneDeep(defect);
      element.Geometry.coordinates = [...e.measurement.points.map(point => [point.position.x, point.position.y, point.position.z])];

      updateElementGeometry(element);
    }
  };

  measureConfig = (args = {}) => {
    let measure = new window.Potree.Measure();

    measure.showDistances = args.showDistances === null ? true : args.showDistances;
    measure.showArea = args.showArea || false;
    measure.showCircle = args.showCircle || false;
    measure.showAngles = args.showAngles || false;
    measure.showCoordinates = args.showCoordinates || false;
    measure.coordinatesText = args.coordinatesText || null;
    measure.showHeight = args.showHeight || false;
    measure.closed = args.closed || false;
    measure.maxMarkers = args.maxMarkers || Infinity;
    measure.name = args.name || 'Measurement';
    measure.systemType = args.systemType || window.Potree.SystemType.none;
    measure.subSystemType = args.subSystemType || null;
    measure.colorName = args.colorName || 'green';
    measure.color = args.color || '#01f6a5';
    measure.enableMove = isNaN(args.enableMove) ? true : args.enableMove;
    measure.showMeasureText = args.showMeasureText ? args.showMeasureText : false;
    measure.measureText = args.measureText ? args.measureText : '';
    measure.addEventListener('marker_clicked', this.onMarkerClick);
    measure.addEventListener('marker_dropped', this.onMarkerDrop);

    return measure;
  };

  addMeasure = element => {
    const { viewer, disableMovement, queryItem } = this.props;
    if (!viewer) return;
    let measure = null;
    const isElementMovable = typeof element.enableMove !== 'undefined' ? element.enableMove : true;

    // MEASURE CONFIGURATION
    if (!element.Geometry || isEmpty(element.Geometry.coordinates)) return;
    const hasGeometryCoordinates = element.Geometry.coordinates.length > 0;
    const hasThreeGeometryCoordinates = element.Geometry.coordinates[0].length === 3;
    if (element.Geometry.type === defectType.point && hasThreeGeometryCoordinates) {
      measure = this.measureConfig({
        showDistances: false,
        showAngles: false,
        coordinatesText: element.Name,
        showArea: false,
        closed: true,
        maxMarkers: 1,
        colorName: element.Colour,
        systemType: element.SystemType,
        subSystemType: element.MainType,
        enableMove: !disableMovement && (element.NumberOfPoints === 1 || !element.NumberOfPoints) && isElementMovable,
        showMeasureText: element.NumberOfPoints ? element.NumberOfPoints > 1 : true,
        measureText: element.NumberOfPoints && element.NumberOfPoints > 1 ? element.NumberOfPoints : '',
        name: 'Point',
        ...measureConfig[measurementTypes.defect].variants[parseInt(element.variant || 0)],
        showCoordinates: element.NumberOfPoints === 1 || !element.NumberOfPoints,
      });
    } else if (element.Geometry.type === defectType.distance && hasGeometryCoordinates) {
      measure = this.measureConfig({
        showDistances: true,
        showArea: false,
        closed: false,
        coordinatesText: element.Name,
        colorName: element.Colour,
        systemType: element.SystemType,
        subSystemType: element.MainType,
        enableMove: !disableMovement && isElementMovable,
        name: 'Distance',
      });
    } else if (element.Geometry.type === defectType.circle && hasGeometryCoordinates) {
      measure = this.measureConfig({
        showDistances: false,
        showHeight: true,
        showArea: false,
        showCircle: true,
        closed: false,
        coordinatesText: element.Name,
        colorName: element.Colour,
        systemType: element.SystemType,
        subSystemType: element.MainType,
        enableMove: !disableMovement && isElementMovable,
        name: 'Circle',
      });
    } else if (element.Geometry.type === defectType.angle && hasGeometryCoordinates) {
      measure = this.measureConfig({
        showDistances: false,
        showAngles: true,
        showArea: false,
        closed: true,
        maxMarkers: 3,
        coordinatesText: element.Name,
        systemType: element.SystemType,
        subSystemType: element.MainType,
        name: 'Angle',
        enableMove: !disableMovement && isElementMovable,
        colorName: element.Colour,
      });
    } else if (element.Geometry.type === defectType.area && hasGeometryCoordinates) {
      measure = this.measureConfig({
        showDistances: false,
        showArea: true,
        closed: true,
        colorName: element.Colour,
        coordinatesText: element.Name,
        systemType: element.SystemType,
        subSystemType: element.MainType,
        enableMove: !disableMovement && isElementMovable,
        name: 'Area',
      });
    } else if (element.Geometry.type === defectType.height && hasGeometryCoordinates) {
      measure = this.measureConfig({
        showDistances: false,
        showHeight: true,
        showArea: false,
        closed: false,
        maxMarkers: 2,
        colorName: element.Colour,
        coordinatesText: element.Name,
        systemType: element.SystemType,
        subSystemType: element.MainType,
        enableMove: !disableMovement && isElementMovable,
        name: 'Height',
      });
    } else return;

    measure.setColorByName(element.Colour);
    // RENDER ON THE VIEW
    element.Geometry.coordinates.forEach(position => {
      measure.addMarker(new window.THREE.Vector3(position[0], position[1], position[2]));
    });

    // CHECK IF DEFECT IS ALREADY IN ARRAY
    this.setState(prevState => {
      const existingIndex = prevState.objectElements.findIndex(el => el.id === element.ID);

      if (existingIndex > -1) {
        const newDefectObjects = [...prevState.objectElements];
        newDefectObjects[existingIndex].measure = measure;
        if (element.ID === queryItem) {
          viewer.scene.selectMeasurement(measure);
        }
        return { objectElements: newDefectObjects };
      }

      viewer.scene.addMeasurement(measure);
      if (element.ID === queryItem) {
        viewer.scene.selectMeasurement(measure);
      }
      return { objectElements: [...prevState.objectElements, { id: element.ID, measure }] };
    });
  };

  removeMeasure = element => {
    const { viewer } = this.props;

    const existingIndex = this.state.objectElements.findIndex(el => el.id === element.ID);

    if (existingIndex > -1) {
      const measure = this.state.objectElements[existingIndex].measure;
      measure.removeEventListener('marker_clicked', this.onMarkerClick);
      measure.removeEventListener('marker_dropped', this.onMarkerDrop);
      viewer.scene.removeMeasurement(measure);
      this.setState(prevState => {
        let newDefectObjects = prevState.objectElements.filter(el => el.id !== element.ID);
        return { objectElements: newDefectObjects };
      });
    }
  };
  // END

  zoomIntoElementPosition = element => {
    const { viewer, is3DViewModeActive, isDirty } = this.props;
    if (isDirty) return;
    if (
      !isEmpty(element.Geometry) &&
      !isEmpty(element.Geometry.coordinates) &&
      !isEmpty(element.Geometry.coordinates[0]) &&
      !isEmpty(element.CameraPosition) &&
      !isEmpty(element.CameraPosition.coordinates) &&
      viewer
    ) {
      if (is3DViewModeActive) {
        viewer.zoomToPosition({ x: element.CameraPosition.coordinates[0], y: element.CameraPosition.coordinates[1], z: element.CameraPosition.coordinates[2] }, element.Geometry.coordinates, 500);
      } else {
        // In 360 mode it should only rotate the camera towards that element
        let cameraPosition = get(viewer, 'scene.view.position');
        cameraPosition && viewer.zoomToPosition(cameraPosition, element.Geometry.coordinates, 500);
      }
    }
  };

  onElementClick = async (e, element) => {
    const { selectElement } = this.props;
    e && e.stopPropagation();
    await selectElement(element);
    this.zoomIntoElementPosition(element);
  };

  showElement = (e, element) => {
    const { toggleElement, toggleElementTemp } = this.props;

    e.stopPropagation();
    if (element.IsTemp) toggleElementTemp(element.ID);
    else toggleElement(element.ID);
  };

  hideElement = (e, element) => {
    const { toggleElement, toggleElementTemp } = this.props;

    e.stopPropagation();
    if (element.IsTemp) toggleElementTemp(element.ID);
    else toggleElement(element.ID);
  };

  handleSelectAll = (type = null, persistState = false) => {
    const { selectAll, selectAllTemp } = this.props;
    if (type) {
      selectAll(type, persistState);
      selectAllTemp(type, persistState);
      this.addAllMeasurementsToScene(type, persistState);
    } else {
      selectAll();
      selectAllTemp();
    }
  };

  handleDeselectAll = (type = null, persistState = false) => {
    const { deselectAll, deselectAllTemp } = this.props;
    if (type) {
      deselectAll(type, persistState);
      deselectAllTemp(type, persistState);
      this.removeAllMeasurementsFromScene(type);
    } else {
      deselectAll();
      deselectAllTemp();
    }
  };

  render() {
    const { children } = this.props;
    return (
      <>
        {children &&
          children({
            elementClickHandler: this.onElementClick,
            elementShowHandler: this.showElement,
            elementHideHandler: this.hideElement,
            selectAllHandler: this.handleSelectAll,
            deselectAllHandler: this.handleDeselectAll,
          })}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: state.userReducer,
  disableMovement: props.disableMove,
  is3DViewModeActive: state.inspectionReducer.is3DViewModeActive,
  isDirty: state.unsavedChangesReducer.isDirty,
});

InspectionRenderer.contextTypes = {
  t: PropTypes.func.isRequired,
};

InspectionRenderer.defaultProps = {
  updateElement: () => null,
  children: () => null,
};

InspectionRenderer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(InspectionRenderer);
