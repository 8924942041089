import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import { AMAZON_IMAGE_SIZES, FEATURES, FORMS } from '../../../../common/constants';
import CustomProperties from '../../../../common/custom-property/components/custom-properties';
import EmptyState from '../../../../common/empty-state-v2/components/empty-state';
import ExpandableSection from '../../../../common/expandable-section/components/expandable-section';
import Button from '../../../../common/form/components/button';
import ColorPalette from '../../../../common/form/components/color-pallete';
import CustomInput from '../../../../common/form/components/input';
import Textarea from '../../../../common/form/components/text-area';
import Toggle from '../../../../common/form/components/toggle-field';
import UneditableInfo from '../../../../common/form/components/uneditable-info';
import Helpers from '../../../../common/helpers';
import LocationExpandableSection from '../../../../common/location-expandable-section/components/location-expandable-section';
import Modal from '../../../../common/modal/components/modal';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import RenderIf from '../../../../common/render-if/components/render-if';
import ActionModal from '../../../document-management/components/modals/action-modal/action-modal';
import { componentFields } from '../../../pdf-tag/constants/constants';
import {
  clearComponentSubTypeSuggestions,
  clearComponentTypeSuggestions,
  clearLocationSuggestions,
  clearManufacturerSuggestions,
  clearMaterialSuggestions,
  clearParentAssetSuggestions,
  clearUnsavedChangesDirty,
  setComponentFormState,
  setComponentLocationAdding,
  setComponentLocationEditing,
  setComponentsFilters,
} from '../../actions/action-creators';
import {
  deleteComponentFile,
  deleteComponentProperty,
  deleteDefect,
  getComponentLinkedDefects,
  //properties
  getComponentProperties,
  getComponentPropertyNames,
  getComponentSubTypeSuggestions,
  getComponentTypeSuggestions,
  getLocationSuggestions,
  getManufacturerSuggestions,
  getMaterialSuggestions,
  getParentAssetSuggestions,
  toggleDeleteDefectModal,
  updateElementGeometry,
} from '../../actions/inspection-actions';
import { componentDropdownActions, componentsSection, formConstants, preventedFieldsPriorComponentIsCreated, textAreaMaxChars } from '../../constants/component-constants';
import { objectTools } from '../../constants/constants';
import '../../styles/component-form.scss';
import AutoComplete from '../common/input/autocomplete';
import MultiSelect from '../common/input/multiselect';
import ChangeHierarchyModal from './common/change-hierarchy-modal';
import EditModuleItemFilesModal from './common/edit-module-item-files-modal';
import sectionTitle from './common/section-title';
import FieldUpload from './component-assets';
import LocationSection from './location-section';
import { validate } from './validators/component-validator';

const ComponentForm = (props, { t }) => {
  const {
    handleSubmit,
    assetSuggestions,
    typeSuggestions,
    fetchTypeSuggestions,
    clearTypeSuggestions,
    subTypeSuggestions,
    fetchSubTypeSuggestions,
    clearSubTypeSuggestions,
    materialSuggestions,
    fetchMaterialSuggestions,
    clearMaterialSuggestions,
    manufacturerSuggestions,
    locationSuggestions,
    fetchManufacturerSuggestions,
    clearManufacturerSuggestions,
    fetchLocationSuggestions,
    clearLocationSuggestions,
    fetchAssetSuggestions,
    clearAssetSuggestions,
    componentFiles,
    selectedComponent,
    handleInputDisabled,
    objectToolClick,
    invalid,
    showGeometryWarning,
    properties,
    getComponentProperties,
    deleteComponentProperty,
    getComponentPropertyNames,
    viewer,
    setComponentLocationAdding,
    setComponentLocationEditing,
    componentLocationAdding,
    componentLocationEditing,
    images360Ref,
    projectDMSCategories,
    openDeleteFileModal,
    openFile,
    updateGeometry,
    formHasUnsavedChanges,
    formHasUnsavedCustomProps,
    unsavedCustomProps,
    handleCustomPropertyUpdate,
    requestInProgress,
    locationObject,
    defaultDMSCategoryID,
    user,
    changeField,
    setComponentFormState,
    clearUnsavedChangesDirty,
    isDefaultComponent, // TODO: this most likely can be removed, since we reroute from this component if true
    toggleElement,
  } = props;

  const imageType = AMAZON_IMAGE_SIZES.small.name;
  const isDisabled = !selectedComponent || !selectedComponent[componentFields.componentId] || selectedComponent[componentFields.componentId] < 0;
  const ownerID = selectedComponent?.CreatedByUser?.ID;
  const [modalData, setModalData] = useState({ isOpen: false });
  const [changeHierarchyModal, setChangeHierarchyModal] = useState({ isOpen: false });
  const [editFilesModal, setEditFilesModal] = useState({ isOpen: false });

  const formatDate = input => {
    if (!input) return;

    return Helpers.getDateFromUnix(input);
  };

  const openChangeHierarchyModal = () => {
    setChangeHierarchyModal({
      isOpen: true,
      type: '',
      title: t('CHANGE_HIERARCHY_MODAL.TITLE'),
      CustomContent: dynamicProps => <ChangeHierarchyModal {...dynamicProps} />,
      customClassName: 'modal-no-max-height modal-large',
      closeAction: () => setChangeHierarchyModal({ isOpen: false }),
      customCloseAction: () => setChangeHierarchyModal({ isOpen: false }),
      selectedComponent,
      changeField,
    });
  };

  const openEditFilesModal = () => {
    setEditFilesModal({
      isOpen: true,
      type: '',
      title: t('EDIT_FILES'),
      CustomContent: dynamicProps => (
        <EditModuleItemFilesModal {...dynamicProps} defaultDMSCategoryID={defaultDMSCategoryID} projectDMSCategories={projectDMSCategories} selectedModuleItem={selectedComponent} />
      ),
      customClassName: 'modal-no-max-height modal-large',
      closeAction: () => setEditFilesModal({ isOpen: false }),
      customCloseAction: () => setEditFilesModal({ isOpen: false }),
    });
  };

  const renderFilesSection = (ownerID, readonly, componentFiles) => {
    const filesByCategory = {};

    if (isEmpty(componentFiles)) {
      return <EmptyState emptyStateText={t('NO_FILES_ADDED')} showButton transparent buttonText={t('CREATE_REGIME_FORM.ADD_FILES')} buttonAction={openEditFilesModal} buttonDisabled={readonly} />;
    }

    if (!isEmpty(componentFiles)) {
      projectDMSCategories.forEach(c => {
        const category = c[componentFields.componentId];

        if (!category) return null;

        const categoryFiles = componentFiles.filter(cf => cf.CategoryID === category) || [];
        filesByCategory[c.Name] = categoryFiles;
      });
    }
    return projectDMSCategories.map(category => {
      return (
        <FieldUpload
          disabled={readonly}
          label={t(category[componentFields.name])}
          labelClass="f-primary defect-form__label"
          noFileText={t('NO_FILES_IN_CATEGORY')}
          files={filesByCategory[category[componentFields.name]]}
          imageType={imageType}
          showUploadSvg={false}
          actionsMenu={[
            {
              title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_1',
              action: data => {
                openFile(data);
              },
            },
            {
              title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_2',
              action: data => {
                Helpers.getFileExtensionAndDownload(data);
              },
            },
            {
              title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.ITEM_5',
              action: data => Helpers.downloadImageWithDrawing(data),
              isDisabled: item => {
                return !item.isImage || isEmpty(item.Drawings);
              },
            },
            {
              title: 'COMPONENT_ASSETS_DROPDOWN.SETTINGS_MENU.UNLINK_FILE',
              action: ({ FileName, SourceID }) => openDeleteFileModal(SourceID, Helpers.decodeIfStringEncoded(FileName), category[componentFields.componentId]),
              access: {
                id: ownerID,
                ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.components].create.name,
                visibleFor: [PERMISSIONS[PERMISSION_TYPES.components].edit.name],
              },
            },
          ]}
        />
      );
    });
  };

  const toggleConfirmationModal = (isOpen, title, content, confirmAction) => {
    if (isOpen) {
      const handleConfirmAction = () => {
        if (typeof confirmAction === 'function') {
          confirmAction();
        }
      };

      setModalData({
        isOpen: true,
        title: t(title),
        type: '',
        customClassName: 'work-areas-action__delete',
        customConfirmAction: handleConfirmAction,
        customCloseAction: () => setModalData(false),
        firstParagraph: t(content),
        confirmButtonText: t(title),
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
      });
    } else {
      setModalData({ isOpen: false });
    }
  };

  const handleDeleteLocation = newCoordinates => {
    const element = cloneDeep({ ...selectedComponent, ...locationObject });
    element.Geometry.coordinates = newCoordinates;

    updateGeometry(element);
    toggleConfirmationModal(false);
  };

  const hasChooseFromDMSPermission = () => {
    const permission = PERMISSIONS[PERMISSION_TYPES.components]?.chooseFromDMS?.name;

    return Helpers.hasAccess({ user, visibleFor: permission });
  };

  return (
    <AccessRenderer visibleFor={[PERMISSIONS[PERMISSION_TYPES.components].edit.name]} id={ownerID} ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.components].create.name}>
      {({ hasAccess }) => {
        const readonly = !hasAccess || isDefaultComponent; // disables the edit of component details if the selected component is the default inspection component

        return (
          <form
            className="defect-form component-form overflow-inherit"
            onSubmit={handleSubmit}
            onKeyDown={e => {
              // prevents submitting form by clicking Enter key
              if (e.key === 'Enter') e.preventDefault();
            }}
            noValidate
          >
            <div className="component-form__fields-container">
              <div className="component-form__fields-container--wrapper">
                <RenderIf if={showGeometryWarning}>
                  <LocationExpandableSection
                    customExpanded={locationObject?.visible}
                    title={t('SECTION_TITLE.COMPONENT_LOCATION')}
                    onToggleLocationSection={() => toggleElement(selectedComponent[componentFields.componentId])}
                    location={Helpers.extractIfValidCoordinates(locationObject?.Geometry?.coordinates || [])}
                    showLocationIcon={true}
                  >
                    <LocationSection
                      viewer={viewer}
                      coordinates={locationObject?.Geometry?.coordinates || []}
                      invalid={invalid}
                      readonlyCamPos={readonly}
                      locationIsAdding={componentLocationAdding}
                      locationIsEditing={componentLocationEditing}
                      setLocationIsAdding={setComponentLocationAdding}
                      setLocationIsEditing={isEditing => {
                        setComponentLocationEditing(isEditing);
                        if (images360Ref) {
                          // Show/Hide all footprints
                          images360Ref.footprintsVisible = isEditing ? false : true;
                        }
                      }}
                      handleAddLocation={() => {
                        setComponentLocationAdding(true);
                        objectToolClick(objectTools.component, componentDropdownActions.points, false, () => {
                          setComponentLocationAdding(false);
                          setComponentFormState({ hasUnsavedChanges: false });
                          clearUnsavedChangesDirty();
                        });
                      }}
                      handleCameraChange={newValue => {
                        const element = cloneDeep({ ...selectedComponent, ...locationObject });
                        element.CameraPosition.coordinates = newValue;

                        updateGeometry(element);
                      }}
                      handleDeleteLocation={newCoordinates => {
                        toggleConfirmationModal(true, 'COMPONENT_DETAILS.DELETE_LOCATION_MODAL.TITLE', 'COMPONENT_DETAILS.DELETE_LOCATION_MODAL.DESC', () => handleDeleteLocation(newCoordinates));
                      }}
                      addingLocationDescription="COMPONENT_DETAILS.MARK_ON_3D.IN_PROGRESS.TEXT"
                      missingLocationDescription="COMPONENT_DETAILS.WARNING.MISSING_PIN.DESCRIPTION"
                      missingLocationErrorMessage="COMPONENT_DETAILS.MISSING_PIN.FORM_INVALID.ERROR_MESSAGE"
                      editLocationDescription="COMPONENT_DETAILS.MARK_ON_3D.EDIT.TEXT"
                    />
                  </LocationExpandableSection>
                </RenderIf>
                <ExpandableSection
                  expanded={true}
                  hideExpandAction={true}
                  CustomComponent={sectionTitle}
                  className="expanded-section"
                  customComponentProps={{
                    ...componentsSection,
                    title: t(componentsSection.title),
                    handleSectionActionClicked: () => null,
                    sectionAction: () => null,
                    disabled: false,
                  }}
                >
                  <Field
                    id={formConstants.fields.hierarchy}
                    name={formConstants.fields.hierarchy}
                    component={UneditableInfo}
                    labelClass="inspection-input__label f-secondary-dark defect-form__label"
                    label="COMPONENT_DETAILS.FORM_HIERARCHY"
                    withTopRightButton
                    topRightButtonAction={openChangeHierarchyModal}
                    topRightButtonText="COMPONENT_DETAILS.FORM_HIERARCHY_CHANGE"
                    visible={FEATURES.componentHierarchy.componentFormFields.visible}
                    textClassName="break-text"
                    disabled={readonly}
                  />
                  <Field
                    id={formConstants.fields.name}
                    name={formConstants.fields.name}
                    disabled={readonly}
                    component={CustomInput}
                    placeholder={t('COMPONENT_DETAILS.FORM_NAME')}
                    label={t('COMPONENT_DETAILS.FORM_NAME')}
                    labelClass="defect-form__label"
                    type="text"
                    size="lg"
                    isRequired
                    redWarning
                  />
                  <Field
                    id={formConstants.fields.componentType}
                    name={formConstants.fields.componentType}
                    disabled={readonly}
                    component={AutoComplete}
                    suggestions={Helpers.convertSuggestionArray(typeSuggestions)}
                    fetchSuggestions={fetchTypeSuggestions}
                    clearSuggestions={clearTypeSuggestions}
                    placeholder={t('COMPONENT_DETAILS.FORM_COMPONENT_TYPE')}
                    label={t('COMPONENT_DETAILS.FORM_COMPONENT_TYPE')}
                    labelClass="defect-form__label"
                    type="text"
                    isRequired
                  />
                  <Field
                    id={formConstants.fields.componentSubType}
                    name={formConstants.fields.componentSubType}
                    disabled={readonly}
                    component={AutoComplete}
                    suggestions={Helpers.convertSuggestionArray(subTypeSuggestions)}
                    fetchSuggestions={fetchSubTypeSuggestions}
                    clearSuggestions={clearSubTypeSuggestions}
                    placeholder={t('COMPONENT_DETAILS.FORM_COMPONENT_SUB_TYPE')}
                    label={t('COMPONENT_DETAILS.FORM_COMPONENT_SUB_TYPE')}
                    labelClass="defect-form__label"
                    type="text"
                  />
                  <Field
                    id={formConstants.fields.critical}
                    name={formConstants.fields.critical}
                    component={Toggle}
                    className="details__toggle"
                    label={t('COMPONENT_DETAILS.FORM_CRITICAL_COMPONENT')}
                    inline={false}
                    labelClass="inspection-input__label defect-form__label"
                    isHidden={!FEATURES.componentHierarchy.componentFormFields.visible}
                    disabled={readonly}
                  />
                  <Field
                    id={formConstants.fields.code}
                    name={formConstants.fields.code}
                    disabled={readonly}
                    component={CustomInput}
                    placeholder={t('COMPONENT_DETAILS.FORM_CODE')}
                    label={t('COMPONENT_DETAILS.FORM_CODE')}
                    labelClass="defect-form__label"
                    type="text"
                    size="lg"
                    // isRequired
                  />
                  <Field
                    id={formConstants.fields.asset}
                    name={formConstants.fields.asset}
                    disabled={readonly}
                    component={AutoComplete}
                    suggestions={Helpers.convertSuggestionArray(assetSuggestions)}
                    fetchSuggestions={fetchAssetSuggestions}
                    clearSuggestions={clearAssetSuggestions}
                    placeholder={t('COMPONENT_DETAILS.FORM_PARENT_ASSET')}
                    label={t('COMPONENT_DETAILS.FORM_PARENT_ASSET')}
                    labelClass="defect-form__label"
                    type="text"
                  />
                  <Field
                    id={formConstants.fields.manufacturer}
                    name={formConstants.fields.manufacturer}
                    disabled={readonly}
                    component={AutoComplete}
                    suggestions={Helpers.convertSuggestionArray(manufacturerSuggestions)}
                    fetchSuggestions={fetchManufacturerSuggestions}
                    clearSuggestions={clearManufacturerSuggestions}
                    placeholder={t('COMPONENT_DETAILS.FORM_MANUFACTURER')}
                    label={t('COMPONENT_DETAILS.FORM_MANUFACTURER')}
                    labelClass=" defect-form__label"
                    type="text"
                  />
                  <Field
                    id={formConstants.fields.material}
                    name={formConstants.fields.material}
                    disabled={readonly}
                    component={MultiSelect}
                    suggestions={Helpers.convertSuggestionArray(materialSuggestions)}
                    fetchSuggestions={fetchMaterialSuggestions}
                    clearSuggestions={clearMaterialSuggestions}
                    placeholder={t('COMPONENT_DETAILS.FORM_TYPE_MATERIALS')}
                    label={t('COMPONENT_DETAILS.FORM_MATERIAL')}
                    labelClass="defect-form__label"
                    type="text"
                    // maxChars={componentFieldMaxCharLength} // TODO: to improve in the future
                  />
                  <Field
                    id={formConstants.fields.location}
                    name={formConstants.fields.location}
                    disabled={readonly}
                    component={AutoComplete}
                    suggestions={Helpers.convertSuggestionArray(locationSuggestions)}
                    fetchSuggestions={fetchLocationSuggestions}
                    clearSuggestions={clearLocationSuggestions}
                    placeholder={t('COMPONENT_DETAILS.FORM_LOCATION')}
                    label={t('COMPONENT_DETAILS.FORM_LOCATION')}
                    labelClass="defect-form__label"
                    type="text"
                  />
                  <Field
                    id={formConstants.fields.weight}
                    name={formConstants.fields.weight}
                    disabled={readonly}
                    component={CustomInput}
                    parse={Number}
                    inputProps={{ step: 0.1, min: 0.1 }}
                    placeholder={t('COMPONENT_DETAILS.FORM_WEIGHT')}
                    label={t('COMPONENT_DETAILS.FORM_WEIGHT')}
                    labelClass="no_transform_defect-form__label"
                    type="number"
                    size="lg"
                  />
                  <Field
                    id={formConstants.fields.description}
                    name={formConstants.fields.description}
                    disabled={readonly}
                    component={Textarea}
                    placeholder={'COMMENT'}
                    label={'COMPONENT_DETAILS.FORM_DESCRIPTION'}
                    labelClass="defect-form__label"
                    maxChars={textAreaMaxChars}
                    enableAutoResize={true}
                    wrapperClassName={''}
                  />
                  <CustomProperties
                    isDisabled={isDisabled || readonly}
                    formName={FORMS.dynamicComponentForm}
                    properties={properties}
                    getProperties={() => getComponentProperties(selectedComponent[componentFields.componentId])}
                    updateProperty={property => handleCustomPropertyUpdate(property)}
                    addProperty={data => handleCustomPropertyUpdate(data)}
                    restProps={{ ComponentID: selectedComponent[componentFields.componentId] }}
                    deleteProperty={(data, callback) => deleteComponentProperty(data, callback)}
                    getSuggestions={getComponentPropertyNames}
                    isEnhancedDesignCustomProp
                    noDebounceOnForm
                    onPropertyDeleteCallback={(deletedProp, _updatedProperties) => {
                      const updatedUnsavedProps = unsavedCustomProps.filter(p => p[formConstants.fields.id] !== deletedProp[formConstants.fields.id]);

                      setComponentFormState({
                        unsavedCustomProps: updatedUnsavedProps,
                        hasUnsavedCustomProps: updatedUnsavedProps.length > 0,
                        hasUnsavedChanges: updatedUnsavedProps.length > 0,
                      });
                    }}
                  />
                  <Field id={formConstants.fields.color} name={formConstants.fields.color} disabled={readonly} component={ColorPalette} label={t('COMPONENT_DETAILS.COLOR_TAG')} />
                  <Field
                    id={formConstants.fields.date}
                    disabled={true}
                    name={formConstants.fields.date}
                    component={UneditableInfo}
                    format={formatDate}
                    label={t('COMPONENT_DETAILS.FORM_DATE')}
                    labelClass=" defect-form__label"
                    type="text"
                    wrapperClassName="date-container"
                  />
                  <Field
                    id={formConstants.fields.createdBy}
                    disabled={true}
                    name={formConstants.fields.createdBy}
                    component={UneditableInfo}
                    placeholder={t('COMPONENT_DETAILS.FORM_CREATED_BY')}
                    label={t('COMPONENT_DETAILS.FORM_CREATED_BY')}
                    labelClass="defect-form__label"
                    type="text"
                    wrapperClassName="date-container"
                  />
                </ExpandableSection>
                <ExpandableSection
                  title={t('SECTION_TITLE.COMPONENT_FILES')}
                  handlePreventClick={isDisabled ? () => handleInputDisabled(preventedFieldsPriorComponentIsCreated.upload) : null}
                  onEditClick={e => {
                    e.stopPropagation();
                    openEditFilesModal();
                  }}
                  // hidden if the User has neither of Component:Edit, Component:Create or Component:ChooseFromDMS
                  hideEditAction={!hasChooseFromDMSPermission() && readonly}
                  expanded
                >
                  {renderFilesSection(ownerID, readonly, componentFiles)}
                </ExpandableSection>
                <Modal {...modalData} />
                <Modal {...changeHierarchyModal} />
                <Modal {...editFilesModal} />
              </div>
            </div>
            <div className="defect-form__save-cta-wrapper default-background">
              <Button type="submit" width="sm" height="md" text={t('SAVE')} disabled={!(formHasUnsavedChanges || formHasUnsavedCustomProps) || requestInProgress} />
            </div>
          </form>
        );
      }}
    </AccessRenderer>
  );
};

ComponentForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  assetSuggestions: state.inspectionReducer.assetSuggestions,
  typeSuggestions: state.inspectionReducer.componentTypeSuggestions,
  subTypeSuggestions: state.inspectionReducer.componentSubTypeSuggestions,
  materialSuggestions: state.inspectionReducer.materialSuggestions,
  manufacturerSuggestions: state.inspectionReducer.manufacturerSuggestions,
  locationSuggestions: state.inspectionReducer.locationSuggestions,
  properties: state.inspectionReducer.componentProperties,
  user: state.userReducer,
  componentFiles: state.uploadReducer.componentFiles,
  componentFilters: state.inspectionReducer.componentsFilter,
  projectDMSCategories: state.projectDetailsReducer.projectDMSCategories,
  componentLocationAdding: state.inspectionReducer.componentLocationAdding,
  componentLocationEditing: state.inspectionReducer.componentLocationEditing,
  images360Ref: state.inspectionReducer.images360Ref,
  defaultDMSCategoryID: state.projectDetailsReducer.DefaultComponentDMSCategoryID,
  formHasUnsavedChanges: state.inspectionReducer.componentFormState.hasUnsavedChanges,
  formHasUnsavedCustomProps: state.inspectionReducer.componentFormState.hasUnsavedCustomProps,
  unsavedCustomProps: state.inspectionReducer.componentFormState.unsavedCustomProps,
  requestInProgress: state.inspectionReducer.componentFormState.requestInProgress,
});

const mapDispatchToProps = dispatch => ({
  fetchAssetSuggestions: val => dispatch(getParentAssetSuggestions(val)),
  clearAssetSuggestions: () => dispatch(clearParentAssetSuggestions()),
  fetchTypeSuggestions: val => dispatch(getComponentTypeSuggestions(val)),
  clearTypeSuggestions: () => dispatch(clearComponentTypeSuggestions()),
  fetchSubTypeSuggestions: val => dispatch(getComponentSubTypeSuggestions(val)),
  clearSubTypeSuggestions: () => dispatch(clearComponentSubTypeSuggestions()),
  fetchMaterialSuggestions: val => dispatch(getMaterialSuggestions(val)),
  clearMaterialSuggestions: () => dispatch(clearMaterialSuggestions()),
  fetchManufacturerSuggestions: val => dispatch(getManufacturerSuggestions(val)),
  clearManufacturerSuggestions: () => dispatch(clearManufacturerSuggestions()),
  fetchLocationSuggestions: val => dispatch(getLocationSuggestions(val)),
  clearLocationSuggestions: () => dispatch(clearLocationSuggestions()),
  toggleDeleteModal: data => dispatch(toggleDeleteDefectModal(data)),
  deleteDefect: (data, callback, deleteOnlyInternal) => dispatch(deleteDefect(data, callback, deleteOnlyInternal)),
  getComponentLinkedDefects: (id, callback) => dispatch(getComponentLinkedDefects(id, callback)),
  deleteFile: (fileID, componentID, callback, categoryID) => dispatch(deleteComponentFile(fileID, componentID, callback, categoryID)),
  // properties
  getComponentProperties: id => dispatch(getComponentProperties(id)),
  deleteComponentProperty: (data, callback) => dispatch(deleteComponentProperty(data, callback)),
  getComponentPropertyNames: (value, callback) => dispatch(getComponentPropertyNames(value, callback)),
  setComponentsFilters: filters => dispatch(setComponentsFilters(filters)),
  setComponentLocationAdding: val => dispatch(setComponentLocationAdding(val)),
  setComponentLocationEditing: val => dispatch(setComponentLocationEditing(val)),
  updateGeometry: data => dispatch(updateElementGeometry(data)),
  setComponentFormState: state => dispatch(setComponentFormState(state)),
  clearUnsavedChangesDirty: () => dispatch(clearUnsavedChangesDirty()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORMS.componentForm,
    validate,
    enableReinitialize: true,
    touchOnChange: true,
  })(ComponentForm)
);
