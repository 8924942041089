import React, { useCallback, useEffect, useState } from 'react';
//styles
import '../../styles/graphing-groups-list.scss';
//components
import TableTemplate from '../../../../../../common/double-table/components/table-template';
import Modal from '../../../../../../common/modal/components/modal';
//constants
import { fields } from '../../constants/constants';
//package imports
import { connect } from 'react-redux';
//prop types
import PropTypes from 'prop-types';
// actions
import { setGenericNotification } from '../../../../../../common/notification/actions/action-creators';
//helpers
import { isEmpty } from 'lodash';
import ButtonWithIcon from '../../../../../../common/button-with-icon/button-with-icon';
import EmptyState from '../../../../../../common/empty-state-v2/components/empty-state';
import ReducerHelpers from '../../../../../../common/reducer-helpers';
import ActionModal from '../../../../../document-management/components/modals/action-modal/action-modal';
import { deleteGraphingGroup, getMeasurementLocationGraphingGroupsList, saveGraphingGroup, saveGraphingGroupAsDefault } from '../../actions/measurement-location-actions';
import { formConstants, formatGraphingGroupTableCells, graphingGroupsTableConfig, settingsFormConstants } from '../../constants/time-series-graph-constants';

export const TIMESERIES_GRAPH_TYPE = 'TIMESERIES';
export const ANALYSIS_GRAPH_TYPE = 'ANALYTIC';

const GraphingGroupsList = (props, { t }) => {
  const {
    getMeasurementLocationGraphingGroupsList,
    measurementLocation,
    handleEditGraphingGroup,
    deleteGraphingGroup,
    saveGraphingGroup,
    saveGraphingGroupAsDefault,
    handleSaveGraphingGroupError,
    handleSaveGraphingGroupSuccess,
    handleCreateGraphingGroup,
    setGenericNotification,
  } = props;

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [confirmModalData, setConfirmModalData] = useState({ isOpen: false });

  const fetchData = () => {
    setIsDataLoading(true);
    getMeasurementLocationGraphingGroupsList(
      measurementLocation[fields.id],
      data => {
        setData(data);
        setIsDataLoading(false);
      },
      () => {
        setIsDataLoading(false);
      }
    );
  };

  const closeConfirmModal = () => setConfirmModalData({ isOpen: false });

  const openConfirmModal = graphingGroup => {
    setConfirmModalData({
      customClassName: 'graph-action__delete',
      isOpen: true,
      closeAction: closeConfirmModal,
      type: '',
      CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
      title: t('GRAPHING_GROUPS_LIST.CONFIRM_MODAL.TITLE'),
      closeButtonText: 'CANCEL',
      confirmButtonText: 'GRAPHING_GROUPS_LIST.CONFIRM_MODAL.CONFIRM_BTN',
      confirmButtonProps: { isCapitalized: true, variant: 'danger' },
      firstParagraph: t('GRAPHING_GROUPS_LIST.CONFIRM_MODAL.DESC', { graphingGroupName: graphingGroup[formConstants.name] }),
      customCloseAction: closeConfirmModal,
      customConfirmAction: () =>
        deleteGraphingGroup(
          graphingGroup[formConstants.id],
          measurementLocation[fields.id],
          () => {
            const newData = ReducerHelpers.removeItemByProp(data, graphingGroup, formConstants.id);
            setData(newData);
            closeConfirmModal();
          },
          () => null
        ),
    });
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, []);

  const handleSaveGraphingGroup = useCallback(
    (values, isTimeSeriesGraph) => {
      const handleSaveSuccess = () => {
        fetchData();
        handleSaveGraphingGroupSuccess();
        setGenericNotification({
          isDisplayed: true,
          type: 'success',
          icon: 'checkmark-outline',
          text: t('GRAPHING_GROUP_CREATION_SUCCESS'),
        });
      };
      const handleSaveError = err => {
        handleSaveGraphingGroupError(err, !!values[formConstants.id]);
      };
      saveGraphingGroup(
        {
          ...values,
          [fields.measurementLocationID]: measurementLocation[fields.id],
          [fields.graphID]: values[formConstants.id] || undefined,
          Aggregations: values[settingsFormConstants.aggregation.name],
          [formConstants.graphType]: isTimeSeriesGraph ? TIMESERIES_GRAPH_TYPE : ANALYSIS_GRAPH_TYPE,
        },
        handleSaveSuccess,
        handleSaveError
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleSaveGraphingGroupAsDefault = useCallback(
    values => {
      const handleSaveSuccess = () => {
        fetchData();
      };
      saveGraphingGroupAsDefault(values[formConstants.id], measurementLocation[fields.id], handleSaveSuccess);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <div className="graphing-groups-list">
      {isEmpty(data) && !isDataLoading ? (
        <div className="graphing-groups-list__empty-state">
          <EmptyState
            emptyStateText={t('GRAPHING_GROUPS_LIST.EMPTY_STATE.TITLE')}
            showButton
            transparent
            buttonText={t('GRAPHING_GROUPS_LIST.EMPTY_STATE.ACTION_TEXT')}
            buttonAction={e => handleCreateGraphingGroup(e, handleSaveGraphingGroup)}
            buttonDisabled={false}
          />
        </div>
      ) : (
        <>
          <ButtonWithIcon
            icon="plus-circle"
            text={t('GRAPHING_GROUPS_LIST.NEW_GROUP')}
            noBorder
            type="success"
            bold
            customClassName="mt-20"
            onClick={e => handleCreateGraphingGroup(e, handleSaveGraphingGroup)}
          />
          <TableTemplate
            data={data || []}
            handleFormatCells={(value, type, searchTerm, row, _onDropDownClick, t) =>
              formatGraphingGroupTableCells(value, type, searchTerm, row, t, {
                editGraphingGroup: (e, group) => handleEditGraphingGroup(e, handleSaveGraphingGroup, group, row),
                deleteGraphingGroup: (_e, group) => openConfirmModal(group),
                setAsDefault: (_e, group) => handleSaveGraphingGroupAsDefault(group),
              })
            }
            tableConfig={graphingGroupsTableConfig}
            filters={{}}
            sortByColumn={fields.sortByColumn}
            sortDirection={fields.sortDirection}
            isLoading={isDataLoading}
            onRowClick={() => null}
            showSearchBar={false}
            showInfo={false}
          />
        </>
      )}

      <Modal {...confirmModalData} />
    </div>
  );
};

GraphingGroupsList.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  deleteGraphingGroup: (id, measurementLocationId, successCallback, errorCallback) => dispatch(deleteGraphingGroup(id, measurementLocationId, successCallback, errorCallback)),
  getMeasurementLocationGraphingGroupsList: (measurementLocationId, successCallback, errorCallback) =>
    dispatch(getMeasurementLocationGraphingGroupsList(measurementLocationId, successCallback, errorCallback)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
  saveGraphingGroup: (data, successCallback, errorCallback) => dispatch(saveGraphingGroup(data, successCallback, errorCallback)),
  saveGraphingGroupAsDefault: (graphId, measurementLocationId, successCallback, errorCallback) => dispatch(saveGraphingGroupAsDefault(graphId, measurementLocationId, successCallback, errorCallback)),
});

export default connect(null, mapDispatchToProps)(GraphingGroupsList);
