import { isEmpty } from 'lodash';
import { formConstants as mpFormConstants } from '../constants/measurement-point-constants';
import { formConstants, settingsFormConstants, steps } from '../constants/time-series-graph-constants';

export const createGraphingGroupValidate = (values, isTimeSeriesGraph = true, options = {}) => {
  let errors = {};
  const { activeStep } = options;

  if (activeStep === steps.firstStep) {
    errors = graphingGroupDetailsValidate(values, isTimeSeriesGraph);
  }

  if (activeStep === steps.secondStep) {
    errors = graphingGroupSettingsValidate(values, isTimeSeriesGraph);
  }

  // If no activeStep is provided, validate both steps
  if (!activeStep) {
    errors = {
      ...graphingGroupDetailsValidate(values, isTimeSeriesGraph),
      ...graphingGroupSettingsValidate(values, isTimeSeriesGraph),
    };
  }

  return errors;
};

export const graphingGroupDetailsValidate = values => {
  let errors = {};

  if (values?.[formConstants.name] && values?.[formConstants.name].length < 3) {
    errors[formConstants.name] = 'GRAPHING_GROUP_DETAILS.FORM_NAME.ERROR_MIN_CHARS';
  }

  if (values?.[formConstants.name] && values?.[formConstants.name].length > 50) {
    errors[formConstants.name] = 'GRAPHING_GROUP_DETAILS.FORM_NAME.ERROR_MAX_CHARS';
  }

  if (!values?.[formConstants.name]) {
    errors[formConstants.name] = 'GRAPHING_GROUP_DETAILS.FORM_NAME.ERROR_REQUIRED';
  }

  if (!values?.[formConstants.measurementPoints] || isEmpty(values?.[formConstants.measurementPoints])) {
    errors[formConstants.measurementPoints] = 'GRAPHING_GROUP_DETAILS.FORM_MEASUREMENT_POINTS.ERROR_REQUIRED';
  } else if (values?.[formConstants.measurementPoints].length > 20) {
    errors[formConstants.measurementPoints] = 'GRAPHING_GROUP_DETAILS.FORM_MEASUREMENT_POINTS.ERROR_MAX';
  }

  return errors;
};

export const graphingGroupSettingsValidate = (values, isTimeSeriesGraph = true) => {
  let errors = {};

  if (!values?.[settingsFormConstants.aggregation.name] || isEmpty(values?.[settingsFormConstants.aggregation.name])) {
    errors[settingsFormConstants.aggregation.name] = settingsFormConstants.aggregation.errorRequired;
  }

  if (!values?.[settingsFormConstants.chunks.name]) {
    errors[settingsFormConstants.chunks.name] = settingsFormConstants.chunks.errorRequired;
  }
  if (!values?.[settingsFormConstants.timePeriod.name]) {
    errors[settingsFormConstants.timePeriod.name] = settingsFormConstants.timePeriod.errorRequired;
  }

  if (!isTimeSeriesGraph && isEmpty(values?.[settingsFormConstants.dateRanges.name])) {
    errors[settingsFormConstants.dateRanges.name] = settingsFormConstants.dateRanges.errorRequired;
  }

  return errors;
};

export const measurementPointsValidate = values => {
  const errors = {
    [formConstants.measurementPoints]: [],
  };
  let hasError = false;

  (values?.[formConstants.measurementPoints] || []).forEach((measurementPoint, index) => {
    errors[formConstants.measurementPoints].push({});

    if (!isNaN(measurementPoint[mpFormConstants.fields.scaleFactor]) && !isFinite(measurementPoint[mpFormConstants.fields.scaleFactor])) {
      errors[formConstants.measurementPoints][index][mpFormConstants.fields.scaleFactor] = 'MEASUREMENT_POINT_DETAILS.FORM_SCALE_FACTOR.ERROR_NOT_IN_RANGE';
    }

    if (!isEmpty(errors[formConstants.measurementPoints])) {
      hasError = true;
    }
  });

  return hasError ? errors : {};
};
