import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { settingsFormConstants } from '../../../app/inspections/components/readings-and-gauges/constants/time-series-graph-constants';
import Icon from '../../icon/components/icon';

/**
 * YearRangePicker component allows users to select up to 5 years with navigation controls
 * Can also operate in navigation mode where only one year can be selected for navigation purposes
 */
const YearRangePicker = ({
  selectedRanges = [],
  onChange,
  className = '',
  disabled,
  isNavigationMode = false, // Flag to indicate navigation mode
  onNavigate, // Called when a year is selected in navigation mode
  onClose, // Called when picker should close (e.g. after navigation selection)
}) => {
  // Calculate the current year for validation
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  // State for the visible year range
  const [startYear, setStartYear] = useState(() => {
    // If we have selected ranges, start from the earliest one, otherwise current year
    if (selectedRanges.length > 0) {
      const earliestDate = Math.min(...selectedRanges.map(range => range[settingsFormConstants.dateRangeFrom.name]));
      return Math.floor(new Date(earliestDate * 1000).getFullYear() / 10) * 10;
    }
    return Math.floor(currentYear / 10) * 10;
  });

  // Convert year to DateRange format
  const yearToDateRange = useCallback(year => {
    const startDate = new Date(year, 0, 1); // January 1st 00:00:00
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(year, 11, 31); // December 31st 23:59:59.999
    endDate.setHours(23, 59, 59, 999);

    return {
      [settingsFormConstants.dateRangeFrom.name]: Math.floor(startDate.getTime() / 1000),
      [settingsFormConstants.dateRangeTo.name]: Math.floor(endDate.getTime() / 1000),
    };
  }, []);

  // Get selected years from ranges
  const selectedYears = useMemo(() => {
    return selectedRanges.map(range => new Date(range[settingsFormConstants.dateRangeFrom.name] * 1000).getFullYear());
  }, [selectedRanges]);

  // Calculate years to display (12 years in 4x3 grid)
  const yearsToDisplay = useMemo(() => {
    const years = [];
    // Show the decade (10 years) plus first year of next decade
    for (let i = 0; i <= 10; i++) {
      const year = startYear + i;
      years.push(year);
    }
    return years;
  }, [startYear]);

  // Handle year selection
  const handleYearClick = useCallback(
    year => {
      if (disabled || year > currentYear) return;

      // In navigation mode, we just call onNavigate with the selected year
      if (isNavigationMode) {
        if (onNavigate) {
          onNavigate(year);
          // Don't close the picker here - let the parent component handle the view change
          // The onClose call was causing the dropdown to close completely
          // if (onClose) onClose();
        } else {
          console.warn('YearRangePicker: onNavigate callback is missing while in navigation mode');
        }
        return;
      }

      // Regular selection mode (original behavior)
      const newSelectedRanges = [...selectedRanges];
      const yearIndex = selectedYears.indexOf(year);

      if (yearIndex !== -1) {
        // Deselect the year
        newSelectedRanges.splice(yearIndex, 1);
      } else if (newSelectedRanges.length < 5) {
        // Select the year if under limit
        newSelectedRanges.push(yearToDateRange(year));
      }

      onChange?.(newSelectedRanges.sort((a, b) => a[settingsFormConstants.dateRangeFrom.name] - b[settingsFormConstants.dateRangeFrom.name]));
    },
    [selectedRanges, selectedYears, onChange, disabled, yearToDateRange, currentYear, isNavigationMode, onNavigate]
  );

  // Navigation handlers
  const handlePrevious = useCallback(() => {
    if (disabled) return;
    setStartYear(prev => Math.max(1970, prev - 10));
  }, [disabled]);

  const handleNext = useCallback(() => {
    if (disabled) return;
    const nextStart = startYear + 10;
    if (nextStart <= currentYear) {
      setStartYear(nextStart);
    }
  }, [startYear, currentYear, disabled]);

  return (
    <div className={`year-range-picker ${className}`}>
      <div className="year-range-picker__header">
        <Icon name="chevron" size="sm" className="year-range-picker__nav-btn previous" onClick={handlePrevious} disabled={startYear <= 1970 || disabled} />
        <span className="year-range">
          {startYear} - {startYear + 9}
        </span>
        <Icon name="chevron" size="sm" className="year-range-picker__nav-btn next" onClick={handleNext} disabled={startYear + 10 > currentYear || disabled} />
      </div>
      <div className="year-range-picker__grid">
        {yearsToDisplay.map(year => {
          const isSelected = selectedYears.includes(year);
          const isDisabled = year > currentYear || disabled;

          return (
            <button
              key={year}
              type="button"
              className={`year-range-picker__year-btn 
                ${isSelected ? 'year-range-picker__year-btn--selected selected' : ''} 
                ${isDisabled ? 'year-range-picker__year-btn--disabled' : ''}
                ${isNavigationMode ? 'year-range-picker__year-btn--navigation' : ''}
              `}
              onClick={() => handleYearClick(year)}
              disabled={isDisabled}
            >
              {year}
            </button>
          );
        })}
      </div>
    </div>
  );
};

YearRangePicker.propTypes = {
  selectedRanges: PropTypes.arrayOf(
    PropTypes.shape({
      [settingsFormConstants.dateRangeFrom.name]: PropTypes.number.isRequired,
      [settingsFormConstants.dateRangeTo.name]: PropTypes.number.isRequired,
    })
  ),
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isNavigationMode: PropTypes.bool,
  onNavigate: PropTypes.func,
  onClose: PropTypes.func,
};

export default YearRangePicker;
