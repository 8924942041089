export default {
  GET_NOTIFICATIONS: '/inspection/notification/list',
  CREATE_NOTIFICATION_FROM_OBSERVATION: '/inspection/notification/defect/create',
  GET_NOTIFICATION_DETAILS: '/inspection/notification/details',
  UPDATE_NOTIFICATION: '/inspection/notification/update',
  DELETE_NOTIFICATION: '/inspection/notification/delete',
  GET_COMPONENTS_BY_NOTIFICATIONS: '/inspection/notification/component/list',
  GET_CHECKLISTS_BY_NOTIFICATIONS: '/inspection/notification/checklists/list',
  GET_FILES_BY_NOTIFICATIONS: '/inspection/notification/file/list',
  GET_NOTIFICATION_WORK_ORDERS: '/inspection/notification/workorders/list',
  GET_NOTIFICATION_CUSTOM_PROPERTIES: '/properties/notification/get',
  GET_NOTIFICATION_CUSTOM_PROPERTIES_V2: '/properties/notification/get/v2',
  ADD_NOTIFICATION_CUSTOM_PROPERTY: '/properties/notification/add',
  DELETE_NOTIFICATION_CUSTOM_PROPERTY: '/properties/notification/delete',
  UPDATE_NOTIFICATION_CUSTOM_PROPERTY: '/properties/notification/update',
  GET_NOTIFICATION_CUSTOM_PROPERTY_NAMES: '/properties/notification/names',
  FETCH_NOTIFICATION_COMMENTS: '/inspection/notification/comment/list',
  ADD_NOTIFICATION_COMMENT: '/inspection/notification/comment/add',
  DELETE_NOTIFICATION_COMMENT: '/inspection/notification/comment/delete',
  FETCH_NOTIFICATION_CLUSTERED: '/inspection/notification/clustered/list',
  FETCH_DETAILS_FOR_OBSERVATION_CREATION: '/inspection/notification/defect/create/configuration/v2',
  FETCH_CUSTOM_PROP_DEPENDANT_VALUES: '/properties/dependencies/resolve',
  FETCH_CUSTOM_PROP_DEPENDANT_VALUES_V2: '/properties/dependencies/resolve/v2',
  FETCH_NOTIFICATION_CUSTOM_PROP_DEPENDANT_VALUES: '/properties/notification/dependencies/resolve',
  FETCH_NOTIFICATION_CUSTOM_PROP_DEPENDANT_VALUES_V2: '/properties/notification/dependencies/resolve/v2',
};
