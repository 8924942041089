import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { settingsFormConstants } from '../../../app/inspections/components/readings-and-gauges/constants/time-series-graph-constants';
import Icon from '../../icon/components/icon';

/**
 * DayRangePicker component allows users to select up to 5 individual days with navigation controls
 * Shows a calendar view with individual days that can be selected
 */
const DayRangePicker = ({ selectedRanges = [], onChange, className = '', disabled = false, onNavigateToYearPicker, onNavigateToMonthPicker, selectedYear, selectedMonth, onClose }, { t }) => {
  // Calculate the current date for validation
  const currentDate = useMemo(() => new Date(), []);
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  // State for visible year and month
  const [visibleYear, setVisibleYear] = useState(() => {
    if (selectedYear !== null && selectedYear !== undefined) {
      return selectedYear;
    }

    if (selectedRanges.length > 0) {
      const earliestDate = Math.min(...selectedRanges.map(range => range[settingsFormConstants.dateRangeFrom.name]));
      return new Date(earliestDate * 1000).getFullYear();
    }
    return currentYear;
  });

  const [visibleMonth, setVisibleMonth] = useState(() => {
    if (selectedMonth !== null && selectedMonth !== undefined) {
      return selectedMonth;
    }

    if (selectedRanges.length > 0) {
      const earliestDate = Math.min(...selectedRanges.map(range => range[settingsFormConstants.dateRangeFrom.name]));
      return new Date(earliestDate * 1000).getMonth();
    }
    return currentMonth;
  });

  // Day names for column headers
  const dayNames = useMemo(
    () => [
      t('DAY_MONDAY_SHORT') || 'Mon',
      t('DAY_TUESDAY_SHORT') || 'Tue',
      t('DAY_WEDNESDAY_SHORT') || 'Wed',
      t('DAY_THURSDAY_SHORT') || 'Thu',
      t('DAY_FRIDAY_SHORT') || 'Fri',
      t('DAY_SATURDAY_SHORT') || 'Sat',
      t('DAY_SUNDAY_SHORT') || 'Sun',
    ],
    [t]
  );

  // Month names for display
  const monthNames = useMemo(() => {
    // Create month names array with fallbacks for missing translations
    const names = [
      t('MONTH_JANUARY') || 'January',
      t('MONTH_FEBRUARY') || 'February',
      t('MONTH_MARCH') || 'March',
      t('MONTH_APRIL') || 'April',
      t('MONTH_MAY') || 'May',
      t('MONTH_JUNE') || 'June',
      t('MONTH_JULY') || 'July',
      t('MONTH_AUGUST') || 'August',
      t('MONTH_SEPTEMBER') || 'September',
      t('MONTH_OCTOBER') || 'October',
      t('MONTH_NOVEMBER') || 'November',
      t('MONTH_DECEMBER') || 'December',
    ];

    // Log if we have any undefined translations for debugging
    if (names.some(name => name === undefined)) {
      console.warn('Some month translations are undefined', names);
    }

    return names;
  }, [t]);

  /**
   * Calculate dates to display in the calendar grid
   * Includes dates from previous and next months to fill the grid
   */
  const calendarDays = useMemo(() => {
    const daysInMonth = new Date(visibleYear, visibleMonth + 1, 0).getDate();
    const firstDayOfMonth = new Date(visibleYear, visibleMonth, 1);

    // Get the day of the week for the first day (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    let firstDayWeekday = firstDayOfMonth.getDay();
    // Convert Sunday from 0 to 7, so days go from Monday (1) to Sunday (7)
    firstDayWeekday = firstDayWeekday === 0 ? 7 : firstDayWeekday;
    // Adjust to get Monday as the first day (1 - 1 = 0)
    const daysFromPrevMonth = firstDayWeekday - 1;

    const days = [];

    // Add days from previous month
    const prevMonth = visibleMonth === 0 ? 11 : visibleMonth - 1;
    const prevMonthYear = visibleMonth === 0 ? visibleYear - 1 : visibleYear;
    const daysInPrevMonth = new Date(prevMonthYear, prevMonth + 1, 0).getDate();

    for (let i = daysInPrevMonth - daysFromPrevMonth + 1; i <= daysInPrevMonth; i++) {
      const date = new Date(prevMonthYear, prevMonth, i);
      days.push({
        date,
        day: i,
        month: prevMonth,
        year: prevMonthYear,
        isCurrentMonth: false,
        isFuture: date > currentDate,
        isPast: date < new Date(1970, 0, 1), // Before epoch
        isToday: false,
      });
    }

    // Add days from current month
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(visibleYear, visibleMonth, i);
      days.push({
        date,
        day: i,
        month: visibleMonth,
        year: visibleYear,
        isCurrentMonth: true,
        isFuture: date > currentDate,
        isPast: date < new Date(1970, 0, 1), // Before epoch
        isToday: i === currentDay && visibleMonth === currentMonth && visibleYear === currentYear,
      });
    }

    // Calculate how many days we need from the next month to complete the grid
    // We want a complete grid with 6 rows (42 days total)
    const totalDaysNeeded = 42;
    const daysFromNextMonth = totalDaysNeeded - days.length;

    // Add days from next month
    const nextMonth = visibleMonth === 11 ? 0 : visibleMonth + 1;
    const nextMonthYear = visibleMonth === 11 ? visibleYear + 1 : visibleYear;

    for (let i = 1; i <= daysFromNextMonth; i++) {
      const date = new Date(nextMonthYear, nextMonth, i);
      days.push({
        date,
        day: i,
        month: nextMonth,
        year: nextMonthYear,
        isCurrentMonth: false,
        isFuture: date > currentDate,
        isPast: date < new Date(1970, 0, 1), // Before epoch
        isToday: false,
      });
    }

    return days;
  }, [visibleYear, visibleMonth, currentYear, currentMonth, currentDay, currentDate]);

  /**
   * Convert a day to a DateRange format
   * @param {Object} day - Day object
   * @returns {Object} Date range object with From and To timestamps
   */
  const dayToDateRange = useCallback(day => {
    if (!day || !day.date) {
      console.warn('Invalid day data provided to dayToDateRange', day);
      return null;
    }

    // Create start and end of the day
    const startDate = new Date(day.date);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(day.date);
    endDate.setHours(23, 59, 59, 999);

    return {
      [settingsFormConstants.dateRangeFrom.name]: Math.floor(startDate.getTime() / 1000),
      [settingsFormConstants.dateRangeTo.name]: Math.floor(endDate.getTime() / 1000),
    };
  }, []);

  /**
   * Check if a day is selected
   * @param {Object} day - Day object
   * @returns {boolean} True if the day is selected
   */
  const isDaySelected = useCallback(
    day => {
      // If we don't have any selected ranges, none are selected
      if (!selectedRanges || selectedRanges.length === 0 || isEmpty(selectedRanges)) return false;

      // Convert the day to start of day timestamp for comparison
      const dayStart = new Date(day.date);
      dayStart.setHours(0, 0, 0, 0);
      const dayTimestamp = Math.floor(dayStart.getTime() / 1000);

      // Check if any selected range matches this day
      return selectedRanges
        .filter(range => range !== null && typeof range === 'object')
        .some(range => {
          const rangeStart = range?.[settingsFormConstants.dateRangeFrom.name];
          if (rangeStart === undefined) return false;

          // Compare the start dates (with some tolerance for time differences)
          const startDiff = Math.abs(rangeStart - dayTimestamp);

          // We consider it a match if it's within an hour (to handle timezone issues)
          return startDiff < 3600;
        });
    },
    [selectedRanges]
  );

  /**
   * Handle day selection
   * @param {Object} day - Day object
   */
  const handleDayClick = useCallback(
    day => {
      // Disallow selection of days in the future or before 1970
      if (disabled || day.isFuture || day.isPast) return;

      const newSelectedRanges = [...(selectedRanges || [])].filter(range => range !== null);

      // Convert the day to a date range
      const dayRange = dayToDateRange(day);

      // Check if this day is already selected
      const isAlreadySelected = isDaySelected(day);

      if (isAlreadySelected) {
        // Find the index of the day in selected ranges and remove it
        const rangeIndex = newSelectedRanges.findIndex(range => {
          const rangeStart = range?.[settingsFormConstants.dateRangeFrom.name];
          if (rangeStart === undefined) return false;

          const dayStart = new Date(day.date);
          dayStart.setHours(0, 0, 0, 0);
          const dayTimestamp = Math.floor(dayStart.getTime() / 1000);

          const startDiff = Math.abs(rangeStart - dayTimestamp);
          return startDiff < 3600; // Within an hour
        });

        if (rangeIndex !== -1) {
          newSelectedRanges.splice(rangeIndex, 1);
        }
      } else if (newSelectedRanges.length < 5) {
        // Add the day if we're under the limit and dayRange is valid
        if (dayRange !== null) {
          newSelectedRanges.push(dayRange);
        }
      }

      // Update the selected ranges, filter out any null values for safety
      if (onChange) {
        onChange(
          newSelectedRanges.filter(range => range !== null && typeof range === 'object').sort((a, b) => a[settingsFormConstants.dateRangeFrom.name] - b[settingsFormConstants.dateRangeFrom.name])
        );
      }
    },
    [disabled, selectedRanges, dayToDateRange, isDaySelected, onChange]
  );

  /**
   * Handle month navigation
   * @param {number} direction - Direction to navigate (-1 for previous, 1 for next)
   */
  const handleMonthNavigation = useCallback(
    direction => {
      if (disabled) return;

      // Prevent navigating beyond current month + future months in the calendar view
      if (direction > 0 && visibleYear === currentYear && visibleMonth >= currentMonth) return;

      // Prevent navigating before 1970
      if (direction < 0 && visibleYear === 1970 && visibleMonth === 0) return;

      setVisibleMonth(prevMonth => {
        let newMonth = prevMonth + direction;
        let newYear = visibleYear;

        if (newMonth < 0) {
          newMonth = 11;
          newYear -= 1;
        } else if (newMonth > 11) {
          newMonth = 0;
          newYear += 1;
        }

        setVisibleYear(newYear);
        return newMonth;
      });
    },
    [disabled, visibleYear, visibleMonth, currentYear, currentMonth]
  );

  // Handle navigation to YearRangePicker
  // eslint-disable-next-line no-unused-vars
  const handleNavigateToYearPicker = useCallback(() => {
    if (onNavigateToYearPicker && !disabled) {
      onNavigateToYearPicker();
    }
  }, [onNavigateToYearPicker, disabled]);

  // Handle navigation to MonthRangePicker
  const handleNavigateToMonthPicker = useCallback(() => {
    if (onNavigateToMonthPicker && !disabled) {
      onNavigateToMonthPicker(visibleYear, visibleMonth);
    }
  }, [onNavigateToMonthPicker, disabled, visibleYear, visibleMonth]);

  // Component for the header with combined month-year display
  const Header = ({ visibleYear, visibleMonth, monthNames, handlePreviousMonth, handleNextMonth, onNavigateToMonthPicker, currentYear, currentMonth, disabled }) => {
    // Handle click on the month-year display to navigate to month picker
    const handleMonthYearClick = e => {
      e.stopPropagation();
      if (onNavigateToMonthPicker) {
        onNavigateToMonthPicker();
      }
    };

    // Check if previous month button should be disabled
    const isPreviousDisabled = disabled || (visibleYear === 1970 && visibleMonth === 0);

    // Check if next month button should be disabled
    const isNextDisabled = disabled || (visibleYear >= currentYear && visibleMonth >= currentMonth);

    return (
      <div className="day-range-picker__header">
        <Icon name="chevron" size="sm" className="day-range-picker__nav-btn previous" onClick={handlePreviousMonth} disabled={isPreviousDisabled} />

        <div className="day-range-picker__month-year-display">
          <div className="day-range-picker__current-month-year" onClick={handleMonthYearClick}>
            {`${(monthNames && monthNames[visibleMonth]) || 'Unknown'} ${visibleYear}`}
            <Icon name="chevron-down" className="dropdown-icon" handleHover={true} size="sm" />
          </div>
        </div>

        <Icon name="chevron" size="sm" className="day-range-picker__nav-btn next" onClick={handleNextMonth} disabled={isNextDisabled} />
      </div>
    );
  };

  // Split the calendarDays into weeks for rendering
  const calendarWeeks = useMemo(() => {
    const weeks = [];
    const totalDays = calendarDays.length;

    for (let i = 0; i < totalDays; i += 7) {
      weeks.push(calendarDays.slice(i, i + 7));
    }

    return weeks;
  }, [calendarDays]);

  return (
    <div className={`day-range-picker ${className}`}>
      <Header
        visibleYear={visibleYear}
        visibleMonth={visibleMonth}
        monthNames={monthNames}
        handlePreviousMonth={() => handleMonthNavigation(-1)}
        handleNextMonth={() => handleMonthNavigation(1)}
        onNavigateToMonthPicker={handleNavigateToMonthPicker}
        currentYear={currentYear}
        currentMonth={currentMonth}
        disabled={disabled}
      />

      <div className="day-range-picker__calendar">
        <div className="day-range-picker__weekdays">
          {dayNames.map(day => (
            <div key={day} className="day-range-picker__weekday">
              {day}
            </div>
          ))}
        </div>

        <div className="day-range-picker__weeks">
          {calendarWeeks.map((week, weekIndex) => (
            <div key={`week-${weekIndex}`} className="day-range-picker__week">
              {week.map(day => (
                <div
                  key={`day-${day.year}-${day.month}-${day.day}`}
                  className={`
                    day-range-picker__day
                    ${!day.isCurrentMonth ? 'day-range-picker__day--other-month' : ''}
                    ${day.isFuture ? 'day-range-picker__day--future' : ''}
                    ${day.isPast ? 'day-range-picker__day--past' : ''}
                    ${day.isToday ? 'day-range-picker__day--today' : ''}
                    ${isDaySelected(day) ? 'day-range-picker__day--selected' : ''}
                    ${day.isFuture || day.isPast || disabled ? 'day-range-picker__day--disabled' : ''}
                  `}
                  onClick={() => handleDayClick(day)}
                >
                  {day.day}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

DayRangePicker.contextTypes = {
  t: PropTypes.func.isRequired,
};

DayRangePicker.propTypes = {
  selectedRanges: PropTypes.arrayOf(
    PropTypes.shape({
      [settingsFormConstants.dateRangeFrom.name]: PropTypes.number.isRequired,
      [settingsFormConstants.dateRangeTo.name]: PropTypes.number.isRequired,
    })
  ),
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onNavigateToYearPicker: PropTypes.func,
  onNavigateToMonthPicker: PropTypes.func,
  selectedYear: PropTypes.number,
  selectedMonth: PropTypes.number,
  onClose: PropTypes.func,
};

export default DayRangePicker;
