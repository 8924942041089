export const ALARMS = [
  {
    id: 'high-high',
    displayName: 'ALARMS.HIGH_HIGH.NAME',
  },
  {
    id: 'high',
    displayName: 'ALARMS.HIGH.NAME',
  },
  {
    id: 'low',
    displayName: 'ALARMS.LOW.NAME',
  },
  {
    id: 'low-low',
    displayName: 'ALARMS.LOW_LOW.NAME',
  },
];

export const formConstants = {
  fields: {
    name: {
      name: 'Name',
      id: 'name',
    },
    enabled: {
      name: 'Enabled',
      id: 'enabled',
      label: 'ALARMS.FORM_ENABLED.LABEL',
    },
    descriptor: {
      name: 'Descriptor',
      id: 'descriptor',
      label: 'ALARMS.FORM_DESCRIPTOR.LABEL',
      placeholder: 'ALARMS.FORM_DESCRIPTOR.PLACEHOLDER',
      errorRequired: 'ALARMS.FORM_DESCRIPTOR.ERROR_REQUIRED',
    },
    alarmLevel: {
      name: 'Level',
      id: 'alarmLevel',
      label: 'ALARMS.FORM_ALARM_LEVEL.LABEL',
      placeholder: 'ALARMS.FORM_ALARM_LEVEL.PLACEHOLDER',
      errorRequired: 'ALARMS.FORM_ALARM_LEVEL.ERROR_REQUIRED',
      errorNotInRange: 'ALARMS.FORM_ALARM_LEVEL.ERROR_NOT_IN_RANGE',
      errorMax: 'ALARMS.FORM_ALARM_LEVEL.MAX_DECIMAL_ERROR',
      errorMin: 'ALARMS.FORM_ALARM_LEVEL.MIN_DECIMAL_ERROR',
      errorIncorrectFormat: 'ALARMS.FORM_ALARM_LEVEL.ERROR_INCORRECT_FORMAT',
    },
    notifyAssignees: {
      name: 'NotifyAssignees',
      id: 'notifyAssignees',
      label: 'ALARMS.FORM_NOTIFY_ASSIGNEES.LABEL',
      placeholder: 'ALARMS.FORM_NOTIFY_ASSIGNEES.PLACEHOLDER',
    },
    colour: {
      name: 'Colour',
      id: 'colour',
      label: 'ALARMS.FORM_COLOUR_TAG.LABEL',
      placeholder: 'ALARMS.FORM_COLOUR_TAG.PLACEHOLDER',
      errorRequired: 'ALARMS.FORM_COLOUR_TAG.ERROR_REQUIRED',
    },
    enableDefectCreation: {
      name: 'EnableDefectCreation',
      id: 'enableDefectCreation',
      label: 'ALARMS.FORM_ENABLE_DEFECT_CREATION.LABEL',
      placeholder: 'ALARMS.FORM_ENABLE_DEFECT_CREATION.PLACEHOLDER',
    },
    enableNotificationCreation: {
      name: 'EnableNotificationCreation',
      id: 'enableNotificationCreation',
      label: 'ALARMS.FORM_ENABLE_NOTIFICATION_CREATION.LABEL',
      placeholder: 'ALARMS.FORM_ENABLE_NOTIFICATION_CREATION.PLACEHOLDER',
    },
    description: {
      name: 'Description',
      id: 'description',
    },
  },
};

export const descriptors = translationModule => ({
  greaterThan: {
    value: '1',
    title: translationModule('ALARMS.FORM_DESCRIPTOR.DROPDOWN.GREATER_THAN'),
  },
  greaterThanOrEqualTo: {
    value: '2',
    title: translationModule('ALARMS.FORM_DESCRIPTOR.DROPDOWN.GREATER_THAN_OR_EQUAL_TO'),
  },
  lessThan: {
    value: '3',
    title: translationModule('ALARMS.FORM_DESCRIPTOR.DROPDOWN.LESS_THAN'),
  },
  lessThanOrEqualTo: {
    value: '4',
    title: translationModule('ALARMS.FORM_DESCRIPTOR.DROPDOWN.LESS_THAN_OR_EQUAL_TO'),
  },
});

export const alarmColorPalette = {
  yellow: {
    name: 'yellow',
    value: '#fecf33',
    icon: 'bell',
    iconProps: {
      handleHover: false,
      solid: true,
      size: 'sm',
    },
  },
  lightOrange: {
    name: 'lightOrange',
    value: '#f69833',
    icon: 'bell',
    iconProps: {
      handleHover: false,
      solid: true,
      size: 'sm',
    },
  },
  red: {
    name: 'red',
    value: '#cc0033',
    icon: 'bell',
    iconProps: {
      className: 'white',
      handleHover: false,
      size: 'sm',
    },
  },
};

export const defaultAlarmColor = Object.keys(alarmColorPalette)[0];

export const descriptorFields = {
  id: 'ID',
  description: 'Description',
  operation: 'Operation',
};

export const defaultAlarmValues = (alarm, descriptors) => ({
  [formConstants.fields.name.name]: alarm.id,
  [formConstants.fields.enabled.name]: false,
  [formConstants.fields.colour.name]: defaultAlarmColor,
  [formConstants.fields.descriptor.name]: descriptors[0],
});
