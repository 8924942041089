import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import CustomInput from '../../../../../../common/form/components/input';
import { formConstants } from '../../constants/time-series-graph-constants';
import '../../styles/graphing-group-details-form.scss';
import AddMeasurementPointField from './add-measurement-point-field';

const GraphingGroupDetailsFormFields = (
  {
    readonly,
    searchApiRequired,
    handleAPISearch,
    measurementPoints,
    measurementPointsFilters,
    loadMoreOnClick,
    keepMPsDropdownVisible,
    measurementPointsLoading,
    measurementLocationId,
    maximumSelectionOfMPs,
    allowMeasurementPointReordering,
  },
  { t }
) => {
  return (
    <div className="graphing-group-details-form">
      <div className="double">
        <div className="split-input">
          <Field
            id={formConstants.name}
            name={formConstants.name}
            component={CustomInput}
            disabled={readonly}
            placeholder={t('GRAPHING_GROUP_DETAILS.FORM_NAME')}
            label={t('GRAPHING_GROUP_DETAILS.FORM_NAME')}
            type="text"
            size="lg"
            additionalClassName="text-transform-none"
            labelClass="text-transform-none"
            isRequired
          />
        </div>
      </div>

      <Field
        id={formConstants.measurementPoints}
        name={formConstants.measurementPoints}
        component={AddMeasurementPointField}
        disabled={readonly}
        items={measurementPoints}
        filters={measurementPointsFilters}
        searchApiRequired={searchApiRequired}
        loadMoreOnClick={loadMoreOnClick}
        handleAPISearch={handleAPISearch}
        isLoading={measurementPointsLoading}
        keepDropdownVisible={keepMPsDropdownVisible}
        measurementLocationId={measurementLocationId}
        size="lg"
        additionalClassName="text-transform-none"
        labelClass="text-transform-none"
        isRequired
        maximumSelectionOfMPs={maximumSelectionOfMPs}
        allowMeasurementPointReordering={allowMeasurementPointReordering}
      />
    </div>
  );
};

GraphingGroupDetailsFormFields.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default GraphingGroupDetailsFormFields;
