import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DropdownList from 'react-widgets/DropdownList';

import 'react-widgets/scss/styles.scss';
import '../styles/input.scss';

class CustomSelect extends Component {
  onChange = (...args) => {
    const { input, handleChange, onChange } = this.props;

    if (handleChange) {
      handleChange(...args);
    }
    const changeEvent = input?.onChange || onChange;
    if (changeEvent) {
      changeEvent(...args);
    }
  };

  onSelect = (value, _e) => {
    const { allowDropdownItemUnselect, input } = this.props;
    const currentValue = typeof input?.value === 'string' ? input?.value : input?.value?.Value;

    // If allowDropdownItemUnselect is true and values match, pass null to onChange
    // Otherwise pass the selected value
    const newValue = allowDropdownItemUnselect && value.Value === currentValue ? null : value;
    this.onChange(newValue);
  };

  render() {
    const { t } = this.context;
    const {
      input,
      className,
      data,
      meta,
      label,
      labelClass,
      defaultValue,
      valueField,
      textField,
      disabled,
      dropUp,
      onChange,
      placeholder,
      labelActions,
      itemComponent,
      ComponentAfter,
      valueComponent,
      groupBy,
      style,
      disabledItems,
      filter,
      size,
      withHiddenError,
      isRequired,
      preventOnBlur,
      defaultOpen,
      isEnhancedDesignCustomProp,
      CustomSelectIcon = undefined,
      onSelect: onSelectActionProp = null,
      ...restProps
    } = this.props;

    let additionalProps = {};

    if (itemComponent) {
      additionalProps = {
        renderListItem: obj => itemComponent({ item: obj.item }, obj, input?.value),
      };
    }

    return (
      <div className={`field-wrapper ${className || ''}`} style={style}>
        {label && (
          <div className="inline-wrapper">
            <label className={`field-wrapper__label ${labelClass} noselect`}>{`${t(label)}${isRequired ? '*' : ''}`}</label>
            {!isEmpty(labelActions) &&
              labelActions.map((el, index) => {
                return <el.IconComponent key={index} onClick={el.onClick} className={`${el.iconClass || ''} label-container__icon ${el.type || ''} label-container__icon--active svg-gray-g`} />;
              })}
          </div>
        )}

        <div className={`input-wrapper ${isEnhancedDesignCustomProp ? 'enhanced-design-custom-prop' : ''}`}>
          <div className={`select-container ${size} ${disabled ? 'disabled' : ''}`}>
            <DropdownList
              {...input}
              {...restProps}
              {...{
                data,
                dataKey: valueField,
                textField,
                onChange: this.onChange,
                onSelect: onSelectActionProp ? onSelectActionProp : this.onSelect,
                value: input?.value || defaultValue,
                onBlur: preventOnBlur
                  ? null
                  : e => {
                      e.preventDefault();
                      if (input && input.onBlur) {
                        input.onBlur(e);
                      }
                    },
                className: meta?.error && meta.touched ? 'error ' : '',
                disabled: disabled || !isEmpty(disabledItems),
                renderValue: valueComponent,
                dropUp,
                defaultOpen,
                placeholder,
                groupBy,
                filter,
                // TODO: maybe we should have chevron-down across the app? To discuss
                selectIcon: CustomSelectIcon ? CustomSelectIcon : undefined, // if undefined, it will by default be caretDown (more info: https://jquense.github.io/react-widgets/docs/DropdownList/#selectIcon)
              }}
              {...additionalProps}
            />
            <div className={`error-message${meta?.error && meta.touched ? '--visible' : ''} ${withHiddenError ? 'with-hidden-error-message' : ''}`}>
              {meta?.error && meta.touched && t(typeof meta.error === 'string' ? meta.error : meta.error.string)}
            </div>
          </div>
          {ComponentAfter && <ComponentAfter />}
        </div>
      </div>
    );
  }
}

CustomSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

CustomSelect.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClass: PropTypes.string,
  label: PropTypes.string,
  labelActions: PropTypes.array,
  disabled: PropTypes.bool,
  dropUp: PropTypes.bool,
  valueField: PropTypes.string,
  textField: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  onChange: PropTypes.func,
  withHiddenError: PropTypes.bool,
  preventOnBlur: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  allowDropdownItemUnselect: PropTypes.bool,
  onSelect: PropTypes.func,
};

CustomSelect.defaultProps = {
  filter: false,
  valueField: 'value',
  textField: 'label',
  valueComponent: null,
  size: 'sm',
  className: '',
  labelClass: '',
  label: '',
  dropUp: false,
  withHiddenError: false,
  preventOnBlur: false,
  defaultOpen: false,
};

CustomSelect.propTypes = {
  filter: PropTypes.oneOfType([PropTypes.bool, PropTypes.func, PropTypes.string]),
};

export default CustomSelect;
