import MeasurementPointNotificationsTab from '../components/tabs/measurement-point-notifications-tab';
import MeasurementPointObservationsTab from '../components/tabs/measurement-point-obesevrations-tab';

export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    color: 'Colour',
    description: 'Description',
    unit: 'Unit',
    expectedRange: 'ExpectedRange',
    alarms: 'Alarms',
    points: 'Geometry.coordinates',
    camPosition: 'CameraPosition.coordinates',
    createdDate: 'CreatedAt',
    createdBy: 'CreatedBy',
    createdByUser: 'CreatedByUser',
    componentId: 'ComponentID',
    componentName: 'ComponentName',
    componentCode: 'ComponentCode',
    lastReadingValue: 'LastReadingValue',
    lastReadingDate: 'LastReadingDate',
    measurementLocationID: 'MeasurementLocationID',
    measurementPointTriggeredAlarms: 'MeasurementPointTriggeredAlarms',
    projectID: 'ProjectID',
    critical: 'Critical',
    archived: 'Archived',
  },
};

export const tabNames = {
  details: 'DETAILS',
  alarms: 'ALARMS',
  linked_items: 'LINKED_ITEMS',
  comments: 'COMMENTS',
};

export const textAreaMaxChars = 500;

export const toolbarItems = {
  [tabNames.details]: {
    name: tabNames.details,
    label: 'MEASUREMENT_POINT_FORM_TAB.DETAILS',
  },
  [tabNames.alarms]: {
    name: tabNames.alarms,
    label: 'MEASUREMENT_POINT_FORM_TAB.ALARMS',
  },
  [tabNames.linked_items]: {
    name: tabNames.linked_items,
    label: 'MEASUREMENT_POINT_FORM_TAB.LINKED_ITEMS',
  },
  [tabNames.comments]: {
    name: tabNames.comments,
    label: 'MEASUREMENT_POINT_FORM_TAB.COMMENTS',
  },
};

export const linkedItemTabs = [
  { title: 'INSPECTION_DEFECTS', tabKey: 'observation', Children: MeasurementPointObservationsTab, visible: true },
  { title: 'INSPECTION_NOTIFICATIONS', tabKey: 'notification', Children: MeasurementPointNotificationsTab, visible: true },
];
