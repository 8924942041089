import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import { debounce, find, get, isEmpty } from 'lodash';

import Button from '../../../../common/form/components/button';
import Select from '../../../../common/form/components/select';
import UploadButton from '../../../../common/form/components/upload-button';
import SimpleLoader from '../../../../common/global-loader/components/simple-loader';
import Toolbar from '../../../../common/toolbar/components/toolbar';
import CustomInputRange from '../common/input/input-range';
import ImageSlider from './image-slider';
import PointImagesList from './point-images-list';

import { AMAZON_IMAGE_SIZES } from '../../../../common/constants';

import { imagesCameraPosition, imagesCameraPositionDefaults, imagesCameraPositionSettins } from '../../constants/constants';

import InspectionRenderer from '../left-toolbar/inspection-renderer';

import 'react-input-range/lib/css/index.css';
import { connect } from 'react-redux';
import AccessRenderer from '../../../../common/access-renderer/components/access-renderer';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import '../../styles/point-images.scss';

class PointImages extends Component {
  state = {
    imageType: AMAZON_IMAGE_SIZES.medium.name,
    imageSizeData: [
      {
        value: AMAZON_IMAGE_SIZES.small.name,
        label: AMAZON_IMAGE_SIZES.small.name,
      },
      {
        value: AMAZON_IMAGE_SIZES.medium.name,
        label: AMAZON_IMAGE_SIZES.medium.name,
      },
      {
        value: AMAZON_IMAGE_SIZES.large.name,
        label: AMAZON_IMAGE_SIZES.large.name,
      },
    ],
    ...imagesCameraPositionDefaults,
    // sliderValue: 100,
  };

  handleRealCameraChangeDebounced = debounce(this.props.handleRealCameraChange, 500);

  componentWillUnmount = () => {
    const { onClose } = this.props;
    onClose();
  };

  componentDidUpdate = prevProps => {
    const { inspectPoint } = this.props;

    const currentPoint = get(inspectPoint, 'Geometry.coordinates[0]') || [];
    const prevPoint = get(prevProps.inspectPoint, 'Geometry.coordinates[0]') || [];

    if (currentPoint.toString() !== prevPoint.toString()) {
      this.setState({
        imagesCameraPositionDefaults,
      });
    }
  };

  handleImageSize = imageType => {
    this.setState({ imageType: imageType.value });
  };

  handleAddDefect = () => {
    if (this._imageGallery) this._imageGallery.fullScreen();
  };

  setImageSliderRef = ref => {
    this._imageGallery = ref;
  };

  render() {
    const { t } = this.context;
    const { imageType, imageSizeData } = this.state;

    const {
      images,
      activeItemIndex,
      slideToIndex,
      onSlide,
      CustomComponent,
      pointImagesLoading,
      loadMoreImages,
      inspectPoint,
      handleInspectPointCloud,
      cols,
      sliderStyle,
      handleQuickDefect,
      handleRealCameraChange,
      realCameraType,
      realCameraTypeItems,
      openInspectionAssetModal,
      viewer,
    } = this.props;

    if (pointImagesLoading) {
      return <SimpleLoader isLoading={true} />;
    }

    let settings = {};

    if (realCameraType === imagesCameraPosition.uav || realCameraType === imagesCameraPosition.ground) {
      settings = {
        ...imagesCameraPositionSettins[realCameraType],
        inputProps: {
          onChange: val => {
            this.setState({ [realCameraType]: val });
            this.handleRealCameraChangeDebounced(realCameraType, val);
          },
        },
        input: {
          value: this.state[realCameraType],
        },
      };
    }
    return (
      <div className="point-images">
        <div className="point-images__container">
          <div className="point-images__title padding-horizontal">
            <p className="f-primary">{t('POINT_IMAGES.RELATED_IMAGES_TITLE')}</p>
            <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.observations].create.name}>
              {({ hasAccess }) => {
                return (
                  <div className="quick-button-1">
                    <p className="f-secondary-green noselect" onClick={hasAccess ? this.handleAddDefect : null}>
                      {t('POINT_IMAGES.ADD_DEFECT')}
                    </p>
                  </div>
                );
              }}
            </AccessRenderer>
          </div>
          <Toolbar
            activeItem={realCameraType}
            toolbarItems={realCameraTypeItems}
            handleActiveTab={type => handleRealCameraChange(type, imagesCameraPositionDefaults[type])}
            className="padding-horizontal"
          />

          <div className="point-images__dropdown padding-horizontal">
            <Select
              data={imageSizeData}
              onChange={this.handleImageSize}
              defaultValue={imageType ? find(imageSizeData, { value: imageType }) : imageSizeData[1]}
              label={'POINT_IMAGES.SLIDER_IMAGE_SIZE'}
            />
            {!isEmpty(settings) && (
              <CustomInputRange
                // className={"range-selector"}
                label={t('POINT_IMAGES.DISTANCE')}
                labelClass="f-secondary-dark defect-form__label defect-form__label--range"
                hideBorder
                type="range"
                {...settings}
              />
            )}
          </div>

          <div className="point-images__slider padding-horizontal">
            <div className="quick-button-2">{openInspectionAssetModal && <UploadButton text={t('POINT_IMAGES.ADD_IMAGES')} onClick={openInspectionAssetModal} />}</div>
            <ImageSlider
              images={images}
              currentSlideIndex={activeItemIndex}
              onSlide={onSlide}
              imageType={imageType}
              style={sliderStyle}
              setImageSliderRef={this.setImageSliderRef}
              handleQuickDefect={handleQuickDefect}
              openInspectionAssetModal={openInspectionAssetModal}
            />
          </div>

          <div className="point-images__section">
            <PointImagesList
              CustomComponent={CustomComponent}
              items={images}
              currentSlideIndex={activeItemIndex}
              handleItemClick={slideToIndex}
              imageType={AMAZON_IMAGE_SIZES.small.name}
              cols={cols}
            />
          </div>

          <Fragment>
            <div className="point-images__start-workflow">
              <Button onClick={loadMoreImages} type="button" width="lg" text={t('WORKFLOW.LOAD_MORE')} disabled={loadMoreImages ? false : true} />
            </div>
          </Fragment>
        </div>

        <InspectionRenderer
          deselectAll={() => null}
          selectAll={() => null}
          toggleElement={() => null}
          deselectAllTemp={() => null}
          selectAllTemp={() => null}
          toggleElementTemp={() => null}
          selectElement={() => null}
          elements={inspectPoint ? [inspectPoint] : []}
          disableMove={false}
          onMarkerDrop={(objectID, e) => {
            const point = e.measurement.points[0].position;
            handleInspectPointCloud(point);
          }}
          selectedDefect={null}
          viewer={viewer}
        >
          {({ elementClickHandler, elementShowHandler, elementHideHandler, selectAllHandler, deselectAllHandler }) => null}
        </InspectionRenderer>
      </div>
    );
  }
}

PointImages.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  viewer: state.potreeReducer.viewerInstance,
});

export default connect(mapStateToProps, null)(PointImages);
