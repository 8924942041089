import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { settingsFormConstants } from '../../../app/inspections/components/readings-and-gauges/constants/time-series-graph-constants';
import Icon from '../../icon/components/icon';

/**
 * WeekRangePicker component allows users to select up to 5 weeks with navigation controls
 * Shows a calendar view with days of the week, where each row represents a selectable week
 */
const WeekRangePicker = ({ selectedRanges = [], onChange, className = '', disabled = false, onNavigateToYearPicker, onNavigateToMonthPicker, selectedYear, selectedMonth }, { t }) => {
  // Calculate the current date for validation
  const currentDate = useMemo(() => new Date(), []);
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  // State for visible year and month
  const [visibleYear, setVisibleYear] = useState(() => {
    if (selectedYear !== null && selectedYear !== undefined) {
      return selectedYear;
    }

    if (selectedRanges.length > 0) {
      const earliestDate = Math.min(...selectedRanges.map(range => range[settingsFormConstants.dateRangeFrom.name]));
      return new Date(earliestDate * 1000).getFullYear();
    }
    return currentYear;
  });

  const [visibleMonth, setVisibleMonth] = useState(() => {
    if (selectedMonth !== null && selectedMonth !== undefined) {
      return selectedMonth;
    }

    if (selectedRanges.length > 0) {
      const earliestDate = Math.min(...selectedRanges.map(range => range[settingsFormConstants.dateRangeFrom.name]));
      return new Date(earliestDate * 1000).getMonth();
    }
    return currentMonth;
  });

  // Day names for column headers
  const dayNames = useMemo(
    () => [
      t('DAY_MONDAY_SHORT') || 'Mon',
      t('DAY_TUESDAY_SHORT') || 'Tue',
      t('DAY_WEDNESDAY_SHORT') || 'Wed',
      t('DAY_THURSDAY_SHORT') || 'Thu',
      t('DAY_FRIDAY_SHORT') || 'Fri',
      t('DAY_SATURDAY_SHORT') || 'Sat',
      t('DAY_SUNDAY_SHORT') || 'Sun',
    ],
    [t]
  );

  // Month names for display
  const monthNames = useMemo(() => {
    // Create month names array with fallbacks for missing translations
    const names = [
      t('MONTH_JANUARY') || 'January',
      t('MONTH_FEBRUARY') || 'February',
      t('MONTH_MARCH') || 'March',
      t('MONTH_APRIL') || 'April',
      t('MONTH_MAY') || 'May',
      t('MONTH_JUNE') || 'June',
      t('MONTH_JULY') || 'July',
      t('MONTH_AUGUST') || 'August',
      t('MONTH_SEPTEMBER') || 'September',
      t('MONTH_OCTOBER') || 'October',
      t('MONTH_NOVEMBER') || 'November',
      t('MONTH_DECEMBER') || 'December',
    ];

    // Log if we have any undefined translations for debugging
    if (names.some(name => name === undefined)) {
      console.warn('Some month translations are undefined', names);
    }

    return names;
  }, [t]);

  /**
   * Get the week number for a given date
   * @param {Date} date - The date to get the week number for
   * @returns {number} The week number
   */
  const getWeekNumber = useCallback(date => {
    const target = new Date(date.valueOf());
    const dayNumber = (date.getDay() + 6) % 7;
    target.setDate(target.getDate() - dayNumber + 3);
    const firstThursday = target.valueOf();
    target.setMonth(0, 1);
    if (target.getDay() !== 4) {
      target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
    }
    return 1 + Math.ceil((firstThursday - target) / 604800000);
  }, []);

  /**
   * Calculate dates to display in the calendar grid
   * Includes dates from previous and next months to fill the grid
   */
  const calendarDays = useMemo(() => {
    const daysInMonth = new Date(visibleYear, visibleMonth + 1, 0).getDate();
    const firstDayOfMonth = new Date(visibleYear, visibleMonth, 1);

    // Get the day of the week for the first day (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    let firstDayWeekday = firstDayOfMonth.getDay();
    // Convert Sunday from 0 to 7, so days go from Monday (1) to Sunday (7)
    firstDayWeekday = firstDayWeekday === 0 ? 7 : firstDayWeekday;
    // Adjust to get Monday as the first day (1 - 1 = 0)
    const daysFromPrevMonth = firstDayWeekday - 1;

    const calendarGrid = [];
    const weeks = [];
    let currentWeek = [];

    // Add days from previous month
    const prevMonth = visibleMonth === 0 ? 11 : visibleMonth - 1;
    const prevMonthYear = visibleMonth === 0 ? visibleYear - 1 : visibleYear;
    const daysInPrevMonth = new Date(prevMonthYear, prevMonth + 1, 0).getDate();

    for (let i = daysInPrevMonth - daysFromPrevMonth + 1; i <= daysInPrevMonth; i++) {
      const date = new Date(prevMonthYear, prevMonth, i);
      currentWeek.push({
        date,
        day: i,
        month: prevMonth,
        year: prevMonthYear,
        isCurrentMonth: false,
        isFuture: date > currentDate,
        isPast: date < new Date(1970, 0, 1),
      });
    }

    // Add days from current month
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(visibleYear, visibleMonth, i);
      currentWeek.push({
        date,
        day: i,
        month: visibleMonth,
        year: visibleYear,
        isCurrentMonth: true,
        isFuture: visibleYear > currentYear || (visibleYear === currentYear && visibleMonth > currentMonth) || (visibleYear === currentYear && visibleMonth === currentMonth && i > currentDay),
        isPast: date < new Date(1970, 0, 1),
      });

      // Start a new week if we've reached Sunday or the end of the month
      if (currentWeek.length === 7 || i === daysInMonth) {
        // If we're at the end of the month but haven't completed the week
        if (currentWeek.length < 7) {
          // Add days from next month
          const nextMonth = visibleMonth === 11 ? 0 : visibleMonth + 1;
          const nextMonthYear = visibleMonth === 11 ? visibleYear + 1 : visibleYear;

          for (let j = 1; currentWeek.length < 7; j++) {
            const date = new Date(nextMonthYear, nextMonth, j);
            currentWeek.push({
              date,
              day: j,
              month: nextMonth,
              year: nextMonthYear,
              isCurrentMonth: false,
              isFuture: nextMonthYear > currentYear || (nextMonthYear === currentYear && nextMonth > currentMonth) || (nextMonthYear === currentYear && nextMonth === currentMonth && j > currentDay),
              isPast: date < new Date(1970, 0, 1),
            });
          }
        }

        // Add the completed week to our weeks array
        weeks.push([...currentWeek]);

        // Reset the currentWeek array
        currentWeek = [];

        // Add the weeks to the calendar grid
        calendarGrid.push({
          weekNumber: getWeekNumber(weeks[weeks.length - 1][0].date),
          days: weeks[weeks.length - 1],
          // A week is in the future if ALL of its days are in the future
          isFuture: weeks[weeks.length - 1].every(day => day.isFuture),
          // A week is in the past (before 1970) if any of its days are before 1970
          isPast: weeks[weeks.length - 1].some(day => day.isPast),
          // Identify if this week contains the current date
          isCurrentWeek: weeks[weeks.length - 1].some(day => day.year === currentYear && day.month === currentMonth && day.day === currentDay),
          // Count how many days in this week are in the future
          futureDaysCount: weeks[weeks.length - 1].filter(day => day.isFuture).length,
        });
      }
    }

    return calendarGrid;
  }, [visibleYear, visibleMonth, currentYear, currentMonth, currentDay, getWeekNumber, currentDate]);

  /**
   * Convert a week of dates to a DateRange format
   * @param {Array} week - Array of day objects for a week
   * @returns {Object} Date range object with From and To timestamps
   */
  const weekToDateRange = useCallback(
    week => {
      // Validate that the week object and days array exists and has elements
      if (!week || !week.days || !Array.isArray(week.days) || week.days.length === 0 || !week.days[0] || !week.days[0].date) {
        console.warn('Invalid week data provided to weekToDateRange', week);
        return null;
      }

      // Find the first day of the week
      const startDate = new Date(week.days[0].date);
      startDate.setHours(0, 0, 0, 0);

      // For the end date, if this is the current week, use today's date as the end
      // Otherwise use the last day of the week
      let endDate;

      if (week.isCurrentWeek) {
        // For the current week, use today as the end date
        endDate = new Date(currentYear, currentMonth, currentDay);
      } else {
        // Ensure the last day of the week exists
        const lastDay = week.days[week.days.length - 1];
        if (!lastDay || !lastDay.date) {
          console.warn('Invalid last day data in week', week);
          endDate = new Date(startDate); // Fallback to using start date
        } else {
          // For past weeks, use the last day of the week
          endDate = new Date(lastDay.date);
        }
      }

      endDate.setHours(23, 59, 59, 999);

      return {
        [settingsFormConstants.dateRangeFrom.name]: Math.floor(startDate.getTime() / 1000),
        [settingsFormConstants.dateRangeTo.name]: Math.floor(endDate.getTime() / 1000),
      };
    },
    [currentYear, currentMonth, currentDay]
  );

  /**
   * Check if a week is selected
   * @param {Object} week - Week object with days array
   * @returns {boolean} True if the week is selected
   */
  const isWeekSelected = useCallback(
    week => {
      // If we don't have any selected ranges, none are selected
      if (!selectedRanges || selectedRanges.length === 0 || isEmpty(selectedRanges)) return false;

      // Convert the current week to timestamps
      const weekTimestamp = Math.floor(week.days[0].date.getTime() / 1000);

      // Check if any selected range matches this week
      return selectedRanges
        .filter(range => range !== null && typeof range === 'object')
        .some(range => {
          const rangeStart = range?.[settingsFormConstants.dateRangeFrom.name];
          if (rangeStart === undefined) return false;

          // Compare the start dates (with some tolerance for time differences)
          const startDiff = Math.abs(rangeStart - weekTimestamp);

          return startDiff < 86400; // Less than one day difference
        });
    },
    [selectedRanges]
  );

  /**
   * Handle week selection
   * @param {Object} week - Week object with days array
   */
  const handleWeekClick = useCallback(
    week => {
      // Allow selection of current week even if it contains future days
      // Disallow selection of weeks that are entirely in the future
      if (disabled || (week.isFuture && !week.isCurrentWeek) || week.isPast) return;

      const newSelectedRanges = [...(selectedRanges || [])].filter(range => range !== null);

      // Convert the current week to a date range
      const weekRange = weekToDateRange(week);

      // Check if this week is already selected
      const isAlreadySelected = isWeekSelected(week);

      if (isAlreadySelected) {
        // Find the index of the week in selected ranges and remove it
        const rangeIndex = newSelectedRanges.findIndex(range => {
          const rangeStart = range?.[settingsFormConstants.dateRangeFrom.name];
          if (rangeStart === undefined) return false;

          const weekTimestamp = Math.floor(week.days[0].date.getTime() / 1000);
          const startDiff = Math.abs(rangeStart - weekTimestamp);
          return startDiff < 86400;
        });

        if (rangeIndex !== -1) {
          newSelectedRanges.splice(rangeIndex, 1);
        }
      } else if (newSelectedRanges.length < 5) {
        // Add the week if we're under the limit and weekRange is valid
        if (weekRange !== null) {
          newSelectedRanges.push(weekRange);
        }
      }

      // Update the selected ranges, filter out any null values for safety
      if (onChange) {
        onChange(
          newSelectedRanges.filter(range => range !== null && typeof range === 'object').sort((a, b) => a[settingsFormConstants.dateRangeFrom.name] - b[settingsFormConstants.dateRangeFrom.name])
        );
      }
    },
    [disabled, selectedRanges, weekToDateRange, isWeekSelected, onChange]
  );

  /**
   * Handle month navigation
   * @param {number} direction - Direction to navigate (-1 for previous, 1 for next)
   */
  const handleMonthNavigation = useCallback(
    direction => {
      if (disabled) return;

      // Prevent navigating beyond current month
      if (direction > 0 && visibleYear === currentYear && visibleMonth >= currentMonth) return;

      // Prevent navigating before 1970
      if (direction < 0 && visibleYear === 1970 && visibleMonth === 0) return;

      setVisibleMonth(prevMonth => {
        let newMonth = prevMonth + direction;
        let newYear = visibleYear;

        if (newMonth < 0) {
          newMonth = 11;
          newYear -= 1;
        } else if (newMonth > 11) {
          newMonth = 0;
          newYear += 1;
        }

        setVisibleYear(newYear);
        return newMonth;
      });
    },
    [disabled, visibleYear, visibleMonth, currentYear, currentMonth]
  );

  // Handle navigation to YearRangePicker
  const handleNavigateToYearPicker = useCallback(() => {
    if (onNavigateToYearPicker && !disabled) {
      onNavigateToYearPicker();
    }
  }, [onNavigateToYearPicker, disabled]);

  // Handle navigation to MonthRangePicker
  const handleNavigateToMonthPicker = useCallback(() => {
    if (onNavigateToMonthPicker && !disabled) {
      onNavigateToMonthPicker(visibleYear, visibleMonth);
    }
  }, [onNavigateToMonthPicker, disabled, visibleYear, visibleMonth]);

  // Component for the header with combined month-year display
  const Header = ({ visibleYear, visibleMonth, setVisibleMonth, monthNames, handlePreviousMonth, handleNextMonth, onNavigateToMonthPicker, currentYear, currentMonth, disabled }) => {
    // Handle click on the month-year display to navigate to month picker
    const handleMonthYearClick = e => {
      e.stopPropagation();
      if (onNavigateToMonthPicker) {
        onNavigateToMonthPicker();
      }
    };

    // Check if previous month button should be disabled
    const isPreviousDisabled = disabled || (visibleYear === 1970 && visibleMonth === 0);

    // Check if next month button should be disabled
    const isNextDisabled = disabled || (visibleYear >= currentYear && visibleMonth >= currentMonth);

    return (
      <div className="week-range-picker__header">
        <Icon name="chevron" size="sm" className="week-range-picker__nav-btn previous" onClick={handlePreviousMonth} disabled={isPreviousDisabled} />

        <div className="week-range-picker__month-year-display">
          <div className="week-range-picker__current-month-year" onClick={handleMonthYearClick}>
            {`${(monthNames && monthNames[visibleMonth]) || 'Unknown'} ${visibleYear}`}
            <Icon name="chevron-down" className="dropdown-icon" handleHover={true} size="sm" />
          </div>
        </div>

        <Icon name="chevron" size="sm" className="week-range-picker__nav-btn next" onClick={handleNextMonth} disabled={isNextDisabled} />
      </div>
    );
  };

  return (
    <div className={`week-range-picker ${className}`}>
      <Header
        visibleYear={visibleYear}
        visibleMonth={visibleMonth}
        setVisibleMonth={setVisibleMonth}
        monthNames={monthNames}
        handlePreviousMonth={() => handleMonthNavigation(-1)}
        handleNextMonth={() => handleMonthNavigation(1)}
        onNavigateToYearPicker={handleNavigateToYearPicker}
        onNavigateToMonthPicker={handleNavigateToMonthPicker}
        isNavigationMode={!!selectedYear}
        currentYear={currentYear}
        currentMonth={currentMonth}
        disabled={disabled}
      />

      <div className="week-range-picker__calendar">
        <div className="week-range-picker__weekdays">
          {dayNames.map(day => (
            <div key={day} className="week-range-picker__weekday">
              {day}
            </div>
          ))}
        </div>

        <div className="week-range-picker__weeks">
          {calendarDays.map(week => (
            <div
              key={`week-${week.weekNumber}-${week.days[0].date.getTime()}`}
              className={`
                week-range-picker__week
                ${isWeekSelected(week) ? 'week-range-picker__week--selected selected' : ''}
                ${(week.isFuture && !week.isCurrentWeek) || week.isPast || disabled ? 'week-range-picker__week--disabled disabled' : ''}
                ${week.isCurrentWeek ? 'week-range-picker__week--current current' : ''}
              `}
              onClick={() => handleWeekClick(week)}
            >
              {week.days.map(day => (
                <div
                  key={`day-${day.year}-${day.month}-${day.day}`}
                  className={`
                    week-range-picker__day
                    ${!day.isCurrentMonth ? 'week-range-picker__day--other-month' : ''}
                    ${day.isFuture ? 'week-range-picker__day--future' : ''}
                    ${day.isPast ? 'week-range-picker__day--past' : ''}
                    ${week.isCurrentWeek && day.year === currentYear && day.month === currentMonth && day.day === currentDay ? 'week-range-picker__day--today' : ''}
                  `}
                >
                  {day.day}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

WeekRangePicker.contextTypes = {
  t: PropTypes.func.isRequired,
};

WeekRangePicker.propTypes = {
  selectedRanges: PropTypes.arrayOf(
    PropTypes.shape({
      [settingsFormConstants.dateRangeFrom.name]: PropTypes.number.isRequired,
      [settingsFormConstants.dateRangeTo.name]: PropTypes.number.isRequired,
    })
  ),
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onNavigateToYearPicker: PropTypes.func,
  onNavigateToMonthPicker: PropTypes.func,
  selectedYear: PropTypes.number,
  selectedMonth: PropTypes.number,
  onClose: PropTypes.func,
};

export default WeekRangePicker;
