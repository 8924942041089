import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { isEmpty } from 'lodash';
import EmptyState from '../../../../../../../common/empty-state-v2/components/empty-state';
import Button from '../../../../../../../common/form/components/button';
import Loader from '../../../../../../../common/global-loader/components/simple-loader';
import TimeSeriesGraph from '../../../../../../../common/graphs/components/time-series-graph';
import Helpers from '../../../../../../../common/helpers';
import Icon from '../../../../../../../common/icon/components/icon';
import RenderIf from '../../../../../../../common/render-if/components/render-if';
import InfoTooltip from '../../../../../../../common/tooltip/components/info-tooltip';
import { TIMESERIES_GRAPH_TYPE } from '../../../../readings-and-gauges/components/graphing-groups/graphing-groups-list';
import { customTimePeriodIndex, formConstants, settingsFormConstants, TIME_SERIES_AGGREGATION_ORDER_MAP } from '../../../../readings-and-gauges/constants/time-series-graph-constants';
import '../../styles/time-series-graph-small-preview.scss';

const CHUNK_TYPES = {
  YEARLY: 'CHUNK_TYPES.YEARLY',
  MONTHLY: 'CHUNK_TYPES.MONTHLY',
  WEEKLY: 'CHUNK_TYPES.WEEKLY',
  DAILY: 'CHUNK_TYPES.DAILY',
};

const CHUNK_TYPES_MAP = {
  YEAR: 'YEARLY',
  MONTH: 'MONTHLY',
  WEEK: 'WEEKLY',
  DAY: 'DAILY',
};

const TimeSeriesGraphSmallPreview = (
  {
    handleViewMore,
    readonly,
    handleCreateGraphingGroup,
    showGraphingGroup = true,
    graphDetailsIsLoading,
    graphLoading,
    graphData,
    graphDetails,
    timePeriodsList,
    unit = '-',
    isTimeSeriesGraph = true,
  },
  context
) => {
  const { t } = context;

  const customAggregationValue = useMemo(() => {
    const selectedAggregations = graphDetails?.[settingsFormConstants.aggregation.name];

    // ordering done on the FE side, due to BE not returning the data in the correct order
    const aggregationList = (selectedAggregations || []).sort((a, b) => TIME_SERIES_AGGREGATION_ORDER_MAP[a.Key] - TIME_SERIES_AGGREGATION_ORDER_MAP[b.Key]);

    if (!isEmpty(selectedAggregations)) {
      if (selectedAggregations.length > 1) {
        return (
          <div className="value-with-tooltip__container">
            <p className="f-primary">{t('MULTIPLE', { numberOfItems: selectedAggregations.length })}</p>
            <InfoTooltip
              customComponent={
                <>
                  <label>{t(settingsFormConstants.aggregation.placeholder)}</label>
                  {aggregationList.map(aggregation => (
                    <p className="f-primary">{t(aggregation.Key)}</p>
                  ))}
                </>
              }
              Component={() => <Icon name="info" size="sm" />}
              containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
            />
          </div>
        );
      } else {
        return <p className="f-primary">{selectedAggregations[0]?.Value}</p>;
      }
    }
    return null;
  }, [graphDetails, t]);

  const customTimePeriodValue = useMemo(() => {
    const selectedTimePeriod = graphDetails?.[settingsFormConstants.timePeriod.name];
    if (selectedTimePeriod?.[formConstants.id] === timePeriodsList[customTimePeriodIndex]?.[formConstants.id]) {
      return (
        <div className="value-with-tooltip__container">
          <p className="f-primary">{selectedTimePeriod?.[formConstants.name]}</p>
          <InfoTooltip
            actionsMenu={{
              'MEASUREMENTS_FILTERS.DATE_FROM': Helpers.formatCell(graphDetails?.[settingsFormConstants.dateFrom.name], 'date'),
              'MEASUREMENTS_FILTERS.DATE_TO': Helpers.formatCell(graphDetails?.[settingsFormConstants.dateTo.name], 'date'),
            }}
            Component={() => <Icon name="info" size="sm" />}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
          />
        </div>
      );
    }
    return <p className="f-primary">{selectedTimePeriod?.[formConstants.name]}</p>;
  }, [graphDetails, timePeriodsList]);

  if (graphDetailsIsLoading) {
    return <Loader isLoading={true} />;
  }

  if (isEmpty(graphDetails)) {
    return (
      <EmptyState
        emptyStateText={t('SECTION_TITLE.TIME_SERIES_GRAPH.EMPTY_STATE.TITLE')}
        showButton={true}
        buttonText={t('SECTION_TITLE.TIME_SERIES_GRAPH.EMPTY_STATE.ACTION_TEXT')}
        buttonAction={handleCreateGraphingGroup}
        buttonDisabled={readonly || !handleCreateGraphingGroup}
        transparent
      />
    );
  }

  const renderExactDateRanges = chunkType => {
    const dateRanges = graphDetails?.[settingsFormConstants.dateRanges.name];
    if (!dateRanges) return;

    if (dateRanges.length > 1) {
      return (
        <div className="value-with-tooltip__container">
          <p className="f-primary">{t('MULTIPLE', { numberOfItems: dateRanges.length })}</p>
          <InfoTooltip
            customComponent={
              <>
                <label>{t(settingsFormConstants.dateRanges.label)}</label>
                {dateRanges.map(range => {
                  const date = new Date(range[settingsFormConstants.dateRangeFrom.name] * 1000);
                  const chunkTypeKey = chunkType.Key;
                  switch (chunkTypeKey) {
                    case CHUNK_TYPES_MAP.YEAR:
                      return <p className="f-primary">{date.getFullYear().toString()}</p>;
                    case CHUNK_TYPES_MAP.MONTH:
                      return <p className="f-primary">{date.toLocaleDateString(undefined, { month: 'short' }) + ', ' + date.getFullYear()}</p>;
                    case CHUNK_TYPES.WEEK: {
                      // Calculate week number
                      const target = new Date(date.valueOf());
                      const dayNumber = (date.getDay() + 6) % 7;
                      target.setDate(target.getDate() - dayNumber + 3);
                      const firstThursday = target.valueOf();
                      target.setMonth(0, 1);
                      if (target.getDay() !== 4) {
                        target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
                      }
                      const weekNumber = 1 + Math.ceil((firstThursday - target) / 604800000);

                      // Get week start and end dates
                      const weekStartDate = new Date(date);
                      const day = weekStartDate.getDay();
                      weekStartDate.setDate(weekStartDate.getDate() - day);

                      const weekEndDate = new Date(weekStartDate);
                      weekEndDate.setDate(weekEndDate.getDate() + 6);

                      // Format as "Week X: MM/DD - MM/DD, YYYY"
                      return `Week ${weekNumber}: ${weekStartDate.getMonth() + 1}/${weekStartDate.getDate()} - ${weekEndDate.getMonth() + 1}/${weekEndDate.getDate()}, ${weekStartDate.getFullYear()}`;
                    }
                    case CHUNK_TYPES.DAY:
                      // Format as "MMM DD, YYYY" (e.g., "Jan 15, 2023")
                      return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' });
                    default:
                      return <p className="f-primary">{date.toLocaleDateString()}</p>;
                  }
                })}
              </>
            }
            Component={() => <Icon name="info" size="sm" />}
            containerProps={{ onMouseEnter: () => null, onMouseLeave: () => null, autoHandlePopover: true }}
          />
        </div>
      );
    }
    const date = new Date(dateRanges[0][settingsFormConstants.dateRangeFrom.name] * 1000);

    const chunkTypeKey = chunkType.Key;

    switch (chunkTypeKey) {
      case CHUNK_TYPES_MAP.YEAR:
        return date.getFullYear().toString();
      case CHUNK_TYPES_MAP.MONTH:
        return date.toLocaleDateString(undefined, { month: 'short' }) + ', ' + date.getFullYear();
      case CHUNK_TYPES.WEEK: {
        // Calculate week number
        const target = new Date(date.valueOf());
        const dayNumber = (date.getDay() + 6) % 7;
        target.setDate(target.getDate() - dayNumber + 3);
        const firstThursday = target.valueOf();
        target.setMonth(0, 1);
        if (target.getDay() !== 4) {
          target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
        }
        const weekNumber = 1 + Math.ceil((firstThursday - target) / 604800000);

        // Get week start and end dates
        const weekStartDate = new Date(date);
        const day = weekStartDate.getDay();
        weekStartDate.setDate(weekStartDate.getDate() - day);

        const weekEndDate = new Date(weekStartDate);
        weekEndDate.setDate(weekEndDate.getDate() + 6);

        // Format as "Week X: MM/DD - MM/DD, YYYY"
        return `Week ${weekNumber}: ${weekStartDate.getMonth() + 1}/${weekStartDate.getDate()} - ${weekEndDate.getMonth() + 1}/${weekEndDate.getDate()}, ${weekStartDate.getFullYear()}`;
      }
      case CHUNK_TYPES.DAY:
        // Format as "MMM DD, YYYY" (e.g., "Jan 15, 2023")
        return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' });
      default:
        return date.toLocaleDateString();
    }
  };

  return (
    <div className="time-series-graph-small-preview">
      <RenderIf if={showGraphingGroup}>
        <h6 className="f-primary mb-20">{graphDetails?.[settingsFormConstants.graphingGroup.name]?.[formConstants.name] || 'Testing graphing group name'}</h6>
      </RenderIf>
      <div className="time-series-graph-small-preview__settings-grid mb-20">
        <div className="time-series-graph-small-preview__settings-grid-item">
          <label>{t(settingsFormConstants.aggregation.placeholder)}</label>
          {customAggregationValue}
        </div>
        <div className="time-series-graph-small-preview__settings-grid-item">
          <label>{isTimeSeriesGraph ? t(settingsFormConstants.timePeriod.placeholder) : t(settingsFormConstants.chunk.placeholder)}</label>
          {isTimeSeriesGraph ? customTimePeriodValue : <p className="f-primary">{t(CHUNK_TYPES[graphDetails?.[settingsFormConstants.chunk.name]?.Key])}</p>}
        </div>
        <div className="time-series-graph-small-preview__settings-grid-item">
          <label>{isTimeSeriesGraph ? t(settingsFormConstants.chunks.placeholder) : t('TIME_SERIES_GRAPH.SETTINGS_FORM.DATE_RANGES.LABEL')}</label>
          <p className="f-primary">{isTimeSeriesGraph ? graphDetails?.[settingsFormConstants.chunks.name]?.Value : renderExactDateRanges(graphDetails?.[settingsFormConstants.chunk.name])}</p>
        </div>
      </div>
      <div className="time-series-graph-small-preview__description-info mb-20">
        <Icon name="info" handleHover={false} size="sm" />
        <p className="f-primary">{t('TIME_SERIES_GRAPH.SMALL_PREVIEW.SECTION_DESCRIPTION_INFO')}</p>
      </div>
      <div className="time-series-graph-small-preview__graph">
        <TimeSeriesGraph
          data={graphData}
          isLoading={graphLoading}
          visibleAggregations={graphDetails?.[settingsFormConstants.aggregation.name] || []}
          showLegend={false}
          showAlarms={graphDetails?.[settingsFormConstants.graphingGroup.name]?.[settingsFormConstants.showAlarms.name] || false}
          isGraphPreview={true}
          unit={unit}
          isTimeSeriesGraph={isTimeSeriesGraph || graphDetails?.[settingsFormConstants.graphType.name] === TIMESERIES_GRAPH_TYPE}
        />
      </div>
      <Button type="button" width="lg" height="md" variant="success-outline" text={t('VIEW_MORE')} onClick={handleViewMore} />
    </div>
  );
};

TimeSeriesGraphSmallPreview.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default TimeSeriesGraphSmallPreview;
