import { find, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, Field, getFormValues, reduxForm } from 'redux-form';

import 'react-input-range/lib/css/index.css';

import { FORMS } from '../../../../common/constants';

import ExpandableSection from '../../../../common/expandable-section/components/expandable-section';
import CustomSelect from '../../../../common/form/components/select';
import Toggle from '../../../../common/form/components/toggle-field';
import Icon from '../../../../common/icon/components/icon';
import {
  formConstants,
  inspectionPresets,
  modelQualityOptions,
  navigationModeOptions,
  pointMaterialIndex,
  pointMaterialOptions,
  pointSizingOptions,
  sceneSettings,
  userSettings,
} from '../../constants/inspection-settings';
import ColorPicker from '../common/input/color-picker';
import CustomInputRange from '../common/input/input-range';
import SettingsPoints from './settings-points';

const defaultModelQuality = modelQualityOptions[1];

class SettingsForm extends Component {
  handleFormChange = values => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(values);
    }
  };

  componentDidMount() {
    const { viewer, initialValues, changeField } = this.props;

    // Initialize form with initial values
    Object.entries(userSettings.fields).forEach(([key, fieldName]) => {
      if (initialValues[fieldName] !== undefined) {
        changeField(fieldName, initialValues[fieldName]);
      }
    });

    // Initialize inspection settings
    Object.entries(formConstants.fields).forEach(([key, fieldName]) => {
      if (initialValues[fieldName] !== undefined) {
        changeField(fieldName, initialValues[fieldName]);
      }
    });

    if (viewer) {
      viewer.addEventListener('move_speed_changed', this.moveSpeedChanged);
    }
  }

  componentWillUnmount() {
    const { viewer } = this.props;
    if (viewer) {
      viewer.removeEventListener('move_speed_changed', this.moveSpeedChanged);
    }
  }

  moveSpeedChanged = e => {
    const { changeField } = this.props;
    let speed = e.speed && !isNaN(e.speed) ? e.speed : null;
    if (speed) {
      speed = Math.min(Math.max(speed, 1), 20);
      changeField(userSettings.fields.speedOfMovement, Math.round(speed));
    }
  };

  changePointMaterial = pointMaterial => {
    const { viewer } = this.props;
    if (viewer && viewer.scene.pointclouds[0]) {
      viewer.scene.pointclouds.forEach(pointcloud => {
        pointcloud.material.activeAttributeName = pointMaterial.value;
      });
    }
  };

  changeModelQuality = modelQuality => {
    const { viewer, changeField } = this.props;
    changeField(userSettings.fields.modelQuality, modelQuality.value);

    if (viewer) {
      const presetToLoad = inspectionPresets[modelQuality.value];
      viewer.setPointBudget(presetToLoad[formConstants.fields.maxPoints] * 1000 * 1000);
      changeField(formConstants.fields.maxPoints, presetToLoad[formConstants.fields.maxPoints]);
      viewer.useHQ = presetToLoad[formConstants.fields.HighQuality];
      changeField(formConstants.fields.HighQuality, presetToLoad[formConstants.fields.HighQuality]);
    }
  };

  getHexColor = color => {
    return color.split('').splice(0, 7).join('');
  };

  handleChange = color => {
    const { viewer, changeField } = this.props;
    const colorHex = this.getHexColor(color);
    changeField(formConstants.fields.color, colorHex);
    if (viewer) viewer.setBackground(colorHex);
  };

  render() {
    const { t } = this.context;
    const {
      viewer,
      initialValues,
      formValues,
      color,
      selectAllCameras,
      deselectAllCameras,
      selectAllCameraThumbnails,
      deselectAllCameraThumbnails,
      isWorkflow,
      images360Ref,
      changeField,
      is3DViewModeActive,
    } = this.props;
    const { Potree } = window;
    return (
      <form className="settings-form" noValidate>
        <ExpandableSection expanded={true} title={t('SECTION_TITLE.GENERAL_SETTINGS')}>
          <Field
            id={userSettings.fields.showNeighbouringPositions}
            disabled={false}
            name={userSettings.fields.showNeighbouringPositions}
            inputProps={{
              onChange: val => {
                if (images360Ref) {
                  images360Ref.showFootprints = val;
                }
              },
            }}
            component={Toggle}
            placeholder={t('INSPECTION_SETTINGS.SHOW_NEIGHBOURING_POSITIONS')}
            label={t('INSPECTION_SETTINGS.SHOW_NEIGHBOURING_POSITIONS')}
            labelClass="f-primary"
            hideBorder
          />
          <Field
            id={userSettings.fields.radiusNeighbouringFootprints}
            name={userSettings.fields.radiusNeighbouringFootprints}
            minValue={1}
            maxValue={20}
            component={CustomInputRange}
            placeholder={t('INSPECTION_SETTINGS.RADIUS_NEIGHBOURING_FOOTPRINTS')}
            label={t('INSPECTION_SETTINGS.RADIUS_NEIGHBOURING_FOOTPRINTS')}
            labelClass="f-primary"
            hideBorder
            inputProps={{
              onChange: () => null,
            }}
            type="range"
          />
          <Field
            id={userSettings.fields.maxTagsDistance}
            disabled={false}
            name={userSettings.fields.maxTagsDistance}
            minValue={1}
            maxValue={50}
            component={CustomInputRange}
            placeholder={t('INSPECTION_SETTINGS.MAX_TAGS_DISTANCE')}
            label={t('INSPECTION_SETTINGS.MAX_TAGS_DISTANCE')}
            labelClass="f-primary"
            hideBorder
            inputProps={{
              onChange: () => null,
            }}
            type="range"
          />
        </ExpandableSection>
        <ExpandableSection title={t('SECTION_TITLE.3D_MODE')} expanded={isWorkflow}>
          <Field
            id={userSettings.fields.modelQualityOption}
            disabled={false}
            name={userSettings.fields.modelQualityOption}
            onChange={this.changeModelQuality}
            component={CustomSelect}
            data={modelQualityOptions}
            placeholder={t('INSPECTION_SETTINGS.FORM_MODEL_QUALITY')}
            label={t('INSPECTION_SETTINGS.FORM_MODEL_QUALITY')}
            labelClass="f-primary"
          />
          <Field
            id={userSettings.fields.navigationModeOption}
            name={userSettings.fields.navigationModeOption}
            onChange={navigationMode => {
              changeField(userSettings.fields.navigationMode, navigationMode.value);
              if (viewer) {
                if (navigationMode.value === navigationModeOptions.pan.value) {
                  // Pan controls
                  viewer.setControls(viewer.earthControls);
                } else {
                  // Viewer controls
                  viewer.setControls(viewer.fpControls);
                }
              }
            }}
            disabled={!is3DViewModeActive}
            component={CustomSelect}
            data={Object.values(navigationModeOptions)}
            placeholder={t('INSPECTION_SETTINGS.FORM_NAVIGATION_MODES')}
            label={t('INSPECTION_SETTINGS.FORM_NAVIGATION_MODES')}
            labelClass="f-primary"
          />
          <Field
            id={userSettings.fields.lockZAxis}
            disabled={false}
            name={userSettings.fields.lockZAxis}
            inputProps={{
              onChange: val => {
                viewer.fpControls.lockElevation = val;
              },
            }}
            component={Toggle}
            placeholder={t('INSPECTION_SETTINGS.LOCK_Z_AXIS')}
            label={t('INSPECTION_SETTINGS.LOCK_Z_AXIS')}
            labelClass="f-primary"
            hideBorder
          />
          <Field
            id={userSettings.fields.fieldOfView}
            disabled={false}
            name={userSettings.fields.fieldOfView}
            inputProps={{
              step: 1,
              onChange: val => {
                if (viewer) viewer.setFOV(val);
              },
            }}
            minValue={20}
            maxValue={100}
            component={CustomInputRange}
            placeholder={t('INSPECTION_SETTINGS.FORM_FOV')}
            label={t('INSPECTION_SETTINGS.FORM_FOV')}
            labelClass="f-primary"
            hideBorder
            type="range"
          />

          {formValues[userSettings.fields.navigationMode] === navigationModeOptions.viewer.value ? (
            <Field
              id={userSettings.fields.speedOfMovement}
              disabled={false}
              name={userSettings.fields.speedOfMovement}
              inputProps={{
                step: 1,
                onChange: val => {
                  if (viewer) {
                    viewer.setMoveSpeed(val);
                  }
                },
              }}
              minValue={1}
              maxValue={20}
              component={CustomInputRange}
              placeholder={t('INSPECTION_SETTINGS.FORM_MOVEMENT_SPEED')}
              label={t('INSPECTION_SETTINGS.FORM_MOVEMENT_SPEED')}
              labelClass="f-primary"
              hideBorder
              type="range"
            />
          ) : (
            <Field
              id={userSettings.fields.sensitivity}
              disabled={false}
              name={userSettings.fields.sensitivity}
              inputProps={{
                step: 1,
                onChange: val => {
                  if (viewer) {
                    viewer.setZoomSpeed(val);
                  }
                },
              }}
              minValue={1}
              maxValue={20}
              component={CustomInputRange}
              placeholder={t('INSPECTION_SETTINGS.FORM_ZOOM_SPEED')}
              label={t('INSPECTION_SETTINGS.FORM_ZOOM_SPEED')}
              labelClass="f-primary"
              hideBorder
              type="range"
            />
          )}
        </ExpandableSection>
        <ExpandableSection title={t('SECTION_TITLE.CAMERA_SETTINGS')}>
          <ColorPicker
            color={color}
            onColorChange={val => {
              if (viewer) viewer.setBackground(this.getHexColor(val));
            }}
            name={formConstants.fields.color}
            changeField={(name, value) => changeField(name, this.getHexColor(value))}
            defaultColor={sceneSettings.defaultBgColor.hexString}
            className="inline"
            placeholder={t('INSPECTION_SETTINGS.FORM_COLOR')}
            label={t('INSPECTION_SETTINGS.FORM_COLOR')}
            labelClass="f-primary label-color"
            hideBorder
          />
          <Field
            id={formConstants.fields.showCameras}
            disabled={false}
            name={formConstants.fields.showCameras}
            inputProps={{
              onChange: val => {
                if (val) {
                  selectAllCameras();
                } else {
                  deselectAllCameras();
                }
                changeField(formConstants.fields.showCameras, val);
              },
            }}
            component={Toggle}
            placeholder={t('INSPECTION_SETTINGS.SHOW_CAMERAS')}
            label={t('INSPECTION_SETTINGS.SHOW_CAMERAS')}
            labelClass="f-primary"
            hideBorder
          />
          <Field
            id={formConstants.fields.showCameraThumbnails}
            name={formConstants.fields.showCameraThumbnails}
            inputProps={{
              onChange: val => {
                if (val) {
                  selectAllCameraThumbnails();
                } else {
                  deselectAllCameraThumbnails();
                }
                changeField(formConstants.fields.showCameraThumbnails, val);
              },
            }}
            component={Toggle}
            disabled={!formValues[formConstants.fields.showCameras]}
            placeholder={t('INSPECTION_SETTINGS.SHOW_CAMERA_THUMBNAILS')}
            label={t('INSPECTION_SETTINGS.SHOW_CAMERA_THUMBNAILS')}
            labelClass="f-primary"
            hideBorder
          />
          <SettingsPoints changeField={changeField} viewer={viewer} isHidden={false} />
        </ExpandableSection>
        <ExpandableSection title={t('SECTION_TITLE.ADVANCED_SETTINGS')}>
          <Field
            id={formConstants.fields.PointSizing}
            disabled={false}
            name={formConstants.fields.PointSizing}
            onChange={pointSizing => {
              if (viewer && viewer.scene.pointclouds[0]) {
                viewer.scene.pointclouds.forEach(pointcloud => {
                  pointcloud.material.pointSizeType = Potree.PointSizeType[pointSizing.value];
                });
              }
            }}
            component={CustomSelect}
            data={pointSizingOptions}
            placeholder={t('INSPECTION_SETTINGS.FORM_MATERIALS')}
            label={'INSPECTION_SETTINGS.FORM_MATERIALS'}
            labelClass="f-primary"
          />
          <Field
            id={formConstants.fields.PointType}
            disabled={false}
            name={formConstants.fields.PointType}
            onChange={this.changePointMaterial}
            component={CustomSelect}
            data={pointMaterialOptions}
            placeholder={t('INSPECTION_SETTINGS.FORM_SIZE_TYPE')}
            label={t('INSPECTION_SETTINGS.FORM_SIZE_TYPE')}
            labelClass="f-primary"
          />
          <div style={{ opacity: initialValues.EDL ? 0.4 : 1 }}>
            <Field
              id={formConstants.fields.Opacity}
              disabled={initialValues.EDL}
              name={formConstants.fields.Opacity}
              inputProps={{
                step: 0.001,
                formatLabel: value => (value % 1 === 0 ? value : parseFloat(value).toFixed(2)),
                onChange: val => {
                  if (viewer && viewer.scene.pointclouds[0]) {
                    viewer.scene.pointclouds.forEach(pointcloud => {
                      pointcloud.material.opacity = val;
                    });
                  }
                },
              }}
              minValue={0}
              maxValue={1}
              component={CustomInputRange}
              placeholder={t('INSPECTION_SETTINGS.FORM_OPACITY')}
              label={t('INSPECTION_SETTINGS.FORM_OPACITY')}
              labelClass="f-primary"
              hideBorder
              type="range"
            />
          </div>
          <Field
            id={formConstants.fields.minNodeSize}
            disabled={false}
            name={formConstants.fields.minNodeSize}
            inputProps={{
              step: 1,
              formatLabel: value => (value % 1 === 0 ? value : parseFloat(value).toFixed(2)),
              onChange: val => {
                if (viewer) viewer.setMinNodeSize(val);
              },
            }}
            minValue={0}
            maxValue={1000}
            component={CustomInputRange}
            placeholder={t('INSPECTION_SETTINGS.FORM_NODE_SIZE')}
            label={t('INSPECTION_SETTINGS.FORM_NODE_SIZE')}
            labelClass="f-primary"
            hideBorder
            type="range"
          />
          <Field
            id={formConstants.fields.maxPoints}
            disabled={false}
            name={formConstants.fields.maxPoints}
            minValue={1}
            maxValue={10}
            component={CustomInputRange}
            placeholder={t('INSPECTION_SETTINGS.FORM_MAX_POINTS')}
            label={t('INSPECTION_SETTINGS.FORM_MAX_POINTS')}
            labelClass="f-primary"
            hideBorder
            inputProps={{
              formatLabel: value => `${value} ${t('INSPECTION_SETTINGS.MILLION')}`,
              onChange: val => {
                if (viewer) viewer.setPointBudget(val * 1000 * 1000);
              },
            }}
            type="range"
          />
          <Field
            id={formConstants.fields.pointSize}
            disabled={false}
            name={formConstants.fields.pointSize}
            inputProps={{
              step: 0.01,
              formatLabel: value => (value % 1 === 0 ? value : parseFloat(value).toFixed(2)),
              onChange: val => {
                if (viewer && viewer.scene.pointclouds[0]) {
                  viewer.scene.pointclouds.forEach(pointcloud => {
                    pointcloud.material.size = val;
                  });
                }
              },
            }}
            minValue={0}
            maxValue={3}
            component={CustomInputRange}
            placeholder={t('INSPECTION_SETTINGS.FORM_POINT_SIZE')}
            label={t('INSPECTION_SETTINGS.FORM_POINT_SIZE')}
            labelClass="f-primary"
            hideBorder
            type="range"
          />
          <Field
            id={formConstants.fields.HighQuality}
            disabled={false}
            name={formConstants.fields.HighQuality}
            inputProps={{
              onChange: val => {
                if (viewer) viewer.useHQ = val;
              },
            }}
            component={Toggle}
            placeholder={t('INSPECTION_SETTINGS.HIGH_QUALITY')}
            label={t('INSPECTION_SETTINGS.HIGH_QUALITY')}
            labelClass="f-primary"
            hideBorder
          />
          <Field
            id={formConstants.fields.displayColor}
            disabled={false}
            name={formConstants.fields.displayColor}
            inputProps={{
              onChange: val => {
                if (viewer && viewer.scene.pointclouds[0]) {
                  let newValue = pointMaterialOptions[pointMaterialIndex.COLOR];
                  if (initialValues[formConstants.fields.displayElevation]) {
                    newValue = val ? pointMaterialOptions[pointMaterialIndex.RGB_ELEVATION] : pointMaterialOptions[pointMaterialIndex.ELEVATION];
                  } else {
                    newValue = val ? pointMaterialOptions[pointMaterialIndex.RGB] : pointMaterialOptions[pointMaterialIndex.COLOR];
                  }
                  changeField(formConstants.fields.PointType, newValue);
                  this.changePointMaterial(newValue);
                }
              },
            }}
            component={Toggle}
            placeholder={t('INSPECTION_SETTINGS.DISPLAY_COLOR')}
            label={t('INSPECTION_SETTINGS.DISPLAY_COLOR')}
            labelClass="f-primary"
            hideBorder
          />
          <Field
            id={formConstants.fields.displayElevation}
            disabled={false}
            name={formConstants.fields.displayElevation}
            inputProps={{
              onChange: val => {
                if (viewer && viewer.scene.pointclouds[0]) {
                  let newValue = pointMaterialOptions[pointMaterialIndex.COLOR];
                  if (initialValues[formConstants.fields.displayColor]) {
                    newValue = val ? pointMaterialOptions[pointMaterialIndex.RGB_ELEVATION] : pointMaterialOptions[pointMaterialIndex.RGB];
                  } else {
                    newValue = val ? pointMaterialOptions[pointMaterialIndex.ELEVATION] : pointMaterialOptions[pointMaterialIndex.COLOR];
                  }
                  changeField(formConstants.fields.PointType, newValue);
                  this.changePointMaterial(newValue);
                }
              },
            }}
            component={Toggle}
            placeholder={t('INSPECTION_SETTINGS.DISPLAY_ELEVATION')}
            label={t('INSPECTION_SETTINGS.DISPLAY_ELEVATION')}
            labelClass="f-primary"
            hideBorder
          />
          {/* Eye-Dome-Lighting */}
          <section className="section">
            <div className="section__label">
              <h6 className="f-primary">{t('INSPECTION_SETTINGS.FORM_EDL')}</h6>
            </div>

            <Field
              id={formConstants.fields.EDL}
              disabled={false}
              name={formConstants.fields.EDL}
              inputProps={{
                onChange: val => {
                  if (viewer) viewer.setEDLEnabled(val);
                },
              }}
              component={Toggle}
              placeholder={t('INSPECTION_SETTINGS.FORM_EDL_ENABLE')}
              label={t('INSPECTION_SETTINGS.FORM_EDL_ENABLE')}
              labelClass="f-primary"
              hideBorder
            />
            <div style={{ opacity: initialValues.EDL ? 1 : 0.4 }}>
              <Field
                id={formConstants.fields.EDLRadius}
                name={formConstants.fields.EDLRadius}
                inputProps={{
                  step: 0.01,
                  formatLabel: value => (value % 1 === 0 ? value : parseFloat(value).toFixed(2)),
                  onChange: val => {
                    if (viewer) viewer.setEDLRadius(val);
                  },
                }}
                minValue={1}
                maxValue={4}
                component={CustomInputRange}
                disabled={!initialValues.EDL}
                placeholder={t('INSPECTION_SETTINGS.FORM_EDL_RADIUS')}
                label={t('INSPECTION_SETTINGS.FORM_EDL_RADIUS')}
                labelClass="f-primary"
                hideBorder
                type="range"
              />
              <Field
                id={formConstants.fields.EDLStrength}
                name={formConstants.fields.EDLStrength}
                inputProps={{
                  step: 0.01,
                  formatLabel: value => (value % 1 === 0 ? value : parseFloat(value).toFixed(2)),
                  onChange: val => {
                    if (viewer) viewer.setEDLStrength(val);
                  },
                }}
                minValue={0}
                maxValue={5}
                component={CustomInputRange}
                disabled={!initialValues.EDL}
                placeholder={t('INSPECTION_SETTINGS.FORM_EDL_STRENGTH')}
                label={t('INSPECTION_SETTINGS.FORM_EDL_STRENGTH')}
                labelClass="f-primary"
                hideBorder
                type="range"
              />
            </div>
          </section>

          {/* end */}
        </ExpandableSection>
        <ExpandableSection title={t('SECTION_TITLE.CLUSTERING')}>
          <div className="warning-message">
            <Icon name="alert-triangle" handleHover={false} className="warning-message__icon" />
            <label className="f-secondary-dark">{t('INSPECTION_SETTINGS.CLUSTERING_SETTINGS_WARNING')}</label>
          </div>
          <Field
            id={formConstants.fields.numberOfRings}
            disabled={false}
            name={formConstants.fields.numberOfRings}
            inputProps={{
              step: 1,
              formatLabel: value => (value % 1 === 0 ? value : parseFloat(value).toFixed(2)),
              onChange: () => null,
            }}
            minValue={0}
            maxValue={100}
            component={CustomInputRange}
            placeholder={t('INSPECTION_SETTINGS.NUMBER_OF_RINGS')}
            label={t('INSPECTION_SETTINGS.NUMBER_OF_RINGS')}
            labelClass="f-primary"
            hideBorder
            type="range"
          />
          <Field
            id={formConstants.fields.numberOfSlices}
            disabled={false}
            name={formConstants.fields.numberOfSlices}
            inputProps={{
              step: 1,
              formatLabel: value => (value % 1 === 0 ? value : parseFloat(value).toFixed(2)),
              onChange: () => null,
            }}
            minValue={0}
            maxValue={100}
            component={CustomInputRange}
            placeholder={t('INSPECTION_SETTINGS.NUMBER_OF_SLICES')}
            label={t('INSPECTION_SETTINGS.NUMBER_OF_SLICES')}
            labelClass="f-primary"
            hideBorder
            type="range"
          />
          <Field
            id={formConstants.fields.initialDistance}
            disabled={false}
            name={formConstants.fields.initialDistance}
            inputProps={{
              step: 0.5,
              formatLabel: value => (value % 1 === 0 ? value : parseFloat(value).toFixed(2)),
              onChange: () => null,
            }}
            minValue={0}
            maxValue={100}
            component={CustomInputRange}
            placeholder={t('INSPECTION_SETTINGS.INITIAL_DISTANCE')}
            label={t('INSPECTION_SETTINGS.INITIAL_DISTANCE')}
            labelClass="f-primary"
            hideBorder
            type="range"
          />
          <Field
            id={formConstants.fields.multiplier}
            disabled={false}
            name={formConstants.fields.multiplier}
            inputProps={{
              step: 0.1,
              formatLabel: value => (value % 1 === 0 ? value : parseFloat(value).toFixed(2)),
              onChange: () => null,
            }}
            minValue={0}
            maxValue={50}
            component={CustomInputRange}
            placeholder={t('INSPECTION_SETTINGS.MULTIPLIER')}
            label={t('INSPECTION_SETTINGS.MULTIPLIER')}
            labelClass="f-primary"
            hideBorder
            type="range"
          />
        </ExpandableSection>
      </form>
    );
  }
}

SettingsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
SettingsForm = reduxForm({
  form: FORMS.inspectionSettings,
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    props.handleFormChange(values);
  },
})(SettingsForm);

const mapStateToProps = (state, props) => {
  const { Setting360View } = props.user;
  const bgColor = get(state.inspectionReducer.inspectionSettings, formConstants.fields.color);
  const maxPoints = get(state.inspectionReducer.inspectionSettings, formConstants.fields.maxPoints),
    pointSize = get(state.inspectionReducer.inspectionSettings, formConstants.fields.pointSize),
    PointType = get(state.inspectionReducer.inspectionSettings, formConstants.fields.PointType),
    modelQualityOption = find(modelQualityOptions, ({ value }) => value === get(Setting360View, userSettings.fields.modelQuality)) || defaultModelQuality,
    navigationModeOption = find(navigationModeOptions, ({ value }) => value === get(Setting360View, userSettings.fields.navigationMode)) || navigationModeOptions.pan;

  const displayColor = PointType?.value === pointMaterialOptions[pointMaterialIndex.RGB].value || PointType?.value === pointMaterialOptions[pointMaterialIndex.RGB_ELEVATION].value,
    displayElevation = PointType?.value === pointMaterialOptions[pointMaterialIndex.ELEVATION].value || PointType?.value === pointMaterialOptions[pointMaterialIndex.RGB_ELEVATION].value;

  return {
    initialValues: {
      ...state.inspectionReducer.inspectionSettings,
      ...Setting360View,
      [userSettings.fields.modelQualityOption]: modelQualityOption,
      [userSettings.fields.navigationModeOption]: navigationModeOption,
      [formConstants.fields.pointSize]: pointSize || 0.1,
      [formConstants.fields.maxPoints]: maxPoints || 1,
      [formConstants.fields.color]: bgColor || sceneSettings.defaultBgColor.hexString,
      [formConstants.fields.displayColor]: displayColor,
      [formConstants.fields.displayElevation]: displayElevation,
      [formConstants.fields.showCameras]: state.startWorkflowReducer.cameraPositionVisible,
      [formConstants.fields.showCameraThumbnails]: state.startWorkflowReducer.cameraThumbnailVisible,
    },
    formValues: getFormValues(FORMS.inspectionSettings)(state) || {},
    color: bgColor,
    viewer: state.potreeReducer.viewerInstance,
    images360Ref: state.inspectionReducer.images360Ref,
  };
};
const mapDispatchToProps = dispatch => ({
  changeField: (field, value) => dispatch(change(FORMS.inspectionSettings, field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsForm);
