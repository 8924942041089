import { debounce, find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import Modal from '../../../../../../common/modal/components/modal';
import Tab from '../../../../../../common/tabs/component/tab';
import Tabs from '../../../../../../common/tabs/component/tabs';
import MeasurementPointForm from './measurement-point-form';

import { FORMS } from '../../../../../../common/constants';
import { formConstants, linkedItemTabs, tabNames, toolbarItems } from '../constants/measurement-point-constants';

import ConfirmWithModal from '../../../../../../common/confirm-with-modal/confirm-with-modal';
import Loader from '../../../../../../common/global-loader/components/simple-loader';
import Helpers from '../../../../../../common/helpers';

import { change, getFormValues, initialize, isDirty } from 'redux-form';
import { setGenericNotification } from '../../../../../../common/notification/actions/action-creators';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../../common/permissions-constants';
import { setElementDetails } from '../../../../actions/action-creators';
import { fetchCommentUsersAndTeams, getDefectDetails, updateElementGeometry } from '../../../../actions/inspection-actions';
import { detailsPages, measurementTypes, modules } from '../../../../constants/constants';
import {
  addMeasurementPointComment,
  archiveMeasurementPoint,
  deleteMeasurementPoint,
  deleteMeasurementPointComment,
  fetchMeasurementPointComments,
  getMeasurementPointGraphDetails,
  getMeasurementPointTimeSeriesGraph,
  linkMeasurementPointComponent,
  unarchiveMeasurementPoint,
  unlinkMeasurementPointComponent,
  updateMeasurementPoint,
} from '../../../readings-and-gauges/actions//measurement-point-actions';
import {
  setMeasurementPointFormState,
  setMeasurementPointLocationObjectAdding,
  setMeasurementPointLocationObjectEditing,
  toggleMeasurementPoint,
  updateMeasurementLocationInArray,
  updateMeasurementPointInArray,
} from '../../../readings-and-gauges/actions/action-creators';
import { defaultUnitsFilters, deleteWithModalFields, fields, viewOptions } from '../../../readings-and-gauges/constants/constants';
import MeasurementPointAlarmsTab from './tabs/measurement-point-alarms-tab';

// style
import ActionModal from '../../../../../document-management/components/modals/action-modal/action-modal';
import { saveMeasurementReading, updateMeasurementReading } from '../../../readings-and-gauges/actions/measurement-readings-actions';
import { measurementReadingFormConstants } from '../../../readings-and-gauges/constants/measurement-readings-constants';

import { withRouter } from 'react-router';
import CommentsTab from '../../../../../../common/comments-tab/components/comments-tab';
import { commentFields } from '../../../../../../common/comments-tab/constants/constants';
import routesConstants from '../../../../../../common/routes-constants';
import WorkTabs from '../../../../../../common/work-tab/components/work-tab';
import useConfirmOnInspectionExit from '../../../../../../hooks/use-confirm-on-inspection-exit';
import { defaultComponentName } from '../../../../constants/component-constants';
import { fetchUnits, generateAndDownloadReport } from '../../../readings-and-gauges/actions/measurement-group-actions';
import { getDefaultTimeSeriesSettings, getTimeSeriesAggregationTypes, getTimeSeriesChunks } from '../../../readings-and-gauges/actions/measurement-location-actions';
import CreateMeasurementReadingModal from '../../../readings-and-gauges/components/measurement-reading/create-measurement-reading-modal';
import { DEFAULT_COLOR } from '../../../readings-and-gauges/constants/measurement-location-constants';
import { formConstants as mpFormConstants } from '../../../readings-and-gauges/constants/measurement-point-constants';
import {
  aggregationFields,
  chunkFields,
  defaultGraphSettingsValues,
  formConstants as graphFormConstants,
  settingsFormConstants,
  timePeriodFields,
  timePeriods,
} from '../../../readings-and-gauges/constants/time-series-graph-constants';
import ModuleHeader from '../../common/module-header';
import '../styles/measurement-point-details.scss';
import GenerateReportModal from './modals/generate-report-modal';

const MeasurementPointDetails = (
  {
    queryItem,
    updateMeasurementPoint,
    deleteMeasurementPoint,
    getDefectDetails,
    viewer,
    inspectionDetails,
    measurementPointDetailsLoading,
    setMeasurementPointFormState,
    measurementPointFormState,
    measurementPointsClustered,
    showGeometryWarning = true,
    projectID,
    inspectionID,
    handleActivePage,
    user,
    selectedMeasurementPoint,
    setSelectedMeasurementPoint,
    images360Ref,
    objectToolClick,
    measurementPointLocationObjectEditing,
    measurementPointLocationObjectAdding,
    setMeasurementPointLocationObjectEditing,
    setMeasurementPointLocationObjectAdding,
    updateGeometry,
    setGenericNotification,
    linkComponent,
    unlinkComponent,
    saveMeasurementReading,
    updateMeasurementReading,
    getMeasurementPointTimeSeriesGraph,
    router,
    toggleMeasurementPoint,
    location,
    generateAndDownloadReport,
    getMeasurementPointGraphDetails,
    getAggregationTypes,
    getChunks,
    isMeasurementReadingFormDirty,
    updateMeasurementPointInArray,
    updateMeasurementLocationInArray,
    changeField,
    fetchUnits,
    formValues,
    initializeMeasurementReadingForm,
    archiveMeasurementPoint,
    unarchiveMeasurementPoint,
    fetchMeasurementPointComments,
    addMeasurementPointComment,
    deleteMeasurementPointComment,
    fetchCommentUsersAndTeams,
  },
  context
) => {
  const { query } = location;
  const currentView = useMemo(() => query[routesConstants.queryAlias.view] || viewOptions.group, [query]);

  const { t } = context;
  const [confirmModalData, setConfirmModalData] = useState({
    isOpen: false,
  });
  const [confirmLeaveMRModal, setConfirmLeaveMRModal] = useState({ isOpen: false });
  const [activeToolbarItem, setActiveToolbarItem] = useState(toolbarItems[tabNames.details].name);
  const [elementAlreadyFocused, setElementAlreadyFocused] = useState(false);
  const [prevQueryItem, setPrevQueryItem] = useState(null);
  const [prevViewer, setPrevViewer] = useState(null);
  const [componentDetails, setComponentDetails] = useState(null);
  const [MRRequestInProgress, setMRRequestInProgress] = useState(false);
  const [graphDetails, setGraphDetails] = useState(null);
  const [graphDetailsIsLoading, setGraphDetailsIsLoading] = useState(false);
  const [graphLoading, setGraphLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [parentMeasurementLocation, setParentMeasurementLocation] = useState(null);
  const [unitsFilters, setUnitFilters] = useState(defaultUnitsFilters);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const timePeriodsList = useMemo(() => timePeriods(t), [t]);
  // comments state
  const [commentsList, setCommentsList] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [addCommentLoading, setAddCommentLoading] = useState(false);
  const [usersAndTeamsComments, setUsersAndTeamsComments] = useState({ commentUsersList: [], commentTeamsList: [] });
  const [commentUsersAndTeamsLoading, setCommentUsersAndTeamsLoading] = useState(false);

  const hasEdit = !Helpers.hasAccess({
    user,
    visibleFor: [PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].pointEdit.name],
    id: selectedMeasurementPoint?.[formConstants.fields.createdBy],
    ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].pointCreate.name,
  });

  useConfirmOnInspectionExit({
    title: t('GENERIC_UNSAVED_CHANGES_TITLE'),
    message: t('GENERIC_UNSAVED_CHANGES_MESSAGE'),
    router: router,
    route: router.location,
    isDirty: measurementPointFormState?.hasUnsavedChanges,
    clearUnsavedChangesDirty: () => clearUnsavedFormChanges(),
  });

  const submitForm = (values, customCallback = () => null) => {
    const { requestInProgress } = measurementPointFormState;
    if (requestInProgress) return;
    const { Geometry, CameraPosition, ...valuesToSave } = values;
    let callback = customCallback;

    setMeasurementPointFormState({ requestInProgress: true });

    updateMeasurementPoint(valuesToSave, () => {
      setMeasurementPointFormState({ hasUnsavedChanges: false, requestInProgress: false });
      callback();
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formChangeDebounce = (_values, _b, c) => {
    setMeasurementPointFormState({ hasUnsavedChanges: c?.dirty });
  };

  useEffect(() => {
    fetchUnitsData(defaultUnitsFilters);
    if (!queryItem || queryItem < 0) {
      setElementAlreadyFocused(true);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryItem]);

  // TODO: handle unmount
  useEffect(() => {
    if (queryItem && queryItem > 0) {
      if (queryItem < 0) {
        // This logic is copied from other modules but this IF statement doesn't make sense it will never go into this
        // Leaving it just for the reference so we can remove it from other modules
        setMeasurementPointLocationObjectEditing(false);
        // Reset measurementPoint state when queryItem is less than 0
        setSelectedMeasurementPoint({});
      } else if (queryItem !== prevQueryItem || viewer !== prevViewer) {
        // Fetch data when queryItem changes or viewer changes
        fetchData(tabNames.details);
      }
    }

    // Update previous queryItem and viewer
    setPrevQueryItem(queryItem);
    setPrevViewer(viewer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryItem, viewer]);

  const fetchComponentDetails = useCallback(
    componentId => {
      if (componentId) {
        getDefectDetails(
          { [formConstants.fields.id]: componentId || inspectionDetails?.DefaultComponent, SystemType: measurementTypes.component },
          component => {
            setComponentDetails(component);
          },
          false
        );
      } else setComponentDetails(null);
    },
    [getDefectDetails, inspectionDetails, setComponentDetails]
  );

  const fetchMesurementPointSeriesGraph = useCallback(
    (graphParams, successCallback, errorCallback) => {
      getMeasurementPointTimeSeriesGraph(
        queryItem,
        graphParams,
        graphData => {
          successCallback && typeof successCallback === 'function' && successCallback([{ ...graphData }]);
        },
        () => {
          errorCallback && typeof errorCallback === 'function' && errorCallback();
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryItem]
  );

  const fetchGraphData = settingsValues => {
    if (isEmpty(settingsValues)) {
      return;
    }

    const formatedGraphSettings = {
      ...settingsValues,
      [settingsFormConstants.graphingGroup.name]: settingsValues[settingsFormConstants.graphingGroup.name]?.[graphFormConstants.id],
      [settingsFormConstants.aggregation.name]: (settingsValues[settingsFormConstants.aggregation.name] || []).map(el => el[aggregationFields.key]),
      [settingsFormConstants.chunks.name]: settingsValues[settingsFormConstants.chunks.name]?.[chunkFields.key],
    };

    setGraphLoading(true);

    fetchMesurementPointSeriesGraph(
      formatedGraphSettings,
      graphData => {
        const mergedData = (graphData || []).map(data => ({
          ...data,
          Series: Helpers.mergeSeriesData(data.Series, data[mpFormConstants.fields.scaleFactor] || undefined, data[mpFormConstants.fields.inverted]),
        }));
        setGraphData(mergedData);
        setGraphLoading(false);
      },
      () => {
        setGraphData([]);
        setGraphLoading(false);
      }
    );
  };

  const fetchData = (tabName, updateAlarms) => {
    // Update activeToolbarItem when tab changes
    setActiveToolbarItem(tabName);

    if (tabName === tabNames.details) {
      setMeasurementPointLocationObjectEditing(false);
      getDefectDetails(
        { [formConstants.fields.id]: queryItem, SystemType: measurementTypes.rgMeasurementPoint },
        measurementPointDetails => {
          fetchParentMeasurementLocation(measurementPointDetails?.[formConstants.fields.measurementLocationID]);
          fetchGraphDetails();
          if (measurementPointDetails?.[formConstants.fields.componentId]) {
            fetchComponentDetails(measurementPointDetails?.[formConstants.fields.componentId]);
          } else setComponentDetails(null); // resets the Equipment section in the MP details

          if (measurementPointDetails?.Geometry?.coordinates?.[0] && measurementPointDetails?.CameraPosition?.coordinates && viewer && !elementAlreadyFocused) {
            // we can use here Geometry and Camera position from measurementPointDetails object since it is freshly fetched but usually we need to use it from clustered elements, they are always up to date
            viewer.zoomToPosition(
              { x: measurementPointDetails.CameraPosition.coordinates[0], y: measurementPointDetails.CameraPosition.coordinates[1], z: measurementPointDetails.CameraPosition.coordinates[2] },
              measurementPointDetails.Geometry.coordinates,
              500
            );
            setElementAlreadyFocused(true);
          }
          if (updateAlarms && selectedMeasurementPoint) {
            const updatedMeasurementPoint = Helpers.updateMeasurementPointAlarmStatus(measurementPointDetails);
            updateMeasurementPointInArray(updatedMeasurementPoint, 'update');
            getDefectDetails(
              { [formConstants.fields.id]: selectedMeasurementPoint?.[formConstants.fields.measurementLocationID], SystemType: measurementTypes.rgMeasurementLocation },
              measurementLocationDetails => {
                updateMeasurementLocationInArray(measurementLocationDetails, 'update');
              },
              false,
              false
            );
          }
        },
        true
      );
    }
  };

  const fetchParentMeasurementLocation = useCallback(measurementLocationId => {
    getDefectDetails(
      { [formConstants.fields.id]: measurementLocationId, SystemType: measurementTypes.rgMeasurementLocation },
      measurementLocationDetails => {
        setParentMeasurementLocation(measurementLocationDetails);
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUnitsData = useCallback(
    (filters, loadMore = false) => {
      fetchUnits(
        { ...filters, [formConstants.fields.projectID]: projectID },
        newData => {
          const updatedUnits = loadMore ? [...measurementUnits, ...newData] : newData;
          setMeasurementUnits(updatedUnits);
        },
        setUnitFilters
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [measurementUnits, fetchUnits, projectID]
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  const unitSearchUnitsTextChanged = useCallback(
    debounce(searchText => fetchUnitsData({ ...defaultUnitsFilters, [fields.lastSeen]: 0, [fields.searchText]: searchText }), 300),
    [fetchUnitsData]
  );

  const unitHandleSearchInputChange = e => {
    const searchText = e.target.value;
    unitSearchUnitsTextChanged(searchText);
    changeField(formConstants.fields.unit, searchText);
  };

  const handleUnitSelect = unit => {
    const unitValue = unit[formConstants.fields.unit];
    const currentUnitValue = formValues[formConstants.fields.unit];

    if (currentUnitValue === unitValue) {
      changeField(formConstants.fields.unit, '');
    } else {
      changeField(formConstants.fields.unit, unitValue);
    }
  };

  const unitHandleLoadMoreClick = () => fetchUnitsData(unitsFilters, true);

  const unitHandleOnBlur = e => {
    const value = e.target.value;
    const currentUnitValue = formValues[formConstants.fields.unit];

    changeField(formConstants.fields.unit, value || currentUnitValue || '');
  };

  const fetchGraphDetails = useCallback(async () => {
    setGraphDetailsIsLoading(true);

    const aggregationList = await getAggregationTypes();
    const chunks = await getChunks();
    getMeasurementPointGraphDetails(
      queryItem,
      data => {
        if (isEmpty(data) || isEmpty(data.DefaultGraphDetails)) {
          const defaultValues = defaultGraphSettingsValues(aggregationList, chunks, timePeriodsList, true);
          const formattedDefaultValues = {
            ...defaultValues,
            [settingsFormConstants.dateFrom.name]: Helpers.dateToUnix(defaultValues[settingsFormConstants.dateFrom.name]),
            [settingsFormConstants.dateTo.name]: Helpers.dateToUnix(defaultValues[settingsFormConstants.dateTo.name]),
          };
          fetchGraphData(formattedDefaultValues);
          setGraphDetails(defaultValues);
        } else {
          const { DefaultGraphDetails: graphDetails } = data;
          const selectedTimePeriodKey = graphDetails[graphFormConstants.timePeriod];
          const selectedAggregationKeys = Helpers.parseAggregationsFromString(graphDetails?.[graphFormConstants.aggregation]);
          let initialAggregation = [];
          (selectedAggregationKeys || []).forEach(key => {
            initialAggregation.push(Helpers.getObjectByKey(aggregationList, aggregationFields.key, key));
          });

          // Set initial settings form values
          const details = {
            [settingsFormConstants.graphingGroup.name]: graphDetails,
            [settingsFormConstants.aggregation.name]: initialAggregation,
            [settingsFormConstants.timePeriod.name]: Helpers.getObjectByKey(timePeriodsList, timePeriodFields.value, selectedTimePeriodKey) || timePeriodsList[timePeriodsList.length - 1],
            [settingsFormConstants.chunks.name]: Helpers.getObjectByKey(chunks, chunkFields.key, graphDetails?.[graphFormConstants.chunk]) || chunks[chunks.length - 1],
            [settingsFormConstants.dateFrom.name]: graphDetails?.[graphFormConstants.startDateFrom],
            [settingsFormConstants.dateTo.name]: graphDetails?.[graphFormConstants.startDateTo],
          };
          fetchGraphData(details);
          setGraphDetails(details);
        }

        setGraphDetailsIsLoading(false);
      },
      () => {
        setGraphDetailsIsLoading(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryItem, timePeriodsList]);

  const toggleConfirmationModal = (isOpen, title, content, confirmAction, _closeButtonText, confirmButtonText = null) => {
    if (isOpen) {
      setConfirmModalData({
        isOpen: true,
        type: '',
        firstParagraph: content,
        title: title,
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        closeButtonText: t('CANCEL'),
        customClassName: 'modal-medium confirmation-modal',
        customConfirmAction: () => {
          confirmAction();
        },
        confirmButtonText: confirmButtonText || title,
        customCloseAction: () => toggleConfirmationModal(false),
      });
    } else {
      setConfirmModalData({ isOpen: false });
    }
  };

  const handleArchiveMeasurementPoint = useCallback(() => {
    const confirmArchiveMeasurementPoint = () => {
      archiveMeasurementPoint(
        {
          [formConstants.fields.id]: selectedMeasurementPoint?.[formConstants.fields.id],
          [formConstants.fields.name]: selectedMeasurementPoint?.[formConstants.fields.name],
          [formConstants.fields.measurementLocationID]: selectedMeasurementPoint?.[formConstants.fields.measurementLocationID],
        },
        () => {
          setSelectedMeasurementPoint({ ...selectedMeasurementPoint, [formConstants.fields.archived]: true });
          toggleConfirmationModal(false);
          setGenericNotification({
            isDisplayed: true,
            type: 'success',
            text: t('MEASUREMENT_POINT.MODAL.ARCHIVE.SUCCESS_NOTIFICATION'),
            icon: 'checkmark-outline',
            wrapperClassName: 'full-width',
          });
        },
        error => {
          console.error(error);
          toggleConfirmationModal(false);
        }
      );
    };

    const confirmUnarchiveMeasurementPoint = () => {
      unarchiveMeasurementPoint(
        {
          [formConstants.fields.id]: selectedMeasurementPoint?.[formConstants.fields.id],
          [formConstants.fields.name]: selectedMeasurementPoint?.[formConstants.fields.name],
          [formConstants.fields.measurementLocationID]: selectedMeasurementPoint?.[formConstants.fields.measurementLocationID],
        },
        () => {
          setSelectedMeasurementPoint({ ...selectedMeasurementPoint, [formConstants.fields.archived]: false });
          toggleConfirmationModal(false);
          setGenericNotification({
            isDisplayed: true,
            type: 'success',
            text: t('MEASUREMENT_POINT.MODAL.UNARCHIVE.SUCCESS_NOTIFICATION'),
            icon: 'checkmark-outline',
            wrapperClassName: 'full-width',
          });
        },
        error => {
          console.error(error);
          toggleConfirmationModal(false);
        }
      );
    };

    toggleConfirmationModal(
      true,
      selectedMeasurementPoint?.[formConstants.fields.archived] ? t('MEASUREMENT_POINT.MODAL.UNARCHIVE.TITLE') : t('MEASUREMENT_POINT.MODAL.ARCHIVE.TITLE'),
      selectedMeasurementPoint?.[formConstants.fields.archived]
        ? t('MEASUREMENT_POINT.MODAL.UNARCHIVE.DESC', { mpName: selectedMeasurementPoint[formConstants.fields.name] })
        : t('MEASUREMENT_POINT.MODAL.ARCHIVE.DESC', { mpName: selectedMeasurementPoint[formConstants.fields.name] }),
      selectedMeasurementPoint?.[formConstants.fields.archived] ? confirmUnarchiveMeasurementPoint : confirmArchiveMeasurementPoint,
      t('MEASUREMENT_POINT.MODAL.ARCHIVE.CANCEL'),
      selectedMeasurementPoint?.[formConstants.fields.archived] ? t('MEASUREMENT_LOCATION.MODAL.UNARCHIVE.CONFIRM') : t('MEASUREMENT_POINT.MODAL.ARCHIVE.CONFIRM')
    );
  }, [toggleConfirmationModal, t, selectedMeasurementPoint, archiveMeasurementPoint, unarchiveMeasurementPoint, setSelectedMeasurementPoint]);

  const handleComponentChange = useCallback(
    (componentId, successCallback) => {
      if (componentId) {
        linkComponent(componentId, queryItem, () => {
          fetchComponentDetails(componentId);
          successCallback && typeof successCallback === 'function' && successCallback();
        });
      } else {
        unlinkComponent(
          componentDetails[formConstants.fields.id],
          queryItem,
          () => {
            setComponentDetails(null);
            successCallback && typeof successCallback === 'function' && successCallback();
          },
          () => {
            setComponentDetails(null);
          }
        );
      }
    },
    [queryItem, linkComponent, unlinkComponent, fetchComponentDetails, componentDetails]
  );

  const copyEquipmentFromParentMeasurementLocation = useCallback(
    setSelectedOption => {
      if (isEmpty(parentMeasurementLocation)) {
        return;
      }

      getDefectDetails(
        { [formConstants.fields.id]: parentMeasurementLocation[formConstants.fields.componentId] || inspectionDetails?.DefaultComponent, SystemType: measurementTypes.component },
        component => {
          setSelectedOption(component);
        },
        false
      );
    },
    [parentMeasurementLocation, getDefectDetails, inspectionDetails]
  );

  if (measurementPointDetailsLoading || !inspectionDetails) {
    return <Loader isLoading={true} />;
  }

  const generateAndDownloadReportHandler = data => {
    generateAndDownloadReport(
      data,
      () => {
        toggleConfirmationModal(false);
        // success generic notification
        setGenericNotification({ isDisplayed: true, type: 'success', text: t('GENERATE_AND_DOWNLOAD_REPORT.SUCCESS_NOTIFICATION'), icon: 'checkmark-outline', wrapperClassName: 'full-width' });
      },
      () => {
        toggleConfirmationModal(false);
        // error generic notification
        setGenericNotification({ isDisplayed: true, type: 'error', text: t('GENERATE_AND_DOWNLOAD_REPORT.ERROR_NOTIFICATION'), icon: 'info', wrapperClassName: 'full-width' });
      }
    );
  };

  const handleGenerateReportClick = () => {
    setConfirmModalData({
      isOpen: true,
      type: '',
      CustomContent: dynamicProps => <GenerateReportModal handleSubmit={data => generateAndDownloadReportHandler(data)} showDatesOnly={true} {...dynamicProps} />,
      closeAction: () => toggleConfirmationModal(false),
      title: t('INSPECTION_DETAILS_DOWNLOAD_REPORT'),
      projectID,
      inspectionId: inspectionID,
      selectedMeasurementPoint,
      customClassName: 'generate-report-modal',
    });
  };

  const handleSubmitCreateMeasurementReading = (data, isEdit, stepCallback) => {
    const dateField = [measurementReadingFormConstants.fields.date];
    const values = data?.[measurementReadingFormConstants.fields.values].map(str => parseFloat(str));
    const date = Helpers.dateToUnix(data[dateField]);
    const measurementReadingId = data[fields.id];

    setMRRequestInProgress(true);
    if (isEdit) {
      updateMeasurementReading(
        values,
        date,
        selectedMeasurementPoint[formConstants.fields.id],
        selectedMeasurementPoint[formConstants.fields.measurementLocationID],
        measurementReadingId,
        measurementReadingValuesData => {
          setMRRequestInProgress(false);
          stepCallback && typeof stepCallback === 'function' && stepCallback(measurementReadingValuesData);
          initializeMeasurementReadingForm(data); // initialize the form with the data, makes the form pristine again
        },
        () => {
          setMRRequestInProgress(false);
        }
      );
    } else {
      saveMeasurementReading(
        values,
        date,
        selectedMeasurementPoint[formConstants.fields.id],
        selectedMeasurementPoint[formConstants.fields.measurementLocationID],
        null,
        measurementReadingValuesData => {
          setMRRequestInProgress(false);
          stepCallback && typeof stepCallback === 'function' && stepCallback(measurementReadingValuesData);
          initializeMeasurementReadingForm(data); // initialize the form with the data, makes the form pristine again
        },
        () => {
          setMRRequestInProgress(false);
        }
      );
    }
  };

  const handleDeleteMeasurementPoint = () => {
    const handleDeleteWithConfirmedPassword = confirmationCheck => {
      deleteMeasurementPoint(selectedMeasurementPoint, confirmationCheck, context, () => {
        handleActivePage(
          modules.readingsAndGauges,
          currentView === viewOptions.location_and_points ? selectedMeasurementPoint[measurementReadingFormConstants.fields.measurementLocationID] : null,
          currentView === viewOptions.location_and_points ? detailsPages.measurementLocation : detailsPages.measurementPoint,
          currentView === viewOptions.location_and_points ? viewOptions.location_and_points : viewOptions.points
        );
        toggleConfirmationModal(false);
      });
    };

    const openPasswordConfirmationModal = () => {
      setConfirmModalData({
        isOpen: true,
        type: '',
        CustomContent: dynamicProps => <ConfirmWithModal {...dynamicProps} />,
        closeAction: () => toggleConfirmationModal(false),
        title: t('MEASUREMENT_POINT.MODAL.CONFIRM_DELETE.TITLE'),
        customCloseAction: () => toggleConfirmationModal(false),
        closeButtonText: t('CANCEL'),
        onSubmit: values => handleDeleteWithConfirmedPassword(values[deleteWithModalFields.field.name]),
        text: 'MEASUREMENT_POINT.MODAL.CONFIRM_DELETE.PARAGRAPH',
        placeholder: deleteWithModalFields.field.placeholder,
        label: deleteWithModalFields.field.label,
        id: deleteWithModalFields.field.id,
        name: deleteWithModalFields.field.name,
        customClassName: 'modal-medium confirmation-modal',
      });
    };

    toggleConfirmationModal(
      true,
      t('MEASUREMENT_POINT.MODAL.DELETE.TITLE'),
      t('MEASUREMENT_POINT.MODAL.DELETE.DESC', { mpName: selectedMeasurementPoint[formConstants.fields.name] }),
      openPasswordConfirmationModal
    );
  };

  const menuOptions = [
    {
      title: 'INSPECTION_DETAILS_DOWNLOAD_REPORT',
      action: handleGenerateReportClick,
    },
    {
      title: selectedMeasurementPoint?.[formConstants.fields.archived] ? 'PROJECT.SIDEBAR.SETTINGS_MENU.ITEM_4' : 'PROJECT.SIDEBAR.SETTINGS_MENU.ITEM_3',
      action: () => handleArchiveMeasurementPoint(),
    },
    {
      title: 'MEASUREMENT_POINT_DETAILS.ACTIONS.DELETE_TEXT',
      isHighlighted: true,
      separator: true,
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].pointDelete.name,
      },
      action: handleDeleteMeasurementPoint,
    },
  ];

  const closeConfirmLeaveModalFetchDetails = updateAlarms => {
    setConfirmLeaveMRModal({ isOpen: false });
    fetchData(tabNames.details, updateAlarms);
  };

  const closeAllLeaveModalsFetchDetails = updateAlarms => {
    setConfirmLeaveMRModal({ isOpen: false });
    setConfirmModalData({ isOpen: false });
    fetchData(tabNames.details, updateAlarms);
  };

  const handleCloseMeasurementReadingAdd = isMeasurementReadingFormDirty => {
    if (isMeasurementReadingFormDirty) {
      const title = 'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM_ADD.LEAVE';
      const firstParagraph = 'READINGS_AND_GAUGES.MEASUREMENT_READINGS_FORM_ADD.UNSAVED_CHANGES';
      setConfirmLeaveMRModal({
        isOpen: true,
        CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
        closeAction: () => closeConfirmLeaveModalFetchDetails(true),
        type: '',
        title: t(title),
        confirmButtonText: 'LEAVE',
        closeButtonText: 'CANCEL',
        firstParagraph: firstParagraph,
        secondParagraph: 'READINGS_AND_GAUGES.EDIT_GROUP_UNSAVED_CHANGES_PARAGRAPH_2',
        customCloseAction: () => setConfirmLeaveMRModal({ isOpen: false }),
        customConfirmAction: () => closeAllLeaveModalsFetchDetails(true),
        customClassName: 'confirm-leave-modal modal-large',
        customClassWrapperName: 'confirm-leave-modal__picker',
      });
    } else {
      setConfirmModalData({ isOpen: false });
    }
  };

  const handleAddMeasurementReading = () => {
    setConfirmModalData({
      isOpen: true,
      customClassName: 'alarms-edit-modal modal-large',
      customClassWrapperName: 'alarms-edit-modal__picker',
      type: '',
      CustomContent: dynamicProps => <CreateMeasurementReadingModal {...dynamicProps} />,
      closeAction: () => toggleConfirmationModal(false),
      title: t('READINGS_AND_GAUGES.MEASUREMENT_READINGS.ADD_MEASUREMENT_READING'),
      customCloseAction: () => toggleConfirmationModal(false),
      onSubmit: (data, isEdit, stepCallback) => handleSubmitCreateMeasurementReading(data, isEdit, stepCallback),
      measurementPointId: selectedMeasurementPoint?.[formConstants.fields.id],
      router,
    });
  };

  // comments handlers

  const fetchMeasurementPointCommentsHandler = () => {
    const onSuccessFetch = newState => {
      setCommentsList(newState.commentsList);
      setCommentsLoading(newState.commentsLoading);

      if (newState.commentsList) {
        Helpers.scrollIntoView('comments-list-wrapper', `comment-${newState.commentsList.length - 1}`, 0);
      }
    };

    fetchMeasurementPointComments({ [commentFields.measurementPointId]: queryItem }, onSuccessFetch);
  };

  const addMeasurementPointCommentHandler = (commentValue, commentTags, resetCommentInput, scrollIntoView) => {
    const commentParams = { InspectionID: inspectionID, ProjectID: projectID, [commentFields.tags]: commentTags, [commentFields.moduleItemID]: queryItem, Comment: commentValue };
    addMeasurementPointComment(
      commentParams,
      () => fetchMeasurementPointCommentsHandler(),
      loading => setAddCommentLoading(loading)
    );
    resetCommentInput();
    scrollIntoView();
  };

  const deleteMeasurementPointCommentHandler = comment => {
    const commentParams = { [commentFields.moduleCommentID]: queryItem, CommentID: comment[commentFields.id] };
    deleteMeasurementPointComment(
      commentParams,
      () => fetchMeasurementPointCommentsHandler(false),
      loading => setAddCommentLoading(loading)
    );
  };

  const searchUserAndTeamsHandler = searchTerm => {
    fetchCommentUsersAndTeams(
      searchTerm,
      (usersList, teamsList) => setUsersAndTeamsComments({ commentUsersList: usersList, commentTeamsList: teamsList }),
      loading => setCommentUsersAndTeamsLoading(loading)
    );
  };

  const handleSubmittedForm = values => {
    setMeasurementPointFormState({ hasUnsavedChanges: false });
    setSelectedMeasurementPoint({ ...selectedMeasurementPoint, ...values });
    submitForm(values, false);
  };

  const clearUnsavedFormChanges = () => {
    setMeasurementPointFormState({ hasUnsavedChanges: false });
  };

  // TODO: extending the initialValues is same as in ML-details, might reconsider moving this to a helper (this enables the visibility of the 3D location section)
  const selectedMPClustered = find(measurementPointsClustered, item => item[formConstants.fields.id] === queryItem);
  const initialValues = selectedMeasurementPoint && {
    ...selectedMeasurementPoint,
    [formConstants.fields.color]: selectedMeasurementPoint[formConstants.fields.color] || DEFAULT_COLOR,
  };

  return (
    <div className="measurement-point-details">
      <Tabs
        defaultTabKey={activeToolbarItem}
        navigationClassName="component-details__tabs"
        onChange={fetchData}
        tabsHeader={activeToolbarItem !== tabNames.comments ? <ModuleHeader id={queryItem} menuOptions={menuOptions} /> : null}
      >
        <Tab title={toolbarItems[tabNames.details].label} tabKey={tabNames.details}>
          <MeasurementPointForm
            inspectionDetails={inspectionDetails}
            projectID={projectID}
            inspectionID={inspectionID}
            toggleConfirmationModal={toggleConfirmationModal}
            handleActivePage={handleActivePage}
            selectedMeasurementPoint={selectedMeasurementPoint}
            onChange={formChangeDebounce}
            onSubmit={handleSubmittedForm}
            showGeometryWarning={showGeometryWarning}
            formHasUnsavedChanges={measurementPointFormState?.hasUnsavedChanges || measurementPointFormState?.requestInProgress}
            locationObject={{
              ...Helpers.getModuleLocationObject({
                ...initialValues,
                Geometry: selectedMPClustered?.Geometry || initialValues?.Geometry,
                CameraPosition: selectedMPClustered?.CameraPosition || initialValues?.CameraPosition,
              }),
              visible: selectedMPClustered?.visible || false,
            }}
            initialValues={initialValues}
            formValues={formValues}
            viewer={viewer}
            user={user}
            measurementPointLocationObjectAdding={measurementPointLocationObjectAdding}
            measurementPointLocationObjectEditing={measurementPointLocationObjectEditing}
            setMeasurementPointLocationObjectAdding={setMeasurementPointLocationObjectAdding}
            setMeasurementPointLocationObjectEditing={setMeasurementPointLocationObjectEditing}
            images360Ref={images360Ref}
            objectToolClick={objectToolClick}
            updateGeometry={updateGeometry}
            componentDetails={componentDetails}
            defaultComponent={{ [formConstants.fields.name]: t('DEFAULT_COMPONENT.NAME'), [formConstants.fields.id]: inspectionDetails?.DefaultComponent }}
            handleComponentChange={handleComponentChange}
            setGenericNotification={setGenericNotification}
            setMeasurementPointFormState={setMeasurementPointFormState}
            handleAddMeasurementReading={handleAddMeasurementReading}
            toggleMeasurementPoint={toggleMeasurementPoint}
            graphDetails={graphDetails}
            graphDetailsIsLoading={graphDetailsIsLoading}
            graphLoading={graphLoading}
            graphData={graphData}
            timePeriodsList={timePeriodsList}
            refetchGraphDetails={fetchGraphDetails}
            copyEquipmentFromParentMeasurementLocation={copyEquipmentFromParentMeasurementLocation}
            unitHandleLoadMoreClick={unitHandleLoadMoreClick}
            unitHandleOnBlur={unitHandleOnBlur}
            unitHandleSearchInputChange={unitHandleSearchInputChange}
            measurementUnits={measurementUnits}
            handleUnitSelect={handleUnitSelect}
            unitsFilters={unitsFilters}
            fetchDetails={() => fetchData(tabNames.details)}
          />
        </Tab>
        <Tab title={toolbarItems[tabNames.alarms].label} tabKey={tabNames.alarms}>
          <MeasurementPointAlarmsTab
            queryItem={queryItem}
            selectedComponent={componentDetails}
            selectedMeasurementPoint={selectedMeasurementPoint}
            hasEdit={hasEdit}
            defaultComponent={{ [formConstants.fields.name]: defaultComponentName, [formConstants.fields.id]: inspectionDetails?.DefaultComponent }}
          />
        </Tab>
        <Tab title={toolbarItems[tabNames.linked_items].label} tabKey={tabNames.linked_items}>
          <WorkTabs tabs={linkedItemTabs} selectedComponent={selectedMeasurementPoint} inspectionId={inspectionID} queryItem={queryItem} />
        </Tab>
        <Tab title={toolbarItems[tabNames.comments].label} tabKey={tabNames.comments}>
          <CommentsTab
            commentsList={commentsList}
            commentsLoading={commentsLoading}
            addCommentLoading={addCommentLoading}
            fetchCommentsList={fetchMeasurementPointCommentsHandler}
            onAddCommentClick={addMeasurementPointCommentHandler}
            onDeleteCommentClick={deleteMeasurementPointCommentHandler}
            fetchCommentUsersAndTeams={searchUserAndTeamsHandler}
            commentUsersList={usersAndTeamsComments.commentUsersList}
            commentTeamsList={usersAndTeamsComments.commentTeamsList}
            commentUsersAndTeamsLoading={commentUsersAndTeamsLoading}
            user={user}
            addCommentPermission={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].addComment.name}
          />
        </Tab>
      </Tabs>
      <Modal
        {...confirmModalData}
        requestInProgress={MRRequestInProgress}
        closeAction={() => handleCloseMeasurementReadingAdd(isMeasurementReadingFormDirty)}
        customCloseAction={() => handleCloseMeasurementReadingAdd(isMeasurementReadingFormDirty)}
        closeConfirmModalAction={() => closeAllLeaveModalsFetchDetails(isMeasurementReadingFormDirty)}
        dirty={isMeasurementReadingFormDirty}
      />
      <Modal {...confirmLeaveMRModal} />
    </div>
  );
};

const mapStateToProps = state => ({
  selectedMeasurementPoint: state.inspectionReducer.selectedDefect,
  measurementPointDetailsLoading: state.measurementPointReducer.measurementPointDetailsLoading,
  measurementPointFormState: state.measurementPointReducer.measurementPointFormState,
  measurementPointsClustered: state.measurementPointReducer.measurementPointsClustered,
  measurementPointLocationObjectEditing: state.measurementPointReducer.measurementPointLocationObjectEditing,
  measurementPointLocationObjectAdding: state.measurementPointReducer.measurementPointLocationObjectAdding,
  inspectionDetails: state.inspectionReducer.inspectionDetails,
  user: state.userReducer,
  images360Ref: state.inspectionReducer.images360Ref,
  isMeasurementReadingFormDirty: isDirty(FORMS.measurementPointMeasurementReadingForm)(state),
  formValues: getFormValues(FORMS.measurementPointForm)(state),
});

const mapDispatchToProps = dispatch => ({
  updateMeasurementPoint: (measurementPoint, callback) => dispatch(updateMeasurementPoint(measurementPoint, callback)),
  getDefectDetails: (defect, callback, saveItem) => dispatch(getDefectDetails(defect, callback, {}, true, saveItem)),
  deleteMeasurementPoint: (data, confirmationCheck, context, successCallback, errorCallback) => dispatch(deleteMeasurementPoint(data, confirmationCheck, context, successCallback, errorCallback)),
  setMeasurementPointFormState: data => dispatch(setMeasurementPointFormState(data)),
  setMeasurementPointLocationObjectEditing: val => dispatch(setMeasurementPointLocationObjectEditing(val)),
  setMeasurementPointLocationObjectAdding: val => dispatch(setMeasurementPointLocationObjectAdding(val)),
  setSelectedMeasurementPoint: measurementPoint => dispatch(setElementDetails({ defect: measurementPoint })),
  updateGeometry: data => dispatch(updateElementGeometry(data)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
  linkComponent: (componentId, measurementPointId, successCallback, errorCallback) => dispatch(linkMeasurementPointComponent(componentId, measurementPointId, successCallback, errorCallback)),
  unlinkComponent: (componentId, measurementPointId, successCallback, errorCallback) => dispatch(unlinkMeasurementPointComponent(componentId, measurementPointId, successCallback, errorCallback)),
  changeField: (fieldName, value) => dispatch(change(FORMS.measurementPointForm, fieldName, value)),
  saveMeasurementReading: (values, date, MeasurementPointID, MeasurementLocationID, MeasurementReadingID, successCallback, errorCallback) =>
    dispatch(saveMeasurementReading(values, date, MeasurementPointID, MeasurementLocationID, MeasurementReadingID, successCallback, errorCallback)),
  updateMeasurementReading: (values, date, MeasurementPointID, MeasurementLocationID, MeasurementReadingID, successCallback, errorCallback) =>
    dispatch(updateMeasurementReading(values, date, MeasurementPointID, MeasurementLocationID, MeasurementReadingID, successCallback, errorCallback)),
  getDefaultTimeSeriesSettings: (measurementLocationId, successCallback, errorCallback) => dispatch(getDefaultTimeSeriesSettings(measurementLocationId, successCallback, errorCallback)),
  getMeasurementPointTimeSeriesGraph: (measurementPointId, graphSettings, successCallback, errorCallback) =>
    dispatch(getMeasurementPointTimeSeriesGraph(measurementPointId, graphSettings, successCallback, errorCallback)),
  toggleMeasurementPoint: id => dispatch(toggleMeasurementPoint(id)),
  generateAndDownloadReport: (data, callback, errorCallback, loadingCallback) => dispatch(generateAndDownloadReport(data, callback, errorCallback, loadingCallback)),
  getMeasurementPointGraphDetails: (measurementPointId, successCallback, errorCallback) => dispatch(getMeasurementPointGraphDetails(measurementPointId, successCallback, errorCallback)),
  getAggregationTypes: () => dispatch(getTimeSeriesAggregationTypes()),
  getChunks: () => dispatch(getTimeSeriesChunks()),
  updateMeasurementPointInArray: (data, method) => dispatch(updateMeasurementPointInArray(data, method)),
  updateMeasurementLocationInArray: (data, method) => dispatch(updateMeasurementLocationInArray(data, method)),
  fetchUnits: (filters, callback, filtersCallback, errorCallback, loadingCallback) => dispatch(fetchUnits(filters, callback, filtersCallback, errorCallback, loadingCallback)),
  initializeMeasurementReadingForm: data => dispatch(initialize(FORMS.measurementPointMeasurementReadingForm, data)),
  archiveMeasurementPoint: (measurementPoint, callback, errorCallback) => dispatch(archiveMeasurementPoint(measurementPoint, callback, errorCallback)),
  unarchiveMeasurementPoint: (measurementPoint, callback, errorCallback) => dispatch(unarchiveMeasurementPoint(measurementPoint, callback, errorCallback)),
  // comments actions
  fetchMeasurementPointComments: (filters, successCallback, errorCallback) => dispatch(fetchMeasurementPointComments(filters, successCallback, errorCallback)),
  addMeasurementPointComment: (commentParams, successCallback, errorCallback) => dispatch(addMeasurementPointComment(commentParams, successCallback, errorCallback)),
  deleteMeasurementPointComment: (commentParams, successCallback, errorCallback) => dispatch(deleteMeasurementPointComment(commentParams, successCallback, errorCallback)),
  fetchCommentUsersAndTeams: (searchTerm, dataCallback, loadingCallback) => dispatch(fetchCommentUsersAndTeams(searchTerm, dataCallback, loadingCallback)),
});

MeasurementPointDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeasurementPointDetails));
