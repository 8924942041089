import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
//components
import SingleRnGItemTable from '../single-item-table/single-item-table';
//constants
import { uniqBy } from 'lodash';
import AccessRenderer from '../../../../../../common/access-renderer/components/access-renderer';
import Helpers from '../../../../../../common/helpers';
import { PERMISSION_TYPES, PERMISSIONS } from '../../../../../../common/permissions-constants';
import { detailsPages, modules } from '../../../../constants/constants';
import { fields, MAX_SEARCH_LOCATION_CHARACTERS, viewOptions } from '../../constants/constants';
import { defaultFilter, filterParams } from '../../constants/measurement-location-constants';

const MeasurementLocationsTableView = (props, { t }) => {
  const {
    isFullScreen,
    projectID,
    view,
    handlePathClick,
    handleBackClick,
    navigateToPath,
    path,
    selectedMeasurementLocation = null,
    selectedClusterElement,
    selectedMeasurementGroupId,
    setSelectedMeasurementLocation,
    user,
    searchPlaceholder,
    isNewMeasurementPointModal,
    toggleModalAction,
    fetchDataAction,
    additionalFetchMLProps,
    updatedMeasurementPoint,
    shouldUpdateMeasurementLocations,
    visibleFor,
  } = props;
  const [measurementLocations, setMeasurementLocations] = useState([]);
  const [measurementLocationsFilter, setMeasurementLocationsFilter] = useState(defaultFilter);
  const [measurementLocationsLoading, setMeasurementLocationsLoading] = useState(false);

  const fetchDataCallback = useCallback(
    (filters, loadMore = false) => {
      const handleFetchData = (data, newFilters) => {
        const newData = loadMore ? [...measurementLocations, ...data] : data;

        setMeasurementLocations(uniqBy(newData, fields.id));
        setMeasurementLocationsFilter({ ...measurementLocationsFilter, ...newFilters });
        setMeasurementLocationsLoading(false);
        if (loadMore) {
          Helpers.scrollIntoView('measurement-location-table', null, -250);
        }
      };

      fetchDataAction({ ...filters, ...additionalFetchMLProps, [filterParams.projectID]: projectID, MeasurementGroupID: selectedMeasurementGroupId }, handleFetchData, setMeasurementLocationsLoading);
    },
    [fetchDataAction, projectID, additionalFetchMLProps, selectedMeasurementGroupId, measurementLocations, measurementLocationsFilter]
  );

  const handleRowClick = row => {
    if (selectedMeasurementLocation?.[fields.id] && selectedMeasurementLocation?.[fields.id] === row[fields.id]) {
      // if query item did not change or measurement point is being clicked
      return;
    }

    setSelectedMeasurementLocation(row);
    const newPathItem = { [fields.name]: row[fields.name], queryParams: { type: modules.readingsAndGauges, selected_item: row[fields.id], details: detailsPages.measurementLocation, view } };
    navigateToPath(newPathItem);
  };

  const toggleModalActionHandler = (selectedMeasurementLocation, shouldLink) => {
    toggleModalAction(selectedMeasurementLocation, shouldLink, () => {
      // this callback updates the selected ML to show the X or + icon
      // Find the selected measurement location by ID
      // Find the index of the selected measurement location by ID
      const foundIndex = measurementLocations.findIndex(l => l[fields.id] === selectedMeasurementLocation[fields.id]);

      if (foundIndex !== -1) {
        // Create a copy of the measurementLocations array
        const updatedMeasurementLocations = [...measurementLocations];

        // Update the NumberOfLinkedMeasurementPoints property
        updatedMeasurementLocations[foundIndex] = {
          ...updatedMeasurementLocations[foundIndex],
          [fields.numberOfLinkedMeasurementPoints]: shouldLink ? updatedMeasurementLocations[foundIndex][fields.totalNumberOfMeasurementPoints] : 0,
        };

        // Update the state with the new array
        setMeasurementLocations(updatedMeasurementLocations);
      }
    });
  };

  useEffect(() => {
    // this updates the ML (so that the icon updates) which had one MP and that MP was linked
    if (updatedMeasurementPoint?.[fields.measurementLocationID] && shouldUpdateMeasurementLocations) {
      const foundIndex = measurementLocations.findIndex(l => l[fields.id] === updatedMeasurementPoint?.[fields.measurementLocationID]);

      if (foundIndex !== -1) {
        const updatedMeasurementLocations = [...measurementLocations];
        updatedMeasurementLocations[foundIndex] = {
          ...updatedMeasurementLocations[foundIndex],
          [fields.numberOfLinkedMeasurementPoints]: updatedMeasurementPoint.link
            ? updatedMeasurementLocations[foundIndex][fields.numberOfLinkedMeasurementPoints] + 1
            : updatedMeasurementLocations[foundIndex][fields.numberOfLinkedMeasurementPoints] - 1,
        };

        setMeasurementLocations(updatedMeasurementLocations);
      }
    }
  }, [updatedMeasurementPoint, measurementLocations, shouldUpdateMeasurementLocations]);

  const emptyStateProps = {
    emptyStateText: t('READINGS_AND_GAUGES.MEASUREMENT_LOCATIONS.EMPTY_STATE.TITLE'),
    showButton: false,
    transparent: true,
    buttonText: null,
    buttonAction: null,
    buttonDisabled: !Helpers.hasAccess({ user, visibleFor: [PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].locationCreate.name] }),
  };

  return (
    <AccessRenderer visibleFor={visibleFor} id={selectedClusterElement && selectedClusterElement[fields.createdBy]}>
      {({ hasAccess: canEdit }) => {
        return canEdit ? (
          <SingleRnGItemTable
            searchPlaceholder={searchPlaceholder}
            customTableClass="measurement-location-table"
            isFullScreen={isFullScreen}
            view={viewOptions.location}
            handleRowClick={row => handleRowClick(row)}
            path={path}
            navigateToPath={navigateToPath}
            handleBackClick={handleBackClick}
            handlePathClick={handlePathClick}
            projectID={projectID}
            fetchData={fetchDataCallback}
            toggleVisibilityHandler={() => null}
            data={measurementLocations}
            filters={measurementLocationsFilter}
            defaultFilters={defaultFilter}
            isDataLoading={measurementLocationsLoading}
            selectedItem={selectedMeasurementLocation?.[fields.id] || null}
            toggleAll={() => null}
            emptyStateProps={emptyStateProps}
            user={user}
            isNewMeasurementPointModal={isNewMeasurementPointModal}
            toggleModalAction={toggleModalActionHandler}
            maxSearchCharacters={MAX_SEARCH_LOCATION_CHARACTERS}
          />
        ) : null;
      }}
    </AccessRenderer>
  );
};

MeasurementLocationsTableView.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MeasurementLocationsTableView;
