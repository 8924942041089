export const formFields = {
  comment: {
    name: 'Comment',
    label: 'COMMENTS.ADD_YOUR_COMMENT',
    maxChars: 1000,
  },
};

export const commentFields = {
  id: 'ID',
  name: 'Name',
  email: 'Email',
  membersCount: 'MembersCount',
  createdBy: 'CreatedBy',
  createdByUser: 'CreatedByUser',
  createdAt: 'CreatedAt',
  value: 'Value',
  moduleCommentID: 'ModuleCommentID',
  moduleItemID: 'ModuleItemID',
  moduleSectionID: 'ModuleSectionID',
  componentId: 'component_id',
  notificationId: 'notification_id',
  defectId: 'defect_id',
  workOrderId: 'workorder_id',
  isolationCertificateId: 'isolation_certificate_id',
  permitId: 'permit_id',
  dropdownType: 'DropdownType',
  type: 'Type',
  tags: 'Tags',
  taggedEntityID: 'TaggedEntityID',
  taggedEntityName: 'TaggedEntityName',
  measurementLocationId: 'measurement_location_id',
  measurementPointId: 'measurement_point_id',
};

export const commentActionTypes = {
  edit: 'editComment',
  delete: 'deleteComment',
};

export const commentTagTypes = {
  user: 'USER',
  team: 'TEAM',
  users: 'Users',
  teams: 'Teams',
};

export const submitButtonName = 'CommentButton';
