import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { placements } from '../../../../constants/constants';

import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { getFormSyncErrors, getFormValues } from 'redux-form';
import { DIMENSIONS, FORMS } from '../../../../../../common/constants';
import TimeSeriesGraph from '../../../../../../common/graphs/components/time-series-graph';
import Helpers from '../../../../../../common/helpers';
import CollapsibleToolbar from '../../../collapsible-toolbar';
import { formConstants as mpFormConstants } from '../../constants/measurement-point-constants';
import { aggregationFields, chunkFields, formConstants, settingsFormConstants, timePeriodFields } from '../../constants/time-series-graph-constants';
import '../../styles/time-series-graph-configuration.scss';
import { TIMESERIES_GRAPH_TYPE } from '../graphing-groups/graphing-groups-list';
import TimeSeriesGraphSettings from './time-series-graph-settings';

const TimeSeriesGraphConfiguration = ({
  queryItem,
  getGraphData,
  showGraphingGroup,
  formValues,
  formErrors,
  canSave,
  saveSettings,
  getMeasurementPointGraphDetails,
  graphingGroupDetails,
  unit = '-',
  isTimeSeriesGraph = true,
}) => {
  const [rightCollapsed, setRightCollapsed] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [graphLoading, setGraphLoading] = useState(false);
  const [appliedGraphSettings, setAppliedGraphSettings] = useState({});

  const fetchGraphData = settingsValues => {
    if (isEmpty(settingsValues)) {
      return;
    }

    setGraphLoading(true);
    let formatedGraphSettings = {
      ...settingsValues,
      [settingsFormConstants.graphingGroup.name]: settingsValues[settingsFormConstants.graphingGroup.name]?.[formConstants.id],
      [settingsFormConstants.aggregation.name]: (settingsValues[settingsFormConstants.aggregation.name] || []).map(el => el[aggregationFields.key]),
      [settingsFormConstants.chunks.name]: settingsValues[settingsFormConstants.chunks.name]?.[chunkFields.key],
      [settingsFormConstants.timePeriod.name]: settingsValues[settingsFormConstants.timePeriod.name]?.[timePeriodFields.value],
    };

    if (isTimeSeriesGraph || formValues?.[settingsFormConstants.graphType.name] === TIMESERIES_GRAPH_TYPE) {
      formatedGraphSettings = {
        ...formatedGraphSettings,
        [settingsFormConstants.dateFrom.name]: Helpers.isDateInUnix(settingsValues[settingsFormConstants.dateFrom.name])
          ? settingsValues[settingsFormConstants.dateFrom.name]
          : Helpers.dateToUnix(settingsValues[settingsFormConstants.dateFrom.name]),
        [settingsFormConstants.dateTo.name]: Helpers.isDateInUnix(settingsValues[settingsFormConstants.dateTo.name])
          ? settingsValues[settingsFormConstants.dateTo.name]
          : Helpers.dateToUnix(settingsValues[settingsFormConstants.dateTo.name]),
      };
    } else {
      const dateRanges =
        settingsValues?.DefaultGraphID?.[settingsFormConstants.dateRanges.name] ||
        settingsValues?.[settingsFormConstants.dateRanges.name] ||
        graphingGroupDetails?.[settingsFormConstants.dateRanges.name] ||
        graphingGroupDetails?.DefaultGraphID?.[settingsFormConstants.dateRanges.name];
      formatedGraphSettings = {
        ...formatedGraphSettings,
        [settingsFormConstants.dateRanges.name]: dateRanges,
      };
    }

    // console.log('formatedGraphSettings 2', formatedGraphSettings);
    getGraphData(
      formatedGraphSettings?.[formConstants.graphType],
      formatedGraphSettings,
      graphData => {
        if (formatedGraphSettings?.[formConstants.graphType] === TIMESERIES_GRAPH_TYPE || isTimeSeriesGraph) {
          // Process time series data
          const mergedData = (graphData || []).map(data => ({
            ...data,
            Series: Helpers.mergeSeriesData(data.Series, data[mpFormConstants.fields.scaleFactor] || undefined, data[mpFormConstants.fields.inverted]),
          }));
          const updatedGraphData = mergedData?.map(mp => {
            const matchedMeasurementPoint = graphingGroupDetails?.MeasurementPoints?.find(point => point[mpFormConstants.fields.id] === mp[mpFormConstants.fields.measurementPointId]);
            if (matchedMeasurementPoint) {
              return {
                ...mp,
                [mpFormConstants.fields.unit]: matchedMeasurementPoint[mpFormConstants.fields.unit] || '-',
              };
            }
            return mp;
          });
          setGraphData(updatedGraphData);
        } else {
          // Process analysis graph data - no need for Helpers.mergeSeriesData
          // Just ensure we have the measurement point name and unit
          const processedData = (graphData || []).map(mp => {
            // Find the corresponding measurement point in graphingGroupDetails
            const matchedMeasurementPoint = graphingGroupDetails?.MeasurementPoints?.find(point => point[mpFormConstants.fields.id] === mp.MeasurementPointID);

            return {
              ...mp,
              MeasurementPointName: matchedMeasurementPoint?.[mpFormConstants.fields.name] || `MP ${mp.MeasurementPointID}`,
              [mpFormConstants.fields.unit]: matchedMeasurementPoint?.[mpFormConstants.fields.unit] || '-',
            };
          });

          setGraphData(processedData);
        }

        setAppliedGraphSettings(settingsValues);
        setGraphLoading(false);
      },
      () => {
        setGraphData([]);
        setGraphLoading(false);
      }
    );
  };

  const isTimeSeriesGraphCheck =
    formValues?.[settingsFormConstants.graphType.name] === TIMESERIES_GRAPH_TYPE ||
    formValues?.DefaultGraphID?.[settingsFormConstants.graphType.name] === TIMESERIES_GRAPH_TYPE ||
    graphingGroupDetails?.DefaultGraphID?.[settingsFormConstants.graphType.name] === TIMESERIES_GRAPH_TYPE ||
    isTimeSeriesGraph;

  return (
    <div className="time-series-graph-configuration">
      <div className={`time-series-graph-configuration__graph-wrapper ${rightCollapsed ? '' : 'with-right-padding'}`}>
        <TimeSeriesGraph
          data={graphData}
          isLoading={graphLoading}
          showAlarms={formValues?.[settingsFormConstants.showAlarms.name]}
          visibleAggregations={appliedGraphSettings?.[settingsFormConstants.aggregation.name] || []}
          ignoreTheme
          unit={unit}
          isTimeSeriesGraph={isTimeSeriesGraphCheck}
        />
      </div>
      <CollapsibleToolbar
        collapsed={rightCollapsed}
        toggleToolbar={() => setRightCollapsed(!rightCollapsed)}
        placement={placements.right}
        resizable={false}
        defaultWidth={DIMENSIONS.mlTimeSeriesGraphRightToolbar}
      >
        <TimeSeriesGraphSettings
          queryItem={queryItem}
          fetchGraphData={fetchGraphData}
          showGraphingGroup={showGraphingGroup}
          formValues={formValues}
          canSave={canSave}
          saveSettings={saveSettings}
          getMeasurementPointGraphDetails={getMeasurementPointGraphDetails}
          isTimeSeriesGraph={isTimeSeriesGraphCheck}
          formErrors={formErrors}
        />
      </CollapsibleToolbar>
    </div>
  );
};

TimeSeriesGraphConfiguration.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  formValues: getFormValues(FORMS.timeSeriesGraphSettingsForm)(state),
  formErrors: getFormSyncErrors(FORMS.timeSeriesGraphSettingsForm)(state),
});

export default connect(mapStateToProps, null)(TimeSeriesGraphConfiguration);
